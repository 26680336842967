import openSocket from "socket.io-client";

import { ERRORS_GET, SCRIP_DATA_UPDATES, PORTFOLIO_UPDATES } from "./types";
import { getScrips } from "./scripsActions";
import { getOrders } from "./orderActions";
import { getCurrentUserDetails } from "./userActions";
import { getPortfolios } from "./portfolioActions";
import {
  getNotifications,
  setNotificationMessage
} from "./notificationActions";

import store from "../store";

export const marketUpdates = res => ({
  type: SCRIP_DATA_UPDATES,
  payload: res
});

export const portfolioUpdates = res => ({
  type: PORTFOLIO_UPDATES,
  payload: res
});

export const loadInitialDataSocket = token => dispatch => {
  const socket = openSocket(window.BASE_URL);
  socket.emit("setUid", { token: token });

  socket.on("market", res => {
    dispatch(marketUpdates(res.data));
  });

  socket.on("pupdate", res => {
    dispatch(portfolioUpdates(res));
    dispatch(getScrips(store.getState().scrips.scrips_portfolio, true, false));
    dispatch(getOrders());
    dispatch(getCurrentUserDetails());
    dispatch(getPortfolios(store.getState().portfolio, true));
    dispatch(getNotifications(5));
    if (res.msg) dispatch(setNotificationMessage(res.msg));
  });
};
