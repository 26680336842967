import React from "react";
import {
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import { Link } from "react-router-dom";
import { formatCurrency } from "./../../utils/format";
export default class ReturnMeter extends React.Component {
  constructor(props) {
    super(props);
  }

  getRow = c => {
    let diff = c.Scrip.market_price - c.Scrip.prev_close;
    var market_update = this.props.market_update[ c.Scrip.srCode]
      ? this.props.market_update[ c.Scrip.srCode]
      : c.Scrip.market_price;
    const pDiff = (market_update - c.Scrip.prev_close);


    return (
      <tr
        key={c.id}
        style={{ backgroundColor: diff > 0 ? "#008700" : "red", color: "#fff" }}
      >
        <td width="50%">{c.Scrip.company}</td>
        <td>
        {market_update.toFixed(2)} {pDiff < 0 ? "\u25BC" : pDiff > 0 ? "\u25B2" : ""} {pDiff.toFixed(2)}
         ( {((pDiff / c.Scrip.prev_close) * 100).toFixed(2)}% )
        
        </td>
      </tr>
    );
  };
  render() {
    return (
      <div
        style={{
          padding: "20px"
        }}
      >
        <div className="darkbg"><b>WATCHLIST</b></div>
        <div className="darkbg">
          <select
            onChange={e => this.props.getWatch(e.target.value)}
            className="form-control typ1"
          >
            <option>LARGE CAP</option>
            <option>MID CAP</option>
            <option>SMALL CAP</option>
          </select>
        </div>
        <table
          className="table table-hover"
          style={{
            width: "100%"
          }}
        >
          <tbody>{this.props.watchList.map(c => this.getRow(c))}</tbody>
        </table>
      </div>
    );
  }
}
