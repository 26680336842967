import React from "react";
import { Button } from "reactstrap";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import { saveResearch } from "../../../actions/newresearchActions";
class SectorProject1 extends React.Component {
  state = {
    selectedFile: null,
    submitted: false
  };
  onChangeHandler = e => {
    this.setState({
      selectedFile: e.target.files[0],
      filename: e.target.files[0].name,
      loaded: 0
    });
  };

  triggerInputFile = () => this.fileInput.click();

  handleSubmit = e => {
    e.preventDefault();
    if (this.state.selectedFile) {
      const data = new FormData();
      data.append("selectedFile", this.state.selectedFile);
      data.append("week", 4);
      this.props.saveResearch(data);
      this.setState({ submitted: true });
    } else {
      this.props.showInvalidFormAlert();
    }
  };
  render() {
    console.log(this.props.research[3], "myresearch");
    if (this.state.submitted || this.props.research[4]) {
      if (!this.props.research[4]) {
        return <div>Please Wait...</div>;
      }
      return (
        <div>
          <div>
            Download:{" "}
            <a
              download
              className="text-primary"
              href={"public/uploads/" + this.props.research[4].filename}
            >
              {this.props.research[4].originalname}
            </a>
          </div>
          <div>
            <div className="submitted text-center">
              <Button className="btn btn-light ">Submitted </Button>
            </div>
          </div>
        </div>
      );
    }
    return (
      <form
        onSubmit={this.handleSubmit}
        method="post"
        encType="multipart/form-data"
      >
        <div className="text-justify">
          <p>
            <div className="row">
              <div className="col-md-12">
                {" "}
                <h4>Sector Project 1:{" "}{this.props.research[2].sector1} </h4>
              </div>
            </div>

            <br></br>
            <font size="4">
              As part of this sector project, you have to analyse your first
              sector pick based on five
              parameters.These parameters were defined by Michael Porter, a
              Professor of
              Strategy at Harvard Business School. He stated that a company’s
              profitability in a 
              sector/industry is determined by five-industry level forces, which
              include:
            </font>
          </p>
          <div style={{ marginTop: "-3px", marginLeft: "10px" }}>
            <ul
              className="spacing"
              style={{ marginTop: "-3px", marginLeft: "10px" }}
            >
              <li>
                <font size="4">Bargaining Power of Buyers</font>
              </li>
              <li>
                <font size="4"> Bargaining Power of Suppliers</font>
              </li>
              <li>
                <font size="4">Rivalry among existing competitors</font>
              </li>
              <li>
                <font size="4">Threat of substitute products</font>
              </li>
              <li>
                <font size="4"> Threat of new entrants</font>
              </li>
            </ul>
          </div>
          <p style ={{marginTop:"-2px"}}>
          <font size="4">
          More details on these five forces can be found in the document uploaded below.
          Kindly go over it in case you need any clarity on the same
          </font>  
          </p>
          <div>
          <font size="4">
            Download:{" "}
            <a
              download
              className="text-primary"
              href={"/uploads/refrenceresearch.pdf"}
            >
              {"Porter’s Five Forces Analysis.pdf"}
            </a>
            </font>
          </div>
          <p>

            <p style ={{marginTop:"8px"}}>
            <font size="4">
              Based on these factors, please prepare a report on your first sector pick covering the
              abovementioned five forces. You can think as an entrepreneur looking to setup a
              business in this industry in order to finish this analysis</font>
              
            </p>
            <p style ={{marginTop:"8px"}}>
            <font size="4">
              Also, please be original in content. It’s your own project at the end of the day so by
              using methods like copy and pasting from the internet, you’re broadly cheating
              yourself</font>
              
            </p>
          </p>

          <div className="mt-3">
            <input
              hidden
              ref={fileInput => (this.fileInput = fileInput)}
              type="file"
              name="selectedFile"
              onChange={this.onChangeHandler}
            />
          </div>

          <div className="text-center btn-upload">
            <Button
              onClick={this.triggerInputFile}
              type="button"
              className="btn btn-light border border-dark d-inline-block"
            >
              Browse...
            </Button>
            <h4 className="pl-3 d-inline-block">{this.state.filename}</h4>
          </div>
          <div className="text-center">
            <Button
              className="btn btn-padding btn-secondary mt-4"
              type="submit"
            >
              Submit
            </Button>
          </div>
        </div>
      </form>
    );
  }
}

SectorProject1.propstype = {
  saveResearch: PropTypes.func.isRequired
};
const mapStateToProps = state => {
  return {
    research: state.newresearch.data
  };
};
export default connect(
  mapStateToProps,
  { saveResearch }
)(SectorProject1);
