import axios from "axios";

import { NOTIFICATION_LIST,NOTIFICATION_MARK_READ,ERRORS_GET,NOTIFICATION_MSG } from "./types";

export const getNotifications = limit => dispatch => {
  axios
    .get(`/api/notifications/list/${limit}`)
    .then(res => {
      dispatch({
        type: NOTIFICATION_LIST,
        payload: { limit: limit, data: res.data }
      });
    })
    .catch(err =>
      dispatch({
        type: ERRORS_GET
      })
    );
};

export const setNotificationMessage = (msg) => dispatch => {
    dispatch({
      type: NOTIFICATION_MSG,
      payload: msg
    });
}

export const markRead = (count) => dispatch => {
  axios
    .get(`/api/notifications/mark-read/${count}`)
    .then(res => {
      dispatch({
        type: NOTIFICATION_MARK_READ,
        payload: {count: res.data.count}
      });
    })
    .catch(err =>
      dispatch({
        type: ERRORS_GET
      })
    );
};
