import React from "react";
import { Table } from "reactstrap";
class ValuationRatios extends React.Component {
  render() {
    return (
      <div className="container resource">
        <div>
          <h4 className="text-center font-weight-bold mt-5 mb-4">
            MODULE 4 - RATIO ANALYSIS
          </h4>
          <hr />
        </div>
        <div className="mt-5">
          <h5 className="text-center mb-4">
            <u>
              <b>VALUATION RATIOS</b>
            </u>
          </h5>
        </div>
        <div>
          <p>
            The valuation ratios are the most commonly used ratios by investors
            for estimating the attractiveness of a stock. These ratios help in
            determining the market valuation of stocks by taking into account
            company fundamentals such as its earnings, dividends, book value,
            etc. Some of the valuation ratios are explained below:
          </p>
        </div>
        <ol>
          <li>Earnings Per Share (EPS)</li>
          <p>
            The earnings per share ratio gauges the profitability of a company
            and the proportion of revenue allocated to each share. It reflects
            the total amount of money earned by each individual share. The ratio
            is determined by two aspects - the total number of outstanding
            shares and the net income of the company. To calculate the earnings
            per share ratio, the net income of a company is divided by its
            outstanding shares. The net income refers to the profits earned by
            the company.
          </p>
          <p>The formula for calculating earnings per share is as follows:</p>
          <div className="g_boxing">
            <p className="text-center">
              EPS Ratio = Net worth of the company/Total number of outstanding
              shares
            </p>
          </div>
          <p>
            When a company buys back its shares, it reduces the number of shares
            with the public, therefore now the profits of the company will be
            distributed amongst lesser shareholders and each share will earn
            more money than it earned before the company made a buyback. This
            leads to an increase in the earnings per share ratio of a company,
            so a company can also undertake a buyback for improving its EPS.
            <span>
              A higher EPS ratio is considered to be a sign of profitable
              investment and therefore higher EPS ratios are preferred by
              investors. EPS is a common figure on which most investors rely to
              decide whether or not to invest in a company. A consistent or a
              growing EPS over a number of years is favourable. EPS also
              determines the market price of the company’s share.
            </span>
          </p>
          <p>
            For instance, if a company ABC Ltd has reported a net income of ₹50
            lac for the previous financial year and it has 5 lac outstanding
            shares, then the EPS ratio of ABC Ltd will be calculated by dividing
            50,00,000 by 5,00,000. The EPS ratio for ABC Ltd turns out to be
            ₹10, which means that if ABC Ltd distributes all of its profits
            amongst its shareholders, each share will receive ₹10 as its
            dividend amount.
          </p>
          <li>Price to Earnings Ratio (P/E Ratio)</li>
          <p>
            Price to earnings ratio depicts the relationship between the
            company’s market share price and its earnings per share. It
            expresses the amount which investors are willing to pay for every
            rupee earned by the company.
            <span>
              It is calculated by dividing the market share price of the company
              by its earnings per share.
            </span>
          </p>
          <p>The formula can be given as follows:</p>
          <div className="g_boxing">
            <p className="text-center">
              Price to Earnings Ratio = Market share price/Earnings per share
            </p>
          </div>
          <p>
            We will continue with our example covered in the explanation of the
            EPS ratio. The company ABC Ltd had an earnings per share ratio of
            ₹10, now suppose the current market share price of ABC Ltd is ₹50,
            then the P/E ratio will be found out by dividing 50 by 10. It is
            observed that ABC Ltd has a P/E ratio of ₹5 which means that
            investors are willing to invest ₹5 for every rupee of earnings.
            <span>
              The P/E ratio is also called a multiple of per rupee of earnings.
              The P/E ratio of ABC Ltd can be referred to as a multiple of 5x,
              which means that the stock of ABC Ltd is trading 5 times its
              earnings.
            </span>
            <span>
              The P/E ratio along with the EPS ratio is the most relied upon
              figures by investors. P/E ratios are used to compare competitors
              of a company or to evaluate whether the stock is undervalued or
              overvalued.
            </span>
          </p>
          <li>Dividend Per Share Ratio (DPS)</li>
          <p>
            This ratio reflects the dividend amount declared by the issuing
            company on a per share basis. It is simply the dividend which the
            issuing company is willing to pay to its shareholders out of its
            declared profits. The dividend is usually declared as a percentage
            of the face value. If a company declares a 20% dividend and if the
            face value of its share is ₹40, then the dividend amount will
            translate into ₹8 per share.
            <span>
              However, the Securities Exchange Board of India (SEBI) has changed
              the regulations regarding the declaration of dividends by
              companies and at present, companies have to directly declare the
              dividend amount, in this case - ₹8 per share, instead of declaring
              the dividend as a percentage of the face value of a share.
            </span>
            <span>
              The DPS Ratio is computed by dividing total dividends paid by the
              number of outstanding shares.
            </span>
          </p>
          <p>
            The formula for obtaining the dividend per share ratio is as
            follows:
          </p>
          <div className="g_boxing">
            <p className="text-center">
              DPS Ratio = Total dividends paid/Number of outstanding shares
            </p>
          </div>
          <p>
            Note that there is a basic difference between the concepts of
            earnings per share ratio and dividend per share ratio. The EPS ratio
            depicts the number of profits earned by each share whereas the DPS
            ratio depicts the dividend paid out of the profits earned to each
            share. There can be a situation in which a company’s EPS ratio might
            be higher than the DPS ratio. Under this situation, there exists a
            possibility that even though a company might have earned substantial
            profits and therefore each share will be allotted a higher sum, in
            practice, the company might decide against spending its total
            profits earned on its shareholders and decide to keep a part of its
            profits for its future operations. This will result in the company
            declaring a lesser amount of dividends per share even though the
            earnings per share will be higher because of a rise in the company’s
            profits.
          </p>
          <li>Price to Sales Ratio</li>
          <p>
            The price to sales ratio also depicts what the market is willing to
            pay for every rupee earned by the company. The difference between
            the price to earnings ratio and price to sales ratio lies in the
            denominator used in the calculation of both these values.
            <span>
              The price to sales ratio expresses the relation between the market
              price of share with the sales revenue generated by the firm
              whereas the price to earnings ratio expresses the relation between
              the market price of share with the earnings (profits) generated by
              the firm.
            </span>
            <span>
              For instance, let us consider that there are two companies - ABC
              Ltd and XYZ Ltd.
            </span>
          </p>
          <Table>
            <thead>
              <tr>
                <th />
                <th>ABC Ltd</th>
                <th>XYZ Ltd</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Sales Revenue</td>
                <td>Rs 100 crores</td>
                <td>Rs 50 crores</td>
              </tr>
              <tr>
                <td>Profits (Earnings)</td>
                <td>Rs 20 crores</td>
                <td>Rs 20 crores</td>
              </tr>
            </tbody>
          </Table>
          <p>
            It can be seen from the above table that both the companies have
            made a profit of Rs 20 crores, however, the sales revenue generated
            by ABC Ltd is twice than that generated by XYZ Ltd. Assuming that
            the market prices of the stocks of both companies do not change,
            this will lead to a similar price to earnings ratio for both of the
            companies but the price to sales ratio will be greater for XYZ Ltd
            than ABC Ltd because at lower levels of sales XYZ Ltd manages to
            earn an equivalent amount of profit earned by ABC Ltd.
          </p>
          <li>Price to Book Value Ratio (P/BV)</li>
          <p>
            Price to book value ratio is used to compare the market price per
            share of a company with its book value per share. We know that the
            book value per share is calculated by dividing the net worth of the
            company by its outstanding shares and the market price of shares can
            be found out by observing the stock markets.
          </p>
          <p>
            The price to book value ratio is computed by the current market
            price of a share by the book value of a share.
          </p>
          <div className="g_boxing">
            <p className="text-center">
              Price to Book Value Ratio = Current market price of a share/Book
              value of a share.
            </p>
          </div>
          <p>
            The price to book value ratio is used for assessing the market value
            attached to a company’s equity with the accounting value of its
            shares derived from its financial statements. The market price of a
            share depends on the anticipated earnings of the company by the
            investors whereas the book value of a share is calculated on the
            basis of its historical earnings, share buybacks, and some other
            factors.
          </p>
          <p>
            Similar to the price-earnings ratio, a price to book value ratio
            cannot be evaluated on the same basis for different industries, it
            might be good to have a higher price to book ratio for one industry
            whereas it might be good to have a lower price to book ratio for
            another industry. Therefore, it is more appropriate to use price to
            book value ratio for comparing companies belonging to the same
            industry or sector.
          </p>
          <p>
            For instance, let us compute a P/BV ratio for a company XY Ltd:
            <br />
            The current market price of share = ₹40
            <br />
            Assets = ₹50 lac
            <br />
            Liabilities = ₹10 lac
            <br />
            Number of outstanding shares = ₹3 lac
          </p>
          <p>
            The book value of shares of XY Ltd = Net worth of XY Ltd / Number of
            outstanding shares
          </p>
          <p>
            As we know, the net worth of a company is calculated by using the
            formula :
          </p>
          <p>
            Net Worth of XY= Assets - Liabilities
            <span className="ml-a">= 50,00,000 - 10,00,00</span>
            <span className="ml-a"> = 40,00,000</span>
          </p>
          <p>
            Book value of shares of XY Ltd = 40,00,000/ 2,00,000
            <span className="ml-b">= 20</span>
          </p>
          <p>
            Price to Book Value Ratio = Current market price of shares / Book
            value of shares
            <span className="ml-c">= 40/20</span>
            <span className="ml-c">= 2</span>
          </p>
          <p>
            Hence, it can be deduced that the market price of shares of company
            XY Ltd is twice its book value per share.
            <br />Generally, a price to book value ratio of less than 1
            indicates that the stock is trading below its book value and might
            be undervalued. However, it is not necessary that a stock trading at
            a discount might be undervalued and hence a good stock to invest in.
            It can be that the company is involved in some controversy or the
            company might have made some unprofitable investments and therefore,
            its market price per share might be less than its book value per
            share.
          </p>
        </ol>
      </div>
    );
  }
}

export default ValuationRatios;
