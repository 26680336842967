import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { searchCompany, clearCompanySearch } from "../../actions/scripsActions";

class SearchBox extends React.Component {
  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
    this.state = {
      showSearch: false
    };
  }

  setCompany = (id, company) => {
    this.props.history.push("/company", { company: company, id: id });
    this.props.clearCompanySearch();
    this.setState({ showSearch: false });
  };
  suggest = e => {
    let search = e.target.value.trim();
    if (search != "") {
      this.props.searchCompany(search);
    } else {
      this.props.clearCompanySearch();
    }
  };
  toggleSearch = () => {
    if (this.state.showSearch) {
      this.props.clearCompanySearch();
    }
    this.setState({ showSearch: !this.state.showSearch });
  };
  getCompanyStr = company => {
    var re = new RegExp(`(${this.props.search.keyword})`, "gi");
    return (
      <span
        dangerouslySetInnerHTML={{
          __html: company.replace(re, "<strong>$1</strong>")
        }}
      />
    );
  };

  componentDidMount = () => {
    document.addEventListener("mousedown", this.handleClickOutside);
  };

  componentWillUnmount = () => {
    document.removeEventListener("mousedown", this.handleClickOutside);
  };

  handleClickOutside = event => {
    if (
      this.wrapperRef.current &&
      !this.wrapperRef.current.contains(event.target) &&
      this.props.search.scrips.length > 0
    ) {
      this.props.clearCompanySearch();
    }
  };

  render() {
    const { scrips } = this.props.search;
    return (
      <div
        ref={this.wrapperRef}
        className="pull-left search-holder"
        style={{
          position: "relative",
          zIndex: 11,
          marginBottom: "5px",
          paddingTop: "25px",
          paddingLeft: "65px"
        }}
      >
        <div
          style={{
            position: "absolute",
            width: this.state.showSearch ? "400px" : "60px",
            top: "0px"
          }}
        >
          <input
            onChange={this.suggest}
            type="text"
            placeholder="SEARCH FOR COMPANIES, NEWS"
            className={
              "form-control top-search-input " +
              (this.state.showSearch ? "show-in" : "hide-in")
            }
          />
          <span
            onClick={this.toggleSearch}
            className="fa fa-search top-search-btn"
          />

          <div id="myInputautocomplete-list" className="autocomplete-items">
            {scrips.length > 0
              ? scrips.map(c => {
                  return (
                    <div
                      key={c.id}
                      onClick={() => this.setCompany(c.id, c.company)}
                    >
                      {this.getCompanyStr(c.company)} (
                      {this.getCompanyStr(c.shortCode)})
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      </div>
    );
  }
}

SearchBox.propTypes = {
  searchCompany: PropTypes.func.isRequired,
  clearCompanySearch: PropTypes.func.isRequired,
  search: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  search: state.scrips.search,
  errors: state.errors
});

export default withRouter(
  connect(
    mapStateToProps,
    { searchCompany, clearCompanySearch }
  )(SearchBox)
);
