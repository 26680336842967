import { RISK_PROFILE_SAVE,RISK_PROFILE_GET } from "../actions/types";

const initialState = { data: {} };

export default function(state = initialState, action) {
  switch (action.type) {
    case RISK_PROFILE_GET:
    case RISK_PROFILE_SAVE:
      return { data: action.payload };
    default:
      return state;
  }
}
