import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  editProfileData,
  deleteData
} from "../../actions/profileDetailsActions";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Form,
  Input,
  Card
} from "reactstrap";

class Edu extends React.Component {
  state = {
    show: false,
    showList: true,
    suggestions: [],
    index: 0,
    add: false,
    modal: false,
    hQua: "",
    modal_trash: false,
    courses: [],
    course: "",
    spec: "",
    otherUni: "",
    specializations: [],
    university: "",
    college: "",
    poy: "",
    educationData: [
      {
        hQua: "",
        course: "",
        s: "",
        university: "",
        college: "",
        poy: "",
        otherUni: ""
      }
    ]
  };

  handleSubmitEdu = e => {
    e.preventDefault();
    this.state.educationData[0].hQua = this.state.hQua;
    this.state.educationData[0].course = this.state.course;
    this.state.educationData[0].s = this.state.spec;
    this.state.educationData[0].university = this.state.university;
    this.state.educationData[0].otherUni = this.state.otherUni;
    this.state.educationData[0].college = this.state.college;
    this.state.educationData[0].poy = this.state.poy;
    if (this.state.add == false) {
      this.props.editProfileData(
        ...this.state.educationData,
        this.state.index,
        "education"
      );
    } else {
      this.props.editProfileData(
        ...this.state.educationData,
        this.props.data.educationData
          ? this.props.data.educationData.length
          : 0,
        "education"
      );
    }
  };
  handleDelete = e => {
    e.preventDefault();
    this.props.deleteData(this.state.index, "education");
  };
  add(cond) {
    this.setState({
      add: cond
    });
    if (cond == true) {
      this.setState({
        hQua: "",
        college: "",
        course: "",
        spec: "",
        poy: "",
        university: "",
        otherUni: ""
      });
    }
  }
  toggle = () => {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  };

  toggle_trash = () => {
    this.setState(prevState => ({
      modal_trash: !prevState.modal_trash
    }));
  };
  modalData = data => {
    if (this.state.add == false) {
      this.setState({
        hQua: data.hQua,
        college: data.college,
        course: data.course,
        spec: data.s,
        poy: data.poy,
        university: data.university,
        otherUni: data.otherUni
      });
    }

    let courseId = this.props.educationDetails
      .filter(o => o.option == data.hQua)
      .map(o => o.id);

    let courses = this.props.educationDetails.filter(
      o => o.question_id == courseId[0] + 1
    );

    this.setState({
      courses: [...courses]
    });

    // let specializationId = this.props.educationDetails
    //   .filter(o => o.option == data.course)
    //   .map(o => o.id);

    // let specializations = this.props.educationDetails.filter(
    //   o => o.question_id == specializationId[0] + 1
    // );
    // this.setState({
    //   specializations: [...specializations]
    // });
  };
  courses = data => {
    let courseId = data.filter(o => o.option == this.state.hQua).map(o => o.id);

    let courses = data.filter(o => o.question_id == courseId[0] + 1);

    this.setState({
      courses: [...courses]
    });
  };

  // specializations = data => {
  //   let specializationId = data
  //     .filter(o => o.option == this.state.course)
  //     .map(o => o.id);

  //   let specializations = data.filter(
  //     o => o.question_id == specializationId[0] + 1
  //   );
  //   this.setState({
  //     specializations: [...specializations]
  //   });
  // };

  suggestionSelected(value) {
    this.setState(() => ({
      showList: false,
      college: value
    }));
  }
  renderSuggestions() {
    if (this.props.clgList.length < 1) {
      return null;
    } else {
      return (
        <div>
          {this.state.showList ? (
            <ul
              id="ul"
              className="autocomplete-items-clg "
              style={{ width: "100%" }}
            >
              {this.props.clgList.map((clg, i) => (
                <li
                  key={i}
                  onClick={() => this.suggestionSelected(clg.College_Name)}
                >
                  {clg.College_Name}
                </li>
              ))}
            </ul>
          ) : null}
        </div>
      );
    }
  }
  checkInput = value => {
    this.setState({
      showList: true,
      college: value
    });
    if (value == "") {
      this.setState({
        showList: false
      });
    }
  };
  otherUniversity = value => {
    this.setState({
      university: value,
      otherUni: ""
    });
  };
  render() {
   
    return (
      <div className="container ">
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}
        >
          <div className="editProfile">
            <Form onSubmit={this.handleSubmitEdu}>
              <ModalHeader toggle={this.toggle}>Education</ModalHeader>

              <ModalBody>
                <div>
                  <FormGroup>
                    <Label for="Qualification" className="required_mark">
                      Qualification
                    </Label>
                    <select
                      required
                      type="hQualification"
                      name="hQualification"
                      id="hQualification"
                      onChange={e => {
                        this.state.educationData[0].hQua = e.target.value;
                        this.state.hQua = e.target.value;
                        this.state.course = "";
                        this.state.spec = "";
                        this.courses(this.props.educationDetails);
                      }}
                    >
                      <option hidden>{this.state.hQua}</option>

                      {this.props.educationDetails
                        .filter(p => p.question_id == 1)
                        .map(o => (
                          <option key={o.id} value={o.option}>
                            {o.option}
                          </option>
                        ))}
                    </select>
                  </FormGroup>

                  <FormGroup>
                    <Label for="course" className="required_mark">
                      Course
                    </Label>
                    <select
                      required
                      type="course"
                      name="course"
                      id="course"
                      onChange={async e => {
                        this.state.educationData[0].course = e.target.value;
                        await this.setState({
                          course: e.target.value,
                          spec: ""
                        });
                      }}
                    >
                      <option hidden>{this.state.course}</option>
                      {this.state.courses.map(o => (
                        <option key={o.id} value={o.option}>
                          {o.option}
                        </option>
                      ))}
                    </select>
                  </FormGroup>
                  <FormGroup>
                    <Label for="specialization">Specialization</Label>
                    <Input
                      type="specialization"
                      name="specialization"
                      id="specialization"
                      value={this.state.spec}
                      onChange={async e => {
                        this.state.educationData[0].s = e.target.value;
                        await this.setState({
                          spec: e.target.value
                        });
                      }}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label for="university">University</Label>
                    <select
                      type="university"
                      name="university"
                      id="university"
                      onChange={e => {
                        this.state.educationData[0].university = e.target.value;
                        this.otherUniversity(e.target.value);
                      }}
                    >
                      <option hidden>{this.state.university}</option>
                      {this.props.university.map(o => (
                        <option key={o.id} value={o.name}>
                          {o.name}
                        </option>
                      ))}
                    </select>
                  </FormGroup>
                  {this.state.university == "Other" ? (
                    <FormGroup>
                      <Label for="university">University</Label>
                      <Input
                        type="other_uni"
                        name="other_uni"
                        id="other_uni"
                        defaultValue={this.state.otherUni}
                        onChange={e => {
                          this.state.educationData[0].otherUni = e.target.value;
                          this.state.otherUni = e.target.value;
                        }}
                      ></Input>
                    </FormGroup>
                  ) : null}
                  <FormGroup>
                    <Label for="college" className="required_mark">
                      College
                    </Label>
                    <div>
                      <Input
                        type="text"
                        autoComplete="off"
                        name="college"
                        id="college"
                        value={this.state.college}
                        onChange={e => {
                          this.props.searchClg(e.target.value);
                          this.state.educationData[0].college = e.target.value;
                          this.checkInput(e.target.value);
                        }}
                      />
                      {this.renderSuggestions()}
                    </div>
                  </FormGroup>

                  <FormGroup className="d-inline-block">
                    <Label for="poy" className="required_mark">
                      Passing Out Year
                    </Label>
                    <select
                      type="poy"
                      name="poy"
                      id="poy"
                      onChange={async e => {
                        this.state.educationData[0].poy = e.target.value;
                        await this.setState({
                          poy: e.target.value
                        });
                      }}
                      style={{ width: "100%" }}
                    >
                      <option> {this.state.poy}</option>
                      {this.props.educationDetails
                        .filter(o => o.question_id == 52)
                        .map(o => (
                          <option key={o.id} value={o.option}>
                            {o.option}
                          </option>
                        ))}
                    </select>
                  </FormGroup>
                </div>
              </ModalBody>
              {this.state.hQua != "" &&
              this.state.course != "" &&
              this.state.college != "" &&
              this.state.poy != "" ? (
                <ModalFooter>
                  <Button
                    className="btn btn-3"
                    type="submit"
                    onClick={() => {
                      this.toggle();
                    }}
                  >
                    Save
                  </Button>
                  <Button color="secondary" onClick={this.toggle}>
                    Cancel
                  </Button>
                </ModalFooter>
              ) : null}
            </Form>
          </div>
        </Modal>

        <Modal isOpen={this.state.modal_trash} toggle={this.toggle_trash}>
          <Form onSubmit={this.handleDelete}>
            <ModalHeader toggle={this.toggle_trash}>
              <i
                className=" fa fa-trash d-inline-block profile_icon pr-3"
                aria-hidden="true"
              ></i>
              <span className="d-inline-block"> Confirm Delete ?</span>
            </ModalHeader>
            <ModalBody>
              <span>
                Are you sure that you want to permanently delete selected
                element ?
              </span>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" type="submit" onClick={this.toggle_trash}>
                Yes
              </Button>
              <Button color="secondary" onClick={this.toggle_trash}>
                No
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
        <Card className="mt-5">
          <div>
            <span
              className="d-inline-block  pl-4 pt-2 pb-4"
              style={{ fontSize: "23px" }}
            >
              Education
            </span>
            <div className="d-inline-block float-right">
              <i
                className="fa fa-plus pr-4 pt-3 profile_icon"
                aria-hidden="true"
                onClick={async () => {
                  await this.add(true);
                  this.toggle();
                }}
              ></i>
            </div>
          </div>
          {this.props.data.educationData ? (
            <div>
              {this.props.data.educationData.map((e, i) => (
                <div key={i} className="mb-4">
                  <i
                    className="fa fa-university pl-4 float-left"
                    aria-hidden="true"
                    style={{
                      fontSize: "50px",
                      color: "#6f6f70",
                      paddingTop: "10px"
                    }}
                  ></i>
                  <div>
                    <div className=" pl-5 d-inline-block  profile_span">
                      <h6>
                        <b>
                          {e.college.length > 50
                            ? e.college.slice(0, 50) + "..."
                            : e.college}
                          {e.university
                            ? e.university.length > 50
                              ? `  ( ${e.university.slice(0, 50)}...)`
                              : `  ( ${e.university})`
                            : ""}
                        </b>
                      </h6>
                      <span className="d-block">
                        {e.course}
                        {e.s ? ` - ${e.s}` : ""}
                      </span>
                      <span className="d-block"> {e.poy}</span>
                    </div>
                    <div className="float-right">
                      <i
                        className=" fa fa-pencil-square-o d-inline-block  profile_icon pr-3"
                        aria-hidden="true"
                        onClick={async () => {
                          await this.add(false);
                          this.toggle();
                          this.modalData(e);
                          this.setState({
                            index: i
                          });
                        }}
                      ></i>
                      <i
                        className=" fa fa-trash d-inline-block profile_icon pr-3"
                        aria-hidden="true"
                        onClick={() => {
                          this.setState({
                            modal_trash: true,
                            index: i
                          });
                        }}
                      ></i>
                    </div>
                    {i == this.props.data.educationData.length - 1 ? null : (
                      <div className="edit_hr"></div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          ) : null}
        </Card>
      </div>
    );
  }
}

Edu.PropType = {
  editProfileData: PropTypes.func.isRequired,
  deleteData: PropTypes.func.isRequired
};

const mapStateToProps = state => ({});
export default connect(
  mapStateToProps,
  { editProfileData, deleteData }
)(Edu);
