import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  Table,
  Input,
  FormGroup,
  Button,
  UncontrolledTooltip,
} from "reactstrap";

export default class PortfolioTable extends React.Component {
  getColor = () => {
    switch (this.props.segment) {
      case "ALL":
        return "#E4E4E4";
      case "Large Cap":
        return "#FFC30B";
      case "Mid Cap":
        return "#990F04";
      case "Small Cap":
        return "#9240C8";
    }
  };
  getTextColor = () => {
    switch (this.props.segment) {
      case "ALL":
        return "#373535";
      case "Large Cap":
        return "#373535";
      case "Mid Cap":
        return "#FFFFFF";
      case "Small Cap":
        return "#FFFFFF";
    }
  };

  getTotal = (e) => {
    let buy = [];
    let sell = 0;
    let sel_scrip = this.props.myScrips.filter(
      (el) => el.scrip_id == e.scrip_id
    );
    if (sel_scrip == undefined) {
      return { total: 0, qty: 0 };
    }
    sel_scrip.sort((a, b) => a.id - b.id);
    sel_scrip.forEach((ms) => {
      if (ms.status === "DONE") {
        if (ms.qty > 0) {
          for (var i = 0; i < ms.qty; i++) {
            buy.push(ms);
          }
        } else {
          sell += Math.abs(ms.qty);
        }
      }
    });
    buy.splice(0, sell);
    let total = 0;

    buy.forEach((ms) => (total += ms.price));
    return { total: total, qty: buy.length };
  };

  getTotalProLoss = (data) => {
    let tpl = 0;
    data.map((e) => {
      let total = this.getTotal(e);

      const mp = this.props.new_market_data[e.Scrip.srCode]
        ? parseFloat(this.props.new_market_data[e.Scrip.srCode])
        : e.Scrip.market_price;
      tpl = tpl + total.qty * mp - total.total;
    });
    return tpl.toFixed(2);
  };
  getRow = (total_price, e, stock) => {
    //console.log(e)
    let total = this.getTotal(e);
    let n;
    const mp = this.props.new_market_data[e.Scrip.srCode]
      ? parseFloat(this.props.new_market_data[e.Scrip.srCode])
      : e.Scrip.market_price;
    var avg = (total.total / total.qty).toFixed(2);
    if (
      this.props.user.planned == "p999" ||
      this.props.user.planned == "combo"
    ) {
      let m = stock.find((el) => el.scrip_id === e.scrip_id);
      if (m) {
        let bse_change = m.bsecprice * 100 - m.bseprice * 100;
        bse_change = bse_change / m.bsecprice;
        let scrip_change = mp * 100 - avg * 100;
        scrip_change = scrip_change / mp;
        if (bse_change < scrip_change) {
          n = 1;
        } else {
          n = 0;
        }
      }
    }

    return (
      <tr key={e.id} className="tableBodyProt" style={{ height: "3rem" }}>
        <td>
          {" "}
          <div>{e.Scrip.company} </div>
        </td>
        {this.props.user.planned == "p999" ||
        this.props.user.planned == "combo" ? (
          <td
            style={{
              wordBreak: "break-all",
              textAlign: "center",
              fontSize: "larger",
            }}
          >
            <div
              className={
                n == 0
                  ? "border border-danger  text-danger"
                  : "border border-success text-success"
              }
              style={
                n == 0
                  ? {
                      background:
                        " linear-gradient(0deg, rgba(236,236,236,1) 44%, rgba(237,193,193,1) 100%)",
                      color: "red",
                    }
                  : {
                      background:
                        "linear-gradient(0deg, rgba(236,236,236,1) 44%, rgba(155,240,148,1) 100%)",
                      color: "#31B258",
                    }
              }
            >
              {" "}
              {n == 0 ? "\u25BC" : "\u25B2"}
            </div>
          </td>
        ) : null}
        {/* {e.trail_mark > 0 ? (
          <td>
            <div style={{ textAlign: "center" }}>
              <div
                onClick={() => {
                  this.props.buy(
                    e.Scrip.id,
                    e.Scrip.company,
                    "SELL",
                    e.qty,
                    mp,
                    e.Scrip.srCode,
                    "TRAILSP",
                    e.id,
                    true,
                    e.trail_mark
                  );
                }}
                style={{
                  color: "white",
                  fontSize: "10px",
                  backgroundColor: "black",
                  height: "fit-content",
                  // paddingBottom:"5px",
                  paddingTop: "2px",
                  borderRadius: "2px",
                }}
                className="btn-market hover-cursor hoverSize"
              >
                Modify
              </div>
              <div style={{ fontSize: "12px", paddingBottom: "2px" }}>
                at ₹{e.trail_price}
              </div>
              <div
                onClick={async () => {
                  await this.props.cancelOrderDlg(e.trail_mark, e.id);
                }}
                style={{
                  color: "white",
                  textAlign: "center",
                  fontSize: "12px",
                  width: "70px",
                  margin: "auto",
                  fontSize: "12px",
                  padding: "2px",
                  marginBottom: "5px",
                  borderRadius: "5px",
                }}
                className="bg-danger hover-cursor hoverSize "
              >
                CANCEL
              </div>
            </div>
          </td>
        ) : (
          <td>
            {" "}
            <div
              onClick={() => {
                this.props.buy(
                  e.Scrip.id,
                  e.Scrip.company,
                  "SELL",
                  e.qty,
                  mp,
                  e.Scrip.srCode,
                  2,
                  e.id,
                  false,
                  e.trail_mark
                );
              }}
              style={{
                color: "black",
                fontSize: "18px",
                textAlignLast: "center",
                height: "inherit",
                // paddingBottom:"5px",
                // paddingTop:"5px"
                width: "100%",
              }}
              className="hoverSize hover-cursor"
            >
              Trail
            </div>
          </td>
        )} */}

        <td>
          <div>{e.qty}</div>
        </td>
        <td>
          <div>{((total.total / total_price) * 100).toFixed(2)}%</div>
        </td>
        <td>
          <div>{e.qty > 0 ? (total.total / total.qty).toFixed(2) : 0}</div>
        </td>

        <td>
          <div
            className={
              (total.qty * mp - total.total).toFixed(2) < 0
                ? "border border-danger  text-danger"
                : "border border-success text-success"
            }
            style={
              (total.qty * mp - total.total).toFixed(2) < 0
                ? { color: "red" }
                : { color: "#31B258" }
            }
          >
            {(total.qty * mp - total.total).toFixed(2)}
          </div>
        </td>
        <td>
          <div>{total.total.toFixed(2)}</div>
        </td>
      </tr>
    );
  };
  render() {
    let stock = this.props.bse;
    //this.props.stock();
    var total_price = 0;

    this.props.portfolio.forEach((element) => {
      let total = this.getTotal(element);

      total_price += total.total;
    });
    return (
      <div style={{ backgroundColor: "#ECECEC", borderRadius: "10px" }}>
        <Table hover bordered className="fixed_header">
          <thead className="tableHeadings">
            <tr>
              <th>
                <div>
                  <i>Company</i>
                </div>
              </th>
              {this.props.user.planned == "p999" ||
              this.props.user.planned == "combo" ? (
                <th style={{ width: "100px" }}>
                  <div>
                    {" "}
                    <i>AI Portfolio Improvement</i>
                    <i
                      className="fa fa-info-circle ml-2"
                      id={"segmentTT" + this.props.src}
                    />
                    <UncontrolledTooltip
                      placement="right"
                      target={"segmentTT" + this.props.src}
                    >
                      This indicator shows whether the particular stock has
                      driven your FinScore up or down
                    </UncontrolledTooltip>
                  </div>
                </th>
              ) : null}
              {/* <th>
                <div>
                  {" "}
                  <i>TrailStopLoss</i>
                  <i
                    className="fa fa-info-circle ml-2"
                    id={"limitTT" + this.props.srCode}
                  />
                  <UncontrolledTooltip
                    placement="right"
                    target={"limitTT" + this.props.srCode}
                  >
                    A trailing stop loss allows traders to set a predetermined
                    loss price that they can incur when trading. It plays an
                    efficient role in managing risks and providing profit
                    protection. Consequently, these are also known as
                    profit-protecting stops. To use this feature, you can enter
                    a trade price that is below the current market price for a
                    particular quantity of share that you own. If the market
                    price reaches that price, your shares will be sold. At
                    market closing, all trail stop loss order are automatically
                    rejected and you can then place it again, after closing, for
                    the next market day.
                  </UncontrolledTooltip>
                </div>
              </th> */}
              <th>
                <div>
                  {" "}
                  <i>Quantity</i>
                </div>
              </th>
              <th>
                <div>
                  <i>Proportion</i>
                </div>
              </th>
              <th>
                <div>
                  <i>Avg Cost</i>
                </div>
              </th>
              <th>
                <div>
                  <i>Profit Loss</i>
                </div>
              </th>

              <th>
                <div>
                  <i>Total Value</i>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {this.props.portfolio.map((e) =>
              this.getRow(total_price, e, stock)
            )}
          </tbody>
          <tbody>
            <tr className="tableBodyProt">
              <td>
                <div>
                  {" "}
                  <b>Total</b>
                </div>
              </td>
              {this.props.user.planned == "p999" ||
              this.props.user.planned == "combo" ? (
                <td />
              ) : null}
              <td />
              <td />
              <td />
              <td>
                <div>
                  <b>{this.getTotalProLoss(this.props.portfolio)}</b>
                </div>
              </td>
              <td>
                <div>
                  <b>{total_price.toFixed(2)}</b>
                </div>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    );
  }
}
