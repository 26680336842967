import React from "react";

import {
  Col,
  Row,
  Collapse,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input
} from "reactstrap";

class Education extends React.Component {
  state = {
    resumeFile: null,
    addMore: false,
    q: [],
    c: [],
    count: 0,
    showList: true,
    suggestions: [],
    educationData: [
      {
        hQua: "",
        course: "",
        s: "",
        university: "",
        otherUni: "",
        college: "",
        poy: ""
      }
    ]
  };

  onChangeHandler = e => {
    this.setState({
      resumeFile: e.target.files
    });
  };
  handleSubmitEdu = e => {
    this.props.eduData(
      this.state.educationData,
      this.state.educationData[0].hQua != "" &&
        this.state.educationData[0].course != "" &&
        this.state.educationData[0].college != "" &&
        this.state.educationData[0].poy != ""
        ? true
        : false
    );
    if (
      this.state.educationData[0].hQua != "" &&
      this.state.educationData[0].course != "" &&
      this.state.educationData[0].college != "" &&
      this.state.educationData[0].poy != ""
    ) {
      this.setState({
        addMore: true
      });
    }
    if (this.state.educationData[0].college == "") {
      this.setState({
        showList: false,
        addMore: false
      });
    }
  };

  handleRemove = (e, i) => {
    e.preventDefault();
    this.state.educationData.splice(i, 1);
    this.setState({ count: i - 1 });
    this.setState({ educationData: [...this.state.educationData] });
    this.props.eduData(this.state.educationData, true);
  };

  addMore() {
    this.setState({
      educationData: [...this.state.educationData, {}]
    });
  }
  addCount() {
    this.setState({
      count: this.state.count + 1
    });
  }

  suggestionSelected(value, num) {
    this.state.educationData[num].college = value;

    this.setState(() => ({
      showList: false
    }));
  }
  renderSuggestions(num) {
    if (this.state.count == num) {
      if (this.props.clgList.length < 1) {
        return null;
      } else {
        return (
          <div>
            {this.state.showList ? (
              <ul
                id="ul"
                className="autocomplete-items-clg "
                style={{ width: "50%" }}
              >
                {this.props.clgList.map((clg, i) => (
                  <li
                    key={i}
                    onClick={() =>
                      this.suggestionSelected(clg.College_Name, num)
                    }
                  >
                    {clg.College_Name}
                  </li>
                ))}
              </ul>
            ) : null}
          </div>
        );
      }
    }
  }
  addEduDetails() {
    return (
      <Form onChange={this.handleSubmitEdu}>
        {this.state.educationData.map((e, i) => {
          let qualifications = this.props.educationDetails.filter(
            p => p.question_id == 1
          );

          let courseId = this.props.educationDetails
            .filter(o => o.option == this.state.q[i])
            .map(o => o.id);

          let courses = this.props.educationDetails.filter(
            o => o.question_id == courseId[0] + 1
          );

          // let specializationId = this.props.educationDetails
          //   .filter(o => o.option == this.state.c[i])
          //   .map(o => o.id);

          // let specializations = this.props.educationDetails.filter(
          //   o => o.question_id == specializationId[0] + 1
          // );
          let passingYear = this.props.educationDetails.filter(
            o => o.question_id == 52
          );
          return (
            <div key={i} value={e} className="mt-3">
              <FormGroup>
                <Label for="highestQualification" className="required_mark">
                  {i == 0 ? "Highest Qualification" : "Qualification"}
                </Label>

                <select
                  required
                  type="hQualification"
                  name="hQualification"
                  id="hQualification"
                  defaultValue={-1}
                  onChange={e => {
                    this.state.educationData[i].hQua = e.target.value;
                    this.state.q.splice(i, 1, e.target.value);
                  }}
                >
                  <option value="-1" disabled>
                    Please Select Qualification
                  </option>
                  <option value="Graduate / Bachelors/ Diploma">Graduate / Bachelors/ Diploma</option>
                  <option value="Post - Graduate / Masters">Post - Graduate / Masters</option>
                  <option value="Doctorate / PhD">Doctorate / PhD</option>
                  <option value="Other">Other</option>
                </select>
              </FormGroup>
              <FormGroup>
                <Label for="course" className="required_mark">
                  Course
                </Label>
                <select
                  required
                  type="course"
                  name="course"
                  id="course"
                  defaultValue={-1}
                  onChange={e => {
                    this.state.c.splice(i, 1, e.target.value);

                    this.state.educationData[i].course = e.target.value;
                  }}
                >
                  <option value="-1" disabled>
                    Please Select Course
                  </option>
                  {courses.map(o => (
                    <option key={o.id} value={o.option}>
                      {o.option}
                    </option>
                  ))}
                </select>
              </FormGroup>
              <FormGroup>
                <Label for="specialization">Specialization</Label>
                <Input
                  type="specialization"
                  name="specialization"
                  id="specialization"
                  onChange={e => {
                    this.state.educationData[i].s = e.target.value;
                  }}
                />
              </FormGroup>

              <FormGroup>
                <Label for="university">University</Label>
                <select
                  type="university"
                  name="university"
                  defaultValue={-1}
                  id="university"
                  onChange={e => {
                    this.state.educationData[i].university = e.target.value;
                  }}
                >
                  <option value="-1" disabled>
                    Please Select university
                  </option>
                  {this.props.university.map(o => (
                    <option key={o.id} value={o.name}>
                      {o.name}
                    </option>
                  ))}
                </select>
              </FormGroup>
              {this.state.educationData[i].university == "Other" ? (
                <FormGroup>
                  <Label for="university">University</Label>
                  <Input
                    type="other_uni"
                    name="other_uni"
                    id="other_uni"
                    onChange={e => {
                      this.state.educationData[i].otherUni = e.target.value;
                    }}
                  ></Input>
                </FormGroup>
              ) : null}
              <FormGroup>
                <Label for="college" className="required_mark">
                  College
                </Label>
                <div>
                  <Input
                    type="text"
                    autoComplete="off"
                    name="college"
                    id="college"
                    value={this.state.educationData[i].college}
                    onChange={e => {
                      this.props.searchClg(e.target.value);
                      this.state.educationData[i].college = e.target.value;
                      this.setState({
                        showList: true
                      });
                    }}
                  />
                  {this.renderSuggestions(i)}
                </div>
              </FormGroup>

              <div>
                <FormGroup className="d-inline-block">
                  <Label for="poy"  className="required_mark">Passing Out Year</Label>
                  <select
                    type="poy"
                    name="poy"
                    id="poy"
                    defaultValue={-1}
                    onChange={e => {
                      this.state.educationData[i].poy = e.target.value;
                    }}
                    style={{ width: "100%" }}
                  >
                    <option value="-1" disabled>
                      Please Select Passing Year
                    </option>
                    {passingYear.map(o => (
                      <option key={o.id} value={o.option}>
                        {o.option}
                      </option>
                    ))}
                  </select>
                </FormGroup>
              </div>
              {this.state.addMore ? <hr className="hr_bottom" /> : ""}
            </div>
          );
        })}
        <div>
          {this.state.addMore ? (
            <button
              className="btn btn-1 "
              type="button"
              onClick={e => {
                this.addMore(e);
                this.addCount();
              }}
            >
              Add Qualification
            </button>
          ) : (
            ""
          )}
          {this.state.count > 0 ? (
            <div className="mr-2 d-inline-block float-right">
              <button
                className="btn btn-4"
                onClick={e => {
                  this.handleRemove(e, this.state.count);
                }}
              >
                Remove
              </button>
            </div>
          ) : (
            ""
          )}
        </div>
      </Form>
    );
  }

  render() {
   
    return (
      <div>
        <div className="profileCollapse mt-5">
          <Row>
            <Col sm="6" lg="6" md="6" className="d-table-cell">
              <img src="/img/education.svg" alt="education" />
              <span>Education Details</span>
            </Col>
            <Col sm="6" lg="6" md="6" className="text-right">
              <button
                className="btn"
                onClick={() => {
                  this.props.collapseValue("education");
                }}
              >
                Add
              </button>
            </Col>
          </Row>
          <Collapse isOpen={this.props.active == "education"}>
            <Card>
              <CardBody>{this.addEduDetails()}</CardBody>
            </Card>
          </Collapse>
        </div>
      </div>
    );
  }
}

export default Education;
