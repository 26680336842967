import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';

const initialState = {};

const middleware = [thunk];

let composeArr = [];
composeArr.push(applyMiddleware(...middleware));
if(window.__REDUX_DEVTOOLS_EXTENSION__){
  composeArr.push(window.__REDUX_DEVTOOLS_EXTENSION__())
};

const store = createStore(
  rootReducer,
  initialState,
  compose(
    ...composeArr
  )
);

export default store;