import React from "react";
import {
  DropdownToggle,
  UncontrolledDropdown,
  FormGroup,
  Input,
  Button,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import { connect } from "react-redux";
import SectorSubmitted from "./sector_pick_submitted";
import PropTypes from "prop-types";
import { getIndustries } from "../../../../actions/scripsActions";
import { saveResearch } from "../../../../actions/newresearchActions";

class SectorPicks extends React.Component {
  state = {
    sector1: "Please select a sector",
    sector2: "Please select a sector",
    submitted: false
  };
  componentDidMount() {
    this.props.getIndustries();
  }
  setSector1 = e => {
    this.setState({ sector1: e.target.innerText });
  };
  setSector2 = e => {
    this.setState({ sector2: e.target.innerText });
  };
  handleSubmit = e => {
    e.preventDefault();
    let data = {
      sector1: this.state.sector1.trim(),
      sector1_desc: e.target.sector1_desc.value.trim(),
      sector2: this.state.sector2.trim(),
      sector2_desc: e.target.sector2_desc.value.trim()
    };
    if (
      data.sector1 == "Please select a sector" ||
      data.sector2 == "Please select a sector" ||
      data.sector1_desc == "" ||
      data.sector2_desc == ""
    ) {
      this.props.showInvalidFormAlert();
    } else {
      this.props.saveResearch({ data, week: 1 });
      this.setState({ submitted: true });
    }
  };
  render() {
    let submit_disabled = false;
    let attr = {
      sec1: {},
      sec2: {},
      sec1_input: {},
      sec2_input: {},
      sec1_sel: {},
      sec2_sel: {}
    };
    if (this.state.submitted || this.props.data) {
      if (!this.props.data) {
        return <div>Please Wait...</div>;
      }
      submit_disabled = true;
      attr["sec1"]["disabled"] = true;
      attr["sec1"]["value"] = this.props.data.sector1;
      attr["sec2"]["disabled"] = true;
      attr["sec2"]["value"] = this.props.data.sector2;
      attr["sec1_input"]["disabled"] = true;
      attr["sec1_input"]["value"] = this.props.data.sector1_desc;
      attr["sec2_input"]["disabled"] = true;
      attr["sec2_input"]["value"] = this.props.data.sector2_desc;
    }
    return (
      <div className="sector_picks">
        <form onSubmit={this.handleSubmit}>
          <div>
            <p>
              Pick any two sectors that you want to conduct your research
              project on. The reason for picking a sector can be varied - right
              from your personal interest and inclination towards the sector to
              a sector where you see growth in near future.
            </p>
          </div>
          <div className="inner_margin">
            <h5 className="d-inline-block">Sector 1: </h5>
            {submit_disabled ? (
              attr["sec1"]["value"]
            ) : (
              <UncontrolledDropdown className="d-inline-block pl-2">
                <DropdownToggle
                  tag="button"
                  type="button"
                  name="sector1"
                  className="form-control "
                  caret
                >
                  {this.state.sector1}
                </DropdownToggle>
                <DropdownMenu>
                  {this.props.industries.map((e, i) => (
                    <DropdownItem onClick={this.setSector1} key={i}>
                      <span dangerouslySetInnerHTML={{ __html: e.industry }} />
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </UncontrolledDropdown>
            )}
          </div>
          <div className="inner_margin">
            <p>
              Please describe in 250 words as to why you want to pick that
              particular sector.
            </p>
            <FormGroup>
              <Input
                type="textarea"
                height="220px"
                name="sector1_desc"
                {...attr["sec1_input"]}
              />
            </FormGroup>
          </div>
          <hr />
          <div className="inner_margin">
            <h5 className="d-inline-block">Sector 2: </h5>
            {submit_disabled ? (
              attr["sec2"]["value"]
            ) : (
              <UncontrolledDropdown className="d-inline-block pl-2">
                <DropdownToggle
                  tag="button"
                  type="button"
                  name="sector2"
                  className="form-control "
                  caret
                >
                  {this.state.sector2}
                </DropdownToggle>
                <DropdownMenu>
                  {this.props.industries.map((e, i) => (
                    <DropdownItem onClick={this.setSector2} key={i}>
                      <span dangerouslySetInnerHTML={{ __html: e.industry }} />
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </UncontrolledDropdown>
            )}
          </div>
          <div className="inner_margin">
            <p>
              Please describe in 250 words as to why you want to pick that
              particular sector.
            </p>
            <FormGroup>
              <Input
                type="textarea"
                name="sector2_desc"
                {...attr["sec2_input"]}
              />
            </FormGroup>
          </div>
          <div className="text-center">
            {!submit_disabled ? (
              <Button
                className="btn btn-padding btn-secondary mt-4"
                type="submit"
              >
                Submit
              </Button>
            ) : null}
          </div>
        </form>
      </div>
    );
  }
}
SectorPicks.propTypes = {
  industries: PropTypes.array.isRequired,
  getIndustries: PropTypes.func.isRequired,
  saveResearch: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    industries: state.scrips.industries
  };
};
export default connect(
  mapStateToProps,
  { getIndustries, saveResearch }
)(SectorPicks);
