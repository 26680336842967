import React from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  UncontrolledTooltip
} from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import Slider from "react-slick";
import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";
import {getECodePermission} from "../../actions/gamesActions";

import { Game as G1, title as G1Title } from "./G1";
import ECode from "./ECode";

class Games extends React.Component {
  state = {
    activeGame: 0
  };
  componentDidMount() {
    this.props.getECodePermission()
  }
  startGame = game => {
    this.setState({ activeGame: game });
  };
  componentWillReceiveProps(nextProps) {
    if (nextProps.location.state !== this.props.location.state) {
      this.startGame(0);
    }
  }
  getGameList = () => {
    const settings = {
      dots: false,
      autoplay: true,
      infinite: true,
      speed: 100,
      slidesToShow: 2,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 100,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true
          }
        },
        {
          breakpoint: 100,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
            infinite: true
          }
        },
        {
          breakpoint: 100,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true
          }
        }
      ]
    };
    return (
      <div className="container" style={{ paddingTop: "80px" }}>
        <Slider {...settings}>
          <div>
            <div className="backimg">
              <div className="text19">
                <h4>Stockdeck Flash</h4>
              </div>
              <div className="imagedesign1">
                <img src="/img/rs1.png" className="center" />
              </div>
              <div className="orderlist">
                <ul>
                  <li>Both you and AI get 10 companies each!</li>
                  <li>Pick among 8 attributes of a company</li>
                  <li>
                    If your company beats AI’s in that attribute, you win Rs.
                    100!!
                  </li>
                  <li>If not, you lose just Rs. 50!</li>
                  <li>Winnings up to Rs. 1000 possible!</li>
                  <li>Play one round and you will know it all!</li>
                </ul>
              </div>
              <div className="foot19">
                <div>
                  <Button
                    className="site-gray"
                    onClick={() => this.startGame(1)}
                  >
                    Play
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="backimg">
              <div className="text19">
                <h4>Stockdeck Shootout</h4>
              </div>
              <div className="image19">
                <img src="img/rs2.png" className="center" />
              </div>
              <div className="orderlist">
                <ul>
                  <li>Both you and AI get 10 companies each!</li>
                  <li>Pick among 8 attributes of a company in 10 seconds!</li>
                  <li>
                    Why 10 seconds? Cause you know AI’s company before you pick!
                  </li>
                  <li>
                    If your company beats AI’s in that attribute, you win Rs.
                    100!
                  </li>
                  <li>If not, you lose just Rs. 50!</li>
                  <li>Winnings up to Rs. 1000 possible!</li>
                  <li>Play one round and you will know it all!</li>
                </ul>
              </div>
              <div className="foot19">
                <div>
                  <Button
                    className="site-gray"
                    onClick={() => this.startGame(2)}
                  >
                    Play
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="backimg">
              <div className="text19">
                <h4>E-Code</h4>
              </div>
              <div className="image19">
                <img src="/img/rs3.png" className="center" />
              </div>
              <div className="orderlist">
                <ul>
                  <li>
                    Choose a correct answer from the given four options on each
                    question within 10 seconds!
                  </li>
                  <li>
                    Yes, we do help you – we’ll be giving an audience poll on
                    some questions that’ll help you pick the correct answer!
                  </li>
                  <li>
                    And yep, we give you second chances – Flip to change
                    questions that you are not sure about!
                  </li>
                  <li>You win Rs. 100 for a correct answer!!</li>
                  <li>You lose just Rs. 50 for a wrong one!</li>
                  <li>Winnings up to Rs. 1000 possible!</li>
                  <li>There will be a new quiz every week so keep following this step</li>
                </ul>
              </div>
              <div className="foot19">
             
                <div>
               {!this.props.check?(<Button
                    className="site-gray"
                    onClick={() => this.startGame(3)}
                  >
                    Play
                  </Button>
                  ):null}
                  
                </div>
                <div className="ml-5"  >
                      {!this.props.check?( <i className="notify d-inline-block "style={{marginLeft:"380px"}}>
                        {1}
                      </i>):null}
                      </div>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    );
  };

  render() {
    if (!this.props.user.info.name) return null;

    if (this.state.activeGame === 0) {
      return this.getGameList();
    }

    if (this.state.activeGame <= 2) {
      return (
        <G1
          goFront={() => this.startGame(0)}
          user={this.props.user.info}
          game={this.state.activeGame}
        />
      );
    }
    if (this.state.activeGame == 3) {
      return (
        <ECode
          goFront={() => this.startGame(0)}
          user={this.props.user.info}
          currentid ={this.props.check1}
          game={3}
        />
      );
    }
  }
}

Games.propTypes = {
  user: PropTypes.object.isRequired,
  getECodePermission:PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.user,
  check:state.games.permission.data,
  check1:state.games.permission.data1
});

export default withRouter(connect(mapStateToProps, {getECodePermission})(Games));
