import React from "react";
import {
  Card,
  CardText,
  CardBody,
  CardTitle,
  Collapse,
  Row,
  Col
} from "reactstrap";
class Question1 extends React.Component {
  state = {
    is_open: false
  };
  toggle = () => {
    this.setState({ is_open: !this.state.is_open });
  };
  render() {
    const data = this.props.data;
    return (
      <div onClick={this.toggle} style={{ cursor: "pointer" }}>
        <div style={{ backgroundColor: "#e4e4e4" }} className="p-2 mt-2">
          <i
            className={
              "fa fontsize-5 d-inline-block  align-middle " +
              (this.state.is_open ? "fa-minus" : "fa-plus")
            }
          />
          <span className="d-inline-block pl-2 pt-1">
            <h5>Is there any minimum amount of trades that I need to do per week and why is that?</h5>
          </span>
        </div>

        <Collapse isOpen={this.state.is_open}>
          <Card>
            <CardBody onClick={this.toggle}>
              <CardTitle />
              <CardText className="">
                <p>Though there is no minimum about of trades per day, we would recommend that you do at least 10 trades a week; which is 2 trades per day. A trade constitutes a single transaction.</p>
                <table class="table table-bordered table-striped">
     <thead>
      <tr>
        <th style={{textAlign : "left"}}>Company</th>
        <th style={{textAlign : "left"}}>Action</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Reliance Industries</td>
        <td>Buy</td>
      </tr>
      <tr>
        <td>Reliance Industries</td>
        <td>Sell</td>
      </tr>
      <tr>
        <td>Tata Consultancy Services</td>
        <td>Buy</td>
      </tr>
      <tr>
        <td>Tata Consultancy Services</td>
        <td>Sell</td>
      </tr>
    </tbody>
  </table>

  <p>The number of trades done in the above mentioned table are 4.</p>

  <table class="table table-bordered table-striped">
     <thead>
      <tr>
        <th style={{textAlign : "left"}}>Company</th>
        <th style={{textAlign : "left"}}>Action</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Reliance Industries</td>
        <td>Buy</td>
      </tr>
      <tr>
        <td>Tata Consultancy Services</td>
        <td>Buy</td>
      </tr>
      <tr>
        <td>HDFC Bank</td>
        <td>Buy</td>
      </tr>
    </tbody>
  </table>

<p>The number of trades done in the above mentioned table are 3.</p>

              </CardText>
            </CardBody>
          </Card>
        </Collapse>
      </div>
    );
  }
}
export default Question1;
