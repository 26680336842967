import React from "react";
import { ResponsiveContainer, PieChart, Pie, Tooltip, Cell,Label } from "recharts";

const COLORS = {
  "Mid Cap": [
    "#023E8A",
    "#0077B6",
    "#0096C7",
    "#00B4D8",
    "#48CAE4",
    "#90E0EF",
    "#ADE8F4",
    "#03045E",
    "#6A9DD9"
  ],
  "Large Cap": [
    "#ADE8F4",
    "#03045E",
    "#6A9DD9",
    "#023E8A",
    "#0077B6",
    "#0096C7",
    "#00B4D8",
    "#48CAE4",
    "#90E0EF",
    
  ],
  "Small Cap": [
    "#023E8A",
    "#0077B6",
    "#0096C7",
    "#00B4D8",
    "#48CAE4",
    "#90E0EF",
    "#ADE8F4",
    "#03045E",
    "#6A9DD9"
  ]
};
const RADIAN = Math.PI / 180;
function CustomLabel({viewBox, value1, value2}){
  const {cx, cy} = viewBox;
  return (
   <text x={cx} y={cy} fill="#707070" className="recharts-text recharts-label" textAnchor="middle" dominantBaseline="central">
      <tspan alignmentBaseline="middle" fontWeight="600" fontSize="18">{value1}</tspan>
   </text>
  )
}

export default class PortfolioChart extends React.Component {
  renderCustomizedLabel3 = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.7;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    const p = (percent * 100).toFixed(2);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${p}%`}
      </text>
    );
  };

  renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.9;

    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (radius + 10) * cos;
    const sy = cy + (radius + 10) * sin;
    const mx = cx + (radius + 20) * cos;
    const my = cy + (radius + 20) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "middle" : "middle";
    const p = (percent * 100).toFixed(2);
    if (p >= 3.5) {
      return (
        <g>
          <path
            d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
            stroke="#000"
            fill="none"
          />
          <circle cx={ex} cy={ey} r={2} fill="#000" stroke="none" />
          <text
            fontSize="10"
            x={ex + (cos >= 0 ? 1 : -1) * 12}
            y={ey}
            textAnchor={textAnchor}
            fill="#111"
          >
            {this.props.portfolio[index].Scrip.shortCode}
          </text>
          <text
            fontSize="11"
            x={ex + (cos >= 0 ? 1 : -1) * 12}
            y={ey}
            dy={12}
            textAnchor={textAnchor}
            fill="#333"
          >{`(${p}%)`}</text>
        </g>
      );
    }
  };
  
  onPieEnter = e => {};
  render() {
    var data = this.props.portfolio.map(el => {
      return {
        name: el.Scrip.company,
        value: parseFloat(el.total_price),
        key: el.id
      };
    });
    return (
      <ResponsiveContainer minWidth={400} minHeight={340}>
        <PieChart width={400} height={340} onMouseEnter={this.onPieEnter}>
          <Pie
             innerRadius={60} 
            isAnimationActive={false}
            dataKey="value"
            data={data}
            cx={200}
            cy={150}
            labelLine={false}
            label={this.renderCustomizedLabel}
            outerRadius={120}
            fill="#8884d8"
          >
            {data.map((entry, index) => (
              <Cell
                key={entry.key}
                fill={COLORS[this.props.segment][index % COLORS[this.props.segment].length]}
              />
            ))}
            <Label width={30} position="center"
  content={<CustomLabel value1={this.props.segment}/>}>
</Label>

          </Pie>
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
    );
  }
}
