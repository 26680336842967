import React from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getResearch, getRateResearch,getnotifyscore } from "../../../actions/newresearchActions";
class Sidebar extends React.Component {
  componentDidMount() {
    this.props.getResearch();
    this.props.getRateResearch();
    this.props.getnotifyscore();
  }
  render() {
    let notification = 0;
    if(this.props.notify){
      console.log(this.props.notify)
      notification = this.props.notify.length;
      console.log(notification)
    }
    
    return (
      <div>
        <Nav vertical className="sidebar">
          <NavItem>
            <NavLink
              disabled={this.props.week >= 1 ? false : true}
              href="#"
              onClick={() => this.props.onSelect("sector_picks1", 1)}
              className={this.props.active == "sector_picks1" ? "active" : ""}
            >
              <i
                className={
                  "fa fa-lock " + (this.props.week >= 1 ? "no-lock-ic" : "")
                }
              />
              Sector Picks
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              disabled={this.props.week >= 2 ? false : true}
              href="#"
              onClick={() => this.props.onSelect("sector_1", 2)}
              className={this.props.active == "sector_1" ? "active" : " "}
            >
              <i
                className={
                  "fa fa-lock " + (this.props.week >= 2 ? "no-lock-ic" : "")
                }
              />
              Sector-1
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              disabled={this.props.week >= 3 ? false : true}
              href="#"
              onClick={() => this.props.onSelect("sector_2", 3)}
              className={this.props.active == "sector_2" ? "active" : ""}
            >
              <i
                className={
                  "fa fa-lock " + (this.props.week >= 3 ? "no-lock-ic" : "")
                }
              />
              Sector-2
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              disabled={this.props.week >= 4 ? false : true}
              href="#"
              onClick={() => this.props.onSelect("sector_project_1", 4)}
              className={this.props.active == "sector_project_1" ? "active" : ""}
            >
              <i
                className={
                  "fa fa-lock " + (this.props.week >= 4 ? "no-lock-ic" : "")
                }
              />
              Sector Project 1
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              disabled={this.props.week >= 5 ? false : true}
              href="#"
              onClick={() => this.props.onSelect("sector_project_2", 5)}
              className={this.props.active == "sector_project_2" ? "active" : ""}
            >
              <i
                className={
                  "fa fa-lock " + (this.props.week >= 5 ? "no-lock-ic" : "")
                }
              />
              Sector Project 2
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              disabled={this.props.week >= 6 ? false : true}
              href="#"
              onClick={() => this.props.onSelect("research_insight_1", 6)}
              className={
                this.props.active == "research_insight_1" ? "active" : ""
              }
            >
              <i
                className={
                  "fa fa-lock " + (this.props.week >= 6 ? "no-lock-ic" : "")
                }
              />
              Research Insight-1
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              disabled={this.props.week >= 7 ? false : true}
              href="#"
              onClick={() => this.props.onSelect("research_insight_2", 7)}
              className={
                this.props.active == "research_insight_2" ? "active" : ""
              }
            >
              <i
                className={
                  "fa fa-lock " + (this.props.week >= 7 ? "no-lock-ic" : "")
                }
              />
              Research Insight-2
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              disabled={this.props.week >= 8 ? false : true}
              href="#"
              onClick={() => this.props.onSelect("research_insight_3", 8)}
              className={
                this.props.active == "research_insight_3" ? "active" : ""
              }
            >
              <i
                className={
                  "fa fa-lock " + (this.props.week >= 8 ? "no-lock-ic" : "")
                }
              />
              Research Insight-3
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              disabled={this.props.week >= 9 ? false : true}
              href="#"
              onClick={() => this.props.onSelect("research_insight_4", 9)}
              className={
                this.props.active == "research_insight_4" ? "active" : ""
              }
            >
              <i
                className={
                  "fa fa-lock " + (this.props.week >= 9 ? "no-lock-ic" : "")
                }
              />
              Research Insight-4
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              href="#"
              onClick={() => this.props.onSelect("my_score")}
              className={this.props.active == "my_score" ? "active" : ""}
            >
             <div>
                <div className="position-relative">
                  <span>
                    <em className="fa fa-bell d-inline-block" />
                    {notification >0? (
                      
                      <i className="notify d-inline-block">
                        <span>
                          
                        {parseInt(this.props.notify.length)}
                        </span>
                      </i>
                    ) : null}
                  </span>
                  <span className="ml-2">MyScore</span>
                </div>
              </div>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              href="#"
              onClick={() => this.props.onSelect("rate_research")}
              className={this.props.active == "rate_research" ? "active" : ""}
            >
              <div>
                <div className="position-relative">
                  <span>
                    <em className="fa fa-bell d-inline-block" />
                    {parseInt(this.props.rate_research.required) < 3 ? (
                      <i className="notify d-inline-block">
                        <span>
                          {3 - parseInt(this.props.rate_research.required)}
                        </span>
                      </i>
                    ) : null}
                  </span>
                  <span className="ml-2">Rate Research</span>
                </div>
              </div>
            </NavLink>
          </NavItem>
        </Nav>
      </div>
    );
  }
}
Sidebar.propstype = {
  getResearch: PropTypes.func.isRequired,
  getnotifyscore:PropTypes.func.isRequired,
  research: PropTypes.object.isRequired,
  getRateResearch: PropTypes.func.isRequired,
  rate_research: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    research: state.newresearch.data,
    rate_research: state.newresearch.rate_research,
    notify:state.newresearch.notify.data
  };
};
export default connect(
  mapStateToProps,
  { getResearch, getRateResearch,getnotifyscore }
)(Sidebar);
