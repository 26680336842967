import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Alert } from "reactstrap";
import moment from 'moment';
import { userData, getEduDetails, searchClg } from '../../actions/profileDetailsActions';
import { getCurrentUserDetails,getuserRenew } from '../../actions/userActions';
import { getFinscore } from '../../actions/portfolioActions';
import Edu from './edu';
import Emp from './emp';
import Resume from './resume';
import Header from './header';
class Profile extends React.Component {
	componentDidMount() {
		this.props.userData();
		this.props.getEduDetails();
		this.props.getCurrentUserDetails();
		this.props.getFinscore();
		this.props.getuserRenew()
	}
	searchCollege = clg => {
		this.props.searchClg(clg);
	};
	render() {
		if (!this.props.data) return null;
		if (!this.props.educationDetails) {
			return <div className="m-4">Please wait...</div>;
		}
		return (
			<div className="container pl-5 pr-5">
				<Header info={this.props.userInformation} finscore={this.props.finscore} />
				<Edu
					data={this.props.data}
					educationDetails={this.props.educationDetails}
					university={this.props.university}
					colleges={this.props.colleges}
					searchClg={this.searchCollege}
					clgList={this.props.clgList}
				/>

				<Emp data={this.props.data} />
				<Resume data={this.props.data} />
				{this.props.renew===null? (
					<div></div>
				) : (
					<div>
						<div className="text-center m-1 text-secondary">
							{' '}
							<i className="fa fa-calendar" aria-hidden="true" style={{ fontSize: '23px' }}></i>{' '}
							<span className="pl-2 pt-2 pb-4 " style={{ fontSize: '23px' }}>
								{this.props.userInformation.start_date}
							</span>
							<span className="pl-2 pt-2 pb-4 ml-2" style={{ fontSize: '23px' }}>
								--
							</span>
							<span className="pl-2 pt-2 pb-4 ml-2" style={{ fontSize: '23px' }}>
								{moment(this.props.userInformation.start_date)
									.add(60, 'days')
									.format('YYYY-MM-DD')}
							</span>{' '}
						</div>
						<div className="pt-1" style={{ float: 'left', width: '33%' }}>
							<div>
								<div className="border p-2 text-center ml-2">
									<div
										className="text-center p-2 d-inline-block download_arrow-1"
										style={{
											backgroundColor: '#a8bf6f',
											borderRadius: '20px',
										}}
									>
										<a
											 href={`${window.BASE_URL}/pdfs/certificates/${this.props.userInformation.user_id}_certificate.pdf`}
											 download={`${window.BASE_URL}/pdfs/certificates/${this.props.userInformation.user_id}_certificate.pdf`}
											className="text-secondary"
										>
											<i className="fa fa-cloud-download" aria-hidden="true"></i>
										</a>
									</div>
									<p className="d-inline-block ml-2">Certificate</p>
								</div>
							</div>
						</div>

						<div className="pt-1" style={{ float: 'left', width: '33%' }}>
							<div>
								<div className="border p-2 text-center ml-2">
									<div
										className="text-center p-2 d-inline-block download_arrow-1"
										style={{
											backgroundColor: '#a8bf6f',
											borderRadius: '20px',
										}}
									>
										<a
											 href={`${window.BASE_URL}/pdfs/reports/${this.props.userInformation.user_id}_report.pdf`}
											 download={`${window.BASE_URL}/pdfs/reports/${this.props.userInformation.user_id}_report.pdf`}
											className="text-secondary"
										>
											<i className="fa fa-cloud-download" aria-hidden="true"></i>
										</a>
									</div>
									<p className="d-inline-block ml-2">Performance Report</p>
								</div>
							</div>
						</div>

						{moment(this.props.userInformation.start_date)
                      .add(74, "days")
                      .format("YYYY-MM-DD") < moment().format("YYYY-MM-DD") ? (
                      <div
                        className="pt-1"
                        style={{ float: "left", width: "33%" }}
                      >
                        <div>
                          <div className="border p-2 text-center ml-2">
                            <div
                              className="text-center p-2 d-inline-block download_arrow-1"
                              style={{
                                backgroundColor: "#a8bf6f",
                                borderRadius: "20px"
                              }}
                            >
                              <a
                                href={`${window.BASE_URL}/pdfs/research/${this.props.userInformation.user_id}_research.pdf`}
                                download={`${window.BASE_URL}/pdfs/research/${this.props.userInformation.user_id}_research.pdf`}
                                className="text-secondary"
                              >
                                <i
                                  className="fa fa-cloud-download"
                                  aria-hidden="true"
                                ></i>
                              </a>
                            </div>
                            <p className="d-inline-block ml-2">
                              Research Report
                            </p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="pt-1"
                        style={{ float: "left", width: "33%" }}
                      >
                        <Alert color="primary" className=" text-center ml-2 ">
                          Research Report will be Available 14 days after your
                          end date
                        </Alert>
                      </div>
                    )}
						<div className="pt-1" style={{ float: 'left', width: '100%', height: '90px' }}></div>
					</div>
				)}
			</div>
		);
	}
}

Profile.PropType = {
	userData: PropTypes.func.isRequired,
	getEduDetails: PropTypes.func.isRequired,
	searchClg: PropTypes.func.isRequired,
	getCurrentUserDetails: PropTypes.func.isRequired,
	getFinscore: PropTypes.func.isRequired,
	getuserRenew: PropTypes.func.isRequired


};

const mapStateToProps = state => ({
	data: state.profile.userInfo,
	educationDetails: state.profile.education,
	university: state.profile.university,
	colleges: state.profile.colleges,
	clgList: state.profile.clgList,
	userInformation: state.user.info,
	finscore: state.portfolio.finscore,
	renew:state.user.renewid
});
export default connect(
	mapStateToProps,
	{ userData, getEduDetails, searchClg, getCurrentUserDetails, getFinscore,getuserRenew}
)(Profile);
