import React from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  CardTitle,
  CardText,
  Row,
  Col
} from "reactstrap";
import classnames from "classnames";
import { withRouter, Link } from "react-router-dom";
import { formatDateTimeToIndia } from "../../utils/format";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getNotifications, markRead } from "../../actions/notificationActions";

class Notification extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.getNotifications();
    this.props.markRead(0);
  }
 
  toggle(tab) {}

  render() {
    let notifications = this.props.notifications.latest;
    console.log(this.props.notifications, "notifictions")
    return (
      <div style={{width: "80%",marginLeft: "250px",marginTop: "30px",paddingBottom: "100px"}}>
        {notifications.length > 0
          ? notifications.map(n => (
              <div
                key={n.id}
                className="list-group-item list-group-item-action"
              >
                <div className="media">
                  <div className="align-self-start mr-2">
                    <em className="fa fa-line-chart fa-2x text-info" />
                  </div>
                  <div className="media-body">
                    <p className="m-0">{n.message}</p>
                    <div className="m-0 text-muted text-sm">
                      {formatDateTimeToIndia(n.createdAt)}
                    </div>
                  </div>
                </div>
              </div>
            ))
          : null}
      </div>
    );
  }
}


Notification.propTypes = {
  markRead: PropTypes.func.isRequired,
  getNotifications: PropTypes.func.isRequired,
  notifications: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  notifications: state.notifications,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { getNotifications, markRead }
)(Notification);

