import React from "react";
import {
  Card,
  CardText,
  CardBody,
  CardTitle,
  Collapse,
  Row,
  Col
} from "reactstrap";
class Question2 extends React.Component {
  state = {
    is_open: false
  };
  toggle = () => {
    this.setState({ is_open: !this.state.is_open });
  };
  render() {
    const data = this.props.data;
    return (
      <div onClick={this.toggle} style={{ cursor: "pointer" }}>
        <div style={{ backgroundColor: "#e4e4e4"}} className="p-2 mt-2">
          <i
            className={
              "fa fontsize-5 d-inline-block  align-middle " +
              (this.state.is_open ? "fa-minus" : "fa-plus")
            }
          />
          <span className="d-inline-block pl-2 pt-1">
            <h5>I do not know anything about the market, how should I navigate this platform to trade and invest in quality stocks?</h5>
          </span>
        </div>

        <Collapse isOpen={this.state.is_open}>
          <Card >
            <CardBody onClick={this.toggle}>
              <CardTitle />
              <CardText className="">
             <p> There are many pointers to trading and investing present on finlatics.com. When you click on any company, there are six company parameters and a chart that pops up. Following are some pointers on how you can use these as pointers to trade:</p>
             <p>   1. Market Capitalisation - This figure shows the average market capitalisation of the company over the last six months. An average of six months is taken to ensure a balance in valuation of the company. Even when you use the ‘Sort By’ filter and pick market cap, companies are sorted in terms of this value.</p>
             <p> 2. 2 month trend - 2 month trend shows how company has fluctuated in price over the last 2 months. You can also check movements of other time periods using the company chart given. Do remember that this is historic data and whatever happens in the past does not necessarily repeat itself going ahead.</p>
             <p> 3. Revenue (in cr.) - This shows the annual sales of the particular company for the last financial year. A good way to use the revenue parameter is to track the company’s balance sheets over the years and then see what has been the trend in the particular company. Along with this, you can also research on quarterly results that has been disclosed by the company to see what has been the quarter on quarter revenue trend for the company. </p>
             <p> 4. Profit Before Tax - This value shows the profit of the company before deducting necessary corporate taxes. This is generally a measure of financial health. However, many companies are in loss due to expenditure on growth activities. Therefore, it is important to be updated about a company’s financial health by reading more about the company. We would strongly advise to do this by reading about them on the Business Standard newsfeed section on the homepage and by reading other news material regularly.</p>
             <p> 5. Earning Per Share (EPS) - This is also a measure of profitability and shows a company’s earnings per outstanding equity shares. Higher EPS indicates greater profitability.</p> 
             <p> 6. Current Ratio - This shows whether the company is going to be able to meet its short term liabilities. A higher current ratio is better for the firm.</p>
             <p> 7. News: - An outstanding way to identify good trading and investing ideas is to read the news. The more you read the newspaper, the more equipped you shall be to perform in this program.</p>

             <p>To know more about these parameters, you can refer to the (i) icon next to a terminology in all screens.</p>

             <p>Lastly, the portfolio management part of the program is for you to try out new trading and investing ideas in a risk free manner. Therefore, do not worry much about it; the key is learning by doing.</p>
              </CardText>
            </CardBody>
          </Card>
        </Collapse>
      </div>
    );
  }
}
export default Question2;
