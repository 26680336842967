import React from "react";
import {
  Card,
  CardText,
  CardBody,
  CardTitle,
  Collapse,
  Row,
  Col
} from "reactstrap";
class Question9 extends React.Component {
  state = {
    is_open: false
  };
  toggle = () => {
    this.setState({ is_open: !this.state.is_open });
  };
  render() {
    const data = this.props.data;
    return (
      <div onClick={this.toggle} style={{ cursor: "pointer" }} className="pb-5" >
        <div style={{ backgroundColor: "#e4e4e4"}} className="p-2 mt-2">
          <i
            className={
              "fa fontsize-5 d-inline-block  align-middle " +
              (this.state.is_open ? "fa-minus" : "fa-plus")
            }
          />
          <span className="d-inline-block pl-2 pt-1">
            <h5>Do I need to trade only in sectors that I have picked up for research?</h5>
          </span>
        </div>

        <Collapse isOpen={this.state.is_open}>
          <Card >
            <CardBody onClick={this.toggle}>
              <CardTitle />
              <CardText className="">
              <p>No, research and portfolio management are parallel but unrelated activities. You should trade in companies across as many sectors as possible to make full use of the learning opportunity.
</p>
                           </CardText>
            </CardBody>
          </Card>
        </Collapse>
      </div>
    );
  }
}
export default Question9;
