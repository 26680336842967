import { NOTIFICATION_LIST, NOTIFICATION_MARK_READ,NOTIFICATION_MSG } from "../actions/types";

const initialState = { count: 0, all: [], latest: [],msg:{} };

export default function(state = initialState, action) {
  switch (action.type) {
    case NOTIFICATION_LIST:
      let notifications = { ...state };
      notifications.count = parseInt(action.payload.data.count);
      if (action.payload.limit == "ALL") {
        notifications.all = action.payload.data.notifications;
      } else {
        notifications.latest = action.payload.data.notifications;
      }
      return notifications;
    case NOTIFICATION_MARK_READ:
      return {
        ...state,
        count: action.payload
      };
    case NOTIFICATION_MSG:
      return {...state, msg:{msg:action.payload, rnd: Math.random()}} 
    default:
      return state;
  }
}
