import React from "react";
import moment from "moment";

class Status extends React.Component {
  statusText = () => {
    switch (this.props.active) {
      case "sector_1":
        return "Sector-1";
        break;
      case "sector_2":
        return "Sector-2";
        break;
      case "research_insight_1":
        return "Research Insight-1";
        break;
      case "research_insight_2":
        return "Research Insight-2";
        break;
      case "research_insight_3":
        return "Research Insight-3";
        break;
      case "research_insight_4":
        return "Research Insight-4";
        break;
      case "my_score":
        return "My Scores";
        break;
      case "rate_research3":
        return "Rate Research";
        break;
      default:
      case "sector_picks1":
        return "Week-1";
    }
  };
  render() {
    let submission_date = moment(this.props.start_date, "YYYY-MM-DD").add(
      7 * this.props.week,
      "day"
    );

    return (
      <div className=" status mt-4">
        <p className="d-inline-block  ml-3">{this.statusText()}</p>

        <div className="d-inline-block">
          {this.props.active == "my_score" ? null : (
            <button
              className="btn mb-1 ml-3"
              style={{
                backgroundColor: submission_date < moment() ? "red" : "#008700"
              }}
            >
              Last date of submission:&nbsp;
              {this.props.start_date ? submission_date.format("ll") : "-"}
            </button>
          )}
        </div>
        <hr />
      </div>
    );
  }
}
export default Status;
