import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter,Row, Col,Table, Input, Label } from 'reactstrap';


export default class LeapModal extends React.Component {
   
    render(){
        return (
            <Modal isOpen={this.props.show} backdrop={true} size="lg">
                <ModalBody>
                    <div style={{padding: '10px'}}>
                        <h2 style={{textTransform: 'uppercase',paddingLeft: '20px'}}>
                            Read before you leap
                        </h2>
                        <div>
                            <ol>
                                <li>
                                    You will not be judged solely 
                                    by your portfolio returns. There are multiple ways on which this assessment is based. So, even if your portfolio is in red; relax! You still have a shot on your dream job. 
                                </li>
                                <li>
                                    There will be many tasks that you will be asked to do. The more you par-ticipate in these tasks, the better your chances of getting hired. 
                                </li>
                                <li>
                                    Investment rationale is a key area that can fetch you points. It is also an area where you can show your skills. Remember to use it well. 
                                </li> 
                            </ol>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button 
                    onClick={this.props.onOk}
                    style={{margin: 'auto',fontSize: '20px!important'}} 
                    className="btn btn-default btn-order">
                    I HAVE READ IT
                    </Button>
                </ModalFooter>
            </Modal>
                
        );
    }
}