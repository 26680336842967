import React from "react";
import {
  Card,
  CardText,
  CardBody,
  CardTitle,
  Collapse,
  Row,
  Col
} from "reactstrap";
class Question7 extends React.Component {
  state = {
    is_open: false
  };
  toggle = () => {
    this.setState({ is_open: !this.state.is_open });
  };
  render() {
    const data = this.props.data;
    return (
      <div onClick={this.toggle} style={{ cursor: "pointer" }}>
        <div style={{ backgroundColor: "#e4e4e4" }} className="p-2 mt-2">
          <i
            className={
              "fa fontsize-5 d-inline-block  align-middle " +
              (this.state.is_open ? "fa-minus" : "fa-plus")
            }
          />
          <span className="d-inline-block pl-2 pt-1">
            <h5>What does the rate research section signify and what do I have to do in it?</h5>
          </span>
        </div>

        <Collapse isOpen={this.state.is_open}>
          <Card>
            <CardBody onClick={this.toggle}>
              <CardTitle />
              <CardText className="">

              <p>In the rate research section, you have to grade research submitted by other FMEP enrolees.</p>
                           </CardText>
            </CardBody>
          </Card>
        </Collapse>
      </div>
    );
  }
}
export default Question7;
