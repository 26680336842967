import axios from "axios";
import { NEWRESEARCH_DATA, NEWRATERESEARCH_DATA ,NEWGET_NOTIFY_SCORE} from "./types";

export const saveResearch = data => dispatch => {
  axios
    .post(`/api/newresearch`, data)
    .then(res => {
      dispatch(getResearch());
    })
    .catch(error => {});
};

export const getResearch = () => dispatch => {
  axios
    .get(`api/newresearch`)
    .then(res => {
      dispatch({
        type: NEWRESEARCH_DATA,
        payload: res.data
      });
    })
    .catch(err => {});
};

export const saveRateResearch = data => dispatch => {
  axios
    .post(`/api/newresearch/newrate-research`, data)
    .then(res => {
      dispatch(getRateResearch());
    })
    .catch(error => {});
};
export const getnotifyscore = ()=> dispatch => {
  axios
    .get(`/api/newresearch/newnotify_score`)
    .then(res => {
      dispatch({
        type:NEWGET_NOTIFY_SCORE,
        payload:res.data
      });
    })
    .catch(error => {});
};
export const getRateResearch = () => dispatch => {
  axios
    .get(`api/newresearch/newrate-research`)
    .then(res => {
      dispatch({
        type: NEWRATERESEARCH_DATA,
        payload: res.data
      });
    })
    .catch(err => {});
};
export const pnotify = data => dispatch => {
  axios
    .post(`/api/newresearch/newpnotify_score`)
    .then(res => {
    })
    .catch(error => {});
};
