import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { resumeUpload } from "../../actions/profileDetailsActions";
import { Input, Label, Button } from "reactstrap";
class Resume extends React.Component {
  state = {
    selectedFile: null,
    file: "",
    fileName: "",
    fileExtension: ""
  };

  onChangeHandler = async event => {
    this.setState({ selectedFile: event.target.files[0] });
    await this.setState({
      fileName: event.target.files[0] ? event.target.files[0].name : ""
    });
    await this.setState({
      fileExtension: this.state.fileName
        .split(".")
        .pop()
        .toLowerCase()
    });
  };

  onSubmit = async e => {
    const data = new FormData();
    data.append("file", this.state.selectedFile);
    this.props.resumeUpload(data, true);
    this.setState({
      fileName: ""
    });
  };

  render() {
    return (
      <div className="mb-5">
        <div className="border p-3 ">
          <div>
            <span className="pl-2 pt-2 pb-4 " style={{ fontSize: "23px" }}>
              Update Resume
            </span>
            {this.props.data.resume ? (
              <div className="d-inline-block float-right downloadArrow text-center">
                <a
                  href={`${window.BASE_URL}/uploads/userProfile/${this.props.data.resume}`}
                  download
                >
                  <i className="fa fa-download" style={{ fontSize: "20px" }} />
                  <span className="tooltiptext">Click here to Download</span>
                  <p>{this.props.data.originalName}</p>
                </a>
              </div>
            ) : null}
          </div>

          <div className="upload-btn-wrapper pt-2">
            <i
              className="fa fa-file pl-2 float-left"
              aria-hidden="true"
              style={{
                fontSize: "50px",
                color: "#6f6f70",
                paddingTop: "10px"
              }}
            ></i>
            <button className="btn d-inline-block ml-3 mt-3">Browse...</button>
            <input type="file" name="file" onChange={this.onChangeHandler} />
            <div className="d-inline-block pl-3">
              <h5> {this.state.fileName != "" ? this.state.fileName : ""}</h5>
            </div>
          </div>

          {this.state.selectedFile ? (
            <div className="text-center mt-4">
              {this.state.fileExtension == "" ? (
                <Button onClick={this.onSubmit}>Submit</Button>
              ) : (this.state.fileExtension == "docx" ||
                  this.state.fileExtension == "doc" ||
                  this.state.fileExtension == "pdf" ||
                  this.state.fileExtension == "txt") &&
                this.state.selectedFile.size < 5000000 ? (
                <Button onClick={this.onSubmit}>Submit</Button>
              ) : (this.state.fileExtension != "docx" ||
                  this.state.fileExtension != "doc" ||
                  this.state.fileExtension != "pdf" ||
                  this.state.fileExtension != "txt") &&
                this.state.selectedFile.size < 5000000 ? (
                <div>
                  <i
                    className="fa fa-exclamation-triangle d-inline-block"
                    aria-hidden="true"
                    style={{ color: "red" }}
                  ></i>
                  <h6 className="d-inline-block pl-2" style={{ color: "red" }}>
                    {this.state.fileExtension} file extension is not allowed.You
                    can only upload pdf,doc,docx,txt files.
                  </h6>
                </div>
              ) : (
                <div>
                  <i
                    className="fa fa-exclamation-triangle d-inline-block"
                    aria-hidden="true"
                    style={{ color: "red" }}
                  ></i>
                  <h6 className="d-inline-block pl-2" style={{ color: "red" }}>
                    Upload the file size less than 5mb
                  </h6>
                </div>
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

Resume.PropType = {
  resumeUpload: PropTypes.func.isRequired
};

const mapStateToProps = state => ({});
export default connect(
  mapStateToProps,
  { resumeUpload }
)(Resume);
