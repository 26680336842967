import {
  GET_GAME_DATA,
  GET_GAME_STATS,
  GET_ECODE_QUESTIONS,
  GET_ECODE_PERMISSION,
  GET_GAME_STATS_G1,
  GET_GAME_STATS_G2,
  GET_GAME_STATS_ECODE
} from "../actions/types";

const initialState = { g1: {}, stats: { wins: [] }, ecode: { questions: [] },permission:[],g1stat:{},g2stat:{},ecodestat:{} };

export default function(state = initialState, action) {
  let newState = { ...state };

  switch (action.type) {
    case GET_GAME_DATA:
      newState[action.payload.game] = { data: action.payload.data.scrips };
      return newState;
    case GET_GAME_STATS:
      newState.stats.wins = action.payload.data.wins;
      return newState;
    case GET_ECODE_QUESTIONS:
      newState.ecode.questions = action.payload.data.questions;
      return newState;
      case GET_ECODE_PERMISSION:
        return {
          ...newState,
          permission: action.payload
        };
        case GET_GAME_STATS_G1:
        return {
          ...newState,
          g1stat: action.payload
        };
        case GET_GAME_STATS_G2:
        return {
          ...newState,
          g2stat: action.payload
        };
        case GET_GAME_STATS_ECODE:
        return {
          ...newState,
          ecodestat: action.payload
        };
    default:
      return state;
  }
}
