import React from "react";
import { Table } from "reactstrap";

class Termology extends React.Component {
  render() {
    return (
      <div className="m3_termo container resource">
        <div>
          <h4 className="text-center font-weight-bold mt-5 mb-4">
            MODULE 3 - TERMINOLOGIES IN EQUITY MARKET
          </h4>
          <hr />
        </div>
        <div className="mt-5">
          <p>
            You must have come across terms like market cap, price-earnings
            ratio, face value etc. while working on your company reports and on
            the platform as a fund manager too. But it is necessary that you
            understand the concept underlying these terminologies. In this
            section, we will be learning about certain terms that are frequently
            used in the study of the stock market.
          </p>
          <ol>
            <li>Face Value:</li>
            <p>
              The face value of a share refers to the price that is stated by
              the issuing company. It is the nominal price of a share. Earlier,
              investors had an option to buy their shares either in physical
              form or in a digital form. In the physical form, investors used to
              receive paper certificates for every share they had purchased. In
              the digital form, shares are bought and sold by operating a demat
              account. Presently, shares have ceased to exist in their physical
              forms and now investors have to compulsorily do their transactions
              via a demat account.
            </p>
            <p>
              For example, suppose the company ABC Ltd is in need of funds to
              the tune of ₹ 5 lakh for expansion purposes. In this case, the
              company can issue 10,000 shares with a face value of ₹50.
            </p>
            <p>
              Usually, the face value of a share does not change unless and
              until the issuing company decides to split or consolidate its
              existing shares.
              <span>
                When a company announces to split its existing shares, it means
                that each individual stock will now be divided into a specified
                number of shares, thus increasing the number of shares held by
                each investor. For example, if a company decides to split a
                single share into 2 shares and if the face value of each share
                before the split is ₹10, then after the split each investor will
                have 2 shares in the place of one share with each share having a
                face value of ₹5.
              </span>
              <span>
                When a company announces to split its existing shares, it means
                that each individual stock will now be divided into a specified
                number of shares, thus increasing the number of shares held by
                each investor. For example, if a company decides to split a
                single share into 2 shares and if the face value of each share
                before the split is ₹10, then after the split each investor will
                have 2 shares in the place of one share with each share having a
                face value of ₹5.
              </span>
            </p>
            <li>Book Value:</li>
            <p>
              The book value of a share is determined by the book value of a
              company. The book value of a firm refers to the net worth of the
              company, which is reflected in its balance sheet. This value
              depicts the worth of the company after all its assets have been
              sold and all its liabilities have been settled up.
              <span>
                To calculate book value per share of a company, its net worth is
                divided by the total outstanding shares (shares circulating in
                the market).
                <span>
                  Book Value = Net worth of the company/Total number of
                  outstanding shares The net worth of a company is calculated by
                  deducting liabilities from its assets. It is the amount of
                  money left with the company after paying off its debts.
                </span>
              </span>
            </p>
            <p>The net worth of a company = Total Assets - Total Liabilities</p>
            <p>
              The net worth of a company is computed on the basis of historical
              values. For instance, suppose a firm had purchased a plot of land
              in 2010 for ₹5,00,000. So, in the books of the company, the book
              value of the land will be written as ₹5,00,00. Now, even if we
              calculate the net worth of the company in 2019 and consider the
              plot of land bought in 2010 as an asset, the book value of the
              land will be considered and therefore the net worth of a company
              will also be based on historical values
            </p>
            <li>Market Value:</li>
            <p>
              The market value of a share determines the market value of a
              company. It is the market price of a share which depends on
              various factors like investor sentiments, domestic and global
              market conditions, seasonal factors, expected performance of the
              company and management and growth potential of the company. As
              said earlier, these factors together determine the demand and
              supply of a particular stock in the market, which in turn
              determines its market price.
            </p>
            <p>
              Note that there is a clear difference between the market price of
              a share and the book value of a share.
              <span>
                The primary distinction between the book value of a share and
                market value of a share is that the book value of a share
                depends on the net worth of the company as reflected in its
                financial statements whereas the market value of a share is
                determined by a wide range of factors.
              </span>
            </p>
            <li>Replacement Value:</li>
            <p>
              The replacement value of an asset refers to its market value. It
              is the current market price of an asset like a building, factory,
              machinery, which the firm will have to pay for replacing it. For
              instance, the management of the company UV Ltd has taken a
              decision to replace its current machinery which was purchased in
              2009 with new machinery. In this case, the company will have to
              purchase the machinery at the current market price which might be
              greater than the price which was prevailing in 2009 because of
              factors like inflation and the rising cost born by the
              manufacturers of the machinery. So the market price which UV Ltd
              will have to pay for buying new machinery in the current year will
              be the replacement value of the machinery.
            </p>
            <li>Market Capitalization (Market Cap):</li>
            <p>
              The market capitalization of a company is simply its market value.
              Once a company undergoes an IPO, it becomes a publicly traded
              company whose shares are held and traded amongst thousands of
              investors. As explained earlier, the share price of a company
              keeps fluctuating on a daily basis because of various factors. The
              importance of the role played by a company’s stock price comes
              into play when we look at the market cap of a company. However,
              the market cap of a company is not solely determined by its stock
              price, it also depends on its number of outstanding shares (total
              number of shares circulating in the market).
            </p>
            <p>
              With that said, we can now compute the market value of a company
              with the following formula:
            </p>
            <div className="g_boxing">
              <p className="text-center">
                Market Capitalization = Total Number of Outstanding Shares *
                Share Price of the Company
              </p>
            </div>
            <p>
              It is necessary to keep in mind that the market value/market cap
              of a company is determined jointly by its number of outstanding
              shares and the market price of its shares. For instance, let us
              consider that there are two companies ABC Ltd and XYZ Ltd.
            </p>
            <Table>
              <thead>
                <tr>
                  <th />
                  <th>Company ABC Ltd</th>
                  <th>Company XYZ Ltd</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Outstanding Shares</td>
                  <td>1,00,000</td>
                  <td>5,00,000</td>
                </tr>
                <tr>
                  <td>Share Price (in Rs)</td>
                  <td>150</td>
                  <td>50</td>
                </tr>
              </tbody>
            </Table>
            <p>
              <span>
                Market Cap of company ABC Ltd: 1,00,000*150 = 1,50,00,000
              </span>
              <span>
                Market Cap of company XYZ Ltd: 5,00,000*50 = 2,50,00,000
              </span>
            </p>
            <p>
              It might come as a surprise that even though the share price of
              company ABC Ltd is higher than that of company XYZ Ltd, the market
              cap of XYZ Ltd comes out to be much higher than that of ABC Ltd.
              The reason behind this can be attributed to the fact that the
              outstanding shares of ABC Ltd are much lesser than that of XYZ
              Ltd. Therefore, both factors need to be considered for computing
              the market cap of a company.
            </p>
            <p>
              In stock markets, stocks are usually categorized on the basis of
              their market capitalization in the following ways:
            </p>
            <ul>
              <li>
                Large Cap Stocks- Also called as blue chip stocks, they
                represent the companies that have the largest market
                capitalization in the stock market. Mostly, they include the top
                100 companies on the basis of market cap.
              </li>
              <li>
                Mid Cap Stocks- They represent companies that have a strong
                potential for growth and are riskier as compared to the large
                caps. The top 101 to 250 companies’ stocks are considered to be
                mid-cap stocks.
              </li>
              <li>
                Small Cap Stocks- These stocks have greater risk as compared to
                the blue chip and mid-cap stocks. The rest of the stocks from
                250 onwards are considered to be small-cap stocks.
              </li>
              <li>
                As a virtual fund manager on Finlatics, you are investing in a
                midst of large cap, mid cap and small cap stocks in the BSE 500
                INDEX, which includes the Top 500 companies in India based on
                their market value.
              </li>
            </ul>
            <li>Enterprise Value :</li>
            <p>
              Enterprise value is nothing but the total value of a company. It
              is similar to the company’s market capitalization. It determines
              the value of the company by taking into account the company’s
              market value of equity (market cap) and the market value of its
              long term and short term debts. It differs from market cap as the
              concept of market cap only takes into account the equity shares of
              the company; the enterprise value includes the company’s debt as
              well.
            </p>
            <p>
              Suppose that an entity is interested in buying an enterprise XY
              Ltd. So the value at which XY Ltd will be sold will include its
              market cap and its debts, but the calculation of enterprise value
              does not end here. Cash reserves of the company are deducted while
              calculating the enterprise value as those cash reserves are used
              to pay off the company’s outstanding liability.
              <span>
                Whenever a takeover takes place, the buying entity not only buys
                the equity of the enterprise but also has to take care of its
                liability.
              </span>
            </p>
            <p>
              The formula for calculating enterprise value is given as follows:
            </p>
            <div className="g_boxing">
              <p className="text-center">
                Enterprise value = Market value of equity + Market value of debt
                - Cash reserves
              </p>
            </div>
            <li>Dematerialised Account</li>
            <p>
              Also called a demat account, it is a type of account availed by
              investors for holding their financial securities in an electronic
              form. As we know, earlier, shares used to be issued in physical as
              well as electronic forms. However at present, shares can be held
              only in electronic forms. Therefore it is mandatory for every
              Indian investor to have a demat account for the purpose of trading
              in securities. Every investor can buy or sell securities via
              his/her demat account.
            </p>
            <li>Earnings (Historical and Trailing)</li>
            <p>
              Earnings refer to the profits available with a corporation. There
              are different kinds of earnings such as historical earnings and
              trailing earnings. Historical earnings are the earnings from
              previous years. Trailing earnings refer to the earnings from the
              previous 4 quarters which are calculated on a rolling basis.
            </p>
            <p>
              Every company announces its earnings on a quarterly and on an
              annual basis. There are 4 quarters (Q1, Q2, Q3, Q4) in a financial
              year. If we consider the financial years of 2016-17 and 2017-18,
              then the trailing earnings of Q2 2017-18 will be calculated by
              taking into account the earnings declared at the end of Q2, Q3,
              and Q4 of the financial year 2016-17 and Q1 of financial year 2017
              - 18.
            </p>
            <p>
              Earnings Before Interest, Taxes, Depreciation, and Amortization
              (EBITDA) measures the profitability of a company without factoring
              in interest, tax and depreciation. It is calculated by subtracting
              expenses from revenue.
              <span>
                To understand the concept of EBITDA, we will first have to learn
                the meaning of depreciation and amortization.
              </span>
              <span>
                Depreciation refers to the wear and tear of machinery which
                occurs every year (due to usage of machinery) and companies need
                to allocate funds from its earnings for maintaining these
                machineries which have a depreciated value once they become old.
                Amortization is simply the depreciation of intangible assets
                like patents and trademarks.
              </span>
              <span>
                It will become easier to understand the concept of depreciation
                with the help of an example.
              </span>
              <span>
                Suppose a firm VFL Ltd had purchased a diesel generator in 2009
                for ₹2,00,000. Now the value of this diesel generator will
                reduce every year as it will wear out due to the heavy
                dependence of the firm on this generator. This wearing out of
                the generator can be called the process of depreciation.
              </span>
              <span>
                Let us assume that this generator depreciates at a rate of 20%
                each year. So, every year ₹40,000 (20% of ₹2,00,00) will get
                subtracted from the value of the machinery in the year under
                consideration. Therefore, by the end of the 5th year, the value
                of the diesel generator will become nil.
              </span>
              <span>
                Hence, VFL Ltd will set aside ₹40,000 every year for meeting
                these depreciation expenses.
              </span>
            </p>
            <Table>
              <thead>
                <tr>
                  <th>Year</th>
                  <th>Depreciation (20% of 2,00,00)</th>
                  <th>Value Of Diesel Generator (in ₹)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>2009-10</td>
                  <td>40,000</td>
                  <td>1,60,000</td>
                </tr>
                <tr>
                  <td>2010-11</td>
                  <td>40,000</td>
                  <td>1,20,000</td>
                </tr>
                <tr>
                  <td>2011-12</td>
                  <td>40,000</td>
                  <td>80,000</td>
                </tr>
                <tr>
                  <td>2012-13</td>
                  <td>40,000</td>
                  <td>40,000</td>
                </tr>
                <tr>
                  <td>2013-14</td>
                  <td>40,000</td>
                  <td>0</td>
                </tr>
              </tbody>
            </Table>
            <p>
              Finally, the EBITDA value can be referred to as those earnings
              which include the amount set aside by VFL Ltd to meet with
              interest, tax, depreciation and amortization expenditures.
            </p>
            <p>
              Earnings Before Interest and Taxes (EBIT) measures the operating
              profitability of a company. Basically, depreciation & amortization
              is subtracted from EBITDA to get EBIT.
              <span>
                EBIT essentially helps in measuring the company’s profit from
                day to day business operations inclusive of interest and tax.
              </span>
              <span>
                Continuing with the previous example, let's calculate EBIT for
                VFL Ltd. Here the EBITDA of VFL Ltd for 5 years is given from
                which we subtract depreciation to get EBIT.
              </span>
            </p>
            <Table>
              <thead>
                <tr>
                  <th>Year</th>
                  <th>EBITDA</th>
                  <th>Depreciation</th>
                  <th>EBIT</th>
                  <th>Cumulative Depreciation</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>2009-10</td>
                  <td>5,00,000</td>
                  <td>40,000</td>
                  <td>4,90,000</td>
                  <td>40,000</td>
                </tr>
                <tr>
                  <td>2010-11</td>
                  <td>7,50,000</td>
                  <td>40,000</td>
                  <td>7,10,000</td>
                  <td>80,000</td>
                </tr>

                <tr>
                  <td>2011-12</td>
                  <td>6,00,000</td>
                  <td>40,000</td>
                  <td>5,60,000</td>
                  <td>1,20,000</td>
                </tr>
                <tr>
                  <td>2012-13</td>
                  <td>4,50,000</td>
                  <td>40,000</td>
                  <td>4,10,000</td>
                  <td>1,60,000</td>
                </tr>
                <tr>
                  <td>2013-14</td>
                  <td>8,00,000</td>
                  <td>40,000</td>
                  <td>7,60,000</td>
                  <td>2,00,000</td>
                </tr>
              </tbody>
            </Table>
            <p>
              Cumulative depreciation is nothing but the addition of the money
              kept aside every year as depreciation. At the end of 5 years it
              amounts to 2,00,000 which is the value of the generator bought in
              2009. Now this amount that is set aside can be used to buy new
              machinery.
            </p>
            <li>Buyback of Shares</li>
            <p>
              A buyback involves companies buying back shares from its
              shareholders. Once a company sells its shares to its shareholders,
              in the future, it might be interested in repurchasing those same
              shares from its shareholders for various reasons which will lead
              to a reduction in the number of shares circulating in the market.
              <span>
                Just like in the case of a rights issue in which a company
                issues shares at a discount to its existing shareholders, in the
                case of a buyback a firm buys back its existing shares at a
                premium from its shareholders. So, suppose the company had
                issued its shares at a face value of ₹50 in 2014. Then, in 2018
                the firm will buy back its shares at ₹70 from its shareholders.
              </span>
              <span>
                The company might want to avoid a takeover bid by reducing the
                number of shares available in the open market as this would
                prevent a single entity from buying a large number of its
                shares. Also, a buyback of shares will lead to an increase in
                the percentage of shares held by each investor.
              </span>
              <span>
                Suppose a company ABC Ltd had earlier issued 1,00,000 shares in
                the market and after 5 years, the company decided to buy back
                20,000 shares from the open market, then the current number of
                outstanding shares will be 80,000.
              </span>
              <span>
                Let us consider that before the buyback, an investor X has 2000
                shares of the company ABC Ltd and hence holds 2% of the total
                outstanding shares of the company. Now after the buyback, the
                investor X will still hold 2000 shares of ABC Ltd, only the
                total number of outstanding shares in the market will reduce and
                as a result, the proportion of shares held by X will increase
                from 2% to 2.5%. Each share issued by a company represents
                ownership in the company. When there are a large number of
                shares available in the market, the ownership of the company
                also gets distributed amongst a large number of investors.
                Similarly, in the case of a buyback, as the total number of
                outstanding shares is reduced, the ownership of the company will
                also become consolidated.
              </span>
              <span>
                Another reason why a company might buy back its shares is to
                reduce the supply of its shares in the market, thus increasing
                the value of its remaining shares.
              </span>
            </p>
          </ol>
        </div>
      </div>
    );
  }
}

export default Termology;
