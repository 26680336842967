import React from "react";
import {
  Card,
  CardText,
  CardBody,
  CardTitle,
  Collapse,
  Row,
  Col
} from "reactstrap";
class Question5 extends React.Component {
  state = {
    is_open: false
  };
  toggle = () => {
    this.setState({ is_open: !this.state.is_open });
  };
  render() {
    const data = this.props.data;
    return (
      <div onClick={this.toggle} style={{ cursor: "pointer" }}>
        <div style={{ backgroundColor: "#e4e4e4"}} className="p-2 mt-2">
          <i
            className={
              "fa fontsize-5 d-inline-block  align-middle " +
              (this.state.is_open ? "fa-minus" : "fa-plus")
            }
          />
          <span className="d-inline-block pl-2 pt-1">
            <h5>The market is going down. Will this impact my program performance?</h5>
          </span>
        </div>

        <Collapse isOpen={this.state.is_open}>
          <Card >
            <CardBody onClick={this.toggle}>
              <CardTitle />
              <CardText className="">
<p>The simple answer is no. Even though the market is going down and your net worth is decreasing, an important part of the program is the percentile with which you finish it.</p>
<p>Soon, in your stats section there will be a percentile measure and that is a very important measure of your performance.</p>
              </CardText>
            </CardBody>
          </Card>
        </Collapse>
      </div>
    );
  }
}
export default Question5;
