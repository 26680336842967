import axios from "axios";
import { logout } from "./authActions";

import {
  ERRORS_GET,
  USER_DETAILS_SET,
  CERTIFICATE_GET,
  REPORT_GET,
  RESEARCH_GET,
  USER_RENEWAL
} from "./types";

export const getCurrentUserDetails = () => dispatch => {
  axios
    .get("/api/user/info")
    .then(res => {
     
     // res.data.fb_access_token ===window.user_token?dispatch(setCurrentUser(res.data)):dispatch(logout());
     dispatch(setCurrentUser(res.data))
    })
    .catch(err =>
      dispatch({
        type: ERRORS_GET
      })
    );
};

export const setSignupStep = step => dispatch => {
  axios
    .post("/api/user/signup_step", { step: step })
    .then(res => {})
    .catch(err =>
      dispatch({
        type: ERRORS_GET
      })
    );
};

export const getCertificate = () => dispatch => {
  axios
    .get("/api/user/download_certificate")
    .then(res => {
      dispatch({
        type: CERTIFICATE_GET,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: ERRORS_GET
      })
    );
};

export const getReport = () => dispatch => {
  axios
    .get("/api/user/download_report")
    .then(res => {
      dispatch({
        type: REPORT_GET,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: ERRORS_GET
      })
    );
};

export const getreportResearch = () => dispatch => {
  axios
    .get("/api/user/download_research")
    .then(res => {
      dispatch({
        type: RESEARCH_GET,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: ERRORS_GET
      })
    );
};

export const getuserRenew = () => dispatch => {
  axios
    .get("/api/user/renewid")
    .then(res => {
      dispatch({
        type: USER_RENEWAL,
        payload: res.data
      });
    })
    .catch(err => console.log(err));
};


export const setCurrentUser = decoded => {
  return {
    type: USER_DETAILS_SET,
    payload: decoded
  };
};
