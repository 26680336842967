import { GET_NW_STATS } from "../actions/types";

const initialState = {
  net_worth: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_NW_STATS:
      return { ...state, net_worth: action.payload.data };
    default:
      return state;
  }
}
