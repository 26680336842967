import React, { Component } from "react";
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import store from "./store";
import { loadInitialDataSocket } from "./actions/socketActions";
import Loader from "./components/Loader";
import { verifyToken, logout } from "./actions/authActions";

import Notification from "./components/Notification";
import Dashboard from "./components/Dashboard";
import Main from "./components/Main";
import PostSignup from "./components/PostSignup";
import Header from "./components/Header";
import Newresearch from "./components/Newresearch";
import Portfolio from "./components/Portfolio";
import Ticker from "./components/Ticker";
import Company from "./components/Company";
import Games from "./components/Games";
import GamesPerformance from "./components/Games/games_performance";
import Stats from "./components/Stats";
import Research from "./components/Research";
import Resource from "./components/Resource";
import Profile from "./components/Profile";
import FAQs from "./components/FAQs";
// import EventDetails from "./components/Leaderboard/leader";

class App extends Component {
  componentDidMount() {
    if (window.user_token) {
      store.dispatch(verifyToken(window.user_token));
      store.dispatch(loadInitialDataSocket(window.user_token));
      window.$("#ticker1").jStockTicker({ interval: 10 });
      window.$("#ticker2").jStockTicker({ interval: 15 });
    } else {
      store.dispatch(logout());
      //logout and redirect to homepage
    }
  }

  render() {
    return (
      <Provider store={store}>
        <Router>
          <div className="App">
            <Header />
            <Loader />
            <Main />
            <div>
              <Switch>
                <Route path="/signup" component={PostSignup} />
                <Route path="/dashboard" component={Dashboard} />
                <Route path="/notifications" component={Notification} />
                <Route exact path="/portfolio" component={Portfolio} />
                {/* <Route exact path="/leaderboard" component={EventDetails} /> */}
                <Route exact path="/company" component={Company} />
                <Route exact path="/stats" component={Stats} />
                <Route path="/games/performance" component={GamesPerformance} />
                 <Route path="/games" component={Games} /> 
                <Route path="/research" component={Research} />
                <Route path="/newresearch" component={Newresearch} />
                <Route path="/resource" component={Resource} />
                <Route path="/profile" component={Profile} />

                <Route path="/faq" component={FAQs} />
              </Switch>
            </div>
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar
              newestOnTop
              closeOnClick
              rtl={false}
              pauseOnVisibilityChange
              draggable
              pauseOnHover
            />

            <Ticker />
          </div>
        </Router>
      </Provider>
    );
  }
}

export default App;