import React from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  UncontrolledTooltip
} from "reactstrap";

import Question1 from "./question_1";
import Question2 from "./question_2";
import Question3 from "./question_3";
import Question4 from "./question_4";
import Question5 from "./question_5";
import Question6 from "./question_6";
import Question7 from "./question_7";
import Question8 from "./question_8";
import Question9 from "./question_9";

class FAQs extends React.Component {
  state = {
    active: ""
  };

  render() {
    return (
      <div className="container p-2">
        <div className="FAQs pt-4 ">
          <h4 style={{ color: "#727272" }} className="pb-4 pt-2">
            {" "}
            FAQs{" "}
          </h4>
        </div>
        <div>
          <Question1 onSelect={this.setActive} active={this.state.active} />
          <Question2 onSelect={this.setActive} active={this.state.active} />
          <Question3 onSelect={this.setActive} active={this.state.active} />
          <Question4 onSelect={this.setActive} active={this.state.active} />
          <Question5 onSelect={this.setActive} active={this.state.active} />
          <Question6 onSelect={this.setActive} active={this.state.active} />
          <Question7 onSelect={this.setActive} active={this.state.active} />
          <Question8 onSelect={this.setActive} active={this.state.active} />
          <Question9 onSelect={this.setActive} active={this.state.active} />
        </div>
      </div>
    );
  }
}

export default FAQs;
