import {
  HR_QUESTIONS_GET,
  HR_RESPONSE_SAVE,
  HR_ANSWER_SET,
  HR_ADD_BLOCK,
  HR_REMOVE_BLOCK
} from "../actions/types";

const initialState = { questions: [], answer: [] };

export default function(state = initialState, action) {
  switch (action.type) {
    case HR_QUESTIONS_GET:
      return {
        questions: action.payload.questions,
        hrPages: action.payload.hrPages,
        hrBlocks: action.payload.hrBlocks,
        defaultVals: action.payload.defaultVals,
        institutes: action.payload.institutes
      };
    case HR_ANSWER_SET:
      let newState = { ...state };
      if (!newState.defaultVals[action.payload.block_id]) {
        newState.defaultVals[action.payload.block_id] = [];
      }
      if (
        !newState.defaultVals[action.payload.block_id][
          action.payload.reapeat_index
        ]
      ) {
        newState.defaultVals[action.payload.block_id][
          action.payload.reapeat_index
        ] = {};
      }
      newState.defaultVals[action.payload.block_id][
        action.payload.reapeat_index
      ][action.payload.qid] = action.payload.answer;
      return newState;
      break;
    case HR_ADD_BLOCK:
      newState = { ...state };
      if (!newState.defaultVals[action.payload.block_id]) {
        newState.defaultVals[action.payload.block_id] = [];
        newState.defaultVals[action.payload.block_id].push({});
      }
      newState.defaultVals[action.payload.block_id].push({});
     
      return newState;
      break;
    case HR_REMOVE_BLOCK:
      newState = { ...state };
      newState.defaultVals[action.payload.block_id].splice(
        action.payload.index,
        1
      );

      return newState;
      break;
    default:
      return state;
  }
}
