import React from "react";

class LiquidityRatios extends React.Component {
  render() {
    return (
      <div className="container resource">
        <div className="mt-5">
          <h5 className="text-center mb-4">
            <u>
              <b>LIQUIDITY RATIOS</b>
            </u>
          </h5>
        </div>
        <p>
          Liquidity ratios are a type of financial ratios that indicate how
          quickly a company is able to pay off its debt. Basically, it reveals
          the liquidity of a company to convert its current assets to cash for
          meeting its debt obligations.
        </p>
        <p>
          Current assets refer to assets that can be converted to cash within a
          period of one year. On the balance sheet, current assets include cash
          equivalents, accounts receivable, inventory and prepaid expenses.
          Similarly, current liabilities refer to the short term debt
          obligations of the company which are required to be met within a
          period of one year. Current liabilities include accounts payable,
          sales taxes payable and payroll taxes payable.
        </p>
        <ol>
          <li>Current Ratio</li>
          <p>
            It is a type of liquidity ratio that gauges the ability of a company
            to pay off its current liabilities. It reveals the proportion of
            current assets available with the company relative to its current
            debt obligations. It is necessary that a firm should possess
            sufficient current assets to meet its short term debt obligations.
          </p>
          <p>
            The value of the current ratio is arrived at with the help of the
            given formula:
          </p>
          <div className="g_boxing">
            <p className="text-center">
              Current Ratio = Current assets / Current Liabilities
            </p>
          </div>
          <p>
            The current ratio is considered to be an important ratio for
            determining the financial health of a company. It measures a firm’s
            short term solvency. There does not exist any specific acceptable
            current ratio and it usually varies from industry to industry.
            <br />
            However, it is preferred by creditors that a company should have at
            least a current ratio of 1 or more than 1 so that it has enough
            assets to convert into cash for paying its current debts. But a very
            high value of the current ratio, like 6 or 7, is regarded as a poor
            indicator of a company’s ability to use its assets for expansion
            purposes or for making profitable investments elsewhere. Even a low
            current ratio like 1 or less than one does not necessarily mean that
            the company is not performing well.
          </p>
          <li>Quick Ratio</li>
          <p>
            Similar to the current ratio, quick ratio is a liquidity ratio that
            is used to reveal how quick the company is in paying off its short
            term debts. But the difference between the current ratio and the
            quick ratio is the quick ratio is a more appropriate measure of
            determining the liquidity of a company as it does not include
            inventory as a current asset. When we calculate the current ratio,
            the inventory is considered to be a part of the current assets and
            is included while computing the current ratio.
            <br />
            On the other hand, inventory is not included in the current assets
            while computing the quick ratio because inventory levels are not
            that liquid in the short run as they cannot be converted into cash
            easily. Therefore the quick ratio is a more rigorous measure of
            determining a company’s ability to convert its assets easily to cash
            for paying its debts.
          </p>
          <p>The formula for quick ratio is given as:</p>
          <div className="g_boxing">
            <p className="text-center">
              Quick Ratio =(Current assets - Inventories) / Current liabilities
            </p>
          </div>
          <p>
            Just like the current ratio, a quick ratio of 1 or more reveals that
            the company is fully equipped with enough assets to instantly
            convert them into cash to pay off its liabilities.
          </p>
        </ol>
      </div>
    );
  }
}

export default LiquidityRatios;
