import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter,Row, Col,Table, Input, Label } from 'reactstrap';


export default class ReasonModal extends React.Component {
    state = {
        reason: ''
    }

    setReasonBuy = (e) =>{
        this.setState({reason:e.target.value});
    }

    render(){
            return (
                <Modal isOpen={this.props.show} backdrop={true} size="lg">
                <ModalHeader >Please provide the reason for the trade?</ModalHeader>
                <ModalBody>
                    <Row style={{marginTop: '10px'}}>
                        <Col sm="4" >
                            <div style={{fontSize: '20px', fontWeight: '400'}}>Reason for trade</div>
                            <i>*Fetches extra points. You can access via 'My Order' book if you want to edit/fill it later</i>
                        </Col>
                        <Col sm="8">
                            <Input onChange={this.setReasonBuy} type="textarea" name="text" style={{height: '100px', borderColor: '#000'}} />
                        </Col>
                    </Row>
        
                </ModalBody>
                <ModalFooter>
                        <Button className="btn site-gray" style={{marginRight: '15px', width: '150px', fontSize: '18px!important'}} onClick={() => this.props.handleReason(this.state.reason)}>SUBMIT</Button>        
                        <Button className="btn site-gray" onClick={this.props.hideModal} style={{width: '150px', fontSize: '18px!important'}}>CANCEL</Button>
                    
                </ModalFooter>
                </Modal>
                
            );
    }
}