import React from "react";
import { Card, Button, CardTitle, CardText, Row, Col, Input } from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classnames from "classnames";
import {
  SortableContainer,
  SortableElement,
  arrayMove
} from "react-sortable-hoc";
import { Link, withRouter } from "react-router-dom";
import {
  getHrQuestions,
  setAnswer,
  saveAnswer,
  addBlock,
  removeBlock
} from "../../actions/hrActions";
import YearMonth from "../Common/FormComponents/year_month";
import Salary from "../Common/FormComponents/salary";
import FileUpload from "../Common/FormComponents/file_upload";

const SortableItem = SortableElement(({ value, sortIndex }) => (
  <li className="Showcase__style__stylizedItem">
    <span className="Showcase__style__stylizedItemNumber">{sortIndex + 1}</span>{" "}
    {value}{" "}
  </li>
));

const SortableList = SortableContainer(
  ({ items, qid, dataVal, dataBlockId, dataRepeatIndex }) => {
    return (
      <ul
        className="Showcase__style__stylizedList page-question"
        data-qid={qid}
        data-block-id={dataBlockId}
        data-val={JSON.stringify(dataVal)}
        data-repeat-index={dataRepeatIndex}
      >
        {items.map((value, index) => (
          <SortableItem
            key={`item-${value.id}`}
            index={index}
            sortIndex={index}
            value={value.option}
          />
        ))}
      </ul>
    );
  }
);

class HRQuestions extends React.Component {
  state = { page_index: 0, block_counts: {}, pageState: {} };

  onSortEnd = (oldIndex, newIndex, q, block_id, reapeat_index) => {
    let answer = this.getDefaultVal(q, block_id, reapeat_index);
    let newAnswer = arrayMove(answer, oldIndex, newIndex);
    this.props.setAnswer(q.id, newAnswer, block_id, reapeat_index);
  };

  componentDidMount() {
    this.props.getHrQuestions();
  }

  sortOptions = (options, sortOrder) => {
    return sortOrder.map(el => options.find(elOption => el == elOption.id));
  };
  setVal = (qid, q, e, block_id, reapeat_index) => {
    if (
      q.type == "TEXT" ||
      q.type == "SINGLE" ||
      q.type == "YEAR_MONTH" ||
      q.type == "SALARY"
    ) {
      let value = typeof e == "string" ? e : e.target.value;
      this.props.setAnswer(qid, value, block_id, reapeat_index);
    } else {
      this.props.setAnswer(
        qid,
        [].slice.call(e.target.selectedOptions).map(o => {
          return o.value;
        }),
        block_id,
        reapeat_index
      );
    }
  };

  getDefaultVal = (q, block_id, reapeat_index) => {
    const hasVal =
      this.props.hr.defaultVals[block_id] &&
      this.props.hr.defaultVals[block_id][reapeat_index] &&
      this.props.hr.defaultVals[block_id][reapeat_index][q.id]
        ? true
        : false;

    let defaultVal = "";
    if (!hasVal && (q.type == "SINGLE" || q.type == "MULTIPLE")) {
      defaultVal = 0;
    } else if (!hasVal && q.type == "SORTABLE") {
      defaultVal = q.HrQuestionOptions.map(el => el.id);
    }
    return hasVal
      ? this.props.hr.defaultVals[block_id][reapeat_index][q.id]
      : defaultVal;
  };
  optionLayout = (q, block_id, reapeat_index) => {
    let defaultVal = this.getDefaultVal(q, block_id, reapeat_index);
    if (q.type == "SINGLE" || q.type == "MULTI" || q.type == "EXTERNAL") {
      let options =
        q.type == "EXTERNAL"
          ? this.props.hr[q.HrQuestionOptions[0].option]
          : q.HrQuestionOptions;
      var multi = q.type == "MULTI" ? { mutiple: true } : {};
      return (
        <select
          data-required={q.is_required}
          value={defaultVal}
          onChange={e => this.setVal(q.id, q, e, block_id, reapeat_index)}
          multiple={q.type == "MULTI" ? true : false}
          data-qid={q.id}
          data-repeat-index={reapeat_index}
          data-block-id={block_id}
          className="form-control page-question"
        >
          {defaultVal == 0 && q.placeholder ? (
            <option value="0">{q.placeholder}</option>
          ) : null}
          {options.map(o => (
            <option key={o.id} value={o.id}>
              {o.option}
            </option>
          ))}
        </select>
      );
    } else if (q.type == "YEAR_MONTH") {
      return (
        <YearMonth
          className="page-question"
          qid={q.id}
          dataBlockId={block_id}
          dataRepeatIndex={reapeat_index}
          dataVal={defaultVal}
          setVal={val => this.setVal(q.id, q, val, block_id, reapeat_index)}
        />
      );
    } else if (q.type == "SALARY") {
      return (
        <Salary
          qid={q.id}
          dataBlockId={block_id}
          dataRepeatIndex={reapeat_index}
          dataVal={defaultVal}
          setVal={val => this.setVal(q.id, q, val, block_id, reapeat_index)}
        />
      );
    } else if (q.type == "FILE") {
      return (
        <FileUpload
          qid={q.id}
          dataVal={defaultVal}
          dataBlockId={block_id}
          dataRepeatIndex={reapeat_index}
          setVal={val => this.setVal(q.id, q, val, block_id, reapeat_index)}
        />
      );
    } else if (q.type == "TEXT") {
      return (
        <Input
          data-required={q.is_required}
          data-qid={q.id}
          data-block-id={block_id}
          data-repeat-index={reapeat_index}
          className="form-control page-question"
          onChange={e => this.setVal(q.id, q, e, block_id, reapeat_index)}
          value={defaultVal}
          type="text"
        />
      );
    } else if (q.type == "SORTABLE") {
      let newOptions = this.sortOptions(q.HrQuestionOptions, defaultVal);
      return (
        <SortableList
          qid={q.id}
          dataBlockId={block_id}
          dataVal={defaultVal}
          dataRepeatIndex={reapeat_index}
          items={newOptions}
          onSortEnd={({ oldIndex, newIndex }) =>
            this.onSortEnd(oldIndex, newIndex, q, block_id, reapeat_index)
          }
        />
      );
    }
  };

  getQuestionIndexFromID = id => {
    return this.props.hr.questions.findIndex(el => el.id == id);
  };

  isParentActive = (q, block_id, reapeat_index) => {
    while (q.parent_question_id > 0) {
      const q_index = this.getQuestionIndexFromID(q.parent_question_id);

      if (
        q_index < 0 ||
        (this.getDefaultVal(
          this.props.hr.questions[q_index],
          block_id,
          reapeat_index
        ) != q.parent_option_id &&
          q.parent_option_id != 0)
      ) {
        return false;
      }
      q = this.props.hr.questions[q_index];
    }
    return true;
  };
  questionLayout = (q, i, block_id, reapeat_index) => {
    let do_render = false;

    if (q.parent_question_id == 0) {
      do_render = true;
    } else {
      if (this.isParentActive(q, block_id, reapeat_index)) {
        do_render = true;
      }
    }
    if (do_render) {
      return (
        <div key={q.id} className="form-group">
          <label>
            <h5 style={{ margin: "15px 0px 5px 0px" }}>
              {q.question}
              {q.is_required ? (
                <span className="astrisk_required">*</span>
              ) : null}
            </h5>
          </label>
          {this.optionLayout(q, block_id, reapeat_index)}
        </div>
      );
    } else {
      return null;
    }
  };

  getNextPageIndex = forward => {
    let next_page_index = -1;
    for (
      let i = forward ? this.state.page_index + 1 : this.state.page_index - 1;
      forward ? i < this.props.hr.hrPages.length : i >= 0;
      forward ? i++ : i--
    ) {
      const p_qid = this.props.hr.hrPages[i].parent_question_id;
      if (p_qid == 0) {
        next_page_index = i;
        break;
      } else {
        //const q_index = this.getQuestionIndexFromID(p_qid);
        const p_oid = this.props.hr.hrPages[i].parent_option_id;
        let found_option = p_oid == 0;

        if (!found_option) {
          Object.keys(this.props.hr.defaultVals).forEach(key => {
            let block = this.props.hr.defaultVals[key];
            if (!block) return true;
            Object.keys(block).forEach(block_q => {
              if (block[block_q][p_qid] == p_oid) {
                found_option = true;
                return false;
              }
            });
            return !found_option;
          });
        }
        if (found_option) {
          next_page_index = i;
          break;
        }
      }
    }
    if (next_page_index >= 0) {
      window.$("html, body").animate({ scrollTop: 0 }, 200);
    }
    return next_page_index;
  };

  validatePage = () => {
    const $ = window.$;
    let valid = true;
    $(".error").removeClass("error");
    $(".page-question").each(function() {
      if ($(this).attr("data-required") == "true") {
        if (
          ($(this).is("select") && $(this).val() == "0") ||
          $(this)
            .val()
            .trim() == ""
        ) {
          $(this).addClass("error");
          valid = false;
        }
      }
    });
    return valid;
  };
  saveFormState = clear => {
    const $ = window.$;
    let page_id = $(".hr-page").attr("data-page-id");
    let pageData = { ...this.state.pageData };

    let found_errors = false;
    if (clear) {
      delete pageData[page_id];
    } else {
      let qData = {};
      found_errors = !this.validatePage();
      if (found_errors) return;
      $(".page-question").each(function() {
        let val = "";
        let ri = $(this).attr("data-repeat-index");
        if ($(this).attr("data-val")) {
          val = $(this).attr("data-val");
        } else {
          val = $(this).val();
        }
        if (!qData[ri]) {
          qData[ri] = {};
        }
        qData[ri][$(this).attr("data-qid")] = val;
      });
      pageData[page_id] = qData;
    }

    if (found_errors) return false;
    this.setState({ pageData: pageData });
    return pageData;
  };
  goBack = () => {
    this.saveFormState(true);
    if (this.state.page_index > 0) {
      let next_page_index = this.getNextPageIndex(false);

      if (next_page_index >= 0) {
        return this.setState({ page_index: next_page_index });
      }
    }
  };

  submitAnswer = () => {
    let pData = this.saveFormState(false);
    if (pData) {
      if (this.state.page_index < this.props.hr.hrPages.length - 1) {
        let next_page_index = this.getNextPageIndex(true);

        if (next_page_index >= 0) {
          return this.setState({ page_index: next_page_index });
        }
      }
      this.props.saveAnswer(pData);
      if (this.props.user.post_signup_steps > 2) {
        this.props.history.push("/portfolio");
      } else {
        this.props.history.push("/signup/step2");
      }
    }
  };

  genRepeatIndex = d => {
    let ret = this.props.hr.defaultVals[d] ? this.props.hr.defaultVals[d] : [0];

    return ret;
  };

  getBlock = (page_id, questions, hrBlocks) => {
    return hrBlocks.filter(b => b.page_id == page_id).map((b, i) => (
      <div key={i}>
        {this.genRepeatIndex(b.id).map((val, defaultValsIndex) => (
          <div key={defaultValsIndex}>
            <h4>
              {b.title}{" "}
              {defaultValsIndex > 0 ? (
                <button
                  className="btn btn-danger"
                  onClick={() => this.props.removeBlock(b.id, defaultValsIndex)}
                >
                  Remove
                </button>
              ) : null}
            </h4>
            {questions
              .filter(q => q.block_id == b.id)
              .map((q, i) => this.questionLayout(q, i, b.id, defaultValsIndex))}
            <hr />
          </div>
        ))}
        {b.is_repeatable ? (
          <button
            className="btn btn-primary"
            style={{ marginBottom: "20px" }}
            onClick={() => this.addBlock(b.id)}
          >
            {b.button_label}
          </button>
        ) : null}
      </div>
    ));
  };

  addBlock = bid => {
    if (this.validatePage()) {
      this.props.addBlock(bid);
    }
  };
  getPage = (questions, hrPages, hrBlocks) => {
    let page_index = this.state.page_index;
    if (hrPages.length == 0) {
      return null;
    }
    return (
      <div className="hr-page" data-page-id={hrPages[page_index].id}>
        <h3 style={{ display: "none" }}>{hrPages[page_index].title} </h3>
        <br />
        {this.getBlock(hrPages[page_index].id, questions, hrBlocks)}
        <div className="form-group">
          {page_index > 0 ? (
            <span style={{ paddingRight: "10px" }}>
              <button onClick={this.goBack} className="btn site-gray">
                Back
              </button>
            </span>
          ) : null}

          <button onClick={this.submitAnswer} className="btn site-gray">
            {page_index < hrPages.length - 1 ? "Next" : "Submit"}
          </button>
        </div>
      </div>
    );
  };
  render() {
    const { questions, hrPages, hrBlocks } = this.props.hr;
    if (!hrPages || !questions || hrPages.length == 0 || questions.length == 0)
      return null;
    return (
      <Row style={{ width: "80%", paddingTop: "20px", paddingBottom: "20px" }}>
        <Col md="4" sm="12" />
        <Col md="8" sm="12" style={{ paddingBottom: "60px" }}>
          <div>
            <h2>Tell your future employer you are worth it!</h2>
            <hr />
            {this.getPage(questions, hrPages, hrBlocks)}
          </div>
        </Col>

        {this.props.user.post_signup_steps > 2 ? (
          <div className="continue-btn" style={{ left: "0px" }}>
            <Link
              to="/dashboard"
              className="btn site-gray"
              style={{ float: "right", marginRight: "25px" ,marginTop: "-10px" }}
            >
              HOME
            </Link>
          </div>
        ) : null}
      </Row>
    );
  }
}

HRQuestions.propTypes = {
  getHrQuestions: PropTypes.func.isRequired,
  setAnswer: PropTypes.func.isRequired,
  saveAnswer: PropTypes.func.isRequired,
  addBlock: PropTypes.func.isRequired,
  removeBlock: PropTypes.func.isRequired,
  hr: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  hr: state.hr,
  user: state.user.info,
  errors: state.errors
});

export default withRouter(
  connect(
    mapStateToProps,
    { getHrQuestions, setAnswer, saveAnswer, addBlock, removeBlock }
  )(HRQuestions)
);
