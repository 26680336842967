import React from "react";
import styled from "styled-components";
import {
  Nav,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import { toast } from "react-toastify";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getNotifications, markRead } from "../../actions/notificationActions";
import { formatDateTimeToIndia } from "../../utils/format";

const NotiHolder = styled.div`
  position: relative;
  top: 10px;
  width: 24px;
`;

class Notification extends React.Component {
  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
    this.state = {
      notiOpen: false
    };
  }

  toggle = () => {
    if (!this.state.notiOpen && this.props.notifications.count > 0) {
      this.props.markRead(5);
    }
    this.setState(prevState => ({
      notiOpen: !prevState.notiOpen
    }));
  };

  componentDidMount = () => {
    this.props.getNotifications(5);
    document.addEventListener("mousedown", this.handleClickOutside);
  };

  componentWillUnmount = () => {
    document.removeEventListener("mousedown", this.handleClickOutside);
  };

  handleClickOutside = event => {
    if (
      this.wrapperRef &&
      this.wrapperRef.current.contains &&
      !this.wrapperRef.current.contains(event.target) &&
      this.state.notiOpen
    ) {
      this.setState({
        notiOpen: false
      });
    }
  };

  componentDidUpdate(prevProp) {
    if (prevProp.notifications.msg.rnd != this.props.notifications.msg.rnd) {
      toast(this.props.notifications.msg.msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    }
  }

  render() {
    console.log(this.props.notifications.count, "notificationssss")
    let notiCalss =
      "dropdown-menu dropdown-menu-right animated flipInX " +
      (this.state.notiOpen ? "show" : "");
    let notification_count = this.props.notifications.count;
    let notifications =
      this.props.notifications.latest.length > 5
        ? this.props.notifications.latest.slice(0, 5)
        : this.props.notifications.latest;
    return (
      <NotiHolder innerRef={this.wrapperRef}>
        <a
          className="nav-link dropdown-toggle-nocaret"
          onClick={this.toggle}
          aria-expanded="true"
        >
          <em className="fa fa-bell" />
          {parseInt(notification_count) > 0 ? (
            <span className="badge badge-danger">{notification_count}</span>
          ) : null}
        </a>
        <div className={notiCalss}>
          <div className="dropdown-item">
            <div className="list-group">
              {notifications.length > 0
                ? notifications.map(n => (
                    <div
                      key={n.id}
                      className="list-group-item list-group-item-action"
                    >
                      <div className="media">
                        <div className="align-self-start mr-2">
                          <em className="fa fa-line-chart fa-2x text-primary" />
                        </div>
                        <div className="media-body">
                          <p className="m-0">{n.message}</p>
                          <div className="m-0 text-muted text-sm">
                            {formatDateTimeToIndia(n.createdAt)}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                : null}

              <div className="list-group-item list-group-item-action">
                <span className="d-flex align-items-center">
                  <Link to="/notifications" className="text-sm text-primary">
                    All notifications
                  </Link>
                  {parseInt(notification_count) > 0 ? (
                    <span className="badge badge-danger ml-auto">
                      {notification_count}
                    </span>
                  ) : null}
                </span>
              </div>
            </div>
          </div>
        </div>
      </NotiHolder>
    );
  }
}

Notification.propTypes = {
  markRead: PropTypes.func.isRequired,
  getNotifications: PropTypes.func.isRequired,
  notifications: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  notifications: state.notifications,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { getNotifications, markRead }
)(Notification);
