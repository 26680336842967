import { NEWRESEARCH_DATA, NEWRATERESEARCH_DATA,NEWGET_NOTIFY_SCORE } from "../actions/types";

const initialState = {
  data: {},
  rate_research: {},
  notify:{}

};

export default function(state = initialState, action) {
  switch (action.type) {
    case NEWRESEARCH_DATA:
      return { ...state, data: action.payload.data,ratings:action.payload.ratings ,rated:action.payload.rated };
    case NEWRATERESEARCH_DATA:
      return {
        ...state,
        rate_research: action.payload
      };
      case NEWGET_NOTIFY_SCORE:
      return {
        ...state,
        notify: action.payload
      };
    default:
      return state;
  }
}

