import React from "react";
import { Label, Input, Button } from "reactstrap";
import moment from "moment";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import ReactCountdownClock from "react-countdown-clock";
import { getvideoScore, fetchvideoScore } from "../../../actions/resourceActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
class VM4_quiz extends React.Component {
  state = {
    submitted: false,
    q1: "",
    q2: "",
    q3: "",
    q4:"",
    q5:"",
    q6:"",
    q7:"",
    q8:"",
    q9:"",
    q10:"",

    alert: true
  };
  componentDidMount() {
    this.props.fetchvideoScore();
  }
  handleSubmit = e => {
    e.preventDefault();
    this.setState({
      submitted: true,
      q1: e.target.mq41.value,
      q2: e.target.mq42.value,
      q3: e.target.mq43.value,
      q4: e.target.mq44.value,
      q5: e.target.mq45.value,
      q6: e.target.mq46.value,
      q7: e.target.mq47.value,
      q8: e.target.mq48.value,
      q9: e.target.mq49.value,
      q10: e.target.mq40.value

    });
  };

  getScore(q1, q2, q3,q4,q5,q6,q7,q8,q9,q10) {
    let score = 0;
    let questions = this.props.questions.Quiz.filter(o => o.model == 4);
    if (q1 == questions[0].correct) {
      score = score + 1;
    }
    if (q2 == questions[1].correct) {
      score = score + 1;
    }
    if (q3 == questions[2].correct) {
      score = score + 1;
    }
    if (q4 == questions[3].correct) {
      score = score + 1;
    }
    if (q5 == questions[4].correct) {
      score = score + 1;
    }
    if (q6 == questions[5].correct) {
      score = score + 1;
    }
    if (q7 == questions[6].correct) {
      score = score + 1;
    }
    if (q8 == questions[7].correct) {
      score = score + 1;
    }
    if (q9 == questions[8].correct) {
      score = score + 1;
    }
    if (q10 == questions[9].correct) {
      score = score + 1;
    }
    this.props.getvideoScore(score, this.props.userId, 4);
    return score;
  }

  questionData() {
    const questions = this.props.questions.Quiz.filter(o => o.model == 4);
    return (
      <div>
        <div className="resource">
          <Modal isOpen={this.props.toggle} className="resource">
            <ModalHeader>
              <b>Are you sure you want to proceed with the quiz?</b>
            </ModalHeader>
            <ModalBody className="text-center">
              Hope you have read Module 4
            </ModalBody>
            <ModalFooter className="text-center">
              <button
                onClick={() => {
                  this.setState({ alert: false });
                  this.props.onToggle(false);
                }}
                className="btn"
              >
                Yes
              </button>

              <button
                onClick={() => {
                  this.props.onSelect("vedio-4");
                  this.props.onToggle(false);
                }}
                className="btn"
              >
                No
              </button>
            </ModalFooter>
          </Modal>
          <form onSubmit={this.handleSubmit}>
            <div className="container">
              {this.state.submitted ? (
                ""
              ) : (
                <div className="mt-3">
                  <div className="w-1">
                    <h5 className="text-right mt-4">
                      <b>QUIZ</b>
                    </h5>
                  </div>

                  <div className="f-1">
                    <ReactCountdownClock
                      seconds={150}
                      color="#000"
                      alpha={0.9}
                      size={75}
                      paused={this.state.alert ? true : false}
                      onComplete={e => {
                        this.setState({ submitted: true });
                      }}
                    />
                  </div>
                </div>
              )}

              <ol>
                <div>
                  <h6 className="pt-4">
                    <li>{questions[0].question}</li>
                  </h6>

                  <div className="pl-4">
                    <div className="radio">
                      <Label>
                        <Input
                          type="radio"
                          name="mq41"
                          value={questions[0].options[0]}
                          onChange={e => {
                            this.setState({ q1: e.target.value });
                          }}
                          disabled={this.state.submitted}
                        />
                        {questions[0].options[0]}
                      </Label>
                    </div>
                    <div className="radio">
                      <Label>
                        <Input
                          type="radio"
                          name="mq41"
                          value={questions[0].options[1]}
                          onChange={e => {
                            this.setState({ q1: e.target.value });
                          }}
                          disabled={this.state.submitted}
                        />
                        {questions[0].options[1]}
                      </Label>
                    </div>
                    <div className="radio">
                      <Label>
                        <Input
                          type="radio"
                          name="mq41"
                          value={questions[0].options[2]}
                          onChange={e => {
                            this.setState({ q1: e.target.value });
                          }}
                          disabled={this.state.submitted}
                        />
                        {questions[0].options[2]}
                      </Label>
                    </div>

                  </div>

                  {this.state.submitted ? (
                    <span
                      className={
                        this.state.q1 == questions[0].correct ? "c-1" : "c-2"
                      }
                    >
                      Correct Answer :
                      {this.state.submitted ? questions[0].correct : ""}
                    </span>
                  ) : (
                    ""
                  )}
                </div>

                <div>
                  <h6 className="pt-5">
                    <li>{questions[1].question}</li>
                  </h6>

                  <div className="pl-4">
                    <div className="radio">
                      <Label>
                        <Input
                          type="radio"
                          name="mq42"
                          value={questions[1].options[0]}
                          onChange={e => {
                            this.setState({ q2: e.target.value });
                          }}
                          disabled={this.state.submitted}
                        />
                        {questions[1].options[0]}
                      </Label>
                    </div>
                    <div className="radio">
                      <Label>
                        <Input
                          type="radio"
                          name="mq42"
                          value={questions[1].options[1]}
                          onChange={e => {
                            this.setState({ q2: e.target.value });
                          }}
                          disabled={this.state.submitted}
                        />
                        {questions[1].options[1]}
                      </Label>
                    </div>
                    <div className="radio">
                      <Label>
                        <Input
                          type="radio"
                          name="mq42"
                          value={questions[1].options[2]}
                          onChange={e => {
                            this.setState({ q2: e.target.value });
                          }}
                          disabled={this.state.submitted}
                        />
                        {questions[1].options[2]}
                      </Label>
                    </div>
                  </div>

                  {this.state.submitted ? (
                    <span
                      className={
                        this.state.q2 == questions[1].correct ? "c-1" : "c-2"
                      }
                    >
                      Correct Answer :
                      {this.state.submitted ? questions[1].correct : ""}
                    </span>
                  ) : (
                    ""
                  )}
                </div>

                <div>
                  <h6 className="pt-5">
                    <li>{questions[2].question}</li>
                  </h6>

                  <div className="pl-4">
                    <div className="radio">
                      <Label>
                        <Input
                          type="radio"
                          name="mq43"
                          value={questions[2].options[0]}
                          onChange={e => {
                            this.setState({ q3: e.target.value });
                          }}
                          disabled={this.state.submitted}
                        />
                        {questions[2].options[0]}
                      </Label>
                    </div>
                    <div className="radio">
                      <Label>
                        <Input
                          type="radio"
                          name="mq43"
                          value={questions[2].options[1]}
                          onChange={e => {
                            this.setState({ q3: e.target.value });
                          }}
                          disabled={this.state.submitted}
                        />
                        {questions[2].options[1]}
                      </Label>
                    </div>
                    <div className="radio">
                      <Label>
                        <Input
                          type="radio"
                          name="mq43"
                          value={questions[2].options[2]}
                          onChange={e => {
                            this.setState({ q3: e.target.value });
                          }}
                          disabled={this.state.submitted}
                        />
                        {questions[2].options[2]}
                      </Label>
                    </div>
                  </div>

                  {this.state.submitted ? (
                    <span
                      className={
                        this.state.q3 == questions[2].correct ? "c-1" : "c-2"
                      }
                    >
                      Correct Answer :
                      {this.state.submitted ? questions[2].correct : ""}
                    </span>
                  ) : (
                    ""
                  )}
                </div>

                <div>
                  <h6 className="pt-5">
                    <li>{questions[3].question}</li>
                  </h6>

                  <div className="pl-4">
                    <div className="radio">
                      <Label>
                        <Input
                          type="radio"
                          name="mq44"
                          value={questions[3].options[0]}
                          onChange={e => {
                            this.setState({ q4: e.target.value });
                          }}
                          disabled={this.state.submitted}
                        />
                        {questions[3].options[0]}
                      </Label>
                    </div>
                    <div className="radio">
                      <Label>
                        <Input
                          type="radio"
                          name="mq44"
                          value={questions[3].options[1]}
                          onChange={e => {
                            this.setState({ q4: e.target.value });
                          }}
                          disabled={this.state.submitted}
                        />
                        {questions[3].options[1]}
                      </Label>
                    </div>
                    <div className="radio">
                      <Label>
                        <Input
                          type="radio"
                          name="mq44"
                          value={questions[3].options[2]}
                          onChange={e => {
                            this.setState({ q4: e.target.value });
                          }}
                          disabled={this.state.submitted}
                        />
                        {questions[3].options[2]}
                      </Label>
                    </div>
                  </div>

                  {this.state.submitted ? (
                    <span
                      className={
                        this.state.q4 == questions[3].correct ? "c-1" : "c-2"
                      }
                    >
                      Correct Answer :
                      {this.state.submitted ? questions[3].correct : ""}
                    </span>
                  ) : (
                    ""
                  )}
                </div>



                <div>
                  <h6 className="pt-5">
                    <li>{questions[4].question}</li>
                  </h6>

                  <div className="pl-4">
                    <div className="radio">
                      <Label>
                        <Input
                          type="radio"
                          name="mq45"
                          value={questions[4].options[0]}
                          onChange={e => {
                            this.setState({ q5: e.target.value });
                          }}
                          disabled={this.state.submitted}
                        />
                        {questions[4].options[0]}
                      </Label>
                    </div>
                    <div className="radio">
                      <Label>
                        <Input
                          type="radio"
                          name="mq45"
                          value={questions[4].options[1]}
                          onChange={e => {
                            this.setState({ q5: e.target.value });
                          }}
                          disabled={this.state.submitted}
                        />
                        {questions[4].options[1]}
                      </Label>
                    </div>
                    <div className="radio">
                      <Label>
                        <Input
                          type="radio"
                          name="mq45"
                          value={questions[4].options[2]}
                          onChange={e => {
                            this.setState({ q5: e.target.value });
                          }}
                          disabled={this.state.submitted}
                        />
                        {questions[4].options[2]}
                      </Label>
                    </div>
                  </div>

                  {this.state.submitted ? (
                    <span
                      className={
                        this.state.q5 == questions[4].correct ? "c-1" : "c-2"
                      }
                    >
                      Correct Answer :
                      {this.state.submitted ? questions[4].correct : ""}
                    </span>
                  ) : (
                    ""
                  )}
                </div>




                <div>
                  <h6 className="pt-5">
                    <li>{questions[5].question}</li>
                  </h6>

                  <div className="pl-4">
                    <div className="radio">
                      <Label>
                        <Input
                          type="radio"
                          name="mq46"
                          value={questions[5].options[0]}
                          onChange={e => {
                            this.setState({ q6: e.target.value });
                          }}
                          disabled={this.state.submitted}
                        />
                        {questions[5].options[0]}
                      </Label>
                    </div>
                    <div className="radio">
                      <Label>
                        <Input
                          type="radio"
                          name="mq46"
                          value={questions[5].options[1]}
                          onChange={e => {
                            this.setState({ q6: e.target.value });
                          }}
                          disabled={this.state.submitted}
                        />
                        {questions[5].options[1]}
                      </Label>
                    </div>
                    <div className="radio">
                      <Label>
                        <Input
                          type="radio"
                          name="mq46"
                          value={questions[5].options[2]}
                          onChange={e => {
                            this.setState({ q6: e.target.value });
                          }}
                          disabled={this.state.submitted}
                        />
                        {questions[5].options[2]}
                      </Label>
                    </div>
                  </div>

                  {this.state.submitted ? (
                    <span
                      className={
                        this.state.q6 == questions[5].correct ? "c-1" : "c-2"
                      }
                    >
                      Correct Answer :
                      {this.state.submitted ? questions[5].correct : ""}
                    </span>
                  ) : (
                    ""
                  )}
                </div>



                <div>
                  <h6 className="pt-5">
                    <li>{questions[6].question}</li>
                  </h6>

                  <div className="pl-4">
                    <div className="radio">
                      <Label>
                        <Input
                          type="radio"
                          name="mq47"
                          value={questions[6].options[0]}
                          onChange={e => {
                            this.setState({ q7: e.target.value });
                          }}
                          disabled={this.state.submitted}
                        />
                        {questions[6].options[0]}
                      </Label>
                    </div>
                    <div className="radio">
                      <Label>
                        <Input
                          type="radio"
                          name="mq47"
                          value={questions[6].options[1]}
                          onChange={e => {
                            this.setState({ q7: e.target.value });
                          }}
                          disabled={this.state.submitted}
                        />
                        {questions[6].options[1]}
                      </Label>
                    </div>
                    <div className="radio">
                      <Label>
                        <Input
                          type="radio"
                          name="mq47"
                          value={questions[6].options[2]}
                          onChange={e => {
                            this.setState({ q7: e.target.value });
                          }}
                          disabled={this.state.submitted}
                        />
                        {questions[6].options[2]}
                      </Label>
                    </div>
                  </div>

                  {this.state.submitted ? (
                    <span
                      className={
                        this.state.q7 == questions[6].correct ? "c-1" : "c-2"
                      }
                    >
                      Correct Answer :
                      {this.state.submitted ? questions[6].correct : ""}
                    </span>
                  ) : (
                    ""
                  )}
                </div>




                <div>
                  <h6 className="pt-5">
                    <li>{questions[7].question}</li>
                  </h6>

                  <div className="pl-4">
                    <div className="radio">
                      <Label>
                        <Input
                          type="radio"
                          name="mq48"
                          value={questions[7].options[0]}
                          onChange={e => {
                            this.setState({ q8: e.target.value });
                          }}
                          disabled={this.state.submitted}
                        />
                        {questions[7].options[0]}
                      </Label>
                    </div>
                    <div className="radio">
                      <Label>
                        <Input
                          type="radio"
                          name="mq48"
                          value={questions[7].options[1]}
                          onChange={e => {
                            this.setState({ q8: e.target.value });
                          }}
                          disabled={this.state.submitted}
                        />
                        {questions[7].options[1]}
                      </Label>
                    </div>
                    <div className="radio">
                      <Label>
                        <Input
                          type="radio"
                          name="mq48"
                          value={questions[7].options[2]}
                          onChange={e => {
                            this.setState({ q8: e.target.value });
                          }}
                          disabled={this.state.submitted}
                        />
                        {questions[7].options[2]}
                      </Label>
                    </div>
                  </div>

                  {this.state.submitted ? (
                    <span
                      className={
                        this.state.q8 == questions[7].correct ? "c-1" : "c-2"
                      }
                    >
                      Correct Answer :
                      {this.state.submitted ? questions[7].correct : ""}
                    </span>
                  ) : (
                    ""
                  )}
                </div>


                <div>
                  <h6 className="pt-5">
                    <li>{questions[8].question}</li>
                  </h6>

                  <div className="pl-4">
                    <div className="radio">
                      <Label>
                        <Input
                          type="radio"
                          name="mq49"
                          value={questions[8].options[0]}
                          onChange={e => {
                            this.setState({ q9: e.target.value });
                          }}
                          disabled={this.state.submitted}
                        />
                        {questions[8].options[0]}
                      </Label>
                    </div>
                    <div className="radio">
                      <Label>
                        <Input
                          type="radio"
                          name="mq49"
                          value={questions[8].options[1]}
                          onChange={e => {
                            this.setState({ q9: e.target.value });
                          }}
                          disabled={this.state.submitted}
                        />
                        {questions[8].options[1]}
                      </Label>
                    </div>
                    <div className="radio">
                      <Label>
                        <Input
                          type="radio"
                          name="mq49"
                          value={questions[8].options[2]}
                          onChange={e => {
                            this.setState({ q9: e.target.value });
                          }}
                          disabled={this.state.submitted}
                        />
                        {questions[8].options[2]}
                      </Label>
                    </div>
                  </div>

                  {this.state.submitted ? (
                    <span
                      className={
                        this.state.q9 == questions[8].correct ? "c-1" : "c-2"
                      }
                    >
                      Correct Answer :
                      {this.state.submitted ? questions[8].correct : ""}
                    </span>
                  ) : (
                    ""
                  )}
                </div>


                <div>
                  <h6 className="pt-5">
                    <li>{questions[9].question}</li>
                  </h6>

                  <div className="pl-4">
                    <div className="radio">
                      <Label>
                        <Input
                          type="radio"
                          name="mq40"
                          value={questions[9].options[0]}
                          onChange={e => {
                            this.setState({ q10: e.target.value });
                          }}
                          disabled={this.state.submitted}
                        />
                        {questions[9].options[0]}
                      </Label>
                    </div>
                    <div className="radio">
                      <Label>
                        <Input
                          type="radio"
                          name="mq40"
                          value={questions[9].options[1]}
                          onChange={e => {
                            this.setState({ q10: e.target.value });
                          }}
                          disabled={this.state.submitted}
                        />
                        {questions[9].options[1]}
                      </Label>
                    </div>
                    <div className="radio">
                      <Label>
                        <Input
                          type="radio"
                          name="mq40"
                          value={questions[9].options[2]}
                          onChange={e => {
                            this.setState({ q10: e.target.value });
                          }}
                          disabled={this.state.submitted}
                        />
                        {questions[9].options[2]}
                      </Label>
                    </div>
                  </div>

                  {this.state.submitted ? (
                    <span
                      className={
                        this.state.q10 == questions[9].correct ? "c-1" : "c-2"
                      }
                    >
                      Correct Answer :
                      {this.state.submitted ? questions[9].correct : ""}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </ol>

              <div className="text-center mb-2">
                {this.state.submitted ? (
                  <div>
                    <p className="text-center font-weight-bold">
                      Score:
                      {this.getScore(
                        this.state.q1,
                        this.state.q2,
                        this.state.q3,
                        this.state.q4,
                        this.state.q5,
                        this.state.q6,
                        this.state.q7,
                        this.state.q8,
                        this.state.q9,
                        this.state.q10,
                      )}
                      /10
                      <br />
                      <span>Submitted on {moment().format("LLL")}</span>
                    </p>
                  </div>
                ) : (
                  <Button
                    className="btn btn-padding btn-secondary mt-4"
                    type="submit"
                  >
                    Submit
                  </Button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
  render() {
    console.log(this.props.scores,"scroesss")
    let score = this.props.scores.response.find(
      o => o.user_id == this.props.userId
    );
    if (score ? score.module3 == null : !score) {
      return (
        <div className="text-center">
          <h3>Please complete the previous module before proceeding ahead</h3>
        </div>
      );
    } else {
      if (score ? score.module4 == null : !score) {
        return this.questionData();
      }
      if (score && score.module3 != null) {
        return (
          <div className="text-center">
            <h3>Your Module 4 Quiz Score is {score.module4}/10</h3>
          </div>
        );
      }
    }
  }
}

VM4_quiz.propTypes = {
  getvideoScore: PropTypes.func.isRequired,
  fetchvideoScore: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  scores: state.resource.vscores
});
export default connect(
  mapStateToProps,
  { getvideoScore, fetchvideoScore }
)(VM4_quiz);
