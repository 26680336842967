import React from "react";

class TechnicalAnalysis extends React.Component {
  render() {
    return (
      <div className="container resource">
        <div className="mt-5">
          <h5 className="text-center">
            <u>
              <b>TECHNICAL ANALYSIS</b>
            </u>
          </h5>
        </div>
        <p>
          The technical analysis is an analysis that is performed for predicting
          the future stock prices of companies on the basis of their historical
          prices and volumes. The end goal of technical analysts is to analyze
          the trends in price movements of stocks over a period of time and on
          the basis of these trends of price movements and volumes foresee
          future price movements of the stocks.
          <br />
          The primary distinction between fundamental analysis and technical
          analysis lies in the fact that fundamental analysts are interested in
          finding the intrinsic value of stock prices whereas the technical
          analysts are interested in estimating the future price trends of
          stocks. Note that technical analysis can be performed on any type of
          financial instrument - bonds, debentures, futures, and options.
        </p>
        <p>The field of technical analysis is based on 3 assumptions:</p>
        <ol>
          <li style={{ fontWeight: "lighter" }}>
            The first assumption is that the market discounts everything. It is
            assumed that the stock price of a company corrects itself at any
            given time for anything that could or has affected the company,
            including fundamental factors and therefore there is no need of
            considering the fundamental factors separately. However, this
            assumption that the market price reflects everything is also
            considered to be a major limitation as it ignores the fundamentals
            of the company.
          </li>
          <li style={{ fontWeight: "lighter" }}>
            The price movements of stocks are believed to follow trends. In
            technical analysis, it is assumed that future stock prices can be
            established on the basis of trends observed in them in the past.
          </li>
          <li style={{ fontWeight: "lighter" }}>
            The patterns of price movements are repetitive because the market
            sentiment regarding similar economic situations tends to remain
            consistent over a period of time. For instance, it was predicted
            that the domestic markets - Sensex and Nifty - will have a rally if
            the NDA government won a 2nd term.
          </li>
        </ol>
        <p>
          Technical analysts use different types of tools for predicting the
          future price trends of stocks on the basis of historical data. There
          are four main types of charts that are used to depict the price
          movements of stocks in the future:
        </p>
        <ol>
          <li style={{ fontWeight: "lighter" }}>The line chart</li>
          <li style={{ fontWeight: "lighter" }}>The bar chart</li>
          <li style={{ fontWeight: "lighter" }}>The candlestick chart</li>
          <li style={{ fontWeight: "lighter" }}>The point and figure chart</li>
        </ol>
        <p>
          We will start by understanding the charting technique of the line
          chart
        </p>
        <p>
          <b>LINE CHART</b>
        </p>
        <p>
          This is the simplest type of chart. It is formed by plotting the
          closing prices of stocks for each period over a period of time and
          then connecting those points. The line chart does not provide
          information regarding the day’s high, low, or the opening price.
          However, many investors consider the closing price to be the most
          important aspect and therefore it is the most popular and widely used
          chart.
        </p>
        <img
          src="/img/linechart.png"
          className="rounded mx-auto d-block"
          alt="chart"
        />
        <p>
          <b>BAR CHART</b>
        </p>
        <p>
          The bar chart can be considered to be an extension of the line chart.
          It comprises of vertical lines that show the price range of stock
          during that time frame. It conveys more information as compared to the
          line chart. The opening price, closing price and the day’s highest and
          lowest can be seen on a bar chart. The closing and the opening price
          are represented on the vertical line by a horizontal line. The
          horizontal line on the right side of the vertical line represents the
          day’s closing price and the one on the left side of the vertical line
          represents the day’s opening price. Generally, if the left dash (open)
          is lower than the right dash (close) then the bar will be shaded
          black, representing an upward movement of the stock, which means it
          has gained value.
        </p>
        <img
          src="/img/barchart.png"
          className="rounded mx-auto d-block"
          alt="chart"
        />
        <p>
          <b>CANDLESTICK CHART</b>
        </p>
        <p>
          The candlestick chart was introduced by a Japanese rice trader, for
          the first time, during the 1700s. The candlestick chart is similar to
          a bar chart as the candlestick chart also has a vertical line
          depicting the period’s trading range (the highest and lowest price of
          the day). Like bar charts, candlestick charts also rely on the use of
          colors and provide information regarding the opening and closing price
          and the day’s highest and lowest. The only distinction is that in
          candlestick charts, there is a box placed on the vertical line which
          indicates the difference between the opening and the closing price.
          The candlesticks are made up of two parts- the body and the wicks. The
          body part comprises of the box which represents the day’s opening and
          closing price. The wicks are present above and below the box which
          represent the day’s high and low.
          <br />
          Similarly, we can make out whether the stock has closed at a higher
          price or has traded lower for the period by looking at the color of
          the candlestick. If the color of the box is white or clear, then it
          can be interpreted that the stock has closed at a higher price than
          the price at which it had opened. If the color of the box is black or
          red, then the stock has closed at a price lower than the opening price
          for that period.
        </p>

        <p>
          For instance, suppose that on Monday the stock of ABC Ltd had closed
          at Rs 20, and on Tuesday it opened at Rs 24 but closed at Rs 22.
          Still, the color of the box will be black or red because though the
          closing price of Tuesday is higher than that of Monday, it is lesser
          than the opening price of Tuesday.
        </p>
        <img
          src="/img/candlesticks.png"
          className="rounded mx-auto d-block"
          alt="chart"
        />
        <div className="mt-3 mb-3">
          <img
            src="/img/montlystock.png"
            className="rounded mx-auto d-block"
            alt="chart"
          />
        </div>
      </div>
    );
  }
}

export default TechnicalAnalysis;
