import React from "react";
import { Table } from "reactstrap";
class M1_intro extends React.Component {


  render() {
    return (
      
      <div className="m1_intro resource container">
        <div>
          <h4 className="text-center font-weight-bold mt-5 mb-4">
            MODULE 1 - INTRODUCTION TO FINANCIAL MARKET
          </h4>
          <hr />
        </div>
        <div className="mt-5">
          <p>
            Let’s start by explaining the concept of securities. Securities are,
            simply put, financial instruments that help in the transfer of
            resources from those who have idle resources with them to those who
            are in need of such resources. Broadly, there are two parties
            involved in such a transaction:
          </p>
        </div>
        <div>
          <ul>
            <li>
              Persons with idle resources are usually the ones who have savings
              that can be put to productive uses. These persons are called
              investors as their idle resources are used for investing in
              entities that are in need of these funds.
            </li>
            <li>
              You are currently a virtual investor who has enrolled in the
              Finlatics Financial Markets Experience Program. In your case, the
              virtual money which has been provided to you for trading and
              investment purposes can be considered as your idle resource.
            </li>
            <li>
              Entities that are in need of funds consist of companies, financial
              institutions or government entities who require these resources
              for productive purposes. These entities issue various kinds of
              financial instruments for availing funds and are called as the
              issuer of financial securities. These funds can be considered
              similar to the loans provided by banks to companies. As an
              investor, you are basically lending your savings to the ones who
              require them.
            </li>
          </ul>
        </div>
        <div>
          <p>
            After understanding securities, we can now move on to the different
            types of financial securities that are available in various markets.
            Securities can be broadly categorized into two distinct types –
            Equity securities and Debt securities
          </p>
          <img
            src="/img/Group596.png"
            className="rounded mx-auto d-block"
            alt="chart"
          />
        </div>
        <div>
          <ol>
            <li className="font-weight-bold">EQUITY SECURITIES</li>
            <p>
              Equity securities are stocks(shares) issued by companies to raise
              funds for financing their business operations, expanding their
              business activities and to meet other such financial needs. The
              holders of equity shares are the real owners of the company and
              enjoy some benefits like voting rights and the right to
              participate in the workings of the company. The voting rights
              given to shareholders allow them to attend and participate in the
              annual general meeting of the issuing company. For a better
              understanding, we can consider shares as being fractions of a
              company that is divided into thousands of parts and distributed
              amongst investors. Let’s take into consideration a practical
              example: Suppose a company ABC Limited requires ₹40 lakh for
              expansion purposes and hence, it plans to raise the required
              amount by issuing equity shares. The company might decide to issue
              4 lakh shares of ₹10 each. Then, these individual shares of ₹10
              each can be called equity shares. Equity shareholders are paid
              dividends by the issuing company. However, it is not obligatory on
              the company to pay regular dividends to its shareholders. Also,
              the rate of dividend paid to shareholders depends on the amount of
              profit declared by the company. They might be paid a higher amount
              of dividend or may not receive anything in a given financial year.
              In the event of liquidation of a company, equity capital is paid
              after claims of all other vested parties are settled; ranging from
              banks to employees to creditors.
            </p>
            <li className="font-weight-bold">DEBT SECURITIES</li>
            <p>
              Debt securities are issued by private companies and government
              entities. They refer to debt instruments such as debentures,
              bonds, municipal bonds. They are mainly availed by entities
              seeking funds for a longer period of time. The funds raised by
              issuing debt securities can be considered to be a borrowed sum
              that pays regular interest to investors and on maturity, the
              investors also receive the original principal amount they had
              invested. However, debt holders do not enjoy voting rights in the
              company management and receive a fixed amount of interest
              irrespective of the company’s revenue growth.
            </p>
            <p>
              If the distinction between equity and debt is not yet clear, let’s
              consider the basic difference from an investor’s perspective. A
              stock is an equity security and a debenture is a debt instrument.
              When an investor buys stocks, he essentially buys a fraction of a
              company. If the company profits, he makes a profit as well, but if
              the company loses money, his stock also loses money.
            </p>
            <p>
              On the other hand, when an investor buys debt security like a
              debenture, he is essentially loaning the issuing company and
              therefore, has the right to be repaid the principal and the
              interest.
            </p>
            <p>
              A debenture is a form of debt security generally used for raising
              long term debts. It can be classified in many different ways.
              However, broadly they are classified on the basis of
            </p>
            <ol>
              <li>
                Collateral (backed by assets like property or plant & machinery)
                also known as secured debentures as they are protected by
                assets.
              </li>
              <li>
                Convertibility – Debentures can be classified as convertible
                debentures and non-convertible debentures.
              </li>
            </ol>
            <p>
              Convertible debentures are those which can be converted into
              equity shares after a specific period of time, i.e. at the time of
              its maturity, specified when the debenture is issued. Therefore,
              if you own a convertible debenture you have the option of
              converting it into equity shares and therefore, availing the
              benefits of equity as well.
            </p>
            <p>
              Let’s continue with our earlier example - Suppose that instead of
              the company ABC Ltd depending upon the issuance of shares alone
              for raising ₹40 lakh, the company decides to issue ₹20 lakh worth
              of equity shares and ₹20 lakh worth of debentures. Also, the
              company issues both kinds of debentures - convertible and
              non-convertible. Now, as an investor who purchases debentures, you
              have the option of purchasing either a convertible debenture or a
              non - convertible debenture.
            </p>
            <img
              src="/img/chart.png"
              className="rounded mx-auto d-block"
              alt="chart"
            />
            <p>
              If you start believing that the company’s stock prices are going
              to rise in the future, holders of convertible debentures enjoy an
              additional benefit of being able to convert their debenture into
              an equity share after a specified time period and thus, have a
              chance to accrue gains. Therefore, it can be seen that the concept
              of convertible debentures is an important one and hence requires
              our special focus.
            </p>
            <p>
              But, conversion of debentures into shares comes at a cost. The
              rate of interest paid on convertible debentures is generally lower
              than the one paid on non-convertible debentures.
            </p>
            <img
              src="/img/png3.png"
              className="rounded mx-auto d-block"
              alt="chart"
            />
            <p>
              As explained earlier, non-convertible debentures are fixed-income
              instruments that offer higher interest rates than convertible
              debentures. These instruments come with a specific term and
              interest income and, investors cannot convert them into equity
              shares. To make up for this limitation, investors enjoy a higher
              rate of interest, liquidity, low risk, and tax concessions that
              are not available to convertible debenture holders.
            </p>
          </ol>
          <Table bordered>
            <thead>
              <tr>
                <th>
                  <div>INVESTOR X </div>
                  (PURCHASES CONVERTIBLE DEBENTURES OF ABC LTD)
                </th>
                <th>
                  <div>INVESTOR Y </div>(PURCHASES NON-CONVERTIBLE DEBENTURES OF
                  ABC LTD)
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  He will have an option to convert his debentures into equity
                  shares or cash out with the principal and the interest payment
                  on maturity.
                </td>
                <td>
                  He will have to compulsorily cash out with the principal and
                  the interest payment on maturity.
                </td>
              </tr>
              <tr>
                <td>
                  He has to bear with lower interest rates as he can convert his
                  debentures to equity shares.
                </td>
                <td>
                  He enjoys a higher rate of interest as he has no option to
                  convert his debentures to equity shares.
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    );
  }
}

export default M1_intro;
