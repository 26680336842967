import React from "react";
import {
  Row,
  Col,
  Button,
  Card,
  CardFooter,
  CardTitle,
  CardSubtitle,
  Table,
  FormGroup,
  Label,
  Input
} from "reactstrap";

import PropTypes from "prop-types";
import { connect } from "react-redux";

import { withRouter } from "react-router-dom";
import { getECodeQuestions, setECodeAns,PostDonegame } from "../../../actions/gamesActions";
import { formatCurrency } from "../../../utils/format";
import GamesPerformance from "../games_performance";

class ECode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show_right: false,
      question: 0,
      flip: false,
      consensus: false,
      fliped_at_1: false,
      fliped_at_2: false,
      response: {},
      time: 10,
      start_consenses_anim: false,
      wins: 0,
      loss: 0,
      pre_init: 0
    };
  }
  timer = 0;
  timer2 = 0;
  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
    if (this.timer2) {
      clearInterval(this.timer2);
    }
  }

  initTimer = () => {
    let that = this;
    this.timer = setInterval(function() {
      if (that.state.time > 0) {
        if (
          that.props.ecode_questions[that.state.question] &&
          that.state.time == 4 &&
          that.state.response[
            that.props.ecode_questions[that.state.question].id
          ] != undefined &&
          that.state.consensus == false &&
          !that.state.flip
        ) {
          setTimeout(function() {
            if (!that.state.flip) {
              that.setState({
                time: 10,
                consensus: true,
                start_consenses_anim: false
              });
            }
          }, 3000);

          that.setState({
            start_consenses_anim: true,
            time: that.state.time - 1
          });
        } else {
          that.setState({ time: that.state.time - 1 });
        }
      } else {
        if (!that.state.show_right) {
          setTimeout(() => {
            that.setState({
              show_right: false,
              time: 10,
              question: that.state.question + 1,
              flip: false,
              consensus: false,
              fliped_at_1: false,
              fliped_at_2: false
            });
          }, 5000);
          let wins_loss = that.getTotalWinsLoss();
          that.setState({
            show_right: true,
            wins: wins_loss.wins,
            loss: wins_loss.loss
          });
        }

        if (that.state.question >= that.props.ecode_questions.length - 1) {
          clearInterval(that.timer);
          let wins_loss = that.getTotalWinsLoss();
          that.props.setECodeAns(that.state.response, wins_loss);
        }
      }
    }, 1000);
  };
  componentDidMount() {
    this.props.getECodeQuestions();
    if(this.props.currentid>0)
    {
      this.props.PostDonegame(this.props.currentid)
  
    }
    this.setState({ pre_init: 0 });
    let that = this;
    this.timer2 = setInterval(() => {
      if (that.state.pre_init > 0) {
        that.setState({ pre_init: that.state.pre_init - 1 });
      } else {
        clearInterval(that.timer2);
        that.setState({ pre_init: 0 });
        that.initTimer();
      }
    }, 1000);
  }

  getTotalWinsLoss = () => {
    let wins = 0;
    let loss = 0;
    Object.keys(this.state.response).forEach(id => {
      //if fliped at 1 or 2 -> match with flip
      //if switched has val match with correct_ans
      //else if selected has val then match with correct ans
      let q = this.props.ecode_questions.find(el => el.id == id);
      if (this.state.response[id].flip_selected) {
        if (q.flip_correct_option == this.state.response[id].flip_selected) {
          wins++;
        }
      } else if (this.state.response[id].switched) {
        if (q.correct_option == this.state.response[id].switched) {
          wins++;
        }
      } else if (
        this.state.response[id].selected &&
        this.state.response[id].selected == q.correct_option
      ) {
        wins++;
      }
    });
    loss =
      (this.state.question >= this.props.ecode_questions.length
        ? this.props.ecode_questions.length
        : this.state.question + 1) - wins;
    return { wins: wins, loss: loss };
  };

  getScoreBoard = result_msg => {
    let win_points = 100;
    let loose_points = 50;

    let amt = this.state.wins * win_points - this.state.loss * loose_points;
    let bg_footer = this.state.showComCard
      ? result_msg == "Congrats! You win"
        ? "#009245"
        : "red"
      : "#dee2e6";
    return (
      <div
        style={{
          maxWidth: "380px",
          height: "480px",
          margin: "auto",
          paddingTop: "30px",
          paddingBottom: "10px"
        }}
      >
        <Card
          className="gameCd1"
          style={{
            width: "380px",
            height: "480px",
            borderRadius: "5px"
          }}
        >
          <table
            className="scoreboard-tbl"
            style={{
              width: "100%",
              borderRadius: "5px",
              margin: "1px"
            }}
          >
            <tbody>
              <tr
                style={{
                  color: "#fff",
                  fontWeight: 700,
                  backgroundColor: "#009245"
                }}
              >
                <td
                  style={{
                    borderRadius: "5px 0 0 0",
                    padding: "15px!important"
                  }}
                >
                  ROUND WINNINGS
                </td>
                <td
                  style={{
                    textAlign: "right",
                    borderRadius: "0px 5px 0 0",
                    padding: "15px!important"
                  }}
                >
                  {formatCurrency(amt, true)}
                </td>
              </tr>
              <tr
                style={{
                  color: "#fff",
                  fontWeight: 700,
                  backgroundColor: "#009245",
                  borderTop: "1px solid #e0e0e0"
                }}
              >
                <td style={{ padding: "15px!important" }}>CASH IN HAND</td>
                <td style={{ textAlign: "right", padding: "15px!important" }}>
                  {formatCurrency(this.props.user.total_money + amt, true)}
                </td>
              </tr>
              <tr
                style={{
                  color: "#fff",
                  fontWeight: 700,
                  backgroundColor: "#009245",
                  borderTop: "1px solid #e0e0e0"
                }}
              >
                <td style={{ padding: "15px!important" }}>ROUNDS</td>
                <td style={{ textAlign: "right", padding: "15px!important" }}>
                  {this.state.question + 1}/{this.props.ecode_questions.length}
                </td>
              </tr>
              <tr
                style={{
                  color: "#fff",
                  fontWeight: 700,
                  backgroundColor: "#009245",
                  borderTop: "1px solid #e0e0e0"
                }}
              >
                <td style={{ padding: "15px!important" }}>EARNING ROUNDS</td>
                <td style={{ textAlign: "right", padding: "15px!important" }}>
                  {this.state.wins}/{this.state.question + 1}
                </td>
              </tr>
            </tbody>
          </table>
          <div style={{ display: "table", height: "208px" }}>
            <div
              key={Math.random()}
              style={{
                textAlign: "-webkit-center",
                display: "table-cell",
                verticalAlign: "middle",
                fontSize: "34px",
                fontWeight: "900",
                width: "380px",
                textAlign: "center"
              }}
            >
              <h1
                className="pulse-button"
                style={{
                  margin: "auto",
                  paddingTop: "28px",
                  borderRadius: "50px",
                  width: "100px",
                  height: "100px",
                  color: "#fff"
                }}
              >
                {this.state.time}
              </h1>
            </div>
          </div>

          <CardFooter
            style={{
              backgroundColor: bg_footer,
              color: "white",
              position: "absolute",
              bottom: "0px",
              width: "380px",
              height: "60px"
            }}
          >
            <div
              className="animated fadeIn"
              style={{
                textAlign: "center",
                paddingBottom: "10px"
              }}
            >
              <h3>{result_msg}</h3>
            </div>
          </CardFooter>
        </Card>
      </div>
    );
  };

  setFlip = () => {
    let fliped_at_1 = this.state.fliped_at_1;
    let fliped_at_2 = this.state.fliped_at_2;
    let time = this.state.time;
    if (!fliped_at_1 && !fliped_at_2) {
      time = 10;
    }
    if (!this.state.show_right) {
      if (this.state.consensus) {
        fliped_at_2 = true;
      } else {
        fliped_at_1 = true;
      }
      this.setState({
        start_consenses_anim: false,
        fliped_at_1: fliped_at_1,
        fliped_at_2: fliped_at_2,
        flip: true,
        consensus: false,
        time: time
      });
    }
  };

  setResponse = val => {
    let response = { ...this.state.response };
    if (
      response[this.props.ecode_questions[this.state.question].id] == undefined
    ) {
      response[this.props.ecode_questions[this.state.question].id] = {};
    }
    response[
      this.props.ecode_questions[this.state.question].id
    ].id = this.props.ecode_questions[this.state.question].id;
    if (this.state.flip == false) {
      if (this.state.consensus == false) {
        response[
          this.props.ecode_questions[this.state.question].id
        ].selected = val;
      } else {
        response[
          this.props.ecode_questions[this.state.question].id
        ].switched = val;
      }
    } else {
      response[
        this.props.ecode_questions[this.state.question].id
      ].flip_selected = val;
      response[
        this.props.ecode_questions[this.state.question].id
      ].fliped_at_1 = this.state.fliped_at_1;
      response[
        this.props.ecode_questions[this.state.question].id
      ].fliped_at_2 = this.state.fliped_at_2;
    }

    return response;
  };

  setAnswer = val => {
    if (this.state.show_right) {
      return;
    }
    let question = this.state.question;
    let consensus = this.state.consensus;
    let response = this.setResponse(val);
    let time = this.state.time;
    if (this.state.flip == false) {
      if (this.state.consensus == false) {
        if (this.props.ecode_questions[this.state.question].has_consensus) {
          consensus = true;
        } else {
          consensus = false;
          question = question + 1;
          time = 10;
        }
      } else {
        consensus = false;
        question = question + 1;
        time = 10;
      }
    } else {
      consensus = false;
      question = question + 1;
      time = 10;
    }
    this.setState({ response: response });
    // setTimeout(() => {
    //     this.setState({
    //         time: time,
    //         question: question,
    //         flip: false,
    //         consensus: false,
    //         response: response
    //     });
    // })

    // if (question >= this.props.ecode_questions.length) {
    //   clearInterval(this.timer);
    //   this.props.setECodeAns(response);
    // }
    // if (consensus == true) {
    //   let that = this;
    // }
  };

  showConsensus = () => {
    var datas = JSON.parse(
      this.props.ecode_questions[this.state.question].consensus_data
    );

    return (
      <div className="animated pulse">
        <h5 className="pb-4">General consensus is</h5>
        <div className="consesus-data">
          {datas.map(o => (
            <div key={o.name}>
              <div
                style={{
                  display: "inline-block",
                  width: "100%",
                  height: "42px",
                  marginBottom: "10px"
                }}
              >
                <div
                  style={{
                    display: "inline-block",
                    width: (o.p * 100).toString() + "%",
                    height: "42px"
                  }}
                />
                <div
                  style={{
                    fontSize: "14px",
                    display: "inline-block",
                    height: "42px",
                    paddingTop: "10px",
                    verticalAlign: "top",
                    paddingLeft: "10px",
                    marginLeft:
                      o.p > 0.8 ? "-" + 2.7 * (1 / (1 - o.p)) + "px" : "0px"
                  }}
                >
                  {(o.p * 100).toFixed(2)}%
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  getBackground = opt => {
    let sel = this.state.response[
      this.props.ecode_questions[this.state.question].id
    ];
    if (this.state.show_right) {
      if (
        (!this.state.flip &&
          opt ==
            this.props.ecode_questions[this.state.question].correct_option) ||
        (this.state.flip &&
          opt ==
            this.props.ecode_questions[this.state.question].flip_correct_option)
      ) {
        return { backgroundColor: "#009245", color: "#fff" };
      }
      let style = { backgroundColor: "red", color: "#fff" };
      if (!this.state.flip) {
        if (sel != undefined) {
          if (
            this.state.consensus &&
            sel.switched == opt &&
            opt !=
              this.props.ecode_questions[this.state.question].correct_option
          ) {
            return style;
          }
          if (
            (this.state.consensus &&
              sel.switched == undefined &&
              sel.selected == opt &&
              opt !=
                this.props.ecode_questions[this.state.question]
                  .correct_option) ||
            (!this.state.consensus &&
              sel.selected == opt &&
              opt !=
                this.props.ecode_questions[this.state.question].correct_option)
          ) {
            return style;
          }
        }
      } else {
        if (sel && sel.flip_selected == opt) {
          return style;
        }
      }
    } else {
      let style = { backgroundColor: "#000000", color: "#fff" };
      if (!this.state.flip) {
        if (sel != undefined) {
          if (this.state.consensus && sel.switched == opt) {
            return style;
          }
          if (
            (this.state.consensus &&
              sel.switched == undefined &&
              sel.selected == opt) ||
            (!this.state.consensus && sel.selected == opt)
          ) {
            return style;
          }
        }
      } else {
        if (sel && sel.flip_selected == opt) {
          return style;
        }
      }
    }

    return {};
  };

  getQuestion = () => (
    <FormGroup
      tag="fieldset"
      key={
        this.props.ecode_questions[this.state.question].id.toString() +
        (this.state.flip ? "A" : "B") +
        (this.state.consensus ? "C" : "D")
      }
    >
      <h5 className="pt-2" style={{ height: "120px" }}>
        {this.state.consensus
          ? "Do you want to change your option?"
          : this.state.flip
          ? this.props.ecode_questions[this.state.question].flip_question
          : this.props.ecode_questions[this.state.question].question}
      </h5>
      <ul
        style={{
          listStyleType: "none",
          padding: "0px",
          fontSize: "20px",
          lineHeight: "20px",
          fontWeight: "500"
        }}
      >
        <li
          className="ecode-option"
          style={this.getBackground("A")}
          onClick={() => this.setAnswer("A")}
        >
          <FormGroup check style={{ left: "-15px" }}>
            <Label check style={{ width: "100%" }}>
              {this.state.flip
                ? this.props.ecode_questions[this.state.question].flip_optionA
                : this.props.ecode_questions[this.state.question].optionA}
            </Label>
          </FormGroup>
        </li>
        <li
          className="ecode-option"
          style={this.getBackground("B")}
          onClick={() => this.setAnswer("B")}
        >
          <FormGroup check style={{ left: "-15px" }}>
            <Label check style={{ width: "100%" }}>
              {this.state.flip
                ? this.props.ecode_questions[this.state.question].flip_optionB
                : this.props.ecode_questions[this.state.question].optionB}
            </Label>
          </FormGroup>
        </li>
        <li
          className="ecode-option"
          style={this.getBackground("C")}
          onClick={() => this.setAnswer("C")}
        >
          <FormGroup check style={{ left: "-15px" }}>
            <Label check style={{ width: "100%" }}>
              {this.state.flip
                ? this.props.ecode_questions[this.state.question].flip_optionC
                : this.props.ecode_questions[this.state.question].optionC}
            </Label>
          </FormGroup>
        </li>
        <li
          className="ecode-option"
          style={this.getBackground("D")}
          onClick={() => this.setAnswer("D")}
        >
          <FormGroup check style={{ left: "-15px" }}>
            <Label check style={{ width: "100%" }}>
              {this.state.flip
                ? this.props.ecode_questions[this.state.question].flip_optionD
                : this.props.ecode_questions[this.state.question].optionD}
            </Label>
          </FormGroup>
        </li>
      </ul>
    </FormGroup>
  );

  showStats = () => {
    return (
      <div className="container">
        <Row
          style={{
            fontSize: "18px"
          }}
        >
          <Col sm="12">
            <div
              style={{
                margin: "auto",
                paddingTop: "30px",
                paddingBottom: "10px",
                textAlign: "center"
              }}
            >
              <h1>E-Code</h1>
            </div>
          </Col>
          <Col sm="12">
            <GamesPerformance
              game="ecode"
              goFront={() => this.props.goFront()}
            />
          </Col>
        </Row>
      </div>
    );
  };

  render() {
    if (this.state.pre_init > 0) {
      return (
        <div
          style={{
            display: "table",
            height: "calc(100vh - 80px)",
            width: "100%"
          }}
        >
          <div
            style={{
              display: "table-cell",
              verticalAlign: "middle",
              height: "100%",
              textAlign: "center"
            }}
          >
            <h1>
              Game will start in{" "}
              <span
                className="pulse-button"
                style={{
                  marginTop: "-25px",
                  paddingTop: "25px",
                  verticalAlign: "top",
                  display: "inline-block",
                  borderRadius: "50px",
                  width: "100px",
                  height: "100px"
                }}
              >
                {this.state.pre_init}
              </span>
            </h1>
          </div>
        </div>
      );
    }
    if (this.props.ecode_questions.length == 0) return null;
    if (this.state.question >= this.props.ecode_questions.length) {
      return this.showStats();
    }

    let win_points = 100;
    let loose_points = 50;

    let amt = this.state.wins * win_points - this.state.loss * loose_points;

    return (
      <div className="container">
        <div className="text-center p-4 m-4">
          <h4>E Code</h4>
        </div>
        <div className="row">
          <div className="col-md-9 left-div18">
            <div className="bg-color18">
              <div className="text-center pt-3">
                <div className="q18">
                  {this.state.consensus
                    ? "Do you want to change your option?"
                    : this.state.flip
                    ? this.props.ecode_questions[this.state.question]
                        .flip_question
                    : this.props.ecode_questions[this.state.question].question}
                </div>
              </div>
              <div className="container">
                <div className="row">
                  <div className="col-md-5">
                    <div
                      className="rectangle18"
                      style={this.getBackground("A")}
                      onClick={() => this.setAnswer("A")}
                    >
                      <span className="font18">
                        {this.state.flip
                          ? this.props.ecode_questions[this.state.question]
                              .flip_optionA
                          : this.props.ecode_questions[this.state.question]
                              .optionA}
                      </span>
                    </div>
                    <div
                      className="rectangle18"
                      style={this.getBackground("C")}
                      onClick={() => this.setAnswer("C")}
                    >
                      <span className="font18">
                        {this.state.flip
                          ? this.props.ecode_questions[this.state.question]
                              .flip_optionC
                          : this.props.ecode_questions[this.state.question]
                              .optionC}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="mid-circle18">
                      <div>
                        <div className="circle18">
                          <span className="font18">{this.state.time}</span>
                        </div>
                        <div>
                          <span>
                            Round - {this.state.question + 1}/
                            {this.props.ecode_questions.length}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div
                      className="rectangle18"
                      style={this.getBackground("B")}
                      onClick={() => this.setAnswer("B")}
                    >
                      <span className="font18">
                        {this.state.flip
                          ? this.props.ecode_questions[this.state.question]
                              .flip_optionB
                          : this.props.ecode_questions[this.state.question]
                              .optionB}
                      </span>
                    </div>
                    <div
                      className="rectangle18"
                      style={this.getBackground("D")}
                      onClick={() => this.setAnswer("D")}
                    >
                      <span className="font18">
                        {this.state.flip
                          ? this.props.ecode_questions[this.state.question]
                              .flip_optionD
                          : this.props.ecode_questions[this.state.question]
                              .optionD}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 pt-4 text-center">
              <div>
                <Button
                  onClick={this.setFlip}
                  className={"btn site-gray"}
                  style={{
                    width: "225px",
                    padding: "5px 40px",
                    fontSize: "20px"
                  }}
                >
                  FLIP
                </Button>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div>
              <div
                className="d-table"
                style={{ width: "100%", paddingBottom: "20px" }}
              >
                <div className="d-table-cell align-middle">
                  <img
                    src="/img/profile.png"
                    style={{ maxWidth: "150px", verticalAlign: "baseline" }}
                    className="mt-3"
                  />
                </div>
                <div className="earning18 table-cell align-middle">
                  <div className="float-right">
                    <div className="p-1 ml-1">Total Amount</div>
                    <div className="pb-2">
                      <span className={"bg-green btn-typ1 ml-2 "}>
                        {formatCurrency(
                          this.props.user.total_money + amt,
                          true
                        )}
                      </span>
                    </div>
                    <div className="p-1 ml-1">Game Earning</div>
                    <div>
                      {" "}
                      <span className={"bg-green btn-typ1 ml-2"}>
                        {formatCurrency(amt, true)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div
                style={{
                  minHeight: "320px"
                }}
              >
                {this.state.consensus ? this.showConsensus() : null}
              </div>
              <div className="border border-color18 text-center p-3 ">
                <span>
                  <strong>Audience poll </strong>will appear in last 3 seconds
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ECode.propTypes = {
  getECodeQuestions: PropTypes.func.isRequired,
  setECodeAns: PropTypes.func.isRequired,
  ecode_questions: PropTypes.array.isRequired,
  PostDonegame:PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  ecode_questions: state.games.ecode.questions,
});

export default connect(
  mapStateToProps,
  { getECodeQuestions, setECodeAns,PostDonegame }
)(ECode);