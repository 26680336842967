import React from "react";
import { Table } from "reactstrap";
class Indices extends React.Component {
  render() {
    return (
      <div className="m2_indices resource container">
        <div>
          <h4 className="text-center font-weight-bold mt-5 mb-4">
            MODULE 2 - INDICES & SECURITIES MARKET
          </h4>
          <hr />
        </div>
        <div className="mt-5" />
        <h5 className="text-center mb-4">
          <u>
            <b>INDICES</b>
          </u>
        </h5>
        <p>
          You may often hear on news channels that Sensex is bullish today, or
          is bearish. However, not everyone is able to understand what does it
          exactly mean for the Sensex to be up or down. Even though watching the
          market conditions on any news channel will seem to be confusing at
          first, once you understand the idea behind stock indices like Sensex,
          you will realize that it’s not rocket science!
        </p>
        <p>
          The fluctuations in stock indices are generally referred to as
          fluctuations in stock markets. A stock index simply measures the
          changes in stock prices of selected firms. A stock exchange is an
          exchange on which stock brokers and traders can engage in the buying
          and selling of stocks of companies that are listed on it. Stock
          exchanges consist of stocks of individual companies that are listed on
          them. These companies are grouped together to make stock indices.
        </p>
        <p>
          As it becomes difficult to measure the performance of thousands of
          individual stocks listed on stock exchanges, this grouping of
          companies can be on the basis of the industry to which the company
          belongs, the size of the company, the company’s market value, or also
          on some other basis.
        </p>
        <p>
          For example, the most popular stock exchanges in India include the
          National Stock Exchange of India (NSE) and the Bombay Stock
          Exchange(BSE). The NIFTY 50 is a stock market index of the NSE, which
          is comprised of the top 50 most valued companies listed on the
          National Stock Exchange.
        </p>
        <p>
          Another popular index is Sensex which is a stock market index of the
          BSE and comprises of the top 30 most valued companies listed on the
          Bombay Stock Exchange. It is calculated by the free float market
          capitalization methodology which is prevalent all around the world.
          For understanding the free float capitalization methodology, we will
          have to understand the following terminology:
        </p>
        <ol>
          <li style={{ fontWeight: "lighter" }}>
            Free float is simply the percentage of shares that are available to
            trade in the market out of the total number of shares issued by the
            company. A company’s stocks are also held by its promoters,
            government and many shareholders other than the general public. But
            shares held by someone who is not a part of the general public
            cannot be traded in the market. So, the free float refers to the
            shares held only by the general investors which can be bought and
            sold in the stock market.
          </li>
          <li style={{ fontWeight: "lighter" }}>
            The market capitalization of a company is computed by multiplying
            the total number of stocks issued by the company by the market price
            of the stock. This value is useful in determining the market value
            of a company.
          </li>
        </ol>
        <p>
          The free float market capitalization is arrived at by multiplying the
          market capitalization of a company by a free float factor which varies
          for different companies and is decided by the BSE.
        </p>
        <p>
          The base market capitalization is the market capitalization of the
          company which was prevailing in the base year of 1978-79. The base
          year is fixed for every company. The base index value is taken to be
          100.
        </p>
        <p>
          Finally, the Sensex value is obtained by using the following formula:
        </p>
        <div className="g_boxing">
          <p className="text-center">
            Sensex Value = (Total free-float market capitalization /Base market
            capitalization) * Base index value
          </p>
        </div>
        <div>
          <p>
            <u>
              <b>Types of Indices : Sectoral and segmental indices</b>
            </u>
          </p>
          <p>
            There are some indices that track the market movement of company
            stocks belonging to similar <b>sectors </b> like technology,
            banking, pharmaceuticals, infrastructure, and such other sectors.
          </p>
          <p>
            Additionally, stock indices like S&P BSE MidCap, S&P BSE SmallCap,
            S&P BSE LargeCap comprise of companies grouped on the basis of their
            market valuation based <b>segments </b>. Based on their current
            market capitalization, stocks are classified into large-cap,
            mid-cap, and small-cap.
          </p>
          <ol>
            <li>Large Cap Stocks:</li>
            <p>
              These stocks are also called as blue-chip stocks. The companies
              which have a market cap of ₹20,000 crore or up and have a strong
              market presence are included in the S&P BSE LargeCap index. Most
              of the companies in this index are well established and their
              stocks are generally considered to be very safe. Example of
              blue-chip stocks includes TCS, Infosys, Wipro.
            </p>
            <li>Mid Cap Stocks:</li>
            <p>
              These stocks are considered to be less safe than large-cap stocks.
              Companies with their market cap value between ₹5000 crore and
              ₹20,000 crore are included in the S&P BSE MidCap index. Investors
              are attracted to the mid-cap stocks as these companies have a lot
              of potential for growth in the future.
            </p>
            <li>Small Cap Stocks:</li>
            <p>
              The S&P BSE SmallCap index comprises of firms which relatively
              small in all aspects - profitability, market cap, market presence,
              operations, as compared to the mid-cap and large-cap companies.
              Most of the small-cap firms are startups or are in their nascent
              stages of development and therefore, have tremendous scope for
              growth. However, these stocks are the riskiest of all.
            </p>
          </ol>
          <Table bordered>
            <thead>
              <tr>
                <th>SECTORAL INDICES</th>
                <th>SEGMENTAL INDICES</th>
                <th>OTHERS</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>BSE AUTO</td>
                <td>BSE MIDCAP</td>
                <td>BSE DOLLEX 30</td>
              </tr>
              <tr>
                <td>BSE FMCG SECTOR</td>
                <td>BSE 200</td>
                <td>BSE 500 Shariah</td>
              </tr>
              <tr>
                <td>BSE IT SECTOR</td>
                <td>BSE SMALLCAP</td>
                <td>BSE Greenex</td>
              </tr>
            </tbody>
          </Table>
          <p>
            The BSE DOLLEX 30 is a dollar-linked version of BSE 30 that was
            launched on July 25, 2001. It is the Bombay Stock Exchange’s Sensex
            index quoted in dollar terms. It is mainly helpful for foreign
            investors.
          </p>
          <p>
            Launched in 2012, BSE Greenex is India’s first carbon-efficient live
            index. This index comprises of those companies that have a minimum
            carbon footprint. It aims to engage investors who are more socially
            aware of the current climate change situation and to attract
            investors who are willing to pay a premium price for buying stocks
            of companies that are environment-friendly.
          </p>
          <p>
            The BSE 500 Shariah index measures the performance of all the Sharia
            law compliant companies of the broad-based S&P BSE 500 index.
          </p>
          <p>
            It is known that stock market indices are useful for indicating the
            performance of the economy or particular sectors of the economy, the
            ongoing market sentiments in the economy, etc.
          </p>
        </div>
      </div>
    );
  }
}

export default Indices;
