import React from 'react';
import { ResponsiveContainer, PieChart, Pie, Tooltip, Cell } from 'recharts';

const COLORS = ["#FFC30B", "#990F04", "#9240C8"];
const RADIAN = Math.PI / 180;      

export default class InvestmentChart extends React.Component {
                    
renderCustomizedLabel3 = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.7;
    const x  = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy  + radius * Math.sin(-midAngle * RADIAN);

    const p = (percent * 100).toFixed(2);

    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} 	dominantBaseline="central">
        {`${p}%`}
      </text>
    );
  };
             
  
  renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.2;
    
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (radius + 10) * cos;
    const sy = cy + (radius + 10) * sin;
    const mx = cx + (radius + 20) * cos;
    const my = cy + (radius + 20) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'middle' : 'middle';
    const p = (percent * 100).toFixed(2);
   if(p >= 3.5){
      return (
      <g>
          <text fontSize="18" x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#111">{this.props.cap_data[index].name}</text>
          <text fontSize="16" x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#fff">{`(${p}%)`}</text>
          
        </g>
      );
    }
  };

  onPieEnter = (e) => {
    
  }
  render(){
      return (
        <ResponsiveContainer minHeight={500}>
          <PieChart onMouseEnter={this.onPieEnter}>
            <Pie
              isAnimationActive={false}
              dataKey="value" 
              data={this.props.cap_data} 
              cy={200}
              labelLine={false}
              label={this.renderCustomizedLabel}
              fill="#8884d8"
            >
              {
                this.props.cap_data.map((entry, index) => <Cell key={entry.name} fill={COLORS[(index)]}/>)
              }
            </Pie>
            <Tooltip/>
          </PieChart>
        </ResponsiveContainer>
      )
  }
}