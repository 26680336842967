import React from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardBody,
  Button,
  CardTitle,
  CardText,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";
import { getStats } from "../../actions/statsActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classnames from "classnames";
import { withRouter, Link } from "react-router-dom";
import Chart from "./chart2";
import PieChart from "./pie";
import ReactSpeedometer from "react-d3-speedometer"
import { getGameStatsg1, getGameStatsg2, getGameStatsecode} from "../../actions/gamesActions";

class Stats extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    this.props.getStats();
    this.props.getGameStatsecode();
    this.props.getGameStatsg1();
    this.props.getGameStatsg2();
  }
  render() {
    
if(this.props.user.planned == "p999"){
  console.log("arvind")
  var g1key = 0;
    var g2key = 0;
    var ecodekey =0;
    
  if(this.props.g1stat){
   // console.log(this.props.g1stat.wins)
    g1key = this.props.g1stat.wins.reduce(function (accumulator, currentValue) {
    return accumulator + currentValue.wins;
  }, 0)
     
//}    
   }
   if(this.props.g2stat){
    g2key = this.props.g2stat.wins.reduce(function (accumulator, currentValue) {
      return accumulator + currentValue.wins;
    }, 0)
   // }
   }
   if(typeof this.props.ecodestat !== "undefined"){
   if(this.props.ecodestat.wins.length>0){
    ecodekey = this.props.ecodestat.wins.reduce(function (accumulator, currentValue) {
      return accumulator + currentValue.wins;
    }, 0)
  }
     

    
   } 
}
   
    if (typeof this.props.net_worth == "undefined") return null;

    let chartData = this.props.net_worth.y1
      ? this.props.net_worth.y1.map(e => {
          const [year, month, day] = [...e.tick.split("-")];
          const monthIndex = month - 1;
          const date = new Date(year, monthIndex, day);
          return { date: date, close: e.price };
        })
      : [];
    chartData.sort((a, b) => a.date.getTime() - b.date.getTime());

    return (
      <div className="container p-4">
        <Row>
          <div className="col-8 offset-md-2">
            <h4 className="text-center pb-2">Net Worth</h4>
            <div>
              {chartData.length > 0 ? (
                <Chart data={chartData} seriesName="Net Worth" />
              ) : null}
            </div>
          </div>
        </Row>
        {this.props.net_worth.buy_reason ? (
          <Row className="mt-4 pt-4">
            <Col md={{ size: 8, offset: 2 }}>
              <h4 className="text-center pb-2">Buy Side Trade Reason</h4>
              <Row>
                <Col md={{ size: 4, offset: 2 }}>
                  <Card>
                    <CardBody style={{ backgroundColor: "#e3e3e3" }}>
                      {this.props.net_worth.buy_reason.map(e => (
                        <div className="p-2 d-table">
                          <div
                            style={{
                              width: "30px",
                              display: "inline-block",
                              backgroundColor: e.color,
                              height: "30px"
                            }}
                          />
                          <div
                            className="pl-2"
                            style={{
                              display: "inline-block",
                              display: "table-cell",
                              verticalAlign: "middle"
                            }}
                          >
                            {e.name}
                          </div>
                        </div>
                      ))}
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <PieChart data={this.props.net_worth.buy_reason} />
                </Col>
              </Row>
            </Col>
          </Row>
        ) : null}

        {this.props.net_worth.sell_reason ? (
          <Row className="mt-4 pt-4 pb-4">
            <Col md={{ size: 8, offset: 2 }}>
              <h4 className="text-center pb-2">Sell Side Trade Reason</h4>
              <Row>
                <Col md={{ size: 4, offset: 2 }}>
                  <Card>
                    <CardBody style={{ backgroundColor: "#e3e3e3" }}>
                      {this.props.net_worth.sell_reason.map(e => (
                        <div className="p-2 d-table">
                          <div
                            style={{
                              width: "30px",
                              display: "inline-block",
                              backgroundColor: e.color,
                              height: "30px"
                            }}
                          />
                          <div
                            className="pl-2"
                            style={{
                              display: "inline-block",
                              display: "table-cell",
                              verticalAlign: "middle"
                            }}
                          >
                            {e.name}
                          </div>
                        </div>
                      ))}
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <PieChart data={this.props.net_worth.sell_reason} />
                </Col>
              </Row>
            </Col>
          </Row>
        ) : null}
        {this.props.user.planned == "p999"?(<Row className="mt-4 pt-4 pb-4" style ={{marginLeft:"120px"}}>
          <Col>
          { this.props.g1stat?( <ReactSpeedometer 
  maxValue={100}
  forceRender={true}
  value={parseFloat(g1key/(0.1*this.props.g1stat.wins.length)).toFixed(2)}
  startColor="yellow"
  needleColor="steelblue"
  needleTransitionDuration={4000}
  needleTransition="easeElastic"
  segments={4}
  endColor="white"
/>):null}
         <div style={{position:"relative",marginTop:"-90px",marginLeft:"60px"}}><b>Stockflash Flash Accuracy</b></div>
          </Col>
          <Col>
          { this.props.g2stat?( <ReactSpeedometer
  maxValue={100}
  value={parseFloat(g2key/(0.1*this.props.g2stat.wins.length)).toFixed(2)}
  needleColor="steelblue"
  needleTransitionDuration={4000}
  needleTransition="easeElastic"
  startColor="yellow"
  segments={4}
  endColor="white"
/>):null}
<div style={{position:"relative",marginTop:"-90px",marginLeft:"45px"}}><b>Stockflash Shootout Accuracy</b></div>
          </Col>
          <Col>
          {this.props.ecodestat?( <ReactSpeedometer
  maxValue={100}
  value={parseFloat(ecodekey/(0.1*this.props.ecodestat.wins.length)).toFixed(2)}
  needleColor="steelblue"
  needleTransitionDuration={4000}
  needleTransition="easeElastic"
  startColor="yellow"
  segments={4}
  endColor="white"
/>):null}
<div style={{position:"relative",marginTop:"-90px",marginLeft:"90px"}}><b>Ecode Accuracy</b></div>
          </Col>
        </Row>):null}
        
      </div>
    );
  }
}

Stats.propTypes = {
  getStats: PropTypes.func.isRequired,
  getGameStatsg1:PropTypes.func.isRequired,
  getGameStatsg2:PropTypes.func.isRequired,
  getGameStatsecode:PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  net_worth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  net_worth: state.stats.net_worth,
  errors: state.errors,
  user: state.user.info,
  g1stat:state.games.g1stat.data,
  g2stat:state.games.g2stat.data,
  ecodestat:state.games.ecodestat.data
});

export default withRouter(
  connect(
    mapStateToProps,
    { getStats,getGameStatsecode,getGameStatsg1,getGameStatsg2 }
  )(Stats)
);
