import { LEADER_BOARD } from '../actions/types';

const initialState = {
eventname:'',
data:[]
};

export default function(state = initialState, action) {
	switch (action.type) {
		case LEADER_BOARD:
			return {
				...state,data:action.payload
			};

		default:
			return state;
	}
}