import React from "react";
import {
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import { Link } from "react-router-dom";

export default class MarketMovers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "gainer"
    };
  }

  getRow = c => {
    let diff = (c.market_price - c.prev_close).toFixed(2);
    return (
      <tr
        key={c.id}
        style={{ background: diff > 0 ? "#008700" : "red", color: "#fff" }}
      >
        <td width="40%">{c.company}</td>
        <td width="30%">{c.market_price}</td>
        <td width="30%">
          {diff > 0 ? "▲" : "▼"}
          {diff}({c.market_change}
          %)
        </td>
      </tr>
    );
  };

  setFilter = e => {
    if (e.target.value == "TOP LOSERS") {
      this.setState({ type: "loser" });
    } else {
      this.setState({ type: "gainer" });
    }
  };
  render() {
    return (
      <div style={{ padding: "20px" }}>
        <div
           className="darkbg font-weight-bold"
          style={{
            textTransform: "uppercase"
            
          }}
        >
          Market Movers
          <select
            onChange={this.setFilter}
            className="form-control"
            style={{
              marginTop: '-7px',
              height: "38px",
              width: "170px",
              float: "right"
            }}
          >
            <option>TOP GAINERS</option>
            <option>TOP LOSERS</option>
          </select>
        </div>
        <table
          className="table table-hover table-bordered"
          style={{ border: "0px!important", width: "100%" }}
        >
          <tbody>
            {this.state.type == "gainer"
              ? this.props.gainers.map(c => this.getRow(c))
              : this.props.losers.map(c => this.getRow(c))}
          </tbody>
        </table>
      </div>
    );
  }
}
