import React, { Component } from "react";

class YearMonth extends Component {
  constructor() {
    super();
    this.state = {
      val: { y: "0", m: "0" }
    };
  }

  updateCompVal = () => {
    let val = this.props.dataVal;
    if (val) {
      val = val.split(/\s*,\s*/);
    } else {
      val = [0, 0];
    }
    this.setState({ y: val[0], m: val[1] });
  };
  componentDidMount = () => {
    this.updateCompVal();
  };

  componentDidUpdate = prevProps => {
    if (prevProps.dataVal != this.props.dataVal) {
      this.updateCompVal();
    }
  };

  onChangeHandle = (type, val) => {
    let newState = { ...this.state };
    newState.val[type] = val;
    this.setState(newState);
    this.props.setVal(`${newState.val.y},${newState.val.m}`);
  };

  render() {
    return (
      <div
        className="form-inline page-question"
        data-qid={this.props.qid}
        data-val={this.props.dataVal}
        data-block-id={this.props.dataBlockId}
        data-repeat-index={this.props.dataRepeatIndex}
      >
        <select
          value={this.state.y}
          className="form-control"
          style={{ marginRight: "20px" }}
          onChange={e => this.onChangeHandle("y", e.target.value)}
        >
          {[...Array(31)].map((x, i) => (
            <option value={i} key={i}>
              {i} Years
            </option>
          ))}
          <option value="30+">30+ Years</option>
        </select>
        <select
          value={this.state.m}
          className="form-control"
          onChange={e => this.onChangeHandle("m", e.target.value)}
        >
          {[...Array(12)].map((x, i) => (
            <option value={i} key={i}>
              {i} Months
            </option>
          ))}
        </select>
      </div>
    );
  }
}

export default YearMonth;
