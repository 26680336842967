import React from "react";
import { Row, Col,Button } from "reactstrap";
import { Table } from "reactstrap";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption
} from "reactstrap";

import { Document, Page } from "react-pdf";
const items = [
    {
      src: "/img/m22/11.png",
      altText: "Slide 1",
      caption: "Slide 1"
    },
    {
      src: "/img/m22/12.png",
      altText: "Slide 2",
      caption: "Slide 2"
    },
    {
      src: "/img/m22/13.png",
      altText: "Slide 3",
      caption: "Slide 3"
    },
    {
      src: "/img/m22/14.png",
      altText: "Slide 4",
      caption: "Slide 4"
    },
    {
      src: "/img/m22/15.png",
      altText: "Slide 5",
      caption: "Slide 5"
    },
    {
      src: "/img/m22/16.png",
      altText: "Slide 6",
      caption: "Slide 6"
    },
    {
      src: "/img/m22/17.png",
      altText: "Slide 7",
      caption: "Slide 7"
    },
    {
      src: "/img/m22/18.png",
      altText: "Slide 8",
      caption: "Slide 8"
    },
    {
      src: "/img/m22/19.png",
      altText: "Slide 9",
      caption: "Slide 9"
    },
    {
      src: "/img/m22/110.png",
      altText: "Slide 10",
      caption: "Slide 10"
    }
  ];
class VEDIO21 extends React.Component {
  constructor(props) {
    super(props);
    this.state = { activeIndex: 0 };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
    
  }
  componentDidMount(){
    this.props.fetchvideoScore()
  }
  state = {
    views:"viedo"
  }
  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === items.length - 1
        ? 0
        : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === 0
        ? items.length - 1
        : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }
  
  
  render() {
    let score = this.props.vscore.response.find(
      o => o.user_id == this.props.userId
    );
    if (score ? score.module1 == null : !score) {
      return (
        <div className="text-center">
          <h3>Please complete the previous module before proceeding ahead</h3>
        </div>
      );
    }else{
    const { activeIndex } = this.state;
    const slides = items.map(item => {
      return (
        <CarouselItem
          className="custom-tag"
          tag="div"
          key={item.src}
          onExiting={this.onExiting}
          onExited={this.onExited}
          
        >
          <img src={item.src} alt={item.altText} style = {{width:"100%",height:"90%"}}  />
         
        </CarouselItem>
      );
    });


    return (
      
      <div className="m1_intro resource container">
        <div>
          <h4 className="text-center font-weight-bold mt-5 mb-4">
          History of stock Exchange in India 
          </h4>
          <hr />
        </div>
        {this.state.views=="presentation"?(
          <div>
          <Carousel
            activeIndex={activeIndex}
            interval={ false }
            next={this.next}
            previous={this.previous}
          >
            <CarouselIndicators
              items={items}
              activeIndex={activeIndex}
              onClickHandler={this.goToIndex}
            />
            {slides}
            <CarouselControl
             className = "carousel-control-prev-icon"
              direction="prev"
              directionText="Previous"
              onClickHandler={this.previous}
              cssModule = {{width:"100%"}}
            />
            <CarouselControl
             className = "carousel-control-next-icon"
              direction="next"
              directionText="Next"
              onClickHandler={this.next}
            />
          </Carousel>
        </div>
        ):(
          <video width="100%" height="90%"  controls controlsList="nodownload">
  <source src="/videos/m22.mp4" type="video/mp4"/>
  <source src="/videos/m22.ogg" type="video/ogg"/>
</video>
        )}
        
    
   < Row>
              <Col sm="12" md="6" lg="6" className="pad-1">
              <Button
                    className="btn btn-padding btn-secondary"
                    type="submit"
                    style = {{width:"95%"}}
                    onClick = {()=>{this.setState({views:"viedo"})}}
                  >
                    Lecture
                  </Button>
              </Col>
              <Col sm="12" md="6" lg="6" >
              <Button
                    className="btn btn-padding btn-secondary"
                    type="submit"
                    style = {{width:"100%"}}
                    onClick = {()=>{this.setState({views:"presentation"})}}
                  >
                    Presentation
                  </Button>
              </Col>
            </Row>
            {this.state.views=="presentation"?(<div style ={{marginBottom:"3px"}}>
             
            </div>):null}
            

       
        </div>
    );
        }
  }
}

export default VEDIO21;
