import React, { Component } from "react";
import FileUploadProgress from "react-fileupload-progress";

class FileUpload extends Component {
  constructor() {
    super();
  }

  componentDidMount = () => {
    // let val = this.props.value;
    // if (val) {
    //   val = val.split(/\s*,\s*/);
    // } else {
    //   val = [0, 0];
    // }
    // this.setState({ y: val[0], m: val[0] });
  };

  render() {
    return (
      <div
        className="form-inline page-question"
        data-qid={this.props.qid}
        data-val={this.props.dataVal}
        data-block-id={this.props.dataBlockId}
        data-repeat-index={this.props.dataRepeatIndex}
      >
        <FileUploadProgress
          beforeSend={req => {
            req.withCredentials = "true";
            req.setRequestHeader("Authorization", "Basic " + window.user_token);
            return req;
          }}
          key="ex1"
          url={window.BASE_URL + "/api/hr/upload"}
          onProgress={(e, request, progress) => {
            //console.log("progress", e, request, progress);
          }}
          onLoad={(e, request) => {
            if (request.response.filename) {
              this.props.setVal(request.response.filename);
            }
          }}
          onError={(e, request) => {
            //console.log("error", e, request);
          }}
          onAbort={(e, request) => {
            //console.log("abort", e, request);
          }}
        />
      </div>
    );
  }
}

export default FileUpload;
