import React from "react";
import {
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getDashboardData,getFeed } from "../../actions/dashboardActions";
import { getWatch } from "../../actions/scripsActions";

import MarketIndices from "./market_indices";
import MarketMovers from "./market_movers";
import ReturnMeter from "./return_meter";
import Feed from "./feed";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
  }
  
  componentDidMount = () => {
    this.props.getDashboardData();
    this.props.getWatch('LARGE CAP');
    this.props.getFeed();
  };

  componentWillUpdate(prevProps){
    if(this.props.socketData != prevProps.socketData){
      this.props.getDashboardData();
    }
  }

  render() {
    if (!this.props.dashboard.spIndices) return null;
    console.log("rendering dashboard")
    return (
      <Col md="12" sm="12" className="container text-center">
        <Row>
          <Col md="8" sm="12">
            <MarketIndices
              spIndices={this.props.dashboard.spIndices}
              indicesLabels={this.props.dashboard.indicesLabels}
            />
            <MarketMovers
              gainers={this.props.dashboard.gainers}
              losers={this.props.dashboard.losers}
            />
          </Col>
          <Col md="4" sm="12">
            <ReturnMeter
              market_update={this.props.market_update}
              getWatch={this.props.getWatch}
              watchList={this.props.watchList}
              pgain={this.props.dashboard.pgain}
              ploss={this.props.dashboard.ploss}
            />
            <Feed feed={this.props.feed}/>
          </Col>
        </Row>
      </Col>
    );
  }
}

Dashboard.propTypes = {
  market_update: PropTypes.object.isRequired,
  getFeed: PropTypes.func.isRequired,
  getDashboardData: PropTypes.func.isRequired,
  getWatch:  PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  watchList: PropTypes.array.isRequired,
  errors: PropTypes.object.isRequired,
  socketData: PropTypes.object.isRequired,
  dashboard: PropTypes.object.isRequired,
  feed:  PropTypes.array.isRequired
};

const mapStateToProps = state => ({
  market_update: state.socketData.new_market_data,
  user: state.user,
  dashboard: state.dashboard.data,
  watchList: state.scrips.watchList,
  socketData: state.socketData,
  errors: state.errors,
  feed: state.dashboard.feed,
});

export default connect(
  mapStateToProps,
  { getDashboardData,getWatch,getFeed }
)(Dashboard);
