import { ORDER_LIST,ORDER_CREATE } from "../actions/types";

const initialState = { orders: [], recent_order_id: 0 };

export default function(state = initialState, action) {
  switch (action.type) {
    case ORDER_LIST:
      return { ...state,orders: action.payload };
    case ORDER_CREATE:
      return { ...state, recent_order_id: action.payload.order_id };
    default:
      return state;
  }
}
