import axios from "axios";
import {
  RESOURCE_QUESTIONS,
  RESOURCE_VIDEOS,
  RESOURCE_FINLATICS_VIDEOS,
  RESOURCE_ALL_VIDEOS,
  RESOURCE_FETCH_SCORE,
  RESOURCE_VIDEOQUESTIONS,
  RESOURCE_VIDEOFETCH_SCORE
} from "./types";

export const getQuestions = () => dispatch => {
  axios
    .get(`api/resource/quiz`)
    .then(res => {
      dispatch({
        type: RESOURCE_QUESTIONS,
        payload: res.data
      });
    })
    .catch(err => {});
};

export const getvideoQuestions = () => dispatch => {
  axios
    .get(`api/resource/videoquiz`)
    .then(res => {
      dispatch({
        type: RESOURCE_VIDEOQUESTIONS,
        payload: res.data
      });
    })
    .catch(err => {});
};

export const getScore = (score, id, moduleId) => dispatch => {
  axios
    .post("api/resource/quizScore", {
      score: score,
      user_id: id,
      module: moduleId
    })

    .then(res => {})
    .catch(err => {});
};

export const getvideoScore = (score, id, moduleId) => dispatch => {
  axios
    .post("api/resource/videoquizScore", {
      score: score,
      user_id: id,
      module: moduleId
    })

    .then(res => {})
    .catch(err => {});
};

export const fetchScore = () => dispatch => {
  axios
    .get("api/resource/finalScore")
    .then(res => {
      dispatch({
        type: RESOURCE_FETCH_SCORE,
        payload: res.data
      });
    })
    .catch(err => {});
};

export const fetchvideoScore = () => dispatch => {
  axios
    .get("api/resource/videofinalScore")
    .then(res => {
      dispatch({
        type: RESOURCE_VIDEOFETCH_SCORE,
        payload: res.data
      });
    })
    .catch(err => {});
};
export const getVideos = () => dispatch => {
  axios
    .get("api/resource/videos")
    .then(res => {
      dispatch({
        type: RESOURCE_VIDEOS,
        payload: res.data
      });
    })
    .catch(err => {});
};

export const getFinlaticsVideos = () => dispatch => {
  axios
    .get("api/resource/fVideos")
    .then(res => {
      dispatch({
        type: RESOURCE_FINLATICS_VIDEOS,
        payload: res.data
      });
    })
    .catch(err => {});
};
export const getAllVideos = () => dispatch => {
  axios
    .get("api/resource/allVideos")
    .then(res => {
      dispatch({
        type: RESOURCE_ALL_VIDEOS,
        payload: res.data
      });
    })
    .catch(err => {});
};
