import React from "react";
import {
  Table,
  Input,
  FormGroup,
  Button,
  UncontrolledTooltip
} from "reactstrap";
import { formatCurrency } from "../../../utils/format";
import styled from "styled-components";
import { last } from "react-stockcharts/lib/utils";
import { ENGINE_METHOD_PKEY_ASN1_METHS } from "constants";
import moment from "moment-timezone";
const ScripInput = styled.input`
  margin: 5px !important;
  width: 90px !important;
  display: inline-block !important;
  font-size: 15px !important;
`;

export default class MarketRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      qty: "",
      timerStart: 0,
      timediff: -1,
      sel_type: 3,
      price: "",
      limit_pending: false,
      last_action_type: ""
    };
  }

  timer = 0;

  countDown = () => {
    // Remove one second, set state so a re-render happens.
    var now = new Date().getTime();
    let timediff = 360 - (now - this.state.timerStart) / 1000;
    this.setState({
      timediff: timediff
    });

    // Check if we're at zero.
    if (timediff <= 0) {
      clearInterval(this.timer);
    }
  };
  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }
  componentDidMount() {
    this.showTimer();
  }

  componentDidUpdate(prevProps) {
    let update =
      prevProps.UserPortfolio &&
      this.props.UserPortfolio &&
      this.props.UserPortfolio.qty > prevProps.UserPortfolio.qty
        ? true
        : false;
    if (!update)
      this.props.MyScrips.forEach(element => {
        const el2 = prevProps.MyScrips.find(t => t.id == element.id);
        if (el2 == undefined || el2.status != element.status) {
          update = true;
          return false;
        }
      });

    if (update) {
      this.setState({ timediff: -1 });
    }
    this.showTimer();
  }

  getCompanyCode = code => {
    if (this.props.company_code_str.trim() == "") {
      return code;
    }
    var re = new RegExp(`(${this.props.company_code_str})`, "gi");
    return (
      <span
        dangerouslySetInnerHTML={{
          __html: code.replace(re, "<strong>$1</strong>")
        }}
      />
    );
  };

  getCompany = company => {
    if (this.props.company_str.trim() == "") {
      return company;
    }
    var re = new RegExp(`(${this.props.company_str})`, "gi");
    return (
      <span
        dangerouslySetInnerHTML={{
          __html: company.replace(re, "<strong>$1</strong>")
        }}
      />
    );
  };
  showTimer = async () => {
    if (
      this.props.MyScrips &&
      this.state.timediff < 0 &&
      this.props.MyScrips.length > 0
    ) {
      //limit_pending
     await  this.props.MyScrips.sort(function(a, b) {
        return b["id"] - a["id"];
      });
      let el = await this.props.MyScrips[0];
      let el1= await this.props.MyScrips[1];
      
      if ((el["status"]!="PENDING")&&(el["typ"]!="MARKETSP"&& el["typ"]!="LIMITSP")) {
        var now = new Date().getTime();
        if (this.props.MyScrips && this.props.MyScrips[0]["updatedAt"]) {
          var timediff =
            (now - new Date(this.props.MyScrips[0]["updatedAt"]).getTime()) /
            1000;

          if (this.timer) {
            clearInterval(this.timer);
          }
          if (
            this.props.MyScrips[0]["status"] == "DONE" ||
            this.props.MyScrips[0]["status"] == "CANCELLED"
          ) {
            console.log("111100000000001")
            if (timediff < 360) {
              let last_action_type =
                parseInt(this.props.MyScrips[0].qty) > 0 ? "BUY" : "SELL";
                console.log(last_action_type,"TYPE1")
              this.setState({
                last_action_type: last_action_type,
                limit_pending: false,
                timerStart: new Date(
                  this.props.MyScrips[0]["updatedAt"]
                ).getTime(),
                timediff: 360 - timediff
              });
              this.timer = setInterval(this.countDown, 1000);
            }
          }
        }
      }
     else if ((el["status"]=="PENDING")&&(el["typ"]=="MARKETSP")) {
        var now = new Date().getTime();
        if (this.props.MyScrips && this.props.MyScrips[0]["updatedAt"]) {
          var timediff =
            (now - new Date(this.props.MyScrips[0]["updatedAt"]).getTime()) /
            1000;
          if (
            this.props.MyScrips[0]["status"] == "PENDING" ||
            this.props.MyScrips[0]["status"] == "CANCELLED"||this.props.MyScrips[0]["status"] == "DONE"
          ) {
            if (timediff < 360) {
              console.log("ppppppppp00000000000")
              let last_action_type =
                parseInt(this.props.MyScrips[0].qty) > 0 ? "SELL" : "BUY";
                console.log(last_action_type,"TYPE3")
              this.setState({
                last_action_type: last_action_type,
                limit_pending: false,
                timerStart: new Date(
                  this.props.MyScrips[0]["updatedAt"]
                ).getTime(),
                timediff: 360 - timediff
              });
              this.timer = setInterval(this.countDown, 1000);
            }
          }
        }
      }
      else if ((el["status"]=="PENDING")&&(el["typ"]=="LIMITSP")&&el1["status"]=="DONE") {
        if (this.timer) {
          clearInterval(this.timer);
        }
        let last_action_type ="BUY"
        console.log(last_action_type,"TYPE3")
        this.setState({
          timediff: 0,
          limit_pending: false,
          last_action_type: last_action_type
        });
      
      } 
      else if ((el["status"]=="PENDING")&&(el["typ"]=="LIMITSP")&&el1["status"]=="PENDING") {
        if (this.timer) {
          clearInterval(this.timer);
        }
        let last_action_type ="BUY"
        console.log(last_action_type,"TYPE3")
        this.setState({
          timediff: 0,
          limit_pending: true,
          last_action_type: last_action_type
        });
      
      } 

      else if((el["status"]=="PENDING")&&(el["typ"]=="LIMIT")){
        console.log("normal")
        if (this.timer) {
          clearInterval(this.timer);
        }
        
        this.setState({
          timediff: 0,
          limit_pending: true,
          last_action_type: parseInt(el.qty) > 0 ? "BUY" : "SELL"
        });
      }
    }
  };

  getBuySellBtn = (buy_dis, sell_dis) => {
    return (
      <span>
        <div style={{display:"flex"}}>
          <div
            style={{
              margin: "10px",
              color: "white",
              textAlign: "center",
              fontSize: "12px",
              padding: "2px 10px",
              width:"40%",
              backgroundColor:"#31B258",
              borderRadius:"5px"
            }}
            onClick={() => {
              if (!buy_dis) {
                this.props.buy(
                  this.props.id,
                  this.props.company,
                  "BUY",
                  this.state.qty,
                  this.state.sel_type,
                  this.state.price
                    ? this.state.price
                    : this.state.sel_type == 2
                    ? null
                    : this.props.market_update,
                  this.props.market_update
                );
                if (
                  this.state.qty &&
                  ((this.state.sel_type == 2 && this.state.price) ||
                    this.state.sel_type == 1)
                ) {
                  this.setState({ qty: "", price: "", sel_type: 3 });
                }
              }
            }}
            className={buy_dis ? "btn-dis" : "btn-buy"}
          >
            BUY
          </div>
          <div
            style={{
              margin: "10px",
              color: "white",
              textAlign: "center",
              fontSize: "12px",
              padding: "2px 10px",
              width:"40%",
              backgroundColor:"#FF0000"
            }}
            onClick={() => {
              if (!sell_dis) {
                this.props.buy(
                  this.props.id,
                  this.props.company,
                  "SELL",
                  this.state.qty,
                  this.state.sel_type,
                  this.state.price
                    ? this.state.price
                    : this.state.sel_type == 2
                    ? null
                    : this.props.market_update,
                  this.props.market_update,
                  this.props.UserPortfolio
                );
                if (
                  this.state.qty &&
                  ((this.state.sel_type == 2 && this.state.price) ||
                    this.state.sel_type == 1)
                ) {
                  this.setState({ qty: "", price: "", sel_type: 3 });
                }
              }
            }}
            className={sell_dis ? "btn-dis" : "btn-sell"}
          >
            SELL
          </div>
        </div>
      </span>
    );
  };
  getActionButtons = () => {
    if (
      ((this.state.timediff > 0 && this.state.timediff < 360) ||
        this.state.limit_pending) &&
      this.props.MyScrips &&
      this.props.MyScrips.length > 0
    ) {
      if (this.state.last_action_type == "BUY") {
        return this.getBuySellBtn(false, true);
      } else {
        return this.getBuySellBtn(true, false);
      }
    } else {
      return this.getBuySellBtn(false, false);
    }
  };

  getTimer = () => {
    if (
      ((this.state.timediff > 0 && this.state.timediff < 360) ||
        this.state.limit_pending) &&
      this.props.MyScrips &&
      this.props.MyScrips.length > 0
    ) {
      if (this.state.last_action_type == "BUY") {
        let msg = this.state.limit_pending
          ? "Sell is disabled until buy limit is executed"
          : `Sell is  disabled for ${this.state.timediff.toFixed(0)}s`;
        return (
          <div className="font14">
            {msg}
            <br />
            {this.state.limit_pending ? (
              <div>
                <a
                  style={{ textDecoration: "underline" }}
                  onClick={() => this.props.toggleTab("2")}
                >
                  Click here
                </a>{" "}
                to cancel limit order
              </div>
            ) : null}
          </div>
        );
      } else {
        let msg = this.state.limit_pending
          ? "Buy is disabled until sell limit is executed"
          : `Buy is  disabled for ${this.state.timediff.toFixed(0)}s`;
        return (
          <div className="font14">
            {msg}
            <br />
            {this.state.limit_pending ? (
              <div>
                <a
                  style={{ textDecoration: "underline" }}
                  onClick={() => this.props.toggleTab("2")}
                >
                  Click here
                </a>{" "}
                to cancel limit order
              </div>
            ) : null}
          </div>
        );
      }
    } else {
      return null;
    }
  };

  getMarketPriceTD = pDiff => {
    return (
      <td
        style={{ fontSize: "16px" }}
             >
        <div className={pDiff <= 0 ? "border border-danger p-2" : "border border-success p-2"} style={pDiff <= 0 ? {background:" linear-gradient(0deg, rgba(236,236,236,1) 44%, rgba(237,193,193,1) 100%)",color:"red"} :  {background:"linear-gradient(0deg, rgba(236,236,236,1) 44%, rgba(155,240,148,1) 100%)",color:"#31B258"} } >
          {formatCurrency(this.props.market_update)}
          <br />
          <p style={{margin:"0px"}}> <span className={pDiff < 0 ? "text-danger":"text-success"}>{pDiff < 0 ? "\u25BC" : pDiff > 0 ? "\u25B2" : ""}</span>   {pDiff}</p>
          
          {((pDiff / this.props.prev_close) * 100).toFixed(2)}%
        </div>
      </td>
    );
  };

  setPrice = e => {
    let price = e.target.value ? e.target.value : "";
    this.setState({ price: price });
  };

  setQty = e => {
    this.setState({ qty: e.target.value });
  };

  buyType = typ => {
    var price = "";
    if (typ == 2) {
      price = this.state.price;
    }
    this.setState({ sel_type: typ, price: price });
  };

  getBuyRate = () => {
    if (this.props.UserPortfolio && this.props.UserPortfolio.qty > 0) {
      let buy = [];
      let sell = 0;
      let myScrips = [...this.props.MyScrips];
      myScrips.sort((a, b) => a.id - b.id);
      myScrips.forEach(ms => {
        if (ms.status === "DONE") {
          if (ms.qty > 0) {
            for (var i = 0; i < ms.qty; i++) {
              buy.push(ms);
            }
          } else {
            sell += Math.abs(ms.qty);
          }
        }
      });
      buy.splice(0, sell);
      let total = 0;
      buy.forEach(ms => (total += ms.price));
      return total / buy.length;
    }
  };

  isMarketOpen = () => {
    // Get current time in IST
    const now = moment.tz("Asia/Kolkata");
    const startTime = moment.tz("Asia/Kolkata").set({ hour: 9, minute: 0, second: 0 });
    const endTime = moment.tz("Asia/Kolkata").set({ hour: 15, minute: 45, second: 0 });
    
    // Check if current time is between 9:00 AM and 3:30 PM
    return now.isBetween(startTime, endTime);
  }

  render() {
    const pDiff = (this.props.market_update - this.props.prev_close).toFixed(2);

    const buyRate = this.getBuyRate();

    const selfProfitLoss =
      buyRate > 0
        ? buyRate - this.props.market_update > 0
          ? "border border-danger  text-danger"
          : "border border-success text-success"
        : "";
    var checked_market =
      this.state.sel_type == 1 ? { checked: "checked" } : { checked: false };
    var checked_limit =
      this.state.sel_type == 2 ? { checked: "checked" } : { checked: false };
    var limit_disabled =
      this.state.sel_type == 1 || this.state.sel_type == 3
        ? { disabled: "disabled" }
        : { disabled: false };

    return (
      <tr style={{ fontSize: "20px" }} className="tableBodyMarket">
        <td>
          <div style={{ textAlign: "center" }}>
            <Button
              onClick={() =>
                this.props.addWatch(
                  this.props.id,
                  this.props.is_watched,
                  this.props.segment
                )
              }
              className="site-gray btn4"
            >
              {this.props.is_watched ? "-" : "+"}
            </Button>
          </div>
        </td>
        <td
          // className={}
        
          style={{ wordBreak: "break-all" ,backgroundColor:"transparent",fontSize:"large"}}
        >
          <div className="cmp-link cmp-link-div" onClick={this.props.onCompanyInfo} style={{backgroundColor:"#C6EDC3",padding:"0px"}}>
         
            <div style={{height:"75%"  ,  width: "100%", margin: "0px",   borderRadius: "10px",backgroundColor:"white",padding:"10px" ,display:"flex",alignItems:"center",justifyContent:"center"}} >

            {this.getCompany(this.props.company)} ({this.props.shortCode})
              
            </div>
            <p style={{verticalAlign:"end",    width: "100%", margin: "0px",   borderRadius: "10px",height:"25%"}}>
            <small>{this.props.industry}</small>
            </p>
          </div>
        </td>
        {/* <td style={{ wordBreak: "break-all" }}>
          <div>{this.props.industry}</div>
        </td> */}
        <td>
          <div>
            {this.props.UserPortfolio ? this.props.UserPortfolio.qty : 0}
          </div>
        </td>
        <td >
          <div className={selfProfitLoss}>{buyRate > 0 ? formatCurrency(buyRate) : 0}</div>
        </td>
        {this.getMarketPriceTD(pDiff)}
        
        <td>
          {
            this.isMarketOpen() ? 
            (
              <div >
                <div style={{ margin: "auto" ,display:"flex" ,flexDirection:"row"}}>
                  <div style={{  minWidth: "200px" }}>
                    {" "}
                  
                    <div
                      className="form-inline"
                      style={{
                        margin: "auto",
                        minWidth: "200px",
                        
                      }}
                    >
                      <ScripInput
                        min="1"
                        step="1"
                        value={this.state.qty}
                        type="number"
                        onKeyPress={event =>
                          event.charCode < 48 || event.charCode > 57
                            ? event.preventDefault()
                            : null
                        }
                        onChange={this.setQty}
                        placeholder="QTY"
                      />
                      <ScripInput
                        min="0"
                        step="0.01"
                        value={this.state.price}
                        onChange={this.setPrice}
                        type="number"
                        placeholder="PRICE"
                        {...limit_disabled}
                      />
                    </div>
                    {this.getActionButtons()}
                  </div>
                  <div
                    style={{
                      float: "right",
                      // textAlign: "center",
                      // marginTop: "10px"
                    }}
                  >
                    
                    <div style={{  fontSize: "15px!important" ,textAlign:"start" ,justifyContent:"space-around"}}>
                      <p className="marketLimitSpan" >
                        <input
                          id={`mT${this.props.srCode}`}
                          value={1}
                          onChange={() => this.buyType(1)}
                          type="radio"
                          {...checked_market}
                          name={this.props.srCode}
                          style={{marginRight:"5px"}}
                        />
                        <label htmlFor={`mT${this.props.srCode}`} style={{fontSize:"medium"}}>Market   <i
                          className="fa fa-info-circle fa-sm"
                          id={"buyTT" + this.props.srCode}
                          style={{display:"inline-block"}}
                        /></label>
                      
                        <UncontrolledTooltip
                          placement="right"
                          target={"buyTT" + this.props.srCode}
                        >
                          These orders will be executed at current market prices,
                          indicated in the panel on the left.
                        </UncontrolledTooltip>
                      </p>
                      <p className="marketLimitSpan" >
                        <input
                          id={`lT${this.props.srCode}`}
                          value={2}
                          onChange={() => this.buyType(2)}
                          type="radio"
                          {...checked_limit}
                                              name={this.props.srCode}
                          style={{marginRight:"5px"}}
                        />
                        <label htmlFor={`lT${this.props.srCode}` } style={{fontSize:"medium"}}>Limit  <i
                          className="fa fa-info-circle fa-sm"
                          id={"limitTT" + this.props.srCode}
                          style={{display:"inline-block"}}
                        /></label>
                        
                        <UncontrolledTooltip
                          placement="right"
                          target={"limitTT" + this.props.srCode}
                        >
                          This gives you the discretion to order at a particular
                          price. You can punch in the price you want to trade at in
                          the ‘Price’ box below. Your order will only be executed if
                          market price reaches that price. However, there’s a catch –
                          limit orders that could not be executed are cancelled at
                          3.30pm of that day or the next day, whichever is earlier.
                        </UncontrolledTooltip>
                      </p>
                    </div>
                  </div>
                </div>
    
                <div style={{ textAlign: "center", clear: "both" }}>
                  {this.getTimer()}
                </div>
              </div>
            )
            :
            (
              <div>
                <h3>Market Closed</h3>
              </div>
            )
          }
        </td>
          
        
      </tr>
    );
  }
}
