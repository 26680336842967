import React from "react";
import { Button } from "reactstrap";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import { saveResearch } from "../../../actions/newresearchActions";
class ResearchInsight2 extends React.Component {
  state = {
    selectedFile: null,
    submitted: false
  };
  onChangeHandler = e => {
    this.setState({
      selectedFile: e.target.files[0],
      filename: e.target.files[0].name,
      loaded: 0
    });
  };

  triggerInputFile = () => this.fileInput.click();

  handleSubmit = e => {
    e.preventDefault();
    if (this.state.selectedFile) {
      const data = new FormData();
      data.append("selectedFile", this.state.selectedFile);
      data.append("week", 7);
      this.props.saveResearch(data);
      this.setState({ submitted: true });
    } else {
      this.props.showInvalidFormAlert();
    }
  };
  render() {
    if (this.state.submitted || this.props.research[7]) {
      if (!this.props.research[7]) {
        return <div>Please Wait...</div>;
      }
      return (
        <div>
          <div>
            Download:{" "}
            <a
              download
              className="text-primary"
              href={"/uploads/" + this.props.research[7].filename}
            >
              {this.props.research[7].originalname}
            </a>
          </div>
          <div>
            <div className="submitted text-center">
              <Button className="btn btn-light ">Submitted </Button>
            </div>
          </div>
        </div>
      );
    }
    return (
      <form
        onSubmit={this.handleSubmit}
        method="post"
        encType="multipart/form-data"
      >
        <div className="text-justify">
          <p>
            <div className="row">
              <div className="col-md-6">
                {" "}
                <h4>Sector 1:{ " "}{this.props.research[2].sector1} </h4>
              </div>
              <div className="col-md-6 text-center">
                {" "}
                <h4>Company 2:{this.props.research[2].segment2_comp}</h4>
              </div>
            </div>
            <font size="4">
              After completing Research Insight 1, you have to now conduct a
              similar research on the 
              second company that you had picked as part of the project.
            </font>
          </p>
          <p className="pt-3">
            <font size="4">
              In this section, you have to write a detailed research report on
              the abovementioned 
              company based on the following parameters.
            </font>
          </p>
          <div>
            <ul className="spacing">
              <li>
                <font size="3">
                  General Overview - This can be drawn from the brief write ups
                  
                  that you have given about the selected company. (100 words)
                </font>
              </li>
              <li>
                <font size="3">
                  Shareholding pattern of the company - You can draw some light
                  on whether
                  the company is family owned or not, by listing out its
                  
                  major shareholders with their percentage of ownership. (50 –
                  100 words)
                </font>
              </li>
              <li>
                <font size="3">
                  Management of the company - this section shall include
                  management qualifications, background and stability
                  of management (duration of their stay with the
                  company). You can also shed light on whether the company is
                  family operated or professionally operated. (50 – 100
                  words)
                </font>
              </li>
              <li>
                <font size="3">
                  Strengths, Weaknesses, Opportunities & Threats (SWOT) analysis
                  of the company (400 - 500 words).
                </font>
                <ul className="spacing" style={{ marginTop: "3px" }}>
                  <li>
                    <font size="3">
                      Strengths and Weaknesses need to be <b>internally</b>{" "}
                      focused and analysed by looking at the company
                      itself.
                    </font>
                  </li>
                  <li>
                    <font size="3">
                      Opportunities & Threats need to be <b>externally </b>
                      focused on the sector / industry that the company
                      is operating in and its standing in the 
                      macroeconomic environment.
                    </font>
                  </li>
                </ul>
              </li>
              <li>
                <font size="3">
                  Competitive Analysis - list down 2 - 3 close competitors and
                  company’s position with regard to them (300 words)
                </font>
              </li>
              <li>
                <font size="3">
                  Conclusion and way forward for the company - Based on your
                  analysis, what do you feel would be the way forward
                  for the company. Here, you can elaborate on how the
                  company can combine its strengths with external 
                  opportunities in order to counter its weaknesses and deal with
                  sector / industry level threats. (100 words)
                </font>
              </li>
            </ul>
          </div>
          <p>
            <font size="3">
              Total word count of the document would be 1000 - 1500 words, per
              company. You can visit our LinkedIn page to have a look
              on top research work in order to take a brief reference
              for the same.
            </font>
          </p>
          <p>
            <font size="3">
              Also, we hope you will be original in your work.
            </font>
          </p>

          <div className="mt-3">
            <input
              hidden
              ref={fileInput => (this.fileInput = fileInput)}
              type="file"
              name="selectedFile"
              onChange={this.onChangeHandler}
            />
          </div>
          <div className="text-center btn-upload">
            <Button
              onClick={this.triggerInputFile}
              type="button"
              className="btn btn-light border border-dark d-inline-block"
            >
              Browse...
            </Button>
            <h4 className="pl-3 d-inline-block">{this.state.filename}</h4>
          </div>
          <div className="text-center">
            <Button
              className="btn btn-padding btn-secondary mt-4"
              type="submit"
            >
              Submit
            </Button>
          </div>
        </div>
      </form>
    );
  }
}
ResearchInsight2.propstype = {
  saveResearch: PropTypes.func.isRequired
};
const mapStateToProps = state => {
  return {
    research: state.newresearch.data
  };
};
export default connect(
  mapStateToProps,
  { saveResearch }
)(ResearchInsight2);
