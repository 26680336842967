import React from "react";
import PropTypes from "prop-types";

import { scaleTime } from "d3-scale";
import { curveMonotoneX } from "d3-shape";
import { discontinuousTimeScaleProvider } from "react-stockcharts/lib/scale";

import { ChartCanvas, Chart } from "react-stockcharts";
import { AreaSeries } from "react-stockcharts/lib/series";
import { XAxis, YAxis } from "react-stockcharts/lib/axes";
import { fitWidth } from "react-stockcharts/lib/helper";
import { HoverTooltip } from "react-stockcharts/lib/tooltip";
import { ema } from "react-stockcharts/lib/indicator";
import moment from "moment";
import { last } from "react-stockcharts/lib/utils";

import {
  createVerticalLinearGradient,
  hexToRGBA
} from "react-stockcharts/lib/utils";

const canvasGradient = createVerticalLinearGradient([
  { stop: 0, color: hexToRGBA("#b5d0ff", 0.2) },
  { stop: 0.7, color: hexToRGBA("#6fa4fc", 0.4) },
  { stop: 1, color: hexToRGBA("#4286f4", 0.8) }
]);
const tooltipContent = ys => {
  return ({ currentItem, xAccessor }) => {
    return {
      x: moment(xAccessor(currentItem).getTime()).format("lll"),
      y: [
        {
          label: "Price",
          value: currentItem.close
        }
      ].concat(
        ys.map(each => ({
          label: each.label,
          value: each.value(currentItem),
          stroke: each.stroke
        }))
      )
    };
  };
};
class AreaChart extends React.Component {
  render() {
    const { data: initData, type, width, ratio, seriesName } = this.props;
    const xScaleProvider = discontinuousTimeScaleProvider.inputDateAccessor(
      d => d.date
    );
    const { data, xScale, xAccessor, displayXAccessor } = xScaleProvider(
      initData
    );

    if (typeof data === "undefined" || data.length == 0) return null;
    const start = xAccessor(data[0]);
    const end = xAccessor(data[data.length - 1]);
    const xExtents = [start, end];
    return (
      <ChartCanvas
        ratio={ratio}
        width={width}
        height={400}
        margin={{ left: 70, right: 50, top: 10, bottom: 30 }}
        seriesName={seriesName}
        data={data}
        xAccessor={xAccessor}
        displayXAccessor={displayXAccessor}
        xScale={xScale}
        xExtents={xExtents}
        panEvent={false}
        zoomEvent={false}
        clamp={false}
      >
        <Chart id={0} yExtents={d => d.close}>
          <defs>
            <linearGradient id="MyGradient" x1="0" y1="100%" x2="0" y2="0%">
              <stop offset="0%" stopColor="#b5d0ff" stopOpacity={0.2} />
              <stop offset="70%" stopColor="#6fa4fc" stopOpacity={0.4} />
              <stop offset="100%" stopColor="#4286f4" stopOpacity={0.8} />
            </linearGradient>
          </defs>
          <XAxis axisAt="bottom" orient="bottom" ticks={10} />
          <YAxis axisAt="left" orient="left" />
          <AreaSeries
            yAccessor={d => d.close}
            fill="url(#MyGradient)"
            strokeWidth={2}
            interpolation={curveMonotoneX}
            canvasGradient={canvasGradient}
          />
          <HoverTooltip
            yAccessor={d => d.totalVolume}
            tooltipContent={tooltipContent([])}
            fontSize={15}
          />
        </Chart>
      </ChartCanvas>
    );
  }
}

AreaChart.propTypes = {
  data: PropTypes.array.isRequired,
  width: PropTypes.number.isRequired,
  ratio: PropTypes.number.isRequired,
  type: PropTypes.oneOf(["svg", "hybrid"]).isRequired
};

AreaChart.defaultProps = {
  type: "svg"
};
AreaChart = fitWidth(AreaChart);

export default AreaChart;
