import React, { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Col } from "reactstrap";
import { saveAnswer } from "../../actions/riskProfileActions";

class Questions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: 1,
      answer: []
    };
  }

  nextBtn = btn => {
    var answer = [...this.state.answer];
    answer[this.state.stage] = {
      question: this.state.stage,
      option: btn,
      time: new Date()
    };
    if (this.state.stage == 10) {
      this.props.saveAnswer(answer);
      this.props.nextScreen();
    }
    this.setState({ answer: answer, stage: this.state.stage + 1 });
  };

  getFinishScreen = () => {
    return null
    return (
      <div className="game-finish animated slideInRight">
        <h2 style={{ paddingTop: "25px", textAlign: "center" }}>
          Done with the warm-up, now lets get real!
        </h2>
        <div className="row">
          <div className="col-md-6">
            <div style={{ height: "200px" }}>
              <p>
                You're relatively new to the world of stock markets. We know
                you're scared but chill, we've got your back!
              </p>
              <p>Let us guide you through setting up a portfolio.</p>
            </div>
            <button
              onClick={this.props.nextScreen}
              className="btn site-gray btn-take-step3"
              style={{
                width: "100%",
                marginBottom: "25px",
                padding: "5px 30px",
                fontSize: "28px",
                fontWeight: "600"
              }}
            >
              YES! Let's give this a shot together!
            </button>
          </div>
          <div className="col-md-6">
            <div style={{ height: "200px" }}>
              <p>
                You've got an aptitude for this and want to give the stock
                market a shot on your own terms!
              </p>
            </div>
            <button
              className="btn site-gray"
              style={{
                width: "100%",
                marginBottom: "25px",
                padding: "5px 30px",
                fontSize: "28px",
                fontWeight: "600"
              }}
            >
              NO! On my own terms!
            </button>
          </div>
        </div>
      </div>
    );
  };

  getQuestionScreen = () => {
    return (
      <div className="risk-questions">
        <div className="row" style={{ backgroundColor: "#fff" }}>
          <Col sm="12">
            <div className="mt-5 text-center">
              <h2>Choose a vault & a random coin from it will be yours</h2>
            </div>
          </Col>
          <Col sm="12">
            <div className="row p-4 text-center">
              <div className="col-sm-12 col-lg-6 col-md-6 col-xs-12 ">
                <img src={`/img/vault/${this.state.stage}a.png`} style={{width: '70%',maxWidth: '300px'}} />
                <div className="mt-4">
                  <button className=" btn btn-color text-white mt-2">
                    Vault 1
                  </button>
                </div>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12   ">
                <img src={`/img/vault/${this.state.stage}b.png`} style={{width: '70%',maxWidth: '300px'}} />
                <div className="mt-4">
                  <button className=" btn btn-color text-white mt-2">
                    Vault 2
                  </button>
                </div>
              </div>
            </div>
          </Col>
          <div className="col-md-6  text-center">
           
            <button
              onClick={() => this.nextBtn("A")}
              className="btn site-gray btn-ball-a btn-ball"
              style={{
                padding: "5px 30px",
                fontSize: "28px",
                fontWeight: "600"
              }}
            >
              Vault 1
            </button>
          </div>
          <div className="col-md-6  text-center">
            <button
              onClick={() => this.nextBtn("B")}
              className="btn site-gray btn-ball-b btn-ball"
              style={{
                padding: "5px 30px",
                fontSize: "28px",
                fontWeight: "600"
              }}
            >
              Vault 2
            </button>
            
          </div>
        </div>
        <div className="container-fluid footer-bg pt-4">
          <div className="container coin-container">
            <div>
              <h4 className="rk-val">Value:</h4>
            </div>
            <div className="rk-container ">
              <div>
                <div>
                  <img
                    src="/img/gold.png"
                    style={{ height: "65px", marginTop: "14px" }}
                  />
                </div>
                <div>
                  <span>&#8377;11,500</span>
                </div>
              </div>
              <div>
                <i className="fa fa-angle-right rk-icon" />
              </div>
            </div>
            <div />
            <div className="rk-container ">
              <div>
                <div>
                  <img
                    src="/img/silver.png"
                    style={{ height: "65px", marginTop: "14px" }}
                  />
                </div>
                <div>
                  <span>&#8377;6000 </span>
                </div>
              </div>
              <div>
                <i className="fa fa-angle-right rk-icon" />
              </div>
            </div>
            <div />
            <div className="rk-container ">
              <div>
                <div>
                  <img
                    src="/img/bronze.png"
                    style={{ height: "65px", marginTop: "14px" }}
                  />
                </div>
                <div>
                  <span>&#8377;4,800</span>
                </div>
              </div>
              <div>
                <i className="fa fa-angle-right rk-icon" />
              </div>
            </div>
            <div />

            <div className="rk-container ">
              <div>
                <div>
                  <img
                    src="/img/iron.png"
                    style={{ height: "65px", marginTop: "14px" }}
                  />
                </div>
                <div>
                  <span>&#8377;300</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <Col md="12" sm="12">
        <div className="mt-5 circle-container">
          {Array.from(Array(10).keys()).map(e => (
            <div className="circle-inner" key={e}>
              <div
                className={
                  "circle " + (this.state.stage == e + 1 ? "active" : "")
                }
              >
                <span>{e + 1}</span>
              </div>
            </div>
          ))}
        </div>
        {this.state.stage < 11
          ? this.getQuestionScreen()
          : this.getFinishScreen()}
      </Col>
    );
  }
}

Questions.propTypes = {
  saveAnswer: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { saveAnswer }
)(Questions);
