import React from "react";
import { Button } from "reactstrap";

class SectorSubmitted extends React.Component {
  render() {
    return (
      <div className="submitted">
        <p>
          Pick any two sectors that you want to conduct your research project
          on. The reason for picking a sector can be varied - right from your
          personal interest and inclination towards the sector to a sector where
          you see growth in near future.
        </p>
        <hr />
        <div className="text-center">
          <Button className="btn btn-light ">Submitted </Button>
        </div>
      </div>
    );
  }
}
export default SectorSubmitted;
