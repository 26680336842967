import axios from "axios";

import {
  ERRORS_GET,
  RISK_PROFILE_SAVE,
  RISK_PROFILE_GET
} from "./types";

import { showLoader } from "./loaderActions";
import { getCurrentUserDetails } from "./userActions";

export const saveAnswer = (answer) => dispatch => {
  axios
    .post("/api/risk-profile/v1/response", {
      answer: answer
    })
    .then(res => {
      dispatch({
        type: RISK_PROFILE_SAVE,
        payload: res.data
      })
      //dispatch(getCurrentUserDetails());
    })
    .catch(err =>
      dispatch({
        type: ERRORS_GET
      })
    );
};

export const getRiskProfile = () => dispatch => {
  axios
    .get("/api/risk-profile/v1/profile")
    .then(res => {
      dispatch({
        type: RISK_PROFILE_GET,
        payload: res.data
      })
    })
    .catch(err =>
      dispatch({
        type: ERRORS_GET
      })
    );
};

