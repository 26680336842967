import React from "react";
import {
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import { Link } from "react-router-dom";
import { formatCurrency } from "./../../utils/format";
export default class Feed extends React.Component {
  constructor(props) {
    super(props);
  }

  getRow = (c,key) => {
    return (
      <tr key={key} className="text-left">
        <td>
          <a href={c.link} target="_blank">
            <h5>{c.title}</h5>
            <h7 className="text-primary">{c.content}</h7>
          </a>
        </td>
      </tr>
    );
  };
  render() {
    return (
      <div
        style={{
          padding: "20px"
        }}
      >
        <div className="darkbg">
          <b>News Feed</b>
        </div>
        <div
          style={{
            height: "450px",
            overflowY: "scroll",
            width: "100%"
          }}
        >
          <table
            className="table table-hover"
            style={{
              width: "100%"
            }}
          >
            <tbody>{this.props.feed.map((c, key) => this.getRow(c,key))}</tbody>
          </table>
        </div>
      </div>
    );
  }
}
