import React from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  CardTitle,
  CardText,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";
import { getScripData, getChartData } from "../../actions/scripsActions";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import classnames from "classnames";
import { withRouter, Link } from "react-router-dom";
import Chart from "./chart2";
const stime = 9 * 60 + 15;
const etime = 15 * 60 + 30;
var x_min = 0;
var x_max = 30;
var x_break_interval = 10;
var x_break_on = 5;
var x_break_size = 4;

class Company extends React.Component {
  constructor(props) {
    super(props);
    let company = "";
    if (this.props.from_popup) {
      this.props.getScripData(this.props.sid);
      this.props.getChartData(this.props.sid);
    } else {
      if (props.company) {
        company = props.company;
      } else if (props.location && props.location.state) {
        company = props.location.state.company;
      }

      this.props.getScripData(this.props.location.state.id);
      this.props.getChartData(this.props.location.state.id);
    }
    this.state = {
      company: company,
      activeChart: "1M"
    };
  }

  componentDidUpdate(prevProp) {
    if (!this.props.from_popup) {
      if (
        this.props.location.state.company != prevProp.location.state.company
      ) {
        this.setState({
          company: this.props.location.state.company
        });
        this.props.getScripData(this.props.location.state.id);
        this.props.getChartData(this.props.location.state.id);
      }
    }
  }
  setChart = chart => {
    this.setState({ activeChart: chart });
  };

  render() {
    if (typeof this.props.cmp_data.Scrip == "undefined") return null;
    let bg = "lg-bg";
    console.log(this.props.cmp_data.Scrip.cap_type);
    switch (this.props.cmp_data.Scrip.cap_type) {
      case "Small Cap":
        bg = "sm-bg";
        break;
      case "Mid Cap":
        bg = "md-bg";
        break;
    }
    let tformat = {};
    let chartData = [];
    switch (this.state.activeChart) {
      case "1Y":
        chartData = this.props.chart_data.y1
          ? this.props.chart_data.y1.map(e => {
              const [year, month, day] = [...e.tick.split("-")];
              const monthIndex = month - 1;
              const date = new Date(year, monthIndex, day);
              return { date: date, close: e.price };
            })
          : [];
        break;
      case "1M":
        chartData = this.props.chart_data.m1
          ? this.props.chart_data.m1.map(e => {
              const [t1, t2] = [...e.tick.split("T")];
              const [year, month, day] = [...t1.split("-")];
              const [h, m, s] = [...t2.split(":")];
              const monthIndex = month - 1;
              const date = new Date(year, monthIndex, day, h, m, 0);
              return { date: date, close: e.price };
            })
          : [];
        break;
      case "1W":
        chartData = this.props.chart_data.w1
          ? this.props.chart_data.w1.map(e => {
              const [t1, t2] = [...e.tick.split("T")];
              const [year, month, day] = [...t1.split("-")];
              const [h, m, s] = [...t2.split(":")];
              const monthIndex = month - 1;
              const date = new Date(year, monthIndex, day, h, m, 0);
              return { date: date, close: e.price };
            })
          : [];
        break;
      case "1D":
        chartData = this.props.chart_data.d1
          ? this.props.chart_data.d1.map(e => {
              const [t1, t2] = [...e.tick.split("T")];
              const [year, month, day] = [...t1.split("-")];
              const [h, m, s] = [...t2.split(":")];
              const monthIndex = month - 1;
              const date = new Date(year, monthIndex, day, h, m, 0);
              return { date: date, close: e.price };
            })
          : [];
        break;
    }
    chartData.sort((a, b) => a.date.getTime() - b.date.getTime());
    console.log(chartData);
    let diff =
      this.props.cmp_data.Scrip.market_price -
      this.props.cmp_data.Scrip.prev_close;
    var market_update = this.props.market_update[
      this.props.cmp_data.Scrip.srCode
    ]
      ? this.props.market_update[this.props.cmp_data.Scrip.srCode]
      : this.props.cmp_data.Scrip.market_price;
    const pDiff = market_update - this.props.cmp_data.Scrip.prev_close;

    return (
      <div className="container p-4">
        <Row>
          <div className={"col-md-12 bg-cmp " + bg}>
            <div className="text-dark">
              {this.props.cmp_data.Scrip.company} (
              {this.props.cmp_data.Scrip.shortCode})
            </div>
            <div>
              <span className="text-dark">
                {this.props.cmp_data.Scrip.industry}
              </span>{" "}
              <span style={{ color: "#ccc" }}> | </span>{" "}
              <span className="text-dark">
                {market_update.toFixed(2)}{" "}
                <span style={{ color: pDiff <= 0 ? "red" : "green" }}>
                  {pDiff < 0 ? "\u25BC" : pDiff > 0 ? "\u25B2" : ""}
                </span>
                {pDiff.toFixed(2)}(
                {((pDiff / this.props.cmp_data.Scrip.prev_close) * 100).toFixed(
                  2
                )}
                % )
              </span>
            </div>
          </div>
        </Row>
        <Row>
          <div className="col-md-6 cmp-info p-4">
            <div className="text-cmp">
              <table className="table">
                <tbody>
                  <tr>
                    <td>
                      <div className="cmp-lbl">
                        1 Year Price Trend (%)
                        <i
                          className="fa fa-info-circle fa-sm d-inline-block pl-1"
                          id={"yr_1"}
                        />
                        <UncontrolledTooltip placement="right" target={"yr_1"}>
                          <b>1 Year Trend</b> indicates the trend of that
                          company’s stock price over the last 1 year.
                        </UncontrolledTooltip>
                      </div>
                    </td>
                    <td>
                      {this.props.cmp_data.yr_1}
                      %
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="cmp-lbl">
                        Avg. Market Capitalisation (in cr.)
                        <i
                          className="fa fa-info-circle fa-sm d-inline-block pl-1"
                          id={"mcap"}
                        />
                        <UncontrolledTooltip placement="right" target={"mcap"}>
                          <b>Market Capitalization</b> is the valuation of a
                          firm based on its current market price and number of
                          shares.
                        </UncontrolledTooltip>
                      </div>
                    </td>
                    <td>{this.props.cmp_data.mcap}</td>
                  </tr>
                  <tr>
                    <td>
                      <div className="cmp-lbl">
                        Revenue (in cr.)
                        <i
                          className="fa fa-info-circle fa-sm d-inline-block pl-1"
                          id={"rev"}
                        />
                        <UncontrolledTooltip placement="right" target={"rev"}>
                          <b>Revenue</b> is the income generated from sales of
                          goods or services of a firm before deducting any costs
                          or expenses.
                        </UncontrolledTooltip>
                      </div>
                    </td>
                    <td>{this.props.cmp_data.revenue}</td>
                  </tr>
                  <tr>
                    <td>
                      <div className="cmp-lbl">
                        Profit Before Tax (in cr.)
                        <i
                          className="fa fa-info-circle fa-sm d-inline-block pl-1"
                          id={"pbt"}
                        />
                        <UncontrolledTooltip placement="right" target={"pbt"}>
                          <b>Profit before tax (PBT)</b> measures the company's
                          profits before deducting corporate tax (company income
                          tax). It deducts all other expenses from revenue like
                          operating expense and interest expense.
                        </UncontrolledTooltip>
                      </div>
                    </td>
                    <td>{this.props.cmp_data.pbt}</td>
                  </tr>
                  <tr>
                    <td>
                      <div className="cmp-lbl">
                        Earnings Per Share (EPS)
                        <i
                          className="fa fa-info-circle fa-sm d-inline-block pl-1"
                          id={"eps_s"}
                        />
                        <UncontrolledTooltip placement="right" target={"eps_s"}>
                          <b>Earnings per share (EPS)</b> is the portion of a
                          company's profit allocated to each share. Higher EPS
                          means higher profitability.
                        </UncontrolledTooltip>
                      </div>
                    </td>
                    <td>{this.props.cmp_data.eps}</td>
                  </tr>
                  <tr>
                    <td>
                      <div className="cmp-lbl">
                        Current Ratio
                        <i
                          className="fa fa-info-circle fa-sm d-inline-block pl-1"
                          id={"cr"}
                        />
                        <UncontrolledTooltip placement="right" target={"cr"}>
                          <b>Current Ratio</b> measures whether or not a firm is
                          capable of meeting its short-term liabilities. It is a
                          measures a firm’s liquidity. A higher current ratio is
                          better for a firm.
                        </UncontrolledTooltip>
                      </div>
                    </td>
                    <td>{this.props.cmp_data.current_ratio}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-md-6 cmp-bg-2">
            <div className="blockers">
              <div className="clearfix pb-4 pt-4">
                <div
                  className={
                    "block4 " + (this.state.activeChart == "1D" ? "active" : "")
                  }
                  onClick={() => this.setChart("1D")}
                >
                  <span>1D</span>
                </div>
                <div
                  className={
                    "block4 " + (this.state.activeChart == "1W" ? "active" : "")
                  }
                  onClick={() => this.setChart("1W")}
                >
                  <span>1W</span>
                </div>
                <div
                  className={
                    "block4 " + (this.state.activeChart == "1M" ? "active" : "")
                  }
                  onClick={() => this.setChart("1M")}
                >
                  <span>1M</span>
                </div>
                <div
                  className={
                    "block4 " + (this.state.activeChart == "1Y" ? "active" : "")
                  }
                  onClick={() => this.setChart("1Y")}
                >
                  <span>1Y</span>
                </div>
              </div>

              <div>
                {chartData.length > 0 ? (
                  <Chart
                    data={chartData}
                    seriesName={this.props.cmp_data.Scrip.shortCode}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </Row>
      </div>
    );
  }
}

Company.propTypes = {
  market_update: PropTypes.object.isRequired,
  cmp_data: PropTypes.object.isRequired,
  chart_data: PropTypes.object.isRequired,
  getScripData: PropTypes.func.isRequired,
  getChartData: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  cmp_data: state.scrips.scrip_cmp_data,
  chart_data: state.scrips.chart_data,
  market_update: state.socketData.new_market_data,
  errors: state.errors
});

export default withRouter(
  connect(
    mapStateToProps,
    { getScripData, getChartData }
  )(Company)
);
