import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class Main extends Component {
	constructor() {
		super();
	}

	render() {
		const { info } = this.props.user;

		let url = '';
		if (info.post_signup_steps > -1) {
			switch (info.post_signup_steps) {
				case 0:
					url = '/signup/step1';
					break;
				case 1:
					url = '/signup/step2';
					break;
				case 2:
					url = '/portfolio';
					break;
				case 3:
					url = '/dashboard';
					break;
			}
		}
		if (info.post_signup_steps < 2) {
			if (this.props.location.pathname != url) {
				return <Redirect to={url} />;
			}
		}
		if (this.props.location.pathname == '/' && url) {
			return <Redirect to={url} />;
		}
		return null;
	}
}

Main.propTypes = {
	user: PropTypes.object.isRequired,
	errors: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
	user: state.user,
	errors: state.errors,
});

export default withRouter(
	connect(
		mapStateToProps,
		{},
		null,
		{ pure: false }
	)(Main)
);
