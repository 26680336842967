import React, { Component } from "react";
import { Row, Col } from "reactstrap";

class Game extends Component {
  componentDidMount = () => {};

  componentWillUnmount = () => {};

  render() {
    return (
      <Col md="12" sm="12" style={{ textAlign: "center" }}>
        <h2 className="p-4">Let's play a small game to get you warmed up!</h2>
        <div className="row" style={{    maxWidth: '900px',margin: 'auto'}}>
          <Col md="6">
            <div>
              <img src="/img/risk-s1.png" className="m-2" />
              <div>Vault 1</div>
            </div>
          </Col>
          <Col md="6">
            <div>
              <img src="/img/risk-s2.png"  className="m-2" />
              <div>Vault 1</div>
            </div>
          </Col>
          <Col sm="12" className="text-left p-4">
            <div className="container p-4">
              <ul>
                <li>
                  Two vaults will be presented to you. The first one has silver
                  and bronze coins, while the second one has gold and iron
                  coins.
                </li>
                <li>
                  In each turn, you have to pick a vault & a random coin from it
                  will be yours!
                </li>
                <li>Wait till the end to see what you got!</li>
              </ul>
            </div>
          </Col>
          <Col sm="12" className="pb-4">
            <Row className="pb-4">
              <Col md="3">
                  <img src="/img/gold.png" />
                  <span className="fs3">₹ 11,500</span>
              </Col>
              <Col md="3">
                  <img src="/img/bronze.png" />
                  <span className="fs3">₹ 6,000&nbsp;</span>
              </Col>
              <Col md="3">
                  <img src="/img/silver.png" />
                  <span className="fs3">₹ 4,800&nbsp;</span>
              </Col>
              <Col md="3">
                  <img src="/img/iron.png" />
                  <span className="fs3">₹ 300&nbsp;&nbsp;&nbsp;</span>
              </Col>
            </Row>
          </Col>
        </div>
        <button
          onClick={this.props.nextScreen}
          className="btn site-green"
          style={{ marginBottom: "60px", width: '340px', padding: "5px 30px", fontSize: "28px", fontWeight: "600" }}
        >
          LET'S PLAY
        </button>
      </Col>
    );
  }
}

export default Game;
