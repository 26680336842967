import React from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getIndustries } from "../../actions/scripsActions";
import { formatCurrency } from "../../utils/format";

import {
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  UncontrolledTooltip,
  Form
} from "reactstrap";

class PortfolioHeader extends React.Component {
  state = {
    showMyPortfolio: false,
    showMyWatchlist: false
  };
  componentDidMount() {
    if (this.props.industries.length == 0) {
      this.props.getIndustries();
    }
  }

  getMoneyCls = money => {
    return money < 0 ? "bg-red" : "bg-green";
  };

  getRecomendedMoney = () => {
    const segment = this.props.segment;
    if (segment === "ALL") {
      return null;
    }
    let recomended_money = 0;
    switch (segment) {
      case "Large Cap":
        recomended_money = this.props.user.info.large_cap_money;
        break;
      case "Mid Cap":
        recomended_money = this.props.user.info.mid_cap_money;
        break;
      case "Small Cap":
        recomended_money = this.props.user.info.small_cap_money;
      break;
    }
    let rec_money_cls = this.getMoneyCls(recomended_money);

    return (
      <div className="recommended-s-1">
        <span className="text2">Recomended segment spend</span>
        <span className={"bg-green btn-typ1 ml-2 " + rec_money_cls}>
          {formatCurrency(recomended_money, true)}
        </span>
      </div>
    );
  };

  toggleShowMyPortfolio = () => {
    this.props.onMyPortfolioChange(!this.state.showMyPortfolio);
    this.setState({ showMyPortfolio: !this.state.showMyPortfolio });
  };
  
  toggleShowMyWatchlist = () => {
    this.props.onMyWatchlistChange (!this.state.showMyWatchlist);
    this.setState({ showMyWatchlist: !this.state.showMyWatchlist });
  };

  getCashInhand = info => {
    return (
      <div>
        <span className="text2">Cash in hand</span>
        <span className="bg-green btn-typ1 ml-2">
          {formatCurrency(info.total_money, true)}
        </span>
      </div>
    );
  };
  render() {
    const { info } = this.props.user;
    const { segment, industry, sort } = this.props;

    if (!info.total_money) return null;
    const recomended_money = 500;
    return (
      <div className="portfolio-header">
        <Row>
          <Col md="4" className="text-right">
            <div className="m-2"> {this.getCashInhand(info)} </div>
            <div className="m-2"> {this.getRecomendedMoney()}</div>
          </Col>
          <Col md="4">
            <div className="text-center vertical-container">
              {/* <div className=" vertical-child">
                <input
                  className="styled-checkbox ml-2"
                  type="checkbox"
                  value="1"
                  onChange={this.toggleShowMyPortfolio}
                  checked={this.state.showMyPortfolio}
                />
                <label
                  className="text2 form-check-label"
                  onClick={this.toggleShowMyPortfolio}
                >
                  Show My Portfolio
                </label>
              </div> */}
              <div className=" vertical-child">
                  <input
                  className="styled-checkbox ml-2"
                  type="checkbox"
                  value="1"
                  onChange={this.toggleShowMyWatchlist}
                  checked={this.state.showMyWatchlist}
                  />
                  <label
                  className="text2 form-check-label"
                  onClick={this.toggleShowMyWatchlist}
                  >
                  Show My Watchlist
                  </label>
              </div>
            </div>
          </Col>
          <Col md="4">
            <div className="text-center vertical-container">
              <div className="vertical-child">
                <div>
                  <div className="form-inline m-1">
                    <span className="text2 lbl3">
                      Segment
                      <i
                        className="fa fa-info-circle ml-2"
                        id={"segmentTT" + this.props.src}
                      />
                      <UncontrolledTooltip
                        placement="right"
                        target={"segmentTT" + this.props.src}
                      >
                        This feature helps you segment the company on basis of
                        market capitalisation. Market capitalisation is the
                        value of the company on basis of its current market
                        price and outstanding shares.
                        <br />
                        <b>Large Cap</b> – Shows companies that are top 100 most
                        valuable ones
                        <br />
                        <b>Mid Cap</b> – Shows companies that are ranked between
                        100 – 250 in terms of value
                        <br />
                        <b>Small Cap</b> – Shows companies that are ranked
                        between 250 – 500 in terms of value
                        <br />
                      </UncontrolledTooltip>
                    </span>
                    <span>
                      <Input
                        style={{ width: "150px" }}
                        type="select"
                        value={segment}
                        onChange={this.props.setSegment}
                        className="ml-2"
                      >
                      
                        <option>Large Cap</option>
                        <option>Mid Cap</option>
                        {this.props.user.info.eventname != "IIM TRICHY JANUARY" ? <option>Small Cap</option> : null}
                      </Input>
                    </span>
                  </div>
                  <div className="form-inline m-1">
                    <span className="text2 lbl3">
                      Sort by{" "}
                      <i
                        className="fa fa-info-circle ml-2"
                        id={"sortTT" + this.props.src}
                      />
                      <UncontrolledTooltip
                        placement="right"
                        target={"sortTT" + this.props.src}
                      >
                        {" "}
                        This feature helps you sort the companies{" "}
                        <b>inside the selected segment</b>. Can be done on basis
                        of: Market Capitalisation – most valued companies are
                        listed on top
                        <br />
                        Top Gainers – the day’s top gainers are listed on top
                        <br />
                        Top Losers – the day’s top losers are listed on top
                      </UncontrolledTooltip>
                    </span>
                    <span>
                      <Input
                        style={{ width: "150px" }}
                        type="select"
                        value={sort}
                        onChange={this.props.setSort}
                        className="ml-2"
                      >
                        <option>MARKET CAP</option>
                        <option>NAME</option>
                        <option>TOP GAINERS</option>
                        <option>TOP LOSERS</option>
                      </Input>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

PortfolioHeader.propTypes = {
  getIndustries: PropTypes.func.isRequired,
  industries: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  industries: state.scrips.industries,
  user: state.user,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { getIndustries }
)(PortfolioHeader);
