import React from "react";
import styled from "styled-components";
import { Navbar, NavbarToggler, Collapse } from "reactstrap";
import SearchBox from "./searchBox";
import Profile from "./profile";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Notification from "./notification";
import LeaderBoard from "./leaderboard";
import { getCurrentUserDetails } from "../../actions/userActions";
import { getECodePermission } from "../../actions/gamesActions";
import { getnotifyscore } from "../../actions/researchActions";
import { withRouter, Link } from "react-router-dom";
import { generateKeyPair } from "crypto";
import { getLeaderBoard } from "../../actions/leaderAction";


const HeaderContent = styled.div`
  height: 90px;
`;
const ProfileDropdownContainer = styled.div`
  float: right;
  padding: 10px;
`;

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      icon: "",
      hover1: false,
      hover2: false,
      hover3: false,
      hover4: false,
      hover5: false,
      hover6: false
    };
    this.state = {
      blocking: true
    };
    this.state = {
      modalIsOpen: true
    };
  }

  componentDidMount = () => {
    this.props.getCurrentUserDetails();
    this.props.getnotifyscore();
    this.props.getECodePermission();
    if(this.props.user){
      this.props.getLeaderBoard(this.props.user.eventname);
    }
    

    //window.$("html,body").css("overflow-y", "hidden");
  };
  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };
  onSelect = s => {
    this.setState({ icon: s });
  };
  getRedirect = o => {
    this.setState({ icon: o });
  };
  render() {
    const props = this.props;
    let notification = 0;
    if (this.props.notify) {
      notification = this.props.notify.length;
    }
    let showHeader = props.location.pathname.indexOf("/signup") < 0;
    return (
      <Navbar color="light" light expand="lg">
        <div className="container">
          <a className="navbar-brand" href="#">
            <img src="/img/brand.png" alt="logo" />
          </a>
          {/* <span className={this.state.isOpen ? " d-none" : ""}>
            <SearchBox />
          </span> */}

          <Collapse isOpen={this.state.isOpen} navbar>
            {showHeader ? (
              <ul id="menu" className="navbar-nav  m-auto navbar-right">
                {/* <li
                  className={
                    "nav-item pl-2 pr-2 " +
                    (this.state.hover1 ? "activeImg" : "inactiveImg") +
                    " " +
                    (this.state.icon == "home" ? "activeHover" : "")
                  }
                  onClick={() => {
                    this.onSelect("home");
                    this.setState({ hover1: true });
                    this.setState({ isOpen: false });
                  }}
                  onMouseOver={() => {
                    this.setState({ hover1: true });
                  }}
                  onMouseLeave={() => {
                    this.setState({ hover1: false });
                  }}
                >
                  <Link to="/dashboard" className="nav-link text-secondary">
                    <div className="imgItems">
                      <img
                        src={
                          this.state.icon == "home" || this.state.hover1
                            ? "/img/active/home.svg"
                            : "/img/inactive/home.svg"
                        }
                        width="30px"
                      />
                      <div>
                        <h6
                          className={
                            this.state.icon == "home" || this.state.hover1
                              ? "cw-1"
                              : "text-dark"
                          }
                        >
                          Home
                        </h6>
                      </div>
                    </div>
                  </Link>
                </li> */}
                <li
                  className={
                    "nav-item pl-2 pr-2 " +
                    (this.state.hover2 ? "activeImg" : "inactiveImg") +
                    " " +
                    (this.state.icon == "portfolio" ? "activeHover" : "")
                  }
                  onClick={() => {
                    this.setState({ hover2: true });
                    this.onSelect("portfolio");
                    this.setState({ isOpen: false });
                  }}
                  onMouseOver={() => {
                    this.setState({ hover2: true });
                  }}
                  onMouseLeave={() => {
                    this.setState({ hover2: false });
                  }}
                >
                  <Link to="/portfolio" className="nav-link text-secondary">
                    <div className="imgItems">
                      <img
                        src={
                          this.state.icon == "portfolio" || this.state.hover2
                            ? "/img/active/portfolio.svg"
                            : "/img/inactive/portfolio.svg"
                        }
                        width="30px"
                      />
                      <div>
                        <h6
                          className={
                            this.state.icon == "portfolio" || this.state.hover2
                              ? "cw-1"
                              : "text-dark"
                          }
                        >
                          Portfolio
                        </h6>
                      </div>
                    </div>
                  </Link>
                </li>

                {/* <li
                  className={
                    "nav-item pl-2 pr-2 " +
                    (this.state.hover3 ? "activeImg" : "inactiveImg") +
                    " " +
                    (this.state.icon == "stats" ? "activeHover" : "")
                  }
                  onClick={() => {
                    this.setState({ hover3: true });
                    this.onSelect("stats");
                    this.setState({ isOpen: false });
                  }}
                  onMouseOver={() => {
                    this.setState({ hover3: true });
                  }}
                  onMouseLeave={() => {
                    this.setState({ hover3: false });
                  }}
                >
                  <Link to="/stats" className="nav-link text-secondary">
                    <div className="imgItems">
                      <img
                        src={
                          this.state.icon == "stats" || this.state.hover3
                            ? "/img/active/stats.svg"
                            : "/img/inactive/stats.svg"
                        }
                        width="30px"
                      />
                      <div>
                        <h6
                          className={
                            this.state.icon == "stats" || this.state.hover3
                              ? "cw-1"
                              : "text-dark"
                          }
                        >
                          Stats
                        </h6>
                      </div>
                    </div>
                  </Link>
                </li> */}
              </ul>
            ) : null}
          </Collapse>

          <ul
            className={
              "nav navbar-nav navbar-right profile-nav " +
              (this.state.isOpen ? " d-none " : "")
            }
          >
              <li className="nav-item dropdown dropdown-list">
              <LeaderBoard
              

              />
            </li>
            <li className="nav-item dropdown dropdown-list">
              <Notification />
            </li>
            <li>
              <Profile getRedirect={this.getRedirect} />
            </li>
          </ul>
          <NavbarToggler onClick={this.toggle} />
        </div>
      </Navbar>
    );
  }
}

Header.propTypes = {
  user: PropTypes.object.isRequired,
  getCurrentUserDetails: PropTypes.func.isRequired,
  getnotifyscore: PropTypes.func.isRequired,
  getECodePermission: PropTypes.func.isRequired,
   eventDetails: PropTypes.object.isRequired,
      getLeaderBoard:PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.user.info,
  notify: state.research.notify.data,
  check: state.games.permission.data,
  eventDetails: state.leaderBoard.data.eventDetails,
  eventname:state.leaderBoard.data.eventname
});

export default withRouter(
  connect(
    mapStateToProps,
    {
      getCurrentUserDetails,
      getnotifyscore,
      getECodePermission,
      getLeaderBoard,
    }
  )(Header)
);

//export default withRouter(Header);