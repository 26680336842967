import axios from "axios";

import { ORDER_CREATE, ORDER_LIST } from "./types";

import { getScrips } from "./scripsActions";
import { getCurrentUserDetails } from "./userActions";
import { getPortfolios } from "./portfolioActions";

import store from "../store";
export const placeOrder = ({
  scrip_id,
  action,
  typ,
  qty,
  price,
  active_sl,
  sprice,
  reason
}) => dispatch => {
  axios
    .post(`/api/order/new/${scrip_id}`, {
      action: action,
      typ: typ,
      qty: qty,
      price: price,
      active_sl:active_sl,
      sprice:sprice,
      reason: reason
    })
    .then(response => {
      dispatch({ type: ORDER_CREATE, payload: response.data });
      dispatch(
        getScrips(store.getState().scrips.scrips_portfolio, true, false)
      );
      dispatch(getOrders());
      dispatch(getCurrentUserDetails());
      dispatch(getPortfolios(store.getState().portfolio, true));
    })
    .catch(error => {});
};

export const placeOrdertrail = ({
  scrip_id,
  action,
  typ,
  qty,
  price,
  active_sl,
  sprice,
  reason,
  upid
}) => dispatch => {
  console.log("placed")
  axios
    .post(`/api/order/newtrail/${scrip_id}`, {
      action: action,
      typ: typ,
      qty: qty,
      price: price,
      active_sl:active_sl,
      sprice:sprice,
      reason: reason,
      upid:upid
    })
    .then(response => {
      dispatch({ type: ORDER_CREATE, payload: response.data });
      dispatch(
        getScrips(store.getState().scrips.scrips_portfolio, true, false)
      );
      dispatch(getOrders());
      dispatch(getCurrentUserDetails());
      dispatch(getPortfolios(store.getState().portfolio, true));
    })
    .catch(error => {});
};

export const placeOrdertrailmodify = ({
  scrip_id,
  action,
  typ,
  qty,
  price,
  active_sl,
  sprice,
  reason,
  upid,
  trailid,
  scripcode
}) => dispatch => {
  console.log("placed")
  axios
    .post(`/api/order/modify/${trailid}`, {
      action: action,
      typ: typ,
      qty: qty,
      price: price,
      active_sl:active_sl,
      sprice:sprice,
      reason: reason,
      upid:upid,
      scripcode:scripcode
    })
    .then(response => {
      dispatch({ type: ORDER_CREATE, payload: response.data });
      dispatch(
        getScrips(store.getState().scrips.scrips_portfolio, true, false)
      );
      dispatch(getOrders());
      dispatch(getCurrentUserDetails());
      dispatch(getPortfolios(store.getState().portfolio, true));
    })
    .catch(error => {});
};


export const getOrders = () => dispatch => {
  axios
    .get(`/api/order/list`)
    .then(res => {
      dispatch({
        type: ORDER_LIST,
        payload: res.data.orders
      });
    })
    .catch(error => {});
};

export const cancelOrder = (order_id, reason) => dispatch => {
  axios
    .delete(`/api/order/cancel/${order_id}`, { reason: reason, })
    .then(res => {
      //dispatch(getOrders());
    })
    .catch(error => {});
};

export const cancelOrdertrail = (order_id,upid) => dispatch => {
  console.log(upid,"upid")
  axios
    .post(`/api/order/canceltrail/${order_id}`, { upid:upid })
    .then(res => {
      //dispatch(getOrders());
    })
    .catch(error => {});
};
export const submitReason = (order_id, reason) => dispatch => {
  axios
    .post(`/api/order/reason/${order_id}`, { reason: reason })
    .then(res => {
      dispatch(getOrders());
    })
    .catch(error => {});
};
