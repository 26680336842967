import React from "react";
import {
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input
} from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  putProfileInfo,
  uploadProfilePic,
  profilePhoto
} from "../../actions/profileDetailsActions";
import { thisTypeAnnotation } from "babel-types";

class Header extends React.Component {
  state = {
    modal: false,
    profileName: "",
    phone: "",
    email: "",
    upload: "",
    selectedFile: null,
    uploadFile: null
  };

  toggle = () => {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  };
  edit = info => {
    this.setState({
      profileName: info.name,
      phone: info.mobile,
      email: info.email,
      uploadFile: info.photo
    });
  };
  onChangeHandler = async event => {
    await this.setState({
      uploadFile: event.target.files[0],
      selectedFile: event.target.files[0]
        ? window.URL.createObjectURL(event.target.files[0])
        : null
    });
  };

  handleSubmit = async e => {
    e.preventDefault();
    const data = new FormData();
    data.append("file", this.state.uploadFile);
    this.props.putProfileInfo(this.state.profileName);
    await this.props.uploadProfilePic(data);

    await this.setState({
      upload: this.props.upload
    });
  };
  handleClick = e => {
    this.refs.fileUploader.click();
  };
  upload = () => {
    this.timeOut();
    return <h6 style={{ color: "#008700" }}>Successs</h6>;
  };
  timeOut = () => {
    setTimeout(() => {
      this.setState({ upload: "", selectedFile: null });
    }, 4000);
  };
  render() {
    if (!this.props.info) return null;
    let info = this.props.info;
    return (
      <div className="profile_header">
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}
        >
          <Form onSubmit={this.handleSubmit}>
            <ModalHeader toggle={this.toggle}>Edit Information</ModalHeader>
            <ModalBody>
              <FormGroup className="text-center ">
                {this.state.selectedFile != null ? (
                  <img
                    src={this.state.selectedFile}
                    width="100px"
                    height="100px"
                    className="rounded-circle dp_outline"
                  ></img>
                ) : (
                  <img
                    src={
                      this.state.uploadFile
                        ? `${window.BASE_URL}/uploads/userProfile/${this.state.uploadFile}`
                        : "img/profile.png"
                    }
                    width="100px"
                    height="100px"
                    className="rounded-circle dp_outline"
                  ></img>
                )}

                <i
                  className=" fa fa-pencil d-inline-block profile_icon"
                  style={{
                    position: "absolute",
                    top: "75px",
                    marginLeft: "-11px"
                  }}
                  aria-hidden="true"
                ></i>
                <input
                  type="file"
                  className="upload_profile"
                  name="file"
                  onChange={this.onChangeHandler}
                />
              </FormGroup>
              <FormGroup>
                <Label for="name" className="required_mark">
                  Profile Name
                </Label>
                <div>
                  <Input
                    type="text"
                    name="profileName"
                    id="profileName"
                    value={this.state.profileName}
                    onChange={e => {
                      this.setState({
                        profileName: e.target.value
                      });
                    }}
                  ></Input>
                </div>
              </FormGroup>
            </ModalBody>
            {this.state.profileName != "" ? (
              <ModalFooter>
                <Button
                  color="primary"
                  type="submit"
                  onClick={async () => {
                    this.toggle();
                  }}
                >
                  Save
                </Button>
                <Button color="secondary" onClick={this.toggle}>
                  Cancel
                </Button>
              </ModalFooter>
            ) : null}
          </Form>
        </Modal>

        <Card>
          <div className="text-center ">
            <img
              src={
                this.props.info.photo == "" || this.props.info.photo == null
                  ? "img/profile.png"
                  : `${window.BASE_URL}/uploads/userProfile/${this.props.info.photo}`
              }
              width="150px"
              height="150px"
              className="pt-3 rounded-circle"
              style={{ marginTop: "-76px", marginRight: "-34px" }}
            ></img>

            <i
              className=" fa fa-pencil-square-o d-inline-block float-right profile_icon pr-3 pt-3"
              aria-hidden="true"
              onClick={() => {
                this.toggle();
                this.edit(info);
              }}
            ></i>
            {this.state.upload == "true" && this.state.selectedFile != null ? (
              this.upload()
            ) : this.state.upload == "" && this.state.selectedFile != null ? (
              <h6 style={{ color: "#008700" }}>uploading ...</h6>
            ) : (
              ""
            )}
            <h5 className="pt-2">{info.name}</h5>
            <div>
              <div className="float-left pl-3">
                <i
                  className="fa fa-phone d-inline-block"
                  style={{ fontSize: "30px" }}
                  aria-hidden="true"
                ></i>
                <span className="d-inline-block pl-2 align-text-bottom">
                  {info.mobile}
                </span>
              </div>
              <div className="text-center d-inline-block">
                <i
                  className="fa fa-envelope d-inline-block"
                  style={{ fontSize: "23px" }}
                  aria-hidden="true"
                ></i>
                <span className="d-inline-block pl-2 align-text-bottom text-center">
                  {info.email}
                </span>
              </div>
              <div className="float-right pr-3 d-inline-block">
                <i
                  className="fa fa-calendar d-inline-block profile_icon_info"
                  aria-hidden="true"
                ></i>
                <span className="d-inline-block pl-2 align-top">
                  {info.start_date}
                </span>
              </div>
            </div>
          </div>
        </Card>
      </div>
    );
  }
}
Header.PropType = {
  putProfileInfo: PropTypes.func.isRequired,
  uploadProfilePic: PropTypes.func.isRequired,
  profilePhoto: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  upload: state.profile.upload
});
export default connect(
  mapStateToProps,
  { putProfileInfo, uploadProfilePic, profilePhoto }
)(Header);
