import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getPortfolios } from "../../../actions/portfolioActions";
import { placeOrdertrail, placeOrdertrailmodify,submitReason,cancelOrdertrail } from "../../../actions/orderActions";
import { Table, Input, FormGroup, Button, Row, Col } from "reactstrap";
import PortfolioHeader from "../header";
import BuyModal from "../MyPortfolio/buytrail_modal";
import PortfolioTable from "./portfolios_table";
import PortfolioChart from "./portfolio_chart";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import 

class MyPortfolio extends React.Component {

  state = {
    alert_msg: "",
    show_buy_modal: false,
    show_alert_modal: false,
    company_id: 0,
    company: "",
    action: "",
    active_sl:0,
    qty: 0,
    typ: 0,
    tqty:0,
    price: 0,
    sprice:0,
    upid:0,
    trailid:0,
    reasonBuy: "",
    company_str: "",
    company_code_str: "",
    company_modal_show: false,
    company_info: "",
    showMyPortfolio: false,
    modify:false,
    scripcode:0
  };

  setSegment = e => {
    this.props.getPortfolios({
      segment: e.target.value,
      industry: this.props.portfolio.industry,
      sort: this.props.portfolio.sort
    });
  };

  setReasonBuy = reason => {
    this.setState({ show_buy_modal: false });
    this.props.submitReason(this.props.recent_order_id, reason);
  };
  
  active_buyslprice = e =>{
    this.setState({sprice:e})
    console.log(e,"func1price")
  }

  settqty = e =>{
    this.setState({tqty:e})
    console.log(e,"func1price")
  }
  setIndustry = e => {
    this.props.getPortfolios({
      segment: this.props.portfolio.segment,
      industry: e.target.value,
      sort: this.props.portfolio.sort
    });
  };

  setSort = e => {
    this.props.getPortfolios({
      segment: this.props.portfolio.segment,
      industry: this.props.portfolio.industry,
      sort: e.target.value
    });
  };
componentDidMount() {
    this.props.getPortfolios({ segment: "ALL", industry: "ALL", sort: "NAME" });
  }
  cancelOrderDlg = (id,upid) => {
    console.log(id,upid)
    var that = this;
    confirmAlert({
      title: "Cancel Order?",
      message: "Are you sure you want to do this?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            that.props.cancelOrdertrail(id,upid);
          }
        },
        {
          label: "No",
          onClick: () => {}
        }
      ]
    });
  };

  hideBuyModal = () => {
    this.setState({ show_buy_modal: false });
  };
  hidemBuyModal = () => {
    this.setState({ modfy: false });
  };
  handleBuy = () => {
    console.log(this.state.modify)
    if(this.state.modify==false){
      this.props.placeOrdertrail({
        scrip_id: this.state.company_id,
        typ: this.state.typ,
        action: this.state.action,
        qty: this.state.tqty,
        price: this.state.price,
        reason: this.state.reasonBuy,
        active_sl:this.state.active_sl,
        sprice:this.state.sprice,
        upid:this.state.upid
      });
    }
    else{
      this.props.placeOrdertrailmodify({
        scrip_id: this.state.company_id,
        typ: this.state.typ,
        action: this.state.action,
        qty: this.state.tqty,
        price: this.state.price,
        reason: this.state.reasonBuy,
        active_sl:this.state.active_sl,
        sprice:this.state.sprice,
        upid:this.state.upid,
        trailid:this.state.trailid,
        scripcode:this.state.scripcode
      });
    }
  };

  handleBuymodify = () => {
    this.props.placeOrdertrailmodify({
      sprice:this.state.sprice,
      upid:this.state.upid
    });
  };

  buy = (id, company, action, qty, price,code,typ,upid,marked,trailid)=>{
    console.log(id,"iddd")
    const mp = this.props.new_market_data[code]
      ? parseFloat(this.props.new_market_data[code])
      : price;
      
    this.setState({
      company_id: id,
      company: company,
      action: action,
      qty: qty,
      price: mp,
      show_buy_modal: true,
      sprice:0,
      typ:typ,
      upid:upid,
      modify:marked,
      trailid:trailid,
      scripcode:code
    });
  }
  render() { 
 // console.log(dataaa)
 
    let large,small,mid;
    if(this.props.portfolio.segment == 'ALL'){
      large = this.props.portfolio.portfolios.filter(e => e.Scrip.cap_type == 'Large Cap');
    }
    return (
      <div>
      <Row style={{ padding: "15px 35px" }}>
        <Col sm="12" style={{ marginTop: "5px", marginBottom: "5px" }}>
        <div className="myPortfolioSort">
        <PortfolioHeader
            segment={this.props.portfolio.segment}
            industry={this.props.portfolio.industry}
            sort={this.props.portfolio.sort}
            setSegment={this.setSegment}
            setSort={this.setSort}
            setIndustry={this.setIndustry}
            src="portfolio"
          />
          {this.props.user.planned =="combo"||this.props.user.planned =="p999"?(<b>Please note that incase you want to have a Portfolio Mentoring Interaction, please WhatsApp a screenshot of this page to Rohan Pathare, FMEP Equity Advisor on +91 9372298192 from Monday to Friday between 10.30am to 6.30pm and he'll get back to you. You can also WhatsApp post that and he'll get back to you at the earliest available time.
</b>):null}
        </div>
       
          
        </Col>
        <Col lg="12">
          <div style={{ width: "400px", margin: "auto" }}>
            {this.props.portfolio.segment == "ALL" ? null : (
              <PortfolioChart
                segment={this.props.portfolio.segment}
                portfolio={this.props.portfolio.portfolios}
              />
            )}
          </div>
        </Col>
        <Col lg="12">
          <PortfolioTable
            segment={this.props.portfolio.segment}
            new_market_data={this.props.new_market_data}
            myScrips={this.props.portfolio.myScrips}
            portfolio={this.props.portfolio.portfolios}
            bse = {this.props.performance}
            user ={this.props.user}
            cancelOrderDlg = {this.cancelOrderDlg}
            buy = {this.buy}
          />
        </Col>
      </Row>

      {/* <BuyModal
          company={this.state.company}
          action={this.state.action}
          qty={this.state.qty}
          typ={this.state.typ}
          price={this.state.price}
          show={this.state.show_buy_modal}
          hideModal={this.hideBuyModal}
          handleBuy={this.handleBuy}
          modify = {this.state.modify}
          hidemModal ={this.hidemBuyModal}
          setActive_buyslprice={this.active_buyslprice}
          settqty = {this.settqty}
          handleReason={this.setReasonBuy}
        /> */}
      </div>
      
    );
  
 
  }
}

MyPortfolio.propTypes = {
  getPortfolios: PropTypes.func.isRequired,
  placeOrdertrail: PropTypes.func.isRequired,
  placeOrdertrailmodify:  PropTypes.func.isRequired,
  portfolio: PropTypes.object.isRequired,
  cancelOrdertrail: PropTypes.func.isRequired,
  submitReason: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  recent_order_id: PropTypes.number.isRequired,
  new_market_data: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  user: state.user.info,
  portfolio: state.portfolio,
  recent_order_id: state.orders.recent_order_id,
  new_market_data: state.socketData.new_market_data,
});

export default connect(
  mapStateToProps,
  { getPortfolios,placeOrdertrail,placeOrdertrailmodify,submitReason, cancelOrdertrail }
)(MyPortfolio);
