import React from "react";
import { Label, Input, Button } from "reactstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import moment from "moment";
import ReactCountdownClock from "react-countdown-clock";
import { getScore, fetchScore } from "../../../actions/resourceActions";

import PropTypes from "prop-types";
import { connect } from "react-redux";
class M5_quiz extends React.Component {
  state = {
    submitted: false,
    q1: "",
    q2: "",
    q3: "",
    alert: true
  };
  componentDidMount() {
    this.props.fetchScore();
  }
  handleSubmit = e => {
    e.preventDefault();

    this.setState({
      submitted: true,
      q1: e.target.mq51.value,
      q2: e.target.mq52.value,
      q3: e.target.mq53.value
    });
  };
  componentDidMount() {
    this.props.fetchScore();
  }
  getScore(q1, q2, q3) {
    let score = 0;
    let questions = this.props.questions.Quiz.filter(o => o.model == 5);
    if (q1 == questions[0].correct) {
      score = score + 1;
    }
    if (q2 == questions[1].correct) {
      score = score + 1;
    }
    if (q3 == questions[2].correct) {
      score = score + 1;
    }
    this.props.getScore(score, this.props.userId, 5);
    return score;
  }

  questionData() {
    const questions = this.props.questions.Quiz.filter(o => o.model == 5);
    return (
      <div>
        <div className="resource">
          <Modal isOpen={this.props.toggle} className="resource">
            <ModalHeader>
              <b>Are you sure you want to proceed with the quiz?</b>
            </ModalHeader>
            <ModalBody className="text-center">
              Hope you have read Module 5
            </ModalBody>
            <ModalFooter className="text-center">
              <Button
                onClick={() => {
                  this.setState({ alert: false });
                  this.props.onToggle(false);
                }}
                className="btn"
              >
                Yes
              </Button>

              <Button
                onClick={() => {
                  this.props.onSelect("m-5_fundamental");
                  this.props.onToggle(false);
                }}
                className="btn"
              >
                No
              </Button>
            </ModalFooter>
          </Modal>
          <form onSubmit={this.handleSubmit}>
            <div className="container">
              <div className="mt-3">
                <div className="w-1">
                  <h5 className="text-right mt-4">
                    <b>QUIZ</b>
                  </h5>
                </div>
                <div className="f-1">
                  {this.state.submitted ? (
                    ""
                  ) : (
                    <ReactCountdownClock
                      seconds={60}
                      color="#000"
                      alpha={0.9}
                      size={75}
                      paused={this.state.alert ? true : false}
                      onComplete={e => {
                        this.setState({ submitted: true });
                      }}
                    />
                  )}
                </div>
              </div>

              <ol>
                <div>
                  <h6 className="pt-4">
                    <li>{questions[0].question}</li>
                  </h6>

                  <div className="pl-4">
                    <div className="radio">
                      <Label>
                        <Input
                          type="radio"
                          name="mq51"
                          value={questions[0].options[0]}
                          onChange={e => {
                            this.setState({ q1: e.target.value });
                          }}
                          disabled={this.state.submitted}
                        />
                        {questions[0].options[0]}
                      </Label>
                    </div>
                    <div className="radio">
                      <Label>
                        <Input
                          type="radio"
                          name="mq51"
                          value={questions[0].options[1]}
                          onChange={e => {
                            this.setState({ q1: e.target.value });
                          }}
                          disabled={this.state.submitted}
                        />
                        {questions[0].options[1]}
                      </Label>
                    </div>
                    <div className="radio">
                      <Label>
                        <Input
                          type="radio"
                          name="mq51"
                          value={questions[0].options[2]}
                          onChange={e => {
                            this.setState({ q1: e.target.value });
                          }}
                          disabled={this.state.submitted}
                        />
                        {questions[0].options[2]}
                      </Label>
                    </div>
                  </div>

                  {this.state.submitted ? (
                    <span
                      className={
                        this.state.q1 == questions[0].correct ? "c-1" : "c-2"
                      }
                    >
                      Correct Answer :
                      {this.state.submitted ? questions[0].correct : ""}
                    </span>
                  ) : (
                    ""
                  )}
                </div>

                <div>
                  <h6 className="pt-5">
                    <li>{questions[1].question}</li>
                  </h6>

                  <div className="pl-4">
                    <div className="radio">
                      <Label>
                        <Input
                          type="radio"
                          name="mq52"
                          value={questions[1].options[0]}
                          onChange={e => {
                            this.setState({ q2: e.target.value });
                          }}
                          disabled={this.state.submitted}
                        />
                        {questions[1].options[0]}
                      </Label>
                    </div>
                    <div className="radio">
                      <Label>
                        <Input
                          type="radio"
                          name="mq52"
                          value={questions[1].options[1]}
                          onChange={e => {
                            this.setState({ q2: e.target.value });
                          }}
                          disabled={this.state.submitted}
                        />
                        {questions[1].options[1]}
                      </Label>
                    </div>
                  </div>

                  {this.state.submitted ? (
                    <span
                      className={
                        this.state.q2 == questions[1].correct ? "c-1" : "c-2"
                      }
                    >
                      Correct Answer :
                      {this.state.submitted ? questions[1].correct : ""}
                    </span>
                  ) : (
                    ""
                  )}
                </div>

                <div>
                  <h6 className="pt-5">
                    <li>{questions[2].question}</li>
                  </h6>

                  <div className="pl-4">
                    <div className="radio">
                      <Label>
                        <Input
                          type="radio"
                          name="mq53"
                          value={questions[2].options[0]}
                          onChange={e => {
                            this.setState({ q3: e.target.value });
                          }}
                          disabled={this.state.submitted}
                        />
                        {questions[2].options[0]}
                      </Label>
                    </div>
                    <div className="radio">
                      <Label>
                        <Input
                          type="radio"
                          name="mq53"
                          value={questions[2].options[1]}
                          onChange={e => {
                            this.setState({ q3: e.target.value });
                          }}
                          disabled={this.state.submitted}
                        />
                        {questions[2].options[1]}
                      </Label>
                    </div>
                    <div className="radio">
                      <Label>
                        <Input
                          type="radio"
                          name="mq53"
                          value={questions[2].options[2]}
                          onChange={e => {
                            this.setState({ q3: e.target.value });
                          }}
                          disabled={this.state.submitted}
                        />
                        {questions[2].options[2]}
                      </Label>
                    </div>
                    <div className="radio">
                      <Label>
                        <Input
                          type="radio"
                          name="mq53"
                          value={questions[2].options[3]}
                          onChange={e => {
                            this.setState({ q3: e.target.value });
                          }}
                          disabled={this.state.submitted}
                        />
                        {questions[2].options[3]}
                      </Label>
                    </div>
                  </div>

                  {this.state.submitted ? (
                    <span
                      className={
                        this.state.q3 == questions[2].correct ? "c-1" : "c-2"
                      }
                    >
                      Correct Answer :
                      {this.state.submitted ? questions[2].correct : ""}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </ol>

              <div className="text-center mb-2">
                {this.state.submitted ? (
                  <div>
                    <p className="text-center font-weight-bold">
                      Score:
                      {this.getScore(
                        this.state.q1,
                        this.state.q2,
                        this.state.q3
                      )}
                      /3
                      <br />
                      <span>Submitted on {moment().format("LLL")}</span>
                    </p>
                  </div>
                ) : (
                  <Button
                    className="btn btn-padding btn-secondary mt-4"
                    type="submit"
                  >
                    Submit
                  </Button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
  render() {
    let score = this.props.scores.response.find(
      o => o.user_id == this.props.userId
    );

    if (score ? score.module5 == null : !score) {
      return this.questionData();
    }
    if (score && score.module5 != null) {
      return (
        <div className="text-center">
          <h3>Your Module 5 Quiz Score is {score.module5}/3</h3>
        </div>
      );
    }
  }
}

M5_quiz.propTypes = {
  getScore: PropTypes.func.isRequired,
  fetchScore: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  scores: state.resource.scores
});
export default connect(
  mapStateToProps,
  { getScore, fetchScore }
)(M5_quiz);
