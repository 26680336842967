import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import InvestmentChart from "./investment_chart";
import { setSignupStep,getCurrentUserDetails } from "../../actions/userActions";
import { withRouter } from "react-router-dom";

class FinalScreen extends Component {
  setStep = () => {
    this.props.getCurrentUserDetails();
    //this.props.history.push("/portfolio");
  };

  render() {
    const risk_profile = this.props.risk_profile.data;

    let data = [
      { name: "Large Cap", value: parseFloat(risk_profile.large_cap) },
      { name: "Mid Cap", value: parseFloat(risk_profile.mid_cap) },
      { name: "Small Cap", value: parseFloat(risk_profile.small_cap) }
    ];

    return (
      <div
        style={{ padding: "60px" }}
        className="row text-center start-building-container"
      >
        <div className="col-md-6">
          <div className="know-about-info text-left animated bounceInLeft">
            <div>
              <h2 className="text-center p-4">
                Thanks for patiently picking among the vaults!
              </h2>
              <h3>Why we asked you to do it?</h3>
              <p>
                This small exercise helped us understand you better. Based on
                your choices, we classified you to be of{" "}
                <b>'{risk_profile.risk_profile_label}'</b> person. We’ve also
                created an optimal portfolio mix for you to help you in this
                journey of investing.
              </p>
            </div>
            <br />
            <div>
              <b>A general thumb rule: </b>
            </div>
            <br />
            <table style={{ width: "100%" }}>
              <tbody>
                <tr>
                  <td>
                    <b>Company Category</b>
                  </td>
                  <td>
                    <b>Safety</b>
                  </td>
                  <td>
                    <b>Return Potential</b>
                  </td>
                </tr>
                <tr>
                  <td>Large Cap</td>
                  <td>High</td>
                  <td>Low</td>
                </tr>
                <tr>
                  <td>Mid Cap</td>
                  <td>Medium</td>
                  <td>Medium</td>
                </tr>
                <tr>
                  <td>Small Cap</td>
                  <td>Low</td>
                  <td>High</td>
                </tr>
              </tbody>
            </table>
          </div>
          <Button
            style={{
              marginTop: "35px",
              padding: "5px 30px",
              fontSize: "28px",
              fontWeight: "600"
            }}
            onClick={this.setStep}
            className="btn site-gray btn-start-building"
          >
            START BUILDING
          </Button>
        </div>
        <div className="col-md-6" style={{ overflow: "hidden" }}>
          <div id="pie-container" style={{ width: "100%" }}>
            <InvestmentChart cap_data={data} />
          </div>
        </div>
      </div>
    );
  }
}

FinalScreen.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired,
  setSignupStep: PropTypes.func.isRequired,
  risk_profile: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  risk_profile: state.risk_profile,
  errors: state.errors
});

export default withRouter(
  connect(
    mapStateToProps,
    { setSignupStep,getCurrentUserDetails }
  )(FinalScreen)
);
