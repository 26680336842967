import isEmpty from "../validation/is-empty";

import { USER_DETAILS_SET,CERTIFICATE_GET,REPORT_GET,RESEARCH_GET,USER_RENEWAL } from "../actions/types";
//var  Growlytics = require('growlytics-browser');
const initialState = {
  isAuthenticated: false,
  info: {},
};

export default function(state = initialState, action) {
  switch (action.type) {
    case USER_DETAILS_SET:
      //Growlytics.identifyUser(action.payload.user_id, action.payload)
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        info: action.payload
      };

      case CERTIFICATE_GET:
      let certificates ={}
      return{
      ...state,
      certificates:action.payload
      };
      
      case REPORT_GET:
      let reports ={}
      return{
      ...state,
      reports:action.payload
      };

      case RESEARCH_GET:
      let researchs ={}
      return{
      ...state,
      researchs:action.payload
      };
      
      case USER_RENEWAL:
      let renewid ={}
      return{
      ...state,
      renewid:action.payload
      };

    default:
      return state;
  }
}
