import axios from "axios";

import {
  ERRORS_GET,
  GET_NW_STATS
} from "./types";

export const getStats = () => dispatch => {
  axios
    .get("/api/user/stats")
    .then(res => {
      dispatch({
        type: GET_NW_STATS,
        payload: res.data
      })
    })
    .catch(err =>
      dispatch({
        type: ERRORS_GET
      })
    );
};