import axios from "axios";

import {
  EDUCATION_DETAILS,
  USER_DETAILS,
  SKILLS,
  BEHAVIOUR,
  SEARCH_COLLEGE,
  PROFILE_PHOTO,
  USER_RENEWAL
} from "./types";
import { getCurrentUserDetails } from "./userActions";

//To getting Education details in dropdown
export const getEduDetails = () => dispatch => {
  axios
    .get("/api/profile_section/eduDetails")
    .then(res => {
      dispatch({
        type: EDUCATION_DETAILS,
        payload: res.data
      });
    })
    .catch(err => {
      console.log(err);
    });
};

export const searchClg = college => dispatch => {
  axios
    .get("/api/profile_section/searchClg", { params: { college: college } })
    .then(res => {
      dispatch({
        type: SEARCH_COLLEGE,
        payload: res.data
      });
    })
    .catch(err => {
      console.log(err);
    });
};

//Edit in profile Details

export const putProfileInfo = profileName => dispatch => {
  axios
    .put("/api/profile_section/setUserInfo", {
      profileName: profileName
    })
    .then(res => {
      dispatch(getCurrentUserDetails());
    })
    .catch(err => console.log(err));
};

//posting all user details filled at time of sign up
export const userDetails = (eduDetails, empDetails) => async dispatch => {
  axios
    .post("/api/profile_section/userDetails", {
      eduDetails: eduDetails,
      empDetails: empDetails
    })
    .then(async res => {
      dispatch(getCurrentUserDetails());
    })
    .catch(err => console.log(err));
};

//Resume Upload
export const resumeUpload = data => async dispatch => {
  if (await data) {
    await axios
      .post("/api/profile_section/upload", data)
      .then(res => {
        dispatch(userData());
      })
      .catch(err => console.log(err));
  }
};

export const uploadProfilePic = data => async dispatch => {
  await axios
    .post("/api/profile_section/profile_picture_upload", data)
    .then(res => {
      if (res.status == 200) {
        dispatch(getCurrentUserDetails());
        dispatch(profilePhoto());
      }
    })
    .catch(err => console.log(err));
};

export const profilePhoto = () => async dispatch => {
  dispatch({
    type: PROFILE_PHOTO,
    payload: "true"
  });
};

//getting user details for edit profile
export const userData = () => async dispatch => {
  await axios
    .get("/api/profile_section/userDetails")
    .then(res => {
      dispatch({
        type: USER_DETAILS,
        payload: res.data
      });
    })
    .catch(err => {
      console.log(err);
    });
};

export const editProfileData = (data, index, type) => dispatch => {
  axios
    .put("/api/profile_section/setEditDetails", {
      details: data,
      index: index,
      type: type
    })
    .then(response => {
      dispatch(userData());
    })
    .catch(err => console.log(err));
};
export const deleteData = (index, type) => dispatch => {
  axios
    .post("/api/profile_section/deleteDetails", {
      index: index,
      type: type
    })
    .then(response => {
      dispatch(userData());
    })
    .catch(err => console.log(err));
};

//drag and drop

export const getSkills = () => dispatch => {
  axios
    .get("/api/profile_section/skills")
    .then(res => {
      dispatch({
        type: SKILLS,
        payload: res.data
      });
    })
    .catch(err => console.log(err));
};

export const getBehaviour = () => dispatch => {
  axios
    .get("/api/profile_section/behaviour")
    .then(res => {
      dispatch({
        type: BEHAVIOUR,
        payload: res.data
      });
    })
    .catch(err => console.log(err));
};

export const getuserRenew = () => dispatch => {
  axios
    .get("/api/profile_section/behaviour")
    .then(res => {
      dispatch({
        type: USER_RENEWAL,
        payload: res.data
      });
    })
    .catch(err => console.log(err));
};

export const getDropData = (data1, data2) => dispatch => {
  axios
    .post("/api/profile_section/userDropData", {
      skillData: data1,
      behaviourData: data2
    })
    .then(async res => {
      if (res) {
      }
    })
    .catch(err => console.log(err));
};
