//ERRORS
export const ERRORS_GET = "ERRORS_GET";
export const ERRORS_CLEAR = "ERRORS_CLEAR";

//LOADER
export const LOADER_SHOW = "LOADER_SHOW";

//AUTH
export const AUTHENTICATION_TOKEN_VERIFY = "AUTHENTICATION_TOKEN_VERIFY";

//DASHBOARD
export const DASHBOARD_DATA_GET = "DASHBOARD_DATA_GET";
export const DASHBOARD_NEWS_FEED = "DASHBOARD_NEWS_FEED";

//HR
export const HR_QUESTIONS_GET = "HR_QUESTIONS_GET";
export const HR_ANSWER_SET = "HR_ANSWER_SET";
export const HR_RESPONSE_SAVE = "HR_RESPONSE_SAVE";
export const HR_ADD_BLOCK = "HR_ADD_BLOCK";
export const HR_REMOVE_BLOCK = "HR_REMOVE_BLOCK";

//NOTIFICATION
export const NOTIFICATION_LIST = "NOTIFICATION_NEW";
export const NOTIFICATION_MARK_READ = "NOTIFICATION_MARK_READ";
export const NOTIFICATION_MSG = "NOTIFICATION_MSG";

//ORDER
export const ORDER_LIST = "ORDER_LIST";
export const ORDER_CREATE = "ORDER_CREATE";
export const ORDER_DELETE = "ORDER_DELETE";
export const ORDER_REASON = "ORDER_REASON";

//PORTFOLIO
export const PORTFOLIO_UPDATES = "PORTFOLIO_UPDATES";
export const PORTFOLIO_LIST = "PORTFOLIO_LIST";
export const PORTFOLIO_SETUP_DONE = "PORTFOLIO_SETUP_DONE";
export const PORTFOLIO_UPDATES_CLEAR = "PORTFOLIO_UPDATES_CLEAR";
export const USER_FINSCORE = "USER_FINSCORE";
export const USER_RENEWAL = "USER_RENEWAL";
//RISK
export const RISK_PROFILE_GET = "RISK_PROFILE_GET";
export const RISK_PROFILE_SAVE = "RISK_PROFILE_SAVE";

//SCRIPS
export const SCRIP_DATA_UPDATES = "SCRIP_DATA_UPDATES";
export const SCRIP_LIST_ALL = "SCRIP_LIST_ALL";
export const SCRIP_LIST_FOR_PORTFOLIO = "SCRIP_LIST_FOR_PORTFOLIO";
export const SCRIP_INDUSTRIES = "SCRIP_INDUSTRIES";
export const SCRIP_COMPANY_SEARCH = "SCRIP_COMPANY_SEARCH";
export const SCRIP_WATCH_LIST = "SCRIP_WATCH_LIST";
export const SCRIP_CMP_DATA = "SCRIP_CMP_DATA";
export const SCRIP_CHART_DATA = "SCRIP_CHART_DATA";

//USER
export const USER_DETAILS_SET = "USER_DETAILS_SET";

export const GET_GAME_DATA = "GET_GAME_DATA";
export const GET_GAME_STATS = "GET_GAME_STATS";
export const GET_GAME_STATS_G1 = "GET_GAME_STATS_G1";
export const GET_GAME_STATS_G2 = "GET_GAME_STATS_G2";
export const GET_GAME_STATS_ECODE = "GET_GAME_STATS_ECODE";
export const CERTIFICATE_GET = "CERTIFICATE_GET";
export const REPORT_GET = "REPORT_GET";
export const RESEARCH_GET = "RESEARCH_GET";


export const GET_ECODE_QUESTIONS = "GET_ECODE_QUESTIONS";
export const GET_ECODE_PERMISSION = "GET_ECODE_PERMISSION";

//STATS

export const GET_NW_STATS = "GET_NW_STATS";

//RESEARCH
export const ON_RESEARCH_SUBMIT = "ON_RESEARCH_SUBMIT";
export const RESEARCH_DATA = "RESEARCH_DATA";
export const RATERESEARCH_DATA = "RATERESEARCH_DATA";
export const GET_NOTIFY_SCORE = "GET_NOTIFY_SCORE";
//NEWRESEARCH
export const NEWON_RESEARCH_SUBMIT = "ON_RESEARCH_SUBMIT";
export const NEWRESEARCH_DATA = "RESEARCH_DATA";
export const NEWRATERESEARCH_DATA = "RATERESEARCH_DATA";
export const NEWGET_NOTIFY_SCORE = "GET_NOTIFY_SCORE";

//RESOURCE

export const RESOURCE_QUESTIONS = "RESOURCE_QUESTIONS";
export const RESOURCE_VIDEOQUESTIONS = "RESOURCE_VIDEOQUESTIONS";
export const RESOURCE_FETCH_SCORE = "RESOURCE_FETCH_SCORE";
export const RESOURCE_VIDEOFETCH_SCORE = "RESOURCE_VIDEOFETCH_SCORE";
export const RESOURCE_VIDEOS = "RESOURCE_VIDEOS";
export const RESOURCE_ALL_VIDEOS = "RESOURCE_ALL_VIDEOS";
export const RESOURCE_FINLATICS_VIDEOS = "RESOURCE_FINLATICS_VIDEOS";



//PROFILE
export const PROFILE_PHOTO = "PROFILE_PHOTO";
export const EDUCATION_DETAILS = "EDUCATION_DETAILS";
export const USER_DETAILS = "USER_DETAILS";
export const SKILLS = "SKILLS";
export const BEHAVIOUR = "BEHAVIOUR";
export const SEARCH_COLLEGE = "SEARCH_COLLEGE";
export const USER_STOCK = "USER_STOCK";


// LEADER_BOARD
export const LEADER_BOARD = "LEADER_BOARD";

