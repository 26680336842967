import React, { Component } from "react";

class Salary extends Component {
  constructor() {
    super();
    this.state = {
      val: { l: "0", t: "0" }
    };
  }

  updateCompVal = () => {
    let val = this.props.dataVal;
    if (val) {
      val = val.split(/\s*,\s*/);
    } else {
      val = [0, 0];
    }
    this.setState({ l: val[0], t: val[1] });
  };
  componentDidMount = () => {
    this.updateCompVal();
  };

  componentDidUpdate = prevProps => {
    if (prevProps.dataVal != this.props.dataVal) {
      this.updateCompVal();
    }
  };

  onChangeHandle = (type, val) => {
    let newState = { ...this.state };
    newState.val[type] = val;
    this.setState(newState);
    this.props.setVal(`${newState.val.l},${newState.val.t}`);
  };
  render() {
    return (
      <div
        className="form-inline page-question"
        data-qid={this.props.qid}
        data-val={this.props.dataVal}
        data-block-id={this.props.dataBlockId}
        data-repeat-index={this.props.dataRepeatIndex}
      >
        <select
          value={this.state.l}
          className="form-control"
          style={{ marginRight: "20px" }}
          onChange={e => this.onChangeHandle("l", e.target.value)}
        >
          {[...Array(51)].map((x, i) => (
            <option value={i} key={i}>
              {i} Lakhs
            </option>
          ))}
          {[...Array(11)].map((x, i) => {
            const v = i * 5 + 50 + "+";
            return (
              <option value={v} key={v}>
                {v} Lakhs
              </option>
            );
          })}
          <option value="100+">100+ Lakhs</option>
        </select>
        <select
          value={this.state.t}
          className="form-control"
          onChange={e => this.onChangeHandle("t", e.target.value)}
        >
          {[...Array(20)].map((x, i) => {
            const v = i * 5;
            return (
              <option value={v} key={v}>
                {v} Thousand
              </option>
            );
          })}
        </select>
      </div>
    );
  }
}

export default Salary;
