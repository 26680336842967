import React from "react";
import { Nav, NavItem, NavLink, UncontrolledCollapse } from "reactstrap";

class VedioSidebar extends React.Component {
  state = {
    showComponent: false
  };

  onButtonClick = () => {
    this.setState({
      showComponent: true
    });
  };
  render() {
    return (
      <div className="resource">
        <Nav vertical className="sidebar">
          <NavItem>
            <NavLink
              onClick={() => {
                this.props.onSelect("vedio-1");
              }}
              className={
                this.props.active == "vedio-1" || this.props.active == "vquiz1"
                  ? "active"
                  : ""
              }
              id="toggler1"
            >
              Introduction
            </NavLink>
            <UncontrolledCollapse
              toggler="#toggler1"
              className="collapse-items"
            >
              <Nav navbar>
                <NavItem>
                  <NavLink
                    onClick={() => {
                      this.props.onSelect("vedio-1");
                    }}
                    className={
                      this.props.active == "vedio-1" ? "activeSub" : ""
                    }
                  >
                   Introduction to Securities
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    onClick={() => {
                      this.props.onSelect("vedio-11");
                    }}
                    className={
                      this.props.active == "vedio-11" ? "activeSub" : ""
                    }
                  >
                    Portfolio
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    onClick={() => {
                      this.props.onSelect("vquiz1");

                      this.props.onToggle(true);
                    }}
                    className={this.props.active == "vquiz1" ? "activeSub" : ""}
                  >
                    Assessment
                  </NavLink>
                </NavItem>
              </Nav>
            </UncontrolledCollapse>
          </NavItem>

          <NavItem>
            <NavLink
              id="toggler2"
              onClick={() => {
                this.props.onSelect("vedio-2");
              }}
              className={
                this.props.active == "vedio-2" || this.props.active == "vquiz2"
                  ? "active"
                  : ""
              }
            >
               Exchange & History
            </NavLink>
            <UncontrolledCollapse
              toggler="#toggler2"
              className="collapse-items"
            >
              <Nav navbar>
                <NavItem>
                  <NavLink
                    onClick={() => {
                      this.props.onSelect("vedio-2");
                    }}
                    className={
                      this.props.active == "vedio-2" ? "activeSub" : ""
                    }
                  >
                    Stock Exchange & Indices
                  </NavLink>
                  
                </NavItem>
                <NavItem>
                  <NavLink
                    onClick={() => {
                      this.props.onSelect("vedio-21");
                    }}
                    className={
                      this.props.active == "vedio-21" ? "activeSub" : ""
                    }
                  >
                  History of stock Exchange in India 
                  </NavLink>
                  
                </NavItem>

                
                <NavItem>
                  <NavLink
                    onClick={() => {
                      this.props.onSelect("vquiz2");
                      this.props.onToggle(true);
                    }}
                    className={this.props.active == "vquiz2" ? "activeSub" : ""}
                  >
                    Assessment
                  </NavLink>
                </NavItem>
              </Nav>
            </UncontrolledCollapse>
          </NavItem>
          <NavItem>
            <NavLink
              id="toggler3"
              onClick={() => {
                this.props.onSelect("vedio-3");
              }}
              className={
                this.props.active == "vedio-3" || this.props.active == "vquiz3"
                  ? "active"
                  : ""
              }
            >
              Securities and Terminology 
            </NavLink>
            <UncontrolledCollapse
              toggler="#toggler3"
              className="collapse-items"
            >
              <Nav navbar>
                <NavItem>
                  <NavLink
                    onClick={() => {
                      this.props.onSelect("vedio-3");
                    }}
                    className={
                      this.props.active == "vedio-3" ? "activeSub" : ""
                    }
                  >
                    Market Securities 
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    onClick={() => {
                      this.props.onSelect("vedio-31");
                    }}
                    className={
                      this.props.active == "vedio-31" ? "activeSub" : ""
                    }
                  >
                    Fundamental Terminology
                  </NavLink>
                  
                </NavItem>
                <NavItem>
                  <NavLink
                    onClick={() => {
                      this.props.onSelect("vquiz3");
                      this.props.onToggle(true);
                    }}
                    className={this.props.active == "vquiz3" ? "activeSub" : ""}
                  >
                  Assessment
                  </NavLink>
                </NavItem>
              </Nav>
            </UncontrolledCollapse>
          </NavItem>
          <NavItem>
            <NavLink
              id="toggler4"
              onClick={() => {
                this.props.onSelect("vedio-4");
              }}
              className={
                this.props.active == "vedio-4" || this.props.active == "vquiz4"
                  ? "active"
                  : ""
              }
            >
             Valuations 
            </NavLink>
            <UncontrolledCollapse
              toggler="#toggler4"
              className="collapse-items"
            >
              <Nav navbar>
                <NavItem>
                  <NavLink
                    onClick={() => {
                      this.props.onSelect("vedio-4");
                    }}
                    className={
                      this.props.active == "vedio-4" ? "activeSub" : ""
                    }
                  >
                     Interpretation 
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    onClick={() => {
                      this.props.onSelect("vedio-41");
                    }}
                    className={
                      this.props.active == "vedio-41" ? "activeSub" : ""
                    }
                  >
                     Fundamental Ratios 
                  </NavLink>
                  
                </NavItem>

                <NavItem>
                  <NavLink
                    onClick={() => {
                      this.props.onSelect("vedio-42");
                    }}
                    className={
                      this.props.active == "vedio-42" ? "activeSub" : ""
                    }
                  >
                            Turnover Ratios 
                  </NavLink>
                  
                </NavItem>
                <NavItem>
                  <NavLink
                    onClick={() => {
                      this.props.onSelect("vquiz4");
                      this.props.onToggle(true);
                    }}
                    className={this.props.active == "vquiz4" ? "activeSub" : ""}
                  >
                   Assessment
                  </NavLink>
                </NavItem>
              </Nav>
            </UncontrolledCollapse>
          </NavItem>
          <NavItem>
            <NavLink
              id="toggler5"
              onClick={() => {
                this.props.onSelect("vedio-5");
              }}
              className={
                this.props.active == "vedio-5" || this.props.active == "vquiz5"
                  ? "active"
                  : ""
              }
            >
             Stock Analysis Approach
            </NavLink>
            <UncontrolledCollapse
              toggler="#toggler5"
              className="collapse-items"
            >
              <Nav navbar>
                <NavItem>
                  <NavLink
                    onClick={() => {
                      this.props.onSelect("vedio-5");
                    }}
                    className={
                      this.props.active == "vedio-5" ? "activeSub" : ""
                    }
                  >
                    Fundamental Analysis
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    onClick={() => {
                      this.props.onSelect("vedio-51");
                    }}
                    className={
                      this.props.active == "vedio-51" ? "activeSub" : ""
                    }
                  >
                  Technical Analysis
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    onClick={() => {
                      this.props.onSelect("vquiz5");
                      this.props.onToggle(true);
                    }}
                    className={this.props.active == "vquiz5" ? "activeSub" : ""}
                  >
                   Assessment
                  </NavLink>
                </NavItem>
              </Nav>
            </UncontrolledCollapse>
          </NavItem>
          <NavItem>
            <NavLink
              id="toggler6"
              onClick={() => {
                this.props.onSelect("vedio-6");
              }}
              className={
                this.props.active == "vedio-6" || this.props.active == "vquiz6"
                  ? "active"
                  : ""
              }
            >
             Legendary Indian Investors 
            </NavLink>
            <UncontrolledCollapse
              toggler="#toggler6"
              className="collapse-items"
            >
              <Nav navbar>
                <NavItem>
                  <NavLink
                    onClick={() => {
                      this.props.onSelect("vedio-6");
                    }}
                    className={
                      this.props.active == "vedio-6" ? "activeSub" : ""
                    }
                  >
                  Legendary Indian Investors 
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    onClick={() => {
                      this.props.onSelect("vquiz6");
                      this.props.onToggle(true);
                    }}
                    className={this.props.active == "vquiz6" ? "activeSub" : ""}
                  >
                   Assessment
                  </NavLink>
                </NavItem>
              </Nav>
            </UncontrolledCollapse>
          </NavItem>
        </Nav>
      </div>
    );
  }
}

export default VedioSidebar;
