import React from "react";

import Sidebar from "./sidebar";
import VedioSlidebar from "./vslidebar";
import { Row, Col } from "reactstrap";
import {
  getQuestions,
  getVideos,
  fetchScore,
  getvideoQuestions,
  fetchvideoScore
} from "../../actions/resourceActions";
import M1_intro from "./module-1/intro";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import M2_quiz from "./module-2/quiz";
import Indices from "./module-2/indices";
import Termology from "./module-3/termology";
import SecurityMarkets from "./module-2/securityMarkets";
import ValuationRatios from "./module-4/valuationRatios";
import LiquidityRatios from "./module-4/liquidityRatios";
import EfficiencyRatios from "./module-4/efficiencyRatios";
import LeverageRatios from "./module-4/leverageRatios";
import FundamentalAnalysis from "./module-5/fundamentalAnalysis";
import M1_quiz from "./module-1/m1_quiz";
import M3_quiz from "./module-3/quiz";
import M4_quiz from "./module-4/quiz";
import M5_quiz from "./module-5/quiz";
import VEDIO1 from "./vmodule-1/vedio-1";
import VEDIO11 from "./vmodule-1/vedio-11";
import VEDIO2 from "./vmodule-2/vedio-2";
import VEDIO21 from "./vmodule-2/vedio-21";
import VEDIO3 from "./vmodule-3/vedio-3";
import VEDIO31 from "./vmodule-3/vedio-31";
import VEDIO4 from "./vmodule-4/vedio-4";
import VEDIO41 from "./vmodule-4/vedio-41";
import VEDIO42 from "./vmodule-4/vedio-42";
import VEDIO5 from "./vmodule-5/vedio-5";
import VEDIO51 from "./vmodule-5/vedio-51";
import VEDIO6 from "./vmodule-6/vedio-6";
import VM1_quiz from "./vmodule-1/vmodule-1quiz";
import VM2_quiz from "./vmodule-2/vmodule-2quiz";
import VM3_quiz from "./vmodule-3/vmodule-3quiz";
import VM4_quiz from "./vmodule-4/vmodule-4quiz";
import VM5_quiz from "./vmodule-5/vmodule-5quiz";
import VM6_quiz from "./vmodule-6/vmodule-6quiz";

import VideoLectures from "./videoLectures";
import TechnicalAnalysis from "./module-5/technicalAnalysis";
import { getCurrentUserDetails } from "../../actions/userActions";

class Resource extends React.Component {
  state = {
    active: "m-1_intro",
    modal: false
  };

  setActive = m => {
    this.setState({ active: m });
  };
  toggle = t => {
    this.setState({
      modal: t
    });
  };

  componentDidMount() {
    this.props.getQuestions();
    this.props.getvideoQuestions();
    this.props.getCurrentUserDetails();
    this.props.fetchScore();
    this.props.fetchvideoScore();
  }

  renderSwitch = a => {
    switch (a) {
      case "m-1_quiz":
        return (
          <M1_quiz
            onSelect={this.setActive}
            toggle={this.state.modal}
            onToggle={this.toggle}
            questions={this.props.questions}
            userId={this.props.userInfo.user_id}
          />
        );

        break;
      case "m-2_indices":
        return <Indices />;

        break;
      case "m-2_markets":
        return <SecurityMarkets />;
        break;

      case "m-2_quiz":
        return (
          <M2_quiz
            onSelect={this.setActive}
            toggle={this.state.modal}
            questions={this.props.questions}
            onToggle={this.toggle}
            userId={this.props.userInfo.user_id}
          />
        );
        break;
      case "m-3_termo":
        return <Termology />;

        break;
      case "m-3_quiz":
        return (
          <M3_quiz
            onSelect={this.setActive}
            toggle={this.state.modal}
            questions={this.props.questions}
            onToggle={this.toggle}
            userId={this.props.userInfo.user_id}
          />
        );
        break;
      case "m-4_val":
        return <ValuationRatios />;
        break;
      case "m-4_leq":
        return <LiquidityRatios />;

        break;

      case "m-4_eff":
        return <EfficiencyRatios />;

        break;
      case "m-4_lev":
        return <LeverageRatios />;

        break;
      case "m-4_quiz":
        return (
          <M4_quiz
            onSelect={this.setActive}
            questions={this.props.questions}
            toggle={this.state.modal}
            onToggle={this.toggle}
            userId={this.props.userInfo.user_id}
          />
        );
        break;
      case "m-5_fundamental":
        return <FundamentalAnalysis />;

        break;

      case "m-5_tech":
        return <TechnicalAnalysis />;

        break;
      case "m-5_quiz":
        return (
          <M5_quiz
            onSelect={this.setActive}
            questions={this.props.questions}
            toggle={this.state.modal}
            onToggle={this.toggle}
            userId={this.props.userInfo.user_id}
          />
        );

        break;
      case "videoLectures":
        return <VideoLectures videos={this.props.videos} />;
      default:
      case "m-1_intro":
        return <M1_intro />;
    }
  };
  vediorenderSwitch = a => {
    switch (a) {
      case "vquiz1":
        return (
          <VM1_quiz
            onSelect={this.setActive}
            toggle={this.state.modal}
            onToggle={this.toggle}
            questions={this.props.vquestions}
            userId={this.props.userInfo.user_id}
          />
        );

        break;
      case "vedio-2":
        return (
          <VEDIO2 
        vscore ={this.props.vscores}
        fetchvideoScore = {this.props.fetchvideoScore}
        userId={this.props.userInfo.user_id}/>
        );
;

        break;
      case "vedio-3":
        return ( <VEDIO3 
        vscore ={this.props.vscores}
        fetchvideoScore = {this.props.fetchvideoScore}
        userId={this.props.userInfo.user_id}/>
        );
        break;

      case "vquiz2":
        return (
          <VM2_quiz
            onSelect={this.setActive}
            toggle={this.state.modal}
            questions={this.props.vquestions}
            onToggle={this.toggle}
            userId={this.props.userInfo.user_id}
          />
        );
        break;
      case "vedio-4":
        return( <VEDIO4 
          vscore ={this.props.vscores}
          fetchvideoScore = {this.props.fetchvideoScore}
          userId={this.props.userInfo.user_id}/>
          );

        break;

      case "vedio-11":
        return ( <VEDIO11 
          vscore ={this.props.vscores}
          userId={this.props.userInfo.user_id}/>
          );

        break;
      case "vedio-21":
        return( <VEDIO21 
          vscore ={this.props.vscores}
          fetchvideoScore = {this.props.fetchvideoScore}
          userId={this.props.userInfo.user_id}/>
          );

        break;

      case "vedio-31":
        return( <VEDIO31 
          vscore ={this.props.vscores}
          fetchvideoScore = {this.props.fetchvideoScore}
          userId={this.props.userInfo.user_id}/>
          );

        break;
      case "vedio-41":
        return ( <VEDIO41 
          vscore ={this.props.vscores}
          fetchvideoScore = {this.props.fetchvideoScore}
          userId={this.props.userInfo.user_id}/>
          );

        break;

      case "vedio-42":
        return ( <VEDIO42 
          vscore ={this.props.vscores}
          fetchvideoScore = {this.props.fetchvideoScore}
          userId={this.props.userInfo.user_id}/>
          );

        break;

      case "vedio-51":
        return( <VEDIO51 
          vscore ={this.props.vscores}
          fetchvideoScore = {this.props.fetchvideoScore}
          userId={this.props.userInfo.user_id}/>
          );

        break;

      case "vquiz3":
        return (
          <VM3_quiz
            onSelect={this.setActive}
            toggle={this.state.modal}
            questions={this.props.vquestions}
            onToggle={this.toggle}
            userId={this.props.userInfo.user_id}
          />
        );
        break;
      case "vedio-5":
        return ( <VEDIO5 
          vscore ={this.props.vscores}
          fetchvideoScore = {this.props.fetchvideoScore}
          userId={this.props.userInfo.user_id}/>
          );
        break;
      case "vedio-6":
        return ( <VEDIO6 
          vscore ={this.props.vscores}
          fetchvideoScore = {this.props.fetchvideoScore}
          userId={this.props.userInfo.user_id}/>
          );

        break;

      case "m-4_eff":
        return <EfficiencyRatios />;

        break;
      case "m-4_lev":
        return <LeverageRatios />;

        break;
      case "vquiz4":
        return (
          <VM4_quiz
            onSelect={this.setActive}
            questions={this.props.vquestions}
            toggle={this.state.modal}
            onToggle={this.toggle}
            userId={this.props.userInfo.user_id}
          />
        );
        break;
      case "m-5_fundamental":
        return <FundamentalAnalysis />;

        break;

      case "m-5_tech":
        return <TechnicalAnalysis />;

        break;
      case "vquiz5":
        return (
          <VM5_quiz
            onSelect={this.setActive}
            questions={this.props.vquestions}
            toggle={this.state.modal}
            onToggle={this.toggle}
            userId={this.props.userInfo.user_id}
          />
        );

        break;
      case "vquiz6":
        return (
          <VM6_quiz
            onSelect={this.setActive}
            questions={this.props.vquestions}
            toggle={this.state.modal}
            onToggle={this.toggle}
            userId={this.props.userInfo.user_id}
          />
        );
      default:
      case "vedio-1":
        return ( <VEDIO1 
          vscore ={this.props.vscores}
          userId={this.props.userInfo.user_id}/>
          );
    }
  };
  render() {
    if (this.props.userInfo.planned == "p199") {
      return (
        <div className="resource container">
          <div>
            <h2 className="title">Resource</h2>
            <hr />
          </div>
          <div className="container-fluid">
            <Row className="mt-4">
              <Col sm="12" md="4" lg="3" className="pad-1">
                <Sidebar
                  onSelect={this.setActive}
                  active={this.state.active}
                  onToggle={this.toggle}
                />
              </Col>
              <Col sm="12" md="8" lg="9" className="boxing mb-5 pl-5 pr-5 ">
                {this.renderSwitch(this.state.active)}
              </Col>
            </Row>
          </div>
        </div>
      );
    } else {
      return (
        <div className="resource container">
          <div>
            <h2 className="title">Resource Lectures </h2>
            <hr />
          </div>
          <div className="container-fluid">
            <Row className="mt-4">
              <Col sm="12" md="4" lg="3" className="pad-1">
                <VedioSlidebar
                  onSelect={this.setActive}
                  active={this.state.active}
                  onToggle={this.toggle}
                />
              </Col>
              <Col sm="12" md="8" lg="9" className="boxing mb-5 pl-5 pr-5 ">
                {this.vediorenderSwitch(this.state.active)}
              </Col>
            </Row>
          </div>
        </div>
      );
    }
  }
}

Resource.propTypes = {
  getCurrentUserDetails: PropTypes.func.isRequired,
  getVideos: PropTypes.func.isRequired,
  getQuestions: PropTypes.func.isRequired,
  getvideoQuestions: PropTypes.func.isRequired,
  fetchScore: PropTypes.func.isRequired,
  fetchvideoScore: PropTypes.func.isRequired,
  questions: PropTypes.object.isRequired,
  vquestions: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  questions: state.resource.questions,
  vquestions: state.resource.vquestions,
  videos: state.resource.videos,
  userInfo: state.user.info,
  scores: state.resource.scores,
  vscores: state.resource.vscores
});

export default connect(mapStateToProps, {
  getQuestions,
  getCurrentUserDetails,
  getVideos,
  fetchScore,
  getvideoQuestions,
  fetchvideoScore
})(Resource);
