import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Table,
  Input,
  UncontrolledTooltip,
  Label,
} from "reactstrap";
import styled from "styled-components";

const ScripInput = styled.input`
  margin: 5px !important;
  width: 90px !important;
  display: inline-block !important;
  font-size: 15px !important;
`;

const buy_reason = {
  "COMPANY FUNDAMENTALS": [
    "Growth in company’s revenue & profitability over past quarters and you see this growth continuing in the future.",
    "Earnings per share (EPS) is higher compared to peers.",
    "Company is run by a very strong & competent management.",
    "There is management change in the company due to merger/takeover etc.",
    "Company does not have high debt and most of the financing is through internal accruals.",
    " All working ratios of the company are very good on financial parameters.",
    " Company has been regularly rewarding investors by way of high dividends or bonus.",
    " The Price to Earnings (P/E) Ratio of the company is above industry average",
    " The Price to Earnings (P/E) Ratio of the company is below industry average",
    " Sudden spurt in volume of shares traded",
    " Others",
  ],

  "MARKET NEWS": [
    "Company was in news recently for positive reasons like announcement of new launches, expansion plans etc.",
    "Company was in news recently for negative reasons like low demand scenario, bad seasonal impact. However, I am confident that it will come out of this setback as fundamentals are strong.",
    "The company was recommended by a market expert in the news recently.",
    "There are rumours that some large investor/ operator is buying/selling in this company.",
    "Company has just declared strong quarterly results",
    "There is merger or takeover related news in the company",
    "Others",
  ],

  "MACROECONOMIC AND INDUSTRY WIDE PARAMETERS": [
    "Government has recently announced benefits for the sector in which the company operates. Budget, GST or any other policy initiative",
    "The government has announced huge spending for growth or infrastructure development in the sector.",
    "Good monsoon or weather forecasts will help in company growth.",
    "There have been technological changes which may affect company growth.",
    "Aggressive strategy adopted by company for market penetration.",
    "Company is a market leader in the industry that it operates in.",
    "Others",
  ],

  "TECHNICAL VIEWPOINTS": [
    "Company shows a rising price trend over the last few days",
    "Company shows a rising price trend over the last 6 months or more",
    "The company’s stock price has fallen recently but it has very good fundamentals and therefore, it is a value purchase at this price",
    "Stock has breached 200 day moving average",
    "Stock is yet to breach 200 day moving average but you’re expecting it to be reached soon",
    "You understand the technical chart and can explain in a few lines below",
    "Others",
  ],
};

const sell_reason = {
  "PORTFOLIO RELATED FACTORS": [
    "You have made money in this company and want to exit profitably",
    "You have lost money in this company and do not want to lose further",
    "You feel opportunity is present in a different company and need funds for the same.",
    "Others",
  ],

  "PRICE TRENDS": [
    "Company is showing a falling price trend",
    "Company is showing a rising price trend",
    "Company is showing a stagnant price trend and so you do not expect any growth in price further",
    "Company has announced strong quarterly result leading to a rise in its price",
    "Company’s quarterly result has been below expectation & you are fearing a fall in its price",
    "Others",
  ],
  NEWS: [
    "Government has announced a policy that will adversely impact the company and the sector that it is operating in.",
    "You have read about technologies that will adversely impact the company’s business in the future",
    "Company has been in news recently for negative reasons and you are fearing a price fall",
    "Company has been in news recently for positive reasons and you want to take advantage of the price rise",
    "Large brokerage houses have placed a ‘Sell’ recommendation on the company",
    "There has been adverse news regarding the company’s debt repayment capacity",
    "Others",
  ],
  "COMPANY RELATED FACTORS": [
    "Company’s business is threatened by competitors and therefore, you do not see any growth going ahead",
    "There are significant management changes in the company that can impact its performance going ahead",
    "You are forecasting a fall in company’s revenue and / or profitability",
    "Sudden spurt in volume of shares traded",
    "Others",
  ],
};

export default class BuyModal extends React.Component {
  state = {
    current_time: 60,
    show_reason: false,
    sprice: 0,
    active_sl: 0,
    reason: "",
    reason_1: "",
    reason_2: "",
    tqty: "",
  };

  timer = 0;
  componentDidMount() {
    this.setState({ active_sl: 0 });
  }
  componentDidUpdate(prevProps) {
    if (this.props.show !== prevProps.show) {
      this.setState({ show_reason: false, current_time: 60 });
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.timer = setInterval(this.countDown, 1000);
    }
  }
  countDown = () => {
    // Remove one second, set state so a re-render happens.
    let seconds = this.state.current_time - 1;
    this.setState({
      current_time: seconds,
    });

    // Check if we're at zero.
    if (seconds <= 0) {
      clearInterval(this.timer);
      this.props.hideModal();
    }
  };

  setQty = (e) => {
    this.setState({ tqty: e });
  };

  setPrice = (e) => {
    this.setState({ sprice: e });
    console.log(e);
  };

  showReason = () => {
    clearInterval(this.timer);
    let d1 =
      this.props.action == "BUY"
        ? Object.keys(buy_reason)[0]
        : Object.keys(sell_reason)[0];
    let d2 =
      this.props.action == "BUY" ? buy_reason[d1][0] : sell_reason[d1][0];
    this.setState({
      reason_1: d1,
      reason_2: d2,
      show_reason: true,
      current_time: -1,
    });
  };

  getReason = () => {
    if (this.state.show_reason) {
      return (
        <div className="animated fadeInDown">
          <Row style={{ marginTop: "10px" }}>
            <Col sm="12" className="p-4">
              <div
                className="pb-4 text-center"
                style={{ fontSize: "20px", fontWeight: "400" }}
              >
                Investment Rationale
              </div>
              {this.props.action == "BUY" ? (
                <div>
                  <Input
                    className="mb-4  inputModal"
                    type="select"
                    name="select"
                    value={this.state.reason_1}
                    onChange={(e) =>
                      this.setState({
                        reason_1: e.target.value,
                        reason_2: buy_reason[e.target.value][0],
                      })
                    }
                  >
                    {Object.keys(buy_reason).map((k) => (
                      <option>{k}</option>
                    ))}
                  </Input>
                  <Input
                    className="rational2 inputModal"
                    type="select"
                    name="select"
                    value={this.state.reason_2}
                    onChange={(e) =>
                      this.setState({
                        reason_2: e.target.value,
                      })
                    }
                  >
                    {buy_reason[this.state.reason_1].map((k) => (
                      <option>{k}</option>
                    ))}
                  </Input>
                </div>
              ) : (
                <div>
                  <Input
                    className="mb-4 inputModal"
                    type="select"
                    name="select"
                    value={this.state.reason_1}
                    onChange={(e) =>
                      this.setState({
                        reason_1: e.target.value,
                        reason_2: sell_reason[e.target.value][0],
                      })
                    }
                  >
                    {Object.keys(sell_reason).map((k) => (
                      <option>{k}</option>
                    ))}
                  </Input>
                  <Input
                    type="select"
                    name="select"
                    className ="inputModal"
                    value={this.state.reason_2}
                    onChange={(e) =>
                      this.setState({
                        reason_2: e.target.value,
                      })
                    }
                  >
                    {sell_reason[this.state.reason_1].map((k) => (
                      <option>{k}</option>
                    ))}
                  </Input>
                </div>
              )}
            </Col>
          </Row>

          <Button
            className="btn site-gray"
            style={{marginLeft:"20px", width: "150px", fontSize: "18px!important" ,backgroundColor : "#31B258" ,boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px", }}
          
            onClick={async () => {
              this.props.handleReason(
                JSON.stringify({ [this.state.reason_1]: this.state.reason_2 })
              );
              await this.setState({
                active_sl: 0,
              });

              await this.setState({
                sprice: "",
              });
            }}
          >
            SUBMIT
          </Button>

          <Button
            className="btn site-gray"
            onClick={async () => {
              this.props.hideModal();
              await this.setState({
                sprice: "",
              });
            }}
            style={{ width: "150px", fontSize: "18px!important" }}
          >
            CANCEL
          </Button>
        </div>
      );
    } else {
      return <div />;
    }
  };
  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }
  render() {
    var checked_sl =
      this.state.active_sl == 1 ? { checked: "checked" } : { checked: false };
    return (
      <Modal
        isOpen={this.props.show}
        backdrop={true}
        size={this.props.modify == false ? "lg" : "lg"}
      >
        {this.props.modify == false ? (
          <div>
            <ModalHeader className={"text-center"}>
              Are you sure you want to proceed with this trade?
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col sm="12">
                  <Table hover >
                  <thead className="buyTrailModalTHeading">
                  <tr>
                        <th>
                          <div>COMPANY</div>
                        </th>
                        <th>
                          <div>PRICE</div>
                        </th>
                        <th>
                          <div>QTY</div>
                        </th>
                        <th>
                          <div>ACTION</div>
                        </th>
                        <th>
                          <div>DEAL VALUE</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="buyTrailModalTBody">
                      <tr>
                        <td> <div>{this.props.company}</div></td>
                        <td>
                        <div>
                            &#8377;
                            {this.props.price}
                          </div>
                        </td>
                        <td>  <div>{this.props.qty}</div></td>
                        <td>
                            <div>   <Label
                            className="site-gray modal-trade-action"
                            style={{
                              marginTop: "8px",
                              padding: "0px 15px",
                              background: "#000",
                            }}
                          >
                            {" "}
                            {this.props.action}{" "}
                          </Label></div>
                       
                        </td>
                        <td>
                        <div>
                            {" "}
                            &#8377;
                            {(this.props.price * this.props.qty).toFixed(2)}
                          </div>   </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
              {this.props.typ == 2 ? (
                <Row>
                  <Col sm="12">
                    <i>
                      Since you have opted to trade at certain price, the trade
                      will only be executed if market price reaches that price.
                    </i>
                  </Col>
                </Row>
              ) : (
                <div />
              )}
              {this.state.current_time >= 0 ? (
                <Row
                  style={{
                    marginTop: "10px",
                    textAlign: "center",
                    fontSize: "18px",
                  }}
                >
                  <Col sm="3">
                    <Button
                      className="btn site-gray enableReason rounded p-1"
                      style={{
                        width: "150px",
                        fontSize: "18px!important",
                        backgroundColor: "#31B258",
                        boxShadow:
                          "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                      }}
                         onClick={async () => {
                        if (
                          this.state.sprice < this.props.price &&
                          (this.state.sprice != "" || this.state.sprice != 0)
                        ) {
                          await this.props.handleBuy();
                          await this.showReason();
                          await this.setState({ sprice: 0 });
                          await this.setState({ tqty: 0 });
                        }
                      }}
                    >
                      CONFIRM
                    </Button>
                  </Col>
                  <Col sm="3">
                    <Button
                 className="btn site-gray rounded p-1"
                 style={{
                   width: "150px",
                   fontSize: "18px!important",
                   boxShadow:
                     "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                 }}
                   onClick={async () => {
                        await this.setState({ sprice: 0 });
                        await this.setState({ tqty: 0 });
                        await this.props.hideModal();
                      }}
                    >
                      WAIT
                    </Button>
                  </Col>
                  <Col>
                    <div
                      className="form-inline"
                      style={{
                        margin: "auto",
                        width: "200px",
                        paddingBottom: "10px",
                      }}
                    >
                      <ScripInput
                        min="1"
                        step="1"
                        value={this.state.tqty}
                        type="number"
                        onKeyPress={(event) =>
                          event.charCode < 48 || event.charCode > 57
                            ? event.preventDefault()
                            : null
                        }
                        onChange={async (e) => {
                          await this.setQty(e.target.value);
                          await this.props.settqty(this.state.tqty);
                        }}
                        placeholder="QTY"
                      />
                    </div>
                  </Col>
                  {this.props.action == "SELL" ? (
                    <Col sm="3">
                      <span className="marketLimitSpan">
                        <label htmlFor={`mT${this.props.srCode}`}>
                          Trail-Stop-Loss
                        </label>{" "}
                        <i
                          className="fa fa-info-circle fa-sm"
                          id={"limitTT" + this.props.srCode}
                        />
                        <UncontrolledTooltip
                          placement="right"
                          target={"limitTT" + this.props.srCode}
                        >
                          A trailing stop loss allows traders to set a
                          predetermined loss price that they can incur when
                          trading. It plays an efficient role in managing risks
                          and providing profit protection. Consequently, these
                          are also known as profit-protecting stops. To use this
                          feature, you can enter a trade price that is below the
                          current market price for a particular quantity of
                          share that you own. If the market price reaches that
                          price, your shares will be sold. At market closing,
                          all trail stop loss order are automatically rejected
                          and you can then place it again, after closing, for
                          the next market day.
                        </UncontrolledTooltip>
                      </span>
                      <ScripInput
                        min="0"
                        step="0.01"
                        value={this.state.sprice}
                        onChange={async (e) => {
                          await this.setPrice(e.target.value);
                          await this.props.setActive_buyslprice(
                            this.state.sprice
                          );
                        }}
                        type="number"
                        placeholder="PRICE"
                      />
                    </Col>
                  ) : null}
                  <Col>{this.state.current_time}s left</Col>
                </Row>
              ) : null}
              {this.props.price <= this.state.sprice ? (
                <Row
                  style={{
                    marginTop: "10px",
                    textAlign: "center",
                    fontSize: "18px",
                  }}
                >
                  <Col lg="12">
                    <p>
                      Please enter the price which is less than Market Price for
                      Trail-Stop-Loss
                    </p>
                  </Col>
                </Row>
              ) : null}
              {this.props.qty < this.state.tqty ? (
                <Row
                  style={{
                    marginTop: "10px",
                    textAlign: "center",
                    fontSize: "18px",
                  }}
                >
                  <Col lg="12">
                    <p>Enter the qty less qty less than</p>
                  </Col>
                </Row>
              ) : null}

              {this.state.sprice == "" ? null : null}
              {this.getReason()}
            </ModalBody>
          </div>
        ) : (
          <div>
            <ModalHeader>
              Are you sure you want to modify with this trade?
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col sm="12">
                  <Table hover >
                    <thead className="buyTrailModalTHeading">
                    <tr>
                        <th>
                          <div>COMPANY</div>
                        </th>
                        <th>
                          <div>PRICE</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="buyTrailModalTBody"> 
                    <tr>
                        <td>
                          <div>{this.props.company}</div>
                        </td>
                        <td>
                          <div>
                            &#8377;
                            {this.props.price}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
              {this.props.typ == 2 ? (
                <Row>
                  <Col sm="12">
                    <i>
                      Since you have opted to trade at certain price, the trade
                      will only be executed if market price reaches that price.
                    </i>
                  </Col>
                </Row>
              ) : (
                <div />
              )}
              {this.state.current_time >= 0 ? (
                <Row
                  style={{
                    marginTop: "10px",
                    textAlign: "center",
                    fontSize: "18px",
                  }}
                >
                  <Col sm="3">
                    <Button
                       className="btn site-gray enableReason rounded p-1"
                       style={{
                         width: "150px",
                         fontSize: "18px!important",
                         backgroundColor: "#31B258",
                         boxShadow:
                           "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                       }}
                       onClick={async () => {
                        if (
                          this.state.sprice < this.props.price &&
                          (this.state.sprice != "" || this.state.sprice != 0)
                        ) {
                          await this.props.handleBuy();
                          await this.props.hideModal();
                          await this.setState({ sprice: 0 });
                          await this.setState({ tqty: 0 });
                        }
                      }}
                    >
                      CONFIRM
                    </Button>
                  </Col>
                  <Col sm="3">
                    <Button
                      className="btn site-gray rounded p-1"
                      style={{
                        width: "150px",
                        fontSize: "18px!important",
                        boxShadow:
                          "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                      }}
                      onClick={async () => {
                        await this.setState({ sprice: 0 });
                        await this.setState({ tqty: 0 });
                        await this.props.hideModal();
                      }}
                    >
                      WAIT
                    </Button>
                  </Col>
                  <Col>
                    <div
                      className="form-inline"
                      style={{
                        margin: "auto",
                        width: "200px",
                        paddingBottom: "10px",
                      }}
                    >
                      <ScripInput
                        min="1"
                        step="1"
                        value={this.state.tqty}
                        type="number"
                        onKeyPress={(event) =>
                          event.charCode < 48 || event.charCode > 57
                            ? event.preventDefault()
                            : null
                        }
                        onChange={async (e) => {
                          await this.setQty(e.target.value);
                          await this.props.settqty(this.state.tqty);
                        }}
                        placeholder="QTY"
                      />
                    </div>
                  </Col>
                  {this.props.action == "SELL" ? (
                    <Col sm="3">
                      <span className="marketLimitSpan">
                        <label htmlFor={`mT${this.props.srCode}`}>
                          Trail-Stop-Loss
                        </label>{" "}
                        <i
                          className="fa fa-info-circle fa-sm"
                          id={"limitTT" + this.props.srCode}
                        />
                        <UncontrolledTooltip
                          placement="right"
                          target={"limitTT" + this.props.srCode}
                        >
                          A trailing stop loss allows traders to set a
                          predetermined loss price that they can incur when
                          trading. It plays an efficient role in managing risks
                          and providing profit protection. Consequently, these
                          are also known as profit-protecting stops. To use this
                          feature, you can enter a trade price that is below the
                          current market price for a particular quantity of
                          share that you own. If the market price reaches that
                          price, your shares will be sold. At market closing,
                          all trail stop loss order are automatically rejected
                          and you can then place it again, after closing, for
                          the next market day.
                        </UncontrolledTooltip>
                      </span>
                      <ScripInput
                        min="0"
                        step="0.01"
                        value={this.state.sprice}
                        onChange={async (e) => {
                          await this.setPrice(e.target.value);
                          await this.props.setActive_buyslprice(
                            this.state.sprice
                          );
                        }}
                        type="number"
                        placeholder="PRICE"
                      />
                    </Col>
                  ) : null}
                  <Col sm="3" style={{ padding: "5px" }}>
                    {this.state.current_time}s left
                  </Col>
                </Row>
              ) : null}
              {this.props.price <= this.state.sprice ? (
                <Row
                  style={{
                    marginTop: "10px",
                    textAlign: "center",
                    fontSize: "18px",
                  }}
                >
                  <Col lg="12">
                    <p>
                      Please enter the price which is less than Market Price for
                      Trail-Stop-Loss
                    </p>
                  </Col>
                </Row>
              ) : null}

              {this.state.sprice == "" ? null : null}
              {this.getReason()}
            </ModalBody>
          </div>
        )}
      </Modal>
    );
  }
}
