import isEmpty from "../validation/is-empty";

import { SCRIP_DATA_UPDATES, PORTFOLIO_UPDATES } from "../actions/types";

const initialState = {
  portfolio_updates: false,
  portfolio_updates_msg: {},
  new_market_data: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SCRIP_DATA_UPDATES:
      let market_data = { ...state.new_market_data };
      for (var key in action.payload) {
        if (action.payload.hasOwnProperty(key)) {
          if (typeof action.payload[key] == "number") {
            market_data[key] = action.payload[key];
          } else {
            market_data[key] = action.payload[key].price;
          }
        }
      }
      return {
        ...state,
        new_market_data: market_data
      };
    case PORTFOLIO_UPDATES:
      if (state.portfolio_updates_msg == action.payload)
        return {
          ...state,
          portfolio_updates_msg: action.payload,
          portfolio_updates: true
        };
    default:
      return state;
  }
}
