import React from "react";
import Status from "./Status";
import Sidebar from "./Sidebar";
import Sector1 from "./Sections/sector_1";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Sector2 from "./Sections/sector_2";
import ResearchInsight1 from "./Sections/research_insight_1";
import ResearchInsight2 from "./Sections/research_insight_2";
import ResearchInsight3 from "./Sections/research_insight_3";
import ResearchInsight4 from "./Sections/research_insight_4";
import moment from "moment";
import { paymentRenewal } from "../../actions/authActions";
import { profilePhoto } from "../../actions/profileDetailsActions";
import { getFinscore } from "../../actions/portfolioActions";

import {
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle
} from "reactstrap";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Input,
  Label
} from "reactstrap";
import MyScoreCard from "./MyScoresCards";
import { getResearch } from "../../actions/researchActions";
import RateResearch from "./RateResearch";
import SectorPicks from "./Sections/SectorPicks/sector_picks1";
import { Row, Col } from "reactstrap";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

class Research extends React.Component {
  state = {
    active: "sector_picks1",
    week: 1
  };
  componentDidMount() {
    this.props.getResearch();
    this.props.getFinscore()
  }
  setActive = (el, week) => {
    this.setState({ active: el, week: week });
  };
  showInvalidFormAlert = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert-body">
            <p>Please fill all the data!</p>
            <div className="react-confirm-alert-button-group">
              <button
                onClick={() => {
                  onClose();
                }}
              >
                Ok
              </button>
            </div>
          </div>
        );
      }
    });
  };
  renderSwitch = (a, week) => {
    switch (a) {
      case "sector_1":
        return this.props.research[1] ? (
          <Sector1 showInvalidFormAlert={this.showInvalidFormAlert} />
        ) : (
          ""
        );

        break;
      case "sector_2":
        return this.props.research[2] ? (
          <Sector2 showInvalidFormAlert={this.showInvalidFormAlert} />
        ) : (
          ""
        );
        break;
      case "research_insight_1":
        return this.props.research[3] ? (
          <ResearchInsight1 showInvalidFormAlert={this.showInvalidFormAlert} />
        ) : (
          ""
        );
        break;
      case "research_insight_2":
        return this.props.research[4] ? (
          <ResearchInsight2 showInvalidFormAlert={this.showInvalidFormAlert} />
        ) : (
          ""
        );
        break;
      case "research_insight_3":
        return this.props.research[5] ? (
          <ResearchInsight3 showInvalidFormAlert={this.showInvalidFormAlert} />
        ) : (
          ""
        );
        break;
      case "research_insight_4":
        return this.props.research[6] ? (
          <ResearchInsight4 showInvalidFormAlert={this.showInvalidFormAlert} />
        ) : (
          ""
        );
        break;
      case "my_score":
        return <MyScoreCard />;
        break;
      case "rate_research":
        return (
          <RateResearch showInvalidFormAlert={this.showInvalidFormAlert} />
        );

        break;
      default:
      case "sector_picks1":
        return (
          <SectorPicks
            data={this.props.research[1]}
            showInvalidFormAlert={this.showInvalidFormAlert}
          />
        );
    }
  };
  render() {
    let week = 1;
    let weeks = Object.keys(this.props.research);
    if (weeks.length > 0) {
      week = Math.max.apply(Math, Object.keys(this.props.research)) + 1;
    }
    let data = this.props.finscore;
    return (
      <div className="container research pb-4 mb-4">
        {moment(this.props.user.end_date)
          .add(1, "days")
          .format("YYYY-MM-DD") >= moment().format("YYYY-MM-DD")  ? (
          <div>
            <Status
              active={this.state.active}
              week={this.state.week}
              start_date={this.props.user.start_date}
            />
            <Row className="mt-4">
              <Col sm="12" md="4" lg="3 " className="pr-5">
                <Sidebar
                  onSelect={this.setActive}
                  week={week}
                  active={this.state.active}
                />
              </Col>
              <Col sm="12" md="8" lg="9" className="content pr-4 pl-4">
                {this.renderSwitch(this.state.active, week)}
              </Col>
            </Row>
          </div>
        ) : (
          <Modal
            isOpen={true}
            modalClassName=".modal .modal-backdrop"
            size="lg"
            centered
          >
            <ModalBody
              style={{
                backgroundColor: "#E2EACE",
                fontSize: "18px!important"
              }}
            >
              <div>
                <Card>
                  <div className="text-center">
                    <img
                      style={{
                        backgroundColor: "#E2EACE",
                        height: "50px"
                      }}
                      src="/img/profile.png"
                      alt="arvind "
                    ></img>
                  </div>
                  <div className="mt-2 text-center">
                    <h6>
                      Your Program has ended on
                      <large className="p-2">
                      {moment(this.props.user.end_date)
                          .add(1, "days")
                          .format("YYYY-MM-DD")}
                      </large>
                    </h6>
                    <Label
                      className="site-white modal-trade-action"
                      style={{
                        background: "#a8bf6f"
                      }}
                    >
                      {" "}
                      Your updated FinScore is {data ? parseFloat(data.Finscore).toFixed(2) : "5"}{" "}
                    </Label>
                    <p>
                      Incase you want to extend your program,following structure
                      and benefits will be applicable:
                    </p>
                  </div>

                  <div
                    className=" border p-6 ml-2 mr-2"
                    style={{ display: "inline-block" }}
                  >
                    <div style={{ float: "left" }}>
                      <small>
                        1.<b>Certificate of Work Experience</b> and{" "}
                        <b>Financial Markets Performance Report</b> would be
                        issued for a period of 4 months.
                      </small>
                    </div>
                    <div style={{ float: "left" }}>
                      <small>
                        2. In case you are not satisfied with your FinScore, you
                        can reset it and <b>Continue</b> the program with a
                        <b> new FinScore</b>.
                      </small>
                    </div>
                    <div style={{ float: "left" }}>
                      <small>
                        3. You can use the extended period to complete your
                        research project in case you haven't completed it yet.{" "}
                      </small>
                    </div>
                  </div>
                  <div className="mb-1">
                    <div
                      className="pt-1"
                      style={{ float: "left", width: "33%" }}
                    >
                      <div>
                        <div className="border p-2 text-center ml-2">
                          <div
                            className="text-center p-2 d-inline-block download_arrow-1"
                            style={{
                              backgroundColor: "#a8bf6f",
                              borderRadius: "20px"
                            }}
                           
                          >
                            <a
                            
                            href={`${window.BASE_URL}/uploads/certificate/${this.props.user.email}_certificate.pdf`}
                              
                              download={`${this.props.user.email}.pdf`}
                              className="text-secondary"
                            >
                              <i
                                className="fa fa-cloud-download"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </div>
                          <p className="d-inline-block ml-2">Certificate</p>
                        </div>
                      </div>
                    </div>

                    <div
                      className="pt-1"
                      style={{ float: "left", width: "33%" }}
                    >
                      <div>
                        <div className="border p-2 text-center ml-2">
                          <div
                            className="text-center p-2 d-inline-block download_arrow-1"
                            style={{
                              backgroundColor: "#a8bf6f",
                              borderRadius: "20px"
                            }}
                          
                          >
                            <a
                              
                              href={`${window.BASE_URL}/uploads/report/${this.props.user.email}_report.pdf`}
                                
                              download={`${this.props.user.email}.pdf`}
                                className="text-secondary"
                              >
                              <i
                                className="fa fa-cloud-download"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </div>
                          <p className="d-inline-block ml-2">
                            Performance Report
                          </p>
                        </div>
                      </div>
                    </div>

                    <div
                      className="pt-1"
                      style={{ float: "left", width: "33%" }}
                    >
                      <div>
                        <div className="border p-2 text-center ml-2">
                          <div
                            className="text-center p-2 d-inline-block download_arrow-1"
                            style={{
                              backgroundColor: "#a8bf6f",
                              borderRadius: "20px"
                            }}

                          >
                             <a
                               
                               href={`${window.BASE_URL}/uploads/research/${this.props.user.email}_research.pdf`}
                                 
                               download={`${this.props.user.email}.pdf`}
                                 className="text-secondary"
                               >
                              <i
                                className="fa fa-cloud-download"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </div>
                          <p className="d-inline-block ml-2">Research Report</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-1 mb-1 text-center">
                    <div
                      className="d-inline-block border p-1 ml-2 float-left .bg-success"
                      style={{ width: "48%" }}
                      onClick={async () =>
                        await this.props.paymentRenewal(
                          this.props.user.email,
                          this.props.user.hash,
                          1
                        )
                      }
                    >
                      <Label
                        className="site-white "
                        style={{
                          marginTop: "8px",

                          background: "#a8bf6f"
                        }}
                      >
                        {" "}
                        <i
                          className="fa fa-external-link p-2"
                          aria-hidden="true"
                        ></i>
                        {""}
                        Proceed with new FinScore{" "}
                      </Label>
                    </div>
                    <div
                      className="d-inline-block border .bg-success p-1 float-right mr-2  .text-light "
                      style={{ width: "48%" }}
                      onClick={() =>
                        this.props.paymentRenewal(
                          this.props.user.email,
                          this.props.user.hash,
                          0
                        )
                      }
                    >
                      <Label
                        className="site-white modal-trade-action"
                        style={{
                          marginTop: "8px",

                          background: "#a8bf6f"
                        }}
                      >
                        {" "}
                        <i
                          className="fa fa-external-link p-2"
                          aria-hidden="true"
                        ></i>{" "}
                        Proceed wih same FinScore{" "}
                      </Label>
                    </div>
                  </div>
                </Card>
              </div>
            </ModalBody>
          </Modal>
        )}
      </div>
    );
  }
}

Research.propTypes = {
  getResearch: PropTypes.func.isRequired,
  research: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  paymentRenewal: PropTypes.func.isRequired,
  getFinscore: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  research: state.research.data,
  user: state.user.info,
  finscore: state.portfolio.finscore,
});

export default connect(
  mapStateToProps,
  {
    getResearch,
    paymentRenewal,
    profilePhoto,
    getFinscore
  }
)(Research);
