import React from "react";

class EfficiencyRatios extends React.Component {
  render() {
    return (
      <div className="container resource">
        <div className="mt-5">
          <h5 className="text-center mb-4">
            <u>
              <b>EFFICIENCY RATIOS</b>
            </u>
          </h5>
        </div>
        <p>
          As the name suggests, efficiency ratios help in determining how
          efficiently a firm is operating. There are different types of
          efficiency ratios like debtors turnover ratio, asset turnover ratio,
          etc which represent how assets and liabilities are being managed
          internally by companies. Efficiency ratios are an important indicator
          of a company’s profitability and hence are used by investors to
          analyze a company’s stock.
        </p>
        <ol>
          <li>Debtors Turnover Ratio</li>
          <p>
            The Debtors Turnover Ratio also called as Receivables Turnover Ratio
            indicates how the firm manages its assets and how quick it is in
            converting its assets into cash. A firm may receive cash payments
            from its customers in exchange for its products or it may extend
            credit to its customers for selling its goods. It is the credit
            sales that is taken into account while calculating the debtors
            turnover ratio as it measures the effectiveness of a firm in
            recovering its credits from the customers.
          </p>
          <p>
            Ideally, a company compares its debtors turnover ratio with the
            companies that have similar business operations and lie within the
            same industry. The formula to compute Debtors Turnover Ratio is:
          </p>
          <div className="g_boxing">
            <p className="text-center">
              Debtors Turnover Ratio = Net Credit Sales/Average Account
              Receivable
            </p>
          </div>
          <p>
            The average accounts receivable is calculated by taking the average
            of accounts receivables at the beginning of the year and accounts
            receivables at the end of the year.
          </p>
          <p>
            For instance, let us consider a pharmaceutical company Health Inc
            that had sales worth Rs 50 lac in the fiscal year 2018, out of which
            Rs 25 lac have been credit sales (products sold on a credit basis).
            Starting and ending accounts receivable for the year were Rs 2 lac
            and Rs 6 lac.
          </p>
          <p>The debtors turnover ratio for Health Inc will be:</p>
          <p>
            Average Accounts Receivable = 2,00,000 +6,00,000/2
            <span className="ml-d">= 4, 00, 000</span>
          </p>
          <p>
            Debtors Turnover Ratio = 25,00,000/4,00,000
            <span className="ml-e"> = 6.25</span>
          </p>
          <p>
            The company Health Inc collected its debts 6.25 times over the
            fiscal year 2018.
          </p>
          <p>
            The debtors turnover ratio can also be expressed in the form of the
            number of days required to collect debts.
          </p>
          <p>Debtors Turnover in days =365 / Debtors turnover ratio</p>
          <p>
            The average customer of Health Inc takes 58.4 days (365/6.25) to pay
            back their credit.
          </p>
          <p>
            A high debtors turnover ratio over a lower debtors turnover ratio in
            days is preferred by investors as it reveals that the company has
            quality customers who pay back in time.
          </p>
          <li>Asset Turnover Ratio</li>
          <p>
            The asset turnover ratio is an efficiency ratio that expresses the
            relation between the assets of a company and the revenue generated
            from its sales. The ratio shows how well the firm utilizes its
            assets to bring in sales.
            <br />A company’s assets include current and fixed assets. The fixed
            assets of a company are assets that are owned by the firm for long
            term use and cannot be converted to cash easily, like a building or
            a factory. A higher asset turnover ratio reveals that the firm under
            consideration utilizes its current and fixed assets efficiently to
            sell its products and earn revenue.
          </p>
          <p>The asset turnover ratio is computed using the formula:</p>
          <div className="g_boxing">
            <p className="text-center">
              Asset Turnover Ratio = Net sales revenue / Average total assets
            </p>
          </div>
          <p>
            Example- An FMCG firm XYZ Ltd has declared total assets in its
            financial statements for the year 2017 to be Rs 80 crores at the
            beginning of the year and 60 crores at the end of the year. Its
            revenue from sales for the year 2017 is 56 crores
          </p>
          <p>
            Average Total Assets = 80,00,00,000 + 60,00,00,000/2
            <span className="ml-f">= 7,00,00,000 (7 crores)</span>
          </p>
          <p>
            Asset Turnover Ratio = 56,00,00,000 / 7,00,00,000
            <span className="ml-f">= 8</span>
          </p>
          <p>
            An asset turnover ratio of 8 implies that for every one rupee of
            assets the company XYZ Ltd is generating 0.8 rupees of revenue.
          </p>
        </ol>
      </div>
    );
  }
}
export default EfficiencyRatios;
