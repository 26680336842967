import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Row } from "reactstrap";
import { getRiskProfile } from "../../actions/riskProfileActions";
import { withRouter } from "react-router-dom";
import Game from "./game";
import Questions from "./questions_v1";
import FinalScreen from "./final_screen";
import Profile from "./Profile";
import DragProfile from "./Drag&Drop";
class RiskProfile extends Component {
  constructor() {
    super();
    this.state = {
      screen: 2
    };
  }

  setInitialScreen = () => {
    if (this.props.location.pathname == "/signup/step2") {
      if (
        this.state.screen == 0 &&
        this.props.risk_profile.data.risk_profile != undefined &&
        this.props.user.post_signup_steps != undefined
      ) {
        if (this.props.user.post_signup_steps >= 2) {
          this.props.history.push("/");
        } else {
          this.setState({ screen: 2 });
        }
      }
    }
  };
  componentDidMount = () => {
    if (!this.props.risk_profile.data.risk_profile) {
      this.props.getRiskProfile();
    } else {
      this.setInitialScreen();
    }
  };

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    this.setInitialScreen();
  };

  nextScreen = () => {
    this.setState({ screen: this.state.screen + 1 });
  };

  getScreen = () => {
    switch (this.state.screen) {
      case 1:
      // case 2:
      //   return <DragProfile nextScreen={this.nextScreen} />;
      case 2:
        return <Game nextScreen={this.nextScreen} />;
      case 3:
        return <Questions nextScreen={this.nextScreen} />;
      case 4:
        return <FinalScreen />;
    }
  };
  render() {
    return (
      <Row style={{ margin: "auto", width: "90%" }}>{this.getScreen()}</Row>
    );
  }
}

RiskProfile.propTypes = {
  getRiskProfile: PropTypes.func.isRequired,
  risk_profile: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  risk_profile: state.risk_profile,
  user: state.user.info,
  errors: state.errors
});

export default withRouter(
  connect(
    mapStateToProps,
    { getRiskProfile }
  )(RiskProfile)
);
