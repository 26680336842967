import React from "react";
import {
  Card,
  CardText,
  CardBody,
  CardTitle,
  Collapse,
  Row,
  Col
} from "reactstrap";
class Question6 extends React.Component {
  state = {
    is_open: false
  };
  toggle = () => {
    this.setState({ is_open: !this.state.is_open });
  };
  render() {
    const data = this.props.data;
    return (
      <div onClick={this.toggle} style={{ cursor: "pointer" }}>
        <div style={{ backgroundColor: "#e4e4e4"}} className="p-2 mt-2">
          <i
            className={
              "fa fontsize-5 d-inline-block  align-middle " +
              (this.state.is_open ? "fa-minus" : "fa-plus")
            }
          />
          <span className="d-inline-block pl-2 pt-1">
            <h5>What is the research program and how to go about it?</h5>
          </span>
        </div>

        <Collapse isOpen={this.state.is_open}>
          <Card>
            <CardBody onClick={this.toggle}>
              <CardTitle />
              <CardText className="">
              <p>The research section has a program that you have to finish to successfully complete the FMEP. This is a seven weeks program that runs parallel to portfolio management. In this, you have to finish a task every week. The details of this task are given on the portal itself.</p>


  <table class="table table-bordered table-striped">
     <thead>
      <tr>
        <th style={{textAlign : "left"}}>Week</th>
        <th style={{textAlign : "left"}}>Activity</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>1</td>
        <td>Sector Picks</td>
      </tr>
      <tr>
        <td>2</td>
        <td>Sector 1</td>
      </tr>
      <tr>
        <td>3</td>
        <td>Sector 2</td>
      </tr>
      <tr>
        <td>4</td>
        <td>Research Insight 1</td>
      </tr>
      <tr>
        <td>5</td>
        <td>Research Insight 2</td>
      </tr>
      <tr>
        <td>6</td>
        <td>Research Insight 3 </td>
      </tr>
      <tr>
        <td>7</td>
        <td>Research Insight 4</td>
      </tr>
    </tbody>
  </table>

<p>Only once you complete the previous week, you will be able to unlock the next week.</p>

                           </CardText>
            </CardBody>
          </Card>
        </Collapse>
      </div>
    );
  }
}
export default Question6;
