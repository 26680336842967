import React from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";

import SectorPicks from "./sector_picks1";
import Sector1 from "./sector_1";
import Sector2 from "./sector_2";
import RateResearch1 from "./rate_research_1";
import RateResearch2 from "./rate_research_2";
import RateResearch3 from "./rate_research_3";
import RateResearch4 from "./rate_research_4";
import { Row, Col, Collapse, CardBody, Card } from "reactstrap";
import { getResearch,getnotifyscore,pnotify } from "../../../actions/researchActions";

import { getResearchRating, getTop3Ratings } from "../../../utils/format";

class MyScoresCards extends React.Component {
  state = {
    active: ""
  };

  componentDidMount() {
    this.props.getResearch();
    this.props.getnotifyscore();
  }

  setActive = el =>
    this.setState({
      active: this.state.active == "" || this.state.active != el ? el : ""
    });

  getTotalScore = () => {
    let total = 0;
    let out_of = 0;
    Object.keys(this.props.research).map(week => {
      let data = this.props.research[week];
      let rated = this.props.rated[this.props.research[week].week];
      let vals = getResearchRating(data, rated, true);
      total += vals.total;
      out_of += vals.out_of;
    });

    return ((total / out_of) * 100).toFixed(2);
  };
  render() {
    console.log(this.props.rate_research.required,"test")
    if(this.props.notify)
    {
       if(this.props.notify.length>0)
       {
         this.props.pnotify()
       }
    }
    let total = this.getTotalScore();
 
    return (
      <div className="myscores">
       
       <div className="text-center border border-dark p-3"><img src="/img/click_tick.png" alt="img" className="d-inline-block" />
          <p className="d-inline-block pl-3">Your score is {total}%</p> </div>
        
       
        <div className="border p-2 mt-3">
          <Row className="col-pad">
            <Col xs="3" sm="3" md="3" lg="3">
              <b>Week</b>
            </Col>
            <Col xs="3" className="text-center " sm="3" md="3" lg="3">
              <b>Status</b>
            </Col>
            <Col xs="3" className="text-center" sm="3" md="3" lg="3">
              <b>Weekly Grade</b>
            </Col>
            <Col xs="3" className="text-center" sm="3" md="3" lg="3">
              <b>Rated</b>
            </Col>
          </Row>
        </div>
        {this.props.research[1] ? (
          <SectorPicks
            onSelect={this.setActive}
            pending = {this.props.rate_research.required}
            active={this.state.active}
            data={this.props.research[1]}
            ratings={this.props.ratings[this.props.research[1].id]}
            rated={this.props.rated[this.props.research[1].week]}
          />
        ) : null}
        {this.props.research[2] ? (
          <Sector1
            onSelect={this.setActive}
            active={this.state.active}
            pending = {this.props.rate_research.required}
            data={this.props.research[2]}
            ratings={this.props.ratings[this.props.research[2].id]}
            rated={this.props.rated[this.props.research[2].week]}
          />
        ) : null}
        {this.props.research[3] ? (
          <Sector2
            onSelect={this.setActive}
            active={this.state.active}
            data={this.props.research[3]}
            pending = {this.props.rate_research.required}
            ratings={this.props.ratings[this.props.research[3].id]}
            rated={this.props.rated[this.props.research[3].week]}
          />
        ) : null}
        {this.props.research[4] ? (
          <RateResearch1
            onSelect={this.setActive}
            active={this.state.active}
            pending = {this.props.rate_research.required}
            data={this.props.research[4]}
            ratings={this.props.ratings[this.props.research[4].id]}
            rated={this.props.rated[this.props.research[4].week]}
          />
        ) : null}
        {this.props.research[5] ? (
          <RateResearch2
            onSelect={this.setActive}
            active={this.state.active}
            pending = {this.props.rate_research.required}
            data={this.props.research[5]}
            ratings={this.props.ratings[this.props.research[5].id]}
            rated={this.props.rated[this.props.research[5].week]}
          />
        ) : null}
        {this.props.research[6] ? (
          <RateResearch3
            onSelect={this.setActive}
            active={this.state.active}
            pending = {this.props.rate_research.required}
            data={this.props.research[6]}
            ratings={this.props.ratings[this.props.research[6].id]}
            rated={this.props.rated[this.props.research[6].week]}
          />
        ) : null}
        {this.props.research[7] ? (
          <RateResearch4
            onSelect={this.setActive}
            active={this.state.active}
            pending = {this.props.rate_research.required}
            data={this.props.research[7]}
            ratings={this.props.ratings[this.props.research[7].id]}
            rated={this.props.rated[this.props.research[7].week]}
          />
        ) : null}
      </div>
    );
  }
}

MyScoresCards.propTypes = {
  getResearch: PropTypes.func.isRequired,
  getnotifyscore:PropTypes.func.isRequired,
  pnotify:PropTypes.func.isRequired,
  research: PropTypes.object.isRequired,
  ratings: PropTypes.object.isRequired,
  rated: PropTypes.object.isRequired,
  rate_research:PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  research: state.research.data,
  ratings: state.research.ratings,
  rated: state.research.rated,
  rate_research: state.research.rate_research,
  notify:state.research.notify.data
});

export default connect(
  mapStateToProps,
  { getResearch,pnotify,getnotifyscore }
)(MyScoresCards);
