import { DASHBOARD_DATA_GET,DASHBOARD_NEWS_FEED} from '../actions/types';

const initialState = {data: {}, feed: []};

export default function(state = initialState, action) {
  switch (action.type) {
    case DASHBOARD_DATA_GET:
      return {...state,data: action.payload};
    case DASHBOARD_NEWS_FEED:
      return {...state, feed: action.payload.feed.items};
    default:
      return state;
  }
}