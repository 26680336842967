import { combineReducers } from "redux";
import userReducer from "./userReducer";
import errorReducer from "./errorReducer";
import socketReducer from "./socketReducer";
import loaderReducer from "./loaderReducer";
import dashboardReducer from "./dashboardReducer";
import hrReducer from "./hrReducers";
import scripReducer from "./scripReducers";
import notificationReducer from "./notificationReducer";
import riskProfileReducer from "./riskProfileReducer";
import portfolioReducer from "./portfolioReducer";
import orderBookReducer from "./orderBookReducer";
import gameReducer from "./gameReducer";
import statsReducer from "./statsReducer";
import researchReducer from "./researchReducer";
import resourceReducer from "./resourceReducer";
import profileReducer from "./profileReducer";
import newresearchReducer from "./newresearchReducer";
import leaderReducer from "./leaderReducer";


export default combineReducers({
  user: userReducer,
  errors: errorReducer,
  socketData: socketReducer,
  loader: loaderReducer,
  dashboard: dashboardReducer,
  hr: hrReducer,
  scrips: scripReducer,
  notifications: notificationReducer,
  risk_profile: riskProfileReducer,
  portfolio: portfolioReducer,
  orders: orderBookReducer,
  games: gameReducer,
  stats: statsReducer,
  research: researchReducer,
  newresearch:newresearchReducer,
  resource: resourceReducer,
  profile: profileReducer,
  leaderBoard: leaderReducer,
});