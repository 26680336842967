import { RESEARCH_DATA, RATERESEARCH_DATA,GET_NOTIFY_SCORE } from "../actions/types";

const initialState = {
  data: {},
  rate_research: {},
  notify:{}

};

export default function(state = initialState, action) {
  switch (action.type) {
    case RESEARCH_DATA:
      return { ...state, data: action.payload.data,ratings:action.payload.ratings ,rated:action.payload.rated };
    case RATERESEARCH_DATA:
      return {
        ...state,
        rate_research: action.payload
      };
      case GET_NOTIFY_SCORE:
      return {
        ...state,
        notify: action.payload
      };
    default:
      return state;
  }
}

