import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import {
  getVideos,
  getFinlaticsVideos,
  getAllVideos
} from "../../../actions/resourceActions";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
class VideoLectures extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false,
      videosLec: false,
      allVideos1: false,
      dropdownValue: "Filter",
      val: ""
    };
  }
  componentDidMount() {
    this.props.getAllVideos();
    this.setState({
      allVideos1: true
    });
    this.setState({
      videosLec: true
    });
  }

  toggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  render() {
    if (this.props.allVideos.videos == undefined) return null;

    return (
      <div className="container-fluid mt-5 resource">
        <div className=" video_lectures d-inline-block">
          <h4 className="d-inline-block">
            Browse through latest news and in our video lab{" "}
          </h4>

          <div
            className="d-inline-block float-right"
            style={{ marginRight: "25px" }}
          >
            <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
              <DropdownToggle className="filterBtn ml-3" caret>
                {this.state.dropdownValue}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() => {
                    this.props.getVideos();
                    this.setState({ videosLec: true });
                    this.setState({
                      allVideos1: false
                    });
                    this.setState({
                      dropdownValue: "Arth Labh"
                    });
                  }}
                >
                  Arth Labh
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    this.props.getFinlaticsVideos();
                    this.setState({ videosLec: true });
                    this.setState({
                      allVideos1: false
                    });
                    this.setState({
                      dropdownValue: "Finlatics"
                    });
                  }}
                >
                  Finlatics
                </DropdownItem>

                <DropdownItem
                  onClick={() => {
                    this.props.getAllVideos();
                    this.setState({ videosLec: true });
                    this.setState({
                      allVideos1: true
                    });
                    this.setState({
                      dropdownValue: "Filter"
                    });
                  }}
                >
                  All
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
        {this.state.videosLec &&
        this.props.allVideos.videos != undefined &&
        this.state.allVideos1 ? (
          <div>
            {this.props.allVideos.videos.map((e, i) => (
              <div
                key={i}
                className="borderYT  d-inline-block mt-3 videoWidth"
               
              >
                <div className="text-center">
                  <iframe
                    src={
                      "https://www.youtube.com/embed/" +
                      e.snippet.resourceId.videoId
                    }
                    autostart="false"
                    allowFullScreen="allowFullScreen"
                    className="mt-2"
                    style={{ width: "95%", height: "70%" }}
                  />
                </div>
                <div
                  style={{ width: "100%", height: "20%" }}
                  className="d-table "
                >
                  <span className="d-table-cell align-middle pl-3">
                    {e.snippet.title}
                  </span>
                </div>
                <div
                  style={{ width: "100%", height: "10%" }}
                  className="d-table "
                >
                  <span className="d-table-cell align-middle pl-3">
                    <b>
                      Uploaded at :
                      {moment(e.snippet.publishedAt).format("YYYY-MM-DD")}
                    </b>
                  </span>
                </div>
              </div>
            ))}
          </div>
        ) : (
          ""
        )}

        {this.state.videosLec &&
        this.props.videos.videos != undefined &&
        this.state.allVideos1 == false ? (
          <div>
            {this.props.videos.videos.items.map((e, i) => (
              <div
                key={i}
                className="borderYT  d-inline-block mt-3 videoWidth"
              
              >
                <div className="text-center">
                  <iframe
                    src={
                      "https://www.youtube.com/embed/" +
                      e.snippet.resourceId.videoId
                    }
                    autostart="false"
                    allowFullScreen="allowFullScreen"
                    className="mt-2"
                    style={{ width: "95%", height: "70%" }}
                  />
                </div>
                <div
                  style={{ width: "100%", height: "20%" }}
                  className="d-table "
                >
                  <span className="d-table-cell align-middle pl-3">
                    {e.snippet.title}
                  </span>
                </div>
                <div
                  style={{ width: "100%", height: "10%" }}
                  className="d-table "
                >
                  <span className="d-table-cell align-middle pl-3">
                    <b>
                      Uploaded at :
                      {moment(e.snippet.publishedAt).format("YYYY-MM-DD")}
                    </b>
                  </span>
                </div>
              </div>
            ))}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

VideoLectures.propTypes = {
  getVideos: PropTypes.func.isRequired,
  getAllVideos: PropTypes.func.isRequired,

  getFinlaticsVideos: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  videos: state.resource.videos,
  allVideos: state.resource.allVideos
});

export default connect(
  mapStateToProps,
  { getVideos, getFinlaticsVideos, getAllVideos }
)(VideoLectures);
