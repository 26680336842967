import React from "react";

import {
  Card,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  Row,
  Col,
  Collapse
} from "reactstrap";
import { newgetResearchRating,newgetTop3Ratings } from "../../../utils/format";

class RateSectorProject2 extends React.Component {
  render() {
    const data = this.props.data;
    if (!data) return null;
    return (
      <div
        className="myScoreCards"
        onClick={() => this.props.onSelect("sector_project_2")}
        style={{ cursor: "pointer" }}
      >
        <Row style={{ backgroundColor: "#e4e4e4" }} className="p-2 mt-2">
          <Col lg="3" md="3" sm="3" xs="3">
            <i
              className={
                "fa fontsize-5 d-inline-block  align-middle " +
                (this.props.active == "sector_project_2"
                  ? "fa-minus"
                  : "fa-plus")
              }
            />
            <span className="d-inline-block">
              <b>Sector-Project-2</b>
            </span>
          </Col>

          <Col className="text-center" lg="3" md="3" sm="3" xs="3">
            <span>
              <b>{data ? "Submitted" : "Locked"}</b>
            </span>
          </Col>
          <Col className="text-center" lg="3" md="3" sm="3" xs="3">
          <span> <b> {this.props.rated && this.props.rated >=3
                ?newgetResearchRating(data, this.props.rated)
                : "Please complete rate research"}</b></span>
          </Col>
          <Col className="text-center" lg="3" md="3" sm="3" xs="3">
            <span>
              {this.props.rated && this.props.rated >= 1
                ? Math.round(this.props.rated * 33.333) + "%"
                : "--"}
            </span>
          </Col>
        </Row>
        <Collapse isOpen={this.props.active == "sector_project_2"}>
          <Card>
            <CardBody>
              <CardTitle>Your File :</CardTitle>
              <div className="text-center">
                <a
                  href={`${window.BASE_URL}/uploads/${
                    this.props.data.filename
                  }`}
                  download
                >
                  <i className="fa fa-download" style={{ fontSize: "70px" }} />

                  <h5>
                    <span style={{ color: "blue", fontSize: "20px" }}>
                      Click
                    </span>{" "}
                    here to download
                  </h5>
                </a>
              </div>
              <hr />
              <CardTitle className="pt-2">Peer Suggestion:</CardTitle>
              {newgetTop3Ratings(data).map((e, i) =>
                e.comment.trim() == "" ? null : (
                  <Row className="pb-3" key={e + "" + i}>
                    <Col xs="1">
                      <div className="text-center">
                        <span
                          className={"dot d-inline-block rate_research_by" + i}
                        />
                      </div>
                    </Col>
                    <Col xs="11" className="padding-3">
                      <p>{e.comment}</p>
                    </Col>
                  </Row>
                )
              )}
            </CardBody>
          </Card>
        </Collapse>
      </div>
    );
  }
}
export default RateSectorProject2;
