import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter,Row, Col,Table, Input, Label } from 'reactstrap';


export default class AlertModal extends React.Component {
   
    render(){
        return (
            <Modal isOpen={this.props.show} backdrop={true} size="md">
                <ModalBody>
                    {this.props.children}
                </ModalBody>
                <ModalFooter> 
                        <Button className="btn site-gray" onClick={this.props.hideModal} style={{width: '150px', fontSize: '18px!important'}}>OK</Button>
                </ModalFooter>
            </Modal>
                
        );
    }
}