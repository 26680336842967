import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactLoader from 'react-loader';
import { withRouter } from "react-router-dom";

class Loader extends Component {
  constructor() {
    super();
  }

  render() {
    return (
      <ReactLoader loaded={!this.props.loader.show}>
        {this.props.children}
      </ReactLoader>
    );
  }
}


Loader.propTypes = {
  loader: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  loader: state.loader
});

export default withRouter(connect(mapStateToProps, { })(Loader));

