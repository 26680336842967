import React from "react";

// import axios from "axios";
import { Col, Row, Collapse, Card, CardBody, Label, Input } from "reactstrap";

class UploadResume extends React.Component {
  state = {
    selectedFile: null
  };

  onChangeHandler = async event => {
    this.setState({ selectedFile: event.target.files[0] });
    const data = await new FormData();
    await data.append("file", this.state.selectedFile);
    await this.props.resumeUpload(data, true);
  };

  render() {
    return (
      <div>
        <div className="profileCollapse mt-5">
          <Row>
            <Col sm="6" lg="6" md="6" className="d-table-cell">
              <img src="/img/resume.svg" alt="education" />
              <span>Upload Resume</span>
            </Col>
            <Col sm="6" lg="6" md="6" className="text-right">
              <button
                className="btn"
                onClick={() => {
                  this.props.collapseValue("resume");
                }}
              >
                Add
              </button>
            </Col>
          </Row>
          <Collapse isOpen={this.props.active == "resume"}>
            <Card>
              <CardBody>
                <Label for="uploadFile">Upload your resume</Label>
                <Input
                  type="file"
                  name="file"
                  onChange={this.onChangeHandler}
                />
              </CardBody>
            </Card>
          </Collapse>
        </div>
      </div>
    );
  }
}

export default UploadResume;
