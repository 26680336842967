import React from "react";
import RateResearch1 from "./rate_research1";
import RateResearch2 from "./rate_research2";
import RateResearch3 from "./rate_research3";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getRateResearch } from "../../../actions/newresearchActions";
class RateResearch extends React.Component {
  componentDidMount() {
    this.props.getRateResearch();
  }
  render() {
    if (!this.props.rate_research.week) return null;

    let week = this.props.rate_research.week;
    if (week == 1)
      return (
        <RateResearch1
          rate_research={this.props.rate_research}
          showInvalidFormAlert={this.props.showInvalidFormAlert}
        />
      );
    else if (week > 1 && week <= 3)
      return (
        <RateResearch2
          rate_research={this.props.rate_research}
          showInvalidFormAlert={this.props.showInvalidFormAlert}
        />
      );
    else if (week > 3)
      return (
        <RateResearch3
          rate_research={this.props.rate_research}
          showInvalidFormAlert={this.props.showInvalidFormAlert}
        />
      );
  }
}
RateResearch.propstype = {
  getRateResearch: PropTypes.func.isRequired,
  rate_research: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    rate_research: state.newresearch.rate_research
  };
};
export default connect(
  mapStateToProps,
  { getRateResearch }
)(RateResearch);
