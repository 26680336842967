import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { getFinscore } from "../../actions/portfolioActions";
class PortfolioScore extends React.Component {
  componentDidMount() {
    this.props.getFinscore();
  }
  render() {
    if (this.props.showScore == false || this.props.finscore > 0) return null;

    let data = this.props.finscore;
    return (
      <div className="portfolioScore">
        <div className="rectangleFinScore">
          <h5>Finscore</h5>
          <div className="sCircle">
            <p>i</p>
            <span className="tooltiptext">
              This score is on a 100 point scale and is arrived by calculating
              your portfolio performance with the BSE 500 index
            </span>
          </div>
          <div className="circleFin">
            <h6>{data ? parseFloat(data.Finscore).toFixed(2) : "5"}</h6>
          </div>
          <div
            className="removeIcon"
            onClick={() => {
              this.props.removePortfolio(false);
            }}
          >
            <h6>x</h6>
          </div>
        </div>
      </div>
    );
  }
}

PortfolioScore.propTypes = {
  getFinscore: PropTypes.func.isRequired,
  finscore: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  finscore: state.portfolio.finscore
});

export default connect(
  mapStateToProps,
  { getFinscore }
)(PortfolioScore);
