import axios from "axios";

import { ERRORS_GET, PORTFOLIO_LIST, USER_FINSCORE,USER_STOCK } from "./types";

export const getPortfolios = ({ segment, industry, sort }) => dispatch => {
  axios
    .get("/api/portfolio/list", {
      params: { segment: segment, sort: sort, industry: industry }
    })
    .then(res => {
      dispatch({
        type: PORTFOLIO_LIST,
        payload: {
          portfolios: res.data.portfolio,
          myScrips: res.data.myScrips,
          segment: segment,
          sort: sort,
          industry: industry
        }
      });
    })
    .catch(err =>
      dispatch({
        type: ERRORS_GET
      })
    );
};

export const postSignupDone = () => dispatch => {
  axios
    .patch(`/api/portfolio/setup-done`)
    .then(res => {})
    .catch(() => {});
};

export const getFinscore = () => dispatch => {
  axios
    .get("/api/portfolio/finscore")
    .then(res => {
      dispatch({
        type: USER_FINSCORE,
        payload: res.data
      });
    })
    .catch(err => {
      console.log(err);
    });
};

export const getstockstatus = () => dispatch => {
  axios
    .get("/api/portfolio/stockrisk")
    .then(res => {
     // console.log(res.data)
      dispatch({
        type: USER_STOCK,
        payload: res.data
      });
    })
    .catch(err => console.log(err));
};
