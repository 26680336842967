import axios from "axios";

import { ERRORS_GET,LEADER_BOARD } from "./types";

export const getLeaderBoard = ( eventname) => dispatch => {
    console.log('hey iam working ----------------------------------',`${eventname}`)
  axios
    .get("/api/portfolio/event", {
      params: { eventname:eventname, }
    })
    .then(res => {
        console.log(res,'sdfghjklertyuio-----------------------------------sdfdfghjklgh')
        // if(res.data != undefined){
            
        // }
      dispatch({  
        type: LEADER_BOARD,
        payload: {
          eventDetails: res.data.data,
          eventname:eventname
        }
      });
    })
    .catch(err =>{
        console.log(err);
      dispatch({
        type: ERRORS_GET
      })}
    );
};


