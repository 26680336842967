import React from "react";

class FundamentalAnalysis extends React.Component {
  render() {
    return (
      <div className="container resource">
        <div className="mt-5">
          <h4 className="text-center font-weight-bold  mb-4">
            MODULE 5 - FUNDAMENTAL & TECHNICAL ANALYSIS
          </h4>
          <hr />
        </div>
        <p>
          A rational investor is interested in buying financial security which
          would appreciate in the future or to know whether there are any stocks
          that are undervalued, so as to buy them and if there are any stocks in
          his portfolio that are overvalued, so as to sell them before their
          market prices begin to fall. For this evaluation, there are two
          approaches to analyze a company’s stock:
        </p>

        <ol>
          <li style={{ fontWeight: "lighter" }}>Fundamental Analysis</li>
          <li style={{ fontWeight: "lighter" }}>Technical Analysis</li>
        </ol>
        <p>
          We will begin with understanding the concept of fundamental analysis.
        </p>
        <div className="mt-5">
          <h5 className="text-center mb-4">
            <u>
              <b>FUNDAMENTAL ANALYSIS</b>
            </u>
          </h5>
        </div>
        <p>
          Let us assume that there is an investor named Jay who is new to the
          stock market and is determined to learn the necessary details about
          investing in stocks. Before investing in any firm, it is imperative
          for Jay to know the fundamentals of the company which he is interested
          in, the ongoing economic situation in the country, the prevailing
          situation in the company’s industry. Performing a fundamental analysis
          on a stock involves all of the above-mentioned tasks and therefore
          plays an important role in influencing an investor’s strategy.
          <br />
          The fundamental analysis involves examination of all the underlying
          forces like changes in industries, companies, the economy which
          affects a company's actual business and its future prospects. It helps
          in determining the intrinsic value of a security and helps us in
          understanding how the current market price of a stock is dependent on
          its capacity of generating income for the investors in the future.
        </p>
        <p>
          There are two ways in which a fundamental analysis can be performed :
        </p>

        <ol>
          <li style={{ fontWeight: "lighter" }}>
            By performing a top-down approach
          </li>
          <li style={{ fontWeight: "lighter" }}>
            By performing a bottom-up approach
          </li>
        </ol>
        <p>
          The term fundamental analysis simply refers to the analysis of the
          economic and financial well being of a financial entity as opposed to
          only its price movements. The end goal of fundamental analysis is to
          estimate the intrinsic or fair value of a stock and to forecast its
          future value.
        </p>
        <div>
          <h6 className="mb-2">
            <u>
              <b>TOP-DOWN APPROACH</b>
            </u>
          </h6>
          <p>
            We will first understand what does it mean to perform a top-down
            approach on stocks. As the name suggests in a top-down approach, we
            first start with the bigger picture, which is the economic health of
            a country, and conclude with the individual stocks. Because the
            top-down approach begins at the top, the first step is to determine
            the economy's health.
          </p>
          <div className="ml-5">
            <p>
              <b>STEP-1 :</b>
            </p>
            <p>
              The first and foremost step involved in performing fundamental
              analysis of financial securities is to examine the economic
              condition of the country in which the company operates. We can
              consider that for instance, recently, the government of India had
              announced in its budget that it would be increasing its spending
              on public goods and also will execute some of the pending public
              infrastructure projects. Continuing with our example of investor
              Jay, let us suppose that Jay has an excess amount of funds with
              him and wants to park his funds in equities.
              <br />
              Before investing in any stock, Jay will first conduct fundamental
              analysis on the stock he desires to buy. As we know, in a top-down
              approach, Jay will have to start by looking at the bigger picture
              first and will start with analyzing the prevailing economic
              condition. After hearing about the announcements made by the
              government, Jay realizes that with a rise in government spending,
              there will be an increase in economic activity and economic growth
              and therefore, this will be a good time to invest as the prospects
              of the overall economic trend is positive. Next, he will have to
              decide the sector in which he should invest. The government
              announcement will directly boost the operations of the firms
              working in the infrastructure sector because infrastructure
              companies will be involved in the execution of the government-led
              infrastructure projects. As more and more private corporations
              working in the infrastructure sector would be given the contracts
              by the government, investors will anticipate that the revenue
              generated by these companies will rise and as a result, they will
              start investing in these companies, driving their stock prices up.
              <br />
              Therefore any rational investor like Jay would be interested in
              purchasing stocks of companies operating in the infrastructure
              sector.
            </p>
            <p>
              <b>STEP-2 :</b>
            </p>
            <p>
              After analyzing the economic condition and selecting the
              infrastructure sector, Jay will be performing an industry analysis
              to select a particular industry for investing. There are many
              industries operating in the infrastructure sector like
              engineering, utilities, telecom, services, conglomerates,
              cement/construction. Now, while performing an industry analysis,
              Jay will have to consider factors such as the relationship between
              the economy and the industry, whether the demand for the product
              is sustainable, the nature of the competition faced by the firms
              in the industry, etc. Because of the increased government
              spending, Jay would be planning to invest in industries that would
              be gaining the most from economic growth. In this case, industries
              like cement, engineering, utilities will be directly benefiting
              from the government’s infrastructure projects as these industries
              will be involved in the execution of these projects. Let us
              suppose that after a careful evaluation, Jay selects the cement
              industry for looking for stocks to invest in.
            </p>
            <p>
              <b>STEP-3 :</b>
            </p>
            The next step involved is to choose a company from a multiplex of
            cement manufacturing companies. It is necessary to select a company
            that is outperforming its peers from the industry in a given
            economic situation. While cherry-picking a particular company, an
            investor has to evaluate various aspects of the company like its
            management structure, whether the board of directors is competent,
            past experience of the management directors, also the financial
            statements released by the company - balance sheet, profit and loss
            statements, and annual reports released by the company which
            contains information regarding future projects being considered by
            the company.
            <br />
            Continuing with our example, let us suppose that Jay has shortlisted
            3 cement companies - Ultratech Cement, ACC Cement, Ambuja Cement.
            Now, from these 3 companies, Jay has to decide which company he
            wants to invest in. He will single out a company by evaluating each
            company’s performance on the basis of the parameters which were
            mentioned above (competence of directors, financial statements,
            etc). For instance, Jay will compare the financial ratios of the 3
            companies, their future projects and their market share among
            others. Finally, we can consider that after a careful evaluation,
            Jay has chosen to invest in the stocks of ACC cement.
          </div>
          <p>
            As we can see here, Jay has conducted a Top - Down approach by
            starting with economy-wide factors, moving on to industries and then
            finally narrowing it down to a specific stock.
          </p>
        </div>
        <div>
          <h6 className="mb-2">
            <u>
              <b>BOTTOM-UP APPROACH</b>
            </u>
          </h6>
          <p>
            Moving forward, we will now have a look at the bottom-up approach of
            performing a fundamental analysis. The bottom-up approach begins
            with the investor focusing on individual stocks and then moves
            towards the industry in which the company operates and then the
            economic situation of the country. In this approach, an investor
            starts by looking at the company fundamentals, its financial health,
            its financial statements, and other factors. More importance is
            given to company-specific factors in the bottom-up approach. The
            only distinction between a top-down approach and a bottom-up
            approach is that in the former approach, investors start by
            analyzing the economic health of a country and conclude by focussing
            on the fundamentals of the company whereas the exact opposite
            happens in the latter approach.
          </p>
          <div className="ml-5">
            <p>
              <b>STEP-1 :</b>
            </p>
            <p>
              Let us assume that Jay is now interested in a specific company -
              say TCS, whose name he had come across in the news for a favorable
              change in the management of the company and is, therefore,
              considering investing in that company. After selecting TCS, Jay
              will perform a company analysis on TCS by analyzing its financial
              statements, calculating its financial ratios, competency of the
              management of the company and its revenue growth.
            </p>
            <p>
              <b>STEP-2 :</b>
            </p>
            <p>
              Once Jay concludes that the overall financial health and business
              performance of TCS is good, he goes ahead to compare TCS with its
              peers to check if the company is able to stay ahead of its
              competitors. Since TCS is an IT firm, Jay compares its performance
              with that of L&T Infotech and Infosys. Remember that it is
              essential that Jay compares all three companies in an unbiased
              manner and across similar parameters. After comparing TCS with its
              competitors, Jay deduces that the company is, in fact,
              outperforming its competitors and seems like a better investment
              than its competitors.
              <br />
              After looking at TCS and its peers, Jay looks to analyze the IT
              industry; since this is where the company is operating. Remember
              that TCS had caught his eye due to a management change. This is a
              factor internal to the company and therefore, it is extremely
              vital to move on to the next stage and analyze the IT industry to
              gauge external factors impacting the company. Here, his focus
              changes to analyzing the industry trends, industry growth rate and
              the threat of various factors impacting the industry as a whole.
              Only after he is convinced about the growth of the industry as a
              whole will he be confident about purchasing TCS stocks.
            </p>
            <p>
              <b>STEP-3 :</b>
            </p>
            At the final stage, Jay decides to have a look at the prevailing
            economic situations in both TCS’s home country, India and the major
            markets that the company is operating in. It is vital to check
            whether any political or economic changes in India or any of TCS’s
            major markets can have an impact on the company’s business.
            Remember, sectors, industries, and companies have varied
            sensitivities to economic and political changes. Therefore, it is
            essential to conclude the bottoms up approach with an analysis of
            this.
          </div>
          <p>
            We can classically conclude that Top - Down Approach can be called
            an EIC approach as it starts with Economy, moves towards Industry
            and then a specific Company, while the Bottom-Up Approach is the
            exact opposite.
          </p>
        </div>
      </div>
    );
  }
}

export default FundamentalAnalysis;
