import React from "react";
import { ResponsiveContainer, PieChart, Pie, Tooltip, Cell,Label } from "recharts";

const COLORS = 
  [
    "#0e91c0",
    "#82cad3",
    "#5aa0e8",
    "#95a2db"
  ];

const RADIAN = Math.PI / 180;
function CustomLabel({viewBox, value1, value2}){
  const {cx, cy} = viewBox;
  return (
   <text x={cx} y={cy} fill="#707070" className="recharts-text recharts-label" textAnchor="middle" dominantBaseline="central">
      <tspan alignmentBaseline="middle" fontWeight="600" fontSize="18">{value1}</tspan>
   </text>
  )
}

export default class StatChart extends React.Component {
  renderCustomizedLabel3 = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.7;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    const p = (percent * 100).toFixed(2);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${p}%`}
      </text>
    );
  };

  
  onPieEnter = e => {};
  render() {
    var data = this.props.data.map(el => {
      return {
        name: el.name,
        value: el.value,
        key: el.color
      };
    });
    return (
      <ResponsiveContainer minWidth={400} minHeight={240}>
        <PieChart width={400} height={240} onMouseEnter={this.onPieEnter}>
          <Pie
             innerRadius={60} 
            isAnimationActive={false}
            dataKey="value"
            data={data}
            cx={200}
            cy={120}
            labelLine={false}
            outerRadius={120}
            fill="#8884d8"
          >
            {data.map((entry, index) => (
              <Cell
                key={entry.key}
                fill={COLORS[index]}
              />
            ))}
            

          </Pie>
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
    );
  }
}
