import React from "react";
import { Row, Col, Card, Button } from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Line } from "react-chartjs";
import Chart from "chart.js";
import { Link, withRouter } from "react-router-dom";
import { formatCurrency } from "../../utils/format";
import { getCurrentUserDetails } from "../../actions/userActions";

import { getGameStats } from "../../actions/gamesActions";

class GamesPerformance extends React.Component {
  componentDidMount() {
    this.props.getCurrentUserDetails();
    this.props.getGameStats("g" + this.props.game);
  }

  getEarn = wins =>
    wins > 0
      ? wins * (this.props.game == 1 ? 500 : 400) -
        (15 - wins) * (this.props.game == 1 ? 150 : 100)
      : 0;

  render() {
    const { wins } = this.props.stats;

    if (wins == undefined) return null;
    let last_wins = 0;
    let prev_wins = 0;

    if (wins.length > 0 && wins.length <= 2) {
      last_wins = wins[wins.length - 1].wins;
    }
    if (wins.length > 2) {
      prev_wins = wins[wins.length - 2].wins;
    }
    let last_earn = this.getEarn(last_wins);
    let prev_earn = this.getEarn(prev_wins);

    const wins_data = {
      labels: [],
      datasets: [
        {
          label: "Wins",
          data: [],
          fill: false,
          borderColor: "rgba(0,0,0,1)",
          lineTension: 0,
          pointBorderColor: "rgba(0,0,0,1)",
          pointBackgroundColor: "rgba(0,0,0,1)"
        }
      ]
    };
    wins.map((el, i) => {
      wins_data.labels.push(`Game ${i + 1}`);
      wins_data.datasets[0].data.push(el.wins);
    });

    return (
      <Col
        style={{
          overflow: "hidden"
        }}
        md="12"
        sm="12"
        className="pb-5"
      >
        <Row style={{ paddingBottom: "10px" }}>
          <Col sm="12" md="8" style={{ textAlign: "center" }}>
            <h3>Winning Stats</h3>
            <Card className="p-4">
              <Line
                data={wins_data}
                options={{
                  legend: { display: false },
                  responsive: true,
                  scales: {
                    xAxes: [
                      {
                        display: true,
                        scaleLabel: {
                          display: true,
                          labelString: "Games"
                        },
                        ticks: {
                          beginAtZero: true
                        }
                      }
                    ],
                    yAxes: [
                      {
                        display: true,
                        scaleLabel: {
                          display: true,
                          labelString: "Wins"
                        },
                        ticks: {
                          beginAtZero: true
                        }
                      }
                    ]
                  }
                }}
                redraw
              />
            </Card>

            <Row>
              <Col md="12" xl="6" style={{ textAlign: "center" }}>
                <Button
                  onClick={this.props.resetGame}
                  style={{
                    width: "225px",
                    margin: "40px 40px 0px 20px",
                    fontSize: "24px",
                    padding: "5px 25px"
                  }}
                >
                  PLAY AGAIN
                </Button>
              </Col>

              <Col md="12" xl="6" style={{ textAlign: "center" }}>
                <Button
                  onClick={this.props.goFront}
                  style={{
                    width: "225px",
                    margin: "40px 40px 0px 20px",
                    fontSize: "24px",
                    padding: "5px 25px"
                  }}
                >
                  GAME PAGE
                </Button>
              </Col>
            </Row>
          </Col>
          <Col md="4">
            <Card
              className="gameCd1"
              style={{
                maxWidth: "440px",
                height: "320px",
                marginLeft: "auto",
                borderRadius: "5px"
              }}
            >
              <table
                className="scoreboard-tbl"
                style={{
                  width: "100%",
                  borderRadius: "5px",
                  margin: "1px",
                  fontSize: "16px"
                }}
              >
                <tbody>
                  <tr
                    style={{
                      color: "#fff",
                      fontWeight: 700,
                      backgroundColor: last_earn > prev_earn ? "green" : "red"
                    }}
                  >
                    <td
                      style={{
                        borderRadius: "5px 0 0 0",
                        padding: "15px!important"
                      }}
                    >
                      ROUND WINNINGS
                    </td>
                    <td
                      style={{
                        textAlign: "right",
                        borderRadius: "0px 5px 0 0",
                        padding: "15px!important"
                      }}
                    >
                      {formatCurrency(last_earn, true)}(
                      {(last_earn > prev_earn ? "▲" : "▼") +
                        formatCurrency(last_earn - prev_earn, true)}
                      )
                    </td>
                  </tr>
                  <tr
                    style={{
                      color: "#fff",
                      fontWeight: 700,
                      backgroundColor: last_earn > 0 ? "green" : "red",
                      borderTop: "1px solid #e0e0e0"
                    }}
                  >
                    <td style={{ padding: "15px!important" }}>CASH IN HAND</td>
                    <td
                      style={{ textAlign: "right", padding: "15px!important" }}
                    >
                      {formatCurrency(this.props.user.info.total_money, true)}(
                      {(last_earn > 0 ? "▲" : "▼") +
                        formatCurrency(last_earn, true)}
                      )
                    </td>
                  </tr>
                </tbody>
              </table>
            </Card>
          </Col>
        </Row>
      </Col>
    );
  }
}

GamesPerformance.propTypes = {
  user: PropTypes.object.isRequired,
  getGameStats: PropTypes.func.isRequired,
  getCurrentUserDetails: PropTypes.func.isRequired,
  wins: PropTypes.array.isRequired,
  stats: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  user: state.user,
  wins: state.games.stats.wins,
  stats: state.games.stats
});

export default withRouter(
  connect(
    mapStateToProps,
    { getGameStats, getCurrentUserDetails }
  )(GamesPerformance)
);
