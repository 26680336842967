import React from "react";
import {
  Col,
  Row,
  Collapse,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input
} from "reactstrap";

class Employee extends React.Component {
  state = {
    count: 0,
    months: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec"
    ],
    empDetails: [
      {
        jobType: "",
        comName: "",
        designation: "",
        fromExpY: "",
        fromExpM: "",
        tillExpY: "",
        tillExpM: "",
        salL: "",
        salT: ""
      }
    ]
  };
  handleSubmitEmp = e => {
    this.props.empData(this.state.empDetails, true);
  };

  handleRemove = (e, i) => {
    e.preventDefault();
    this.state.empDetails.splice(i, 1);
    this.setState({ count: i - 1 });
    this.setState({ empDetails: this.state.empDetails });
  };

  selectYears() {
    let years = [];
    var d = new Date();
    var n = d.getFullYear();
    for (let i = n; i >= n - 10; i--) {
      years.push(
        <option key={i} value={i} className="text-center">
          {i}
        </option>
      );
    }
    return years;
  }
  createSelectItems() {
    let items = [];
    for (let i = 0; i <= 12; i++) {
      items.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
    return items;
  }
  createSelectThousands() {
    let items = [];
    for (let i = 0; i <= 99; i += 10) {
      items.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
    return items;
  }
  addMore() {
    this.setState({
      empDetails: [...this.state.empDetails, {}]
    });
  }
  addCount() {
    this.setState({
      count: this.state.count + 1
    });
  }
  render() {
    return (
      <div>
        <div className="profileCollapse mt-5">
          <Row>
            <Col sm="6" lg="6" md="6" className="d-table-cell">
              <img src="/img/employee.svg" alt="employee" />
              <span>Employment Details</span>
            </Col>
            <Col sm="6" lg="6" md="6" className="text-right">
              <button
                className="btn"
                onClick={() => {
                  this.props.collapseValue("employee");
                }}
              >
                Add
              </button>
            </Col>
          </Row>
          <Collapse isOpen={this.props.active == "employee"}>
            <Card>
              <CardBody>
                <Form onChange={this.handleSubmitEmp}>
                  {this.state.empDetails.map((e, i) => {
                    return (
                      <div key={i}>
                        <FormGroup>
                          <Label for="jobType">Job Type</Label>

                          <select
                            required
                            type="jobType"
                            name="jobType"
                            id="jobType"
                            onChange={e => {
                              this.state.empDetails[i].jobType = e.target.value;
                            }}
                          >
                            <option>Select job type</option>
                            <option>Full Time</option>
                            <option>Internship</option>
                          </select>
                        </FormGroup>
                        <FormGroup>
                          <Label for="companyName">Company Name</Label>
                          <Input
                            type="companyName"
                            name="companyName"
                            id="companyName"
                            onChange={e => {
                              this.state.empDetails[i].comName = e.target.value;
                            }}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label for="designation">Designation</Label>
                          <Input
                            type="designation"
                            name="designation"
                            id="designation"
                            onChange={e => {
                              this.state.empDetails[i].designation =
                                e.target.value;
                            }}
                          />
                        </FormGroup>
                        <FormGroup>
                          <div className="d-inline-block">
                            <Label className="d-block " for="experience">
                              Start Date
                            </Label>

                            <Input
                              type="select"
                              name="months"
                              id="months"
                              style={{ width: "42%" }}
                              className="d-inline-block"
                              onChange={e => {
                                this.state.empDetails[i].fromExpM =
                                  e.target.value;
                              }}
                            >
                              <option hidden>
                                {this.state.fromExpM
                                  ? this.state.fromExpM
                                  : "Months"}
                              </option>
                              {this.state.months.map((e, i) => (
                                <option key={i}>{e}</option>
                              ))}
                            </Input>
                            <Input
                              type="select"
                              name="years"
                              id="years"
                              style={{ width: "46%" }}
                              className="d-inline-block ml-3 "
                              onChange={e => {
                                this.state.empDetails[i].fromExpY =
                                  e.target.value;
                              }}
                            >
                              <option hidden>
                                {this.state.fromExpY
                                  ? this.state.fromExpY
                                  : "Years"}
                              </option>
                              {this.selectYears()}
                            </Input>
                          </div>

                          <div className="d-inline-block ">
                            <Label className="d-block" for="experience">
                              End Date
                            </Label>

                            <Input
                              type="select"
                              name="months"
                              id="months"
                              style={{ width: "46%" }}
                              className="d-inline-block "
                              onChange={e => {
                                this.state.empDetails[i].tillExpM =
                                  e.target.value;
                              }}
                            >
                              <option hidden>
                                {this.state.tillExpM
                                  ? this.state.tillExpM
                                  : "Months"}
                              </option>
                              {this.state.months.map((e, i) => (
                                <option key={i}>{e}</option>
                              ))}
                            </Input>
                            <Input
                              type="select"
                              name="years"
                              id="years"
                              style={{ width: "49%" }}
                              className="d-inline-block ml-2"
                              onChange={e => {
                                this.state.empDetails[i].tillExpY =
                                  e.target.value;
                              }}
                            >
                              <option hidden>
                                {this.state.tillExpY
                                  ? this.state.tillExpY
                                  : "Years"}
                              </option>
                              {this.selectYears()}
                            </Input>
                          </div>
                        </FormGroup>
                        <FormGroup>
                          <Label className="d-block" for="salary">
                            Salary (only if full time)
                          </Label>
                          <Input
                            type="select"
                            name="lakhs"
                            id="lakhs"
                            className="d-inline-block"
                            style={{ width: "10%" }}
                            onChange={e => {
                              this.state.empDetails[i].salL = e.target.value;
                            }}
                          >
                            <option>Lakhs</option>
                            {this.createSelectItems()}
                          </Input>
                          <Input
                            type="select"
                            name="thousands"
                            id="thousands"
                            className="d-inline-block ml-3"
                            style={{ width: "10%" }}
                            onChange={e => {
                              this.state.empDetails[i].salT = e.target.value;
                            }}
                          >
                            <option>Thousands</option>
                            {this.createSelectThousands()}
                          </Input>
                        </FormGroup>

                        <hr className="hr_bottom" />
                      </div>
                    );
                  })}
                  <div>
                    <button
                      className="btn btn-1 "
                      type="button"
                      onClick={e => {
                        this.addMore(e);
                        this.addCount();
                      }}
                    >
                      Add Employment
                    </button>
                    {this.state.count > 0 ? (
                      <div className="mr-2 d-inline-block float-right">
                        <button
                          className="btn btn-4"
                          onClick={e => {
                            this.handleRemove(e, this.state.count);
                          }}
                        >
                          Remove
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Collapse>
        </div>
      </div>
    );
  }
}

export default Employee;
