import React from "react";
import { Button } from "reactstrap";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import { saveResearch } from "../../../actions/researchActions";
class ResearchInsight1 extends React.Component {
  state = {
    selectedFile: null,
    submitted: false
  };
  onChangeHandler = e => {
    this.setState({
      selectedFile: e.target.files[0],
      filename: e.target.files[0].name,
      loaded: 0
    });
  };

  triggerInputFile = () => this.fileInput.click();

  handleSubmit = e => {
    e.preventDefault();
    if (this.state.selectedFile) {
      const data = new FormData();
      data.append("selectedFile", this.state.selectedFile);
      data.append("week", 4);
      this.props.saveResearch(data);
      this.setState({ submitted: true });
    } else {
      this.props.showInvalidFormAlert();
    }
  };
  render() {
    console.log(this.props.research[3],"myresearch")
    if (this.state.submitted || this.props.research[4]) {
      if (!this.props.research[4]) {
        return <div>Please Wait...</div>;
      }
      return (
        <div>
          <div>
            Download:{" "}
            <a
              download
              className="text-primary"
              href={"/uploads/" + this.props.research[4].filename}
            >
              {this.props.research[4].originalname}
            </a>
          </div>
          <div>
            <div className="submitted text-center">
              <Button className="btn btn-light ">Submitted </Button>
            </div>
          </div>
        </div>
      );
    }
    return (
      <form
        onSubmit={this.handleSubmit}
        method="post"
        encType="multipart/form-data"
      >
        <div className="text-justify">
          <p>
            <div className ="row">
            <div className = "col-md-6"> <h4>Sector1:{this.props.research[2].sector1} </h4></div>
            <div className = "col-md-6 text-center"> <h4>Company1:{this.props.research[2].segment1_comp}</h4></div>
            </div>
           
            <br></br>
            We hope you have received feedback and ratings with regard to the
            sectors and companies that have been picked by you. Next is the
            'Research Insight' activity .
          </p>
          <p className="pt-3">
            This is a critical activity in your research program and carries the
            maximum weightage in your Research score. Your research score shall
            be displayed in your overall certification .
          </p>
          <p className="pt-3">
            In following weeks, you have to prepare a report on each of the
            companies that you have picked in the following structure :
          </p>
          <div>
            <ul className="spacing">
              <li>
                General Overview - this can be drawn from the brief write ups
                that you have given about the selected company . (100 words)
              </li>
              <li>
                Management of the company - this section shall include
                management qualifications, background and stability of
                management (duration of their stay with the company).
              </li>
              <li>
                You can also shed light on whether the company is family
                operated or professionally operated. (100 150 words)
              </li>
              <li>
                Strengths, Weaknesses, Opportunities & Threats (SWOT) analysis
                of the company (400 words)
              </li>
              <li>
                Competitive Analysis - list down 2 - 3 close competitors and
                company’s position with regard to them (300 words)
              </li>
              <li>
                Conclusion and way forward for the company - Based on your
                analysis, what do you feel would be the way forward for the
                company (100 words)
              </li>
            </ul>
          </div>
          <p>
            Total word count of the document would be 1000 - 1500 words, per
            company. Top quality reports will be published on the 'Publications'
            section on finlatics.com. If your report is selected for
            publication, this will be mentioned on your certificate and will add
            tremendous value to you.
          </p>

          <div className="mt-3">
            <input
              hidden
              ref={fileInput => (this.fileInput = fileInput)}
              type="file"
              name="selectedFile"
              onChange={this.onChangeHandler}
            />
          </div>

          <div className="text-center btn-upload">
            <Button
              onClick={this.triggerInputFile}
              type="button"
              className="btn btn-light border border-dark d-inline-block"
            >
              Browse...
            </Button>
            <h4 className="pl-3 d-inline-block">{this.state.filename}</h4>
          </div>
          <div className="text-center">
            <Button
              className="btn btn-padding btn-secondary mt-4"
              type="submit"
            >
              Submit
            </Button>
          </div>
        </div>
      </form>
    );
  }
}

ResearchInsight1.propstype = {
  saveResearch: PropTypes.func.isRequired
};
const mapStateToProps = state => {
  return {
    research: state.research.data
  };
};
export default connect(
  mapStateToProps,
  { saveResearch }
)(ResearchInsight1);
