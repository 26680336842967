import React from "react";
import ReactDOM from "react-dom";
import Cookies from "js-cookie";
import "./styles/main.scss";

import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
import setAuthToken from "./utils/setAuthToken";

if (process.env.NODE_ENV === "development") {
  window.BASE_URL = "http://localhost:3000";
} else {
  window.BASE_URL = window.location.origin;
}
window.user_token = Cookies.get("token");

if (!window.user_token) {
  window.user_token = localStorage.getItem("token");
}

if (window.user_token) {
  setAuthToken(window.user_token);
}

var isMobile = window.orientation > -1;
if (isMobile) {
  window
    .$("meta[name=viewport]")
    .attr("content", "width=initial,shrink-to-fit=yes");
}

ReactDOM.render(<App />, document.getElementById("root"));
registerServiceWorker();
