import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import PropTypes from "prop-types";
import { logout } from "../../actions/authActions";
import { Redirect } from "react-router-dom";

import {
  Nav,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import { Link } from "react-router-dom";

const ProfilePic = styled.img`
  border-radius: 50%;
  width: 50px;
`;

const ProfileName = styled.span`
  padding-left: 10px;
`;

class ProfileDropdown extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dropdownOpen: false,
      redirect: false
    };
  }

  toggle = () => {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  };
  setRedirect = url => {
    this.setState({ redirect: url, dropdownOpen: false });
  };
  render() {
    const photo =
      this.props.user.info.photo == "" || this.props.user.info.photo == null
        ? "img/profile.png"
        : `${window.BASE_URL}/uploads/userProfile/${this.props.user.info.photo}`;
    if (this.state.redirect) {
      this.setState({ redirect: false, dropdownOpen: false });
      return <Redirect to={this.state.redirect} />;
    }
    return (
      <Dropdown
        className="nav-item"
        isOpen={this.state.dropdownOpen}
        toggle={this.toggle}
      >
        <DropdownToggle caret color="light">
          <ProfilePic
            src={photo}
            width="100px"
            className="rounded-circle"
            onError={e => {
              e.target.onerror = null;
              e.target.src = "/img/profile.png";
            }}
          />
          <ProfileName>{this.props.user.info.name}</ProfileName>
        </DropdownToggle>
        <DropdownMenu>
          {this.props.user &&
          this.props.user.info &&
          parseInt(this.props.user.info.post_signup_steps) > 1 ? (
            <React.Fragment>
              {/* <DropdownItem header>Edit Profile</DropdownItem> */}
{/* 
              <DropdownItem
                onClick={() => {
                  this.setRedirect("/profile");
                  this.props.getRedirect("profile");
                }}
              >
                Edit Profile
              </DropdownItem> */}

              {/* <DropdownItem divider />
              <DropdownItem onClick={() => this.setRedirect("/faq")}>
                FAQs
              </DropdownItem>
              <DropdownItem divider /> */}
            </React.Fragment>
          ) : null}

          <DropdownItem onClick={this.props.logout}>Logout</DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  }
}

ProfileDropdown.propTypes = {
  user: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  user: state.user
});

export default connect(
  mapStateToProps,
  { logout }
)(ProfileDropdown);
