import {
  SCRIP_COMPANY_SEARCH,
  SCRIP_LIST_ALL,
  SCRIP_LIST_FOR_PORTFOLIO,
  SCRIP_INDUSTRIES,
  SCRIP_WATCH_LIST,
  SCRIP_CMP_DATA,
  SCRIP_CHART_DATA
} from "../actions/types";

const initialState = {
  search: { scrips: [], keyword: "" },
  scrips: [],
  indices: {},
  indicesLabel: [],
  scrips_portfolio: {
    scrips: [],
    segment: "ALL",
    sort: "MARKET CAP",
    industry: "ALL"
  },
  watchList: [],
  industries: [],
  scrip_cmp_data: {},
  chart_data: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SCRIP_INDUSTRIES:
      return { ...state, industries: action.payload };
    case SCRIP_COMPANY_SEARCH:
      return { ...state, search: action.payload };
    case SCRIP_LIST_ALL:
      return {
        ...state,
        scrips: action.payload.scrips,
        indices: action.payload.indices,
        indicesLabel: action.payload.indicesLabel
      };
    case SCRIP_LIST_FOR_PORTFOLIO:
      return { ...state, scrips_portfolio: action.payload };
    case SCRIP_WATCH_LIST:
      return { ...state, watchList: action.payload };
    case SCRIP_CMP_DATA:
      return { ...state, scrip_cmp_data: action.payload.data };
    case SCRIP_CHART_DATA:
      return { ...state,  chart_data: action.payload.data };
    default:
      return state;
  }
}
