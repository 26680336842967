import React from "react";
import PrimaryMarket from "./primaryMarket";
import SecurityMarket from "./secondaryMarket";

class SecurityMarkets extends React.Component {
  render() {
    return (
      <div className="container resource">
        <div className="mt-5">
          <h5 className="text-center">
            <u>
              <b>STRUCTURE OF SECURITIES MARKET</b>
            </u>
          </h5>
        </div>
        <p>
          A securities market is a market where different players present in the
          economy engage in the buying and selling of securities like equity
          instruments (stocks) and debt instruments (debentures, bonds). It is
          the market in which companies who require funds and persons with
          surplus funds can meet for transacting these funds with each other.
          <br />
          Right now, you are a participant of the securities market created in
          the Finlatics program. Here, you have a virtual corpus of Rs. 2,00,000
          and are engaged in the buying and selling of equity instruments
          (stocks).
        </p>
        <p>Broadly, the securities market can be divided into two forms:</p>

        <ol>
          <li style={{ fontWeight: "lighter" }}>Primary Market</li>
          <li style={{ fontWeight: "lighter" }}>Secondary Market</li>
        </ol>
        <p>Let’s start by understanding the primary market.</p>
        <div className="mt-5">
          <PrimaryMarket />
        </div>
        <div className="mt-5">
          <SecurityMarket />
        </div>
      </div>
    );
  }
}

export default SecurityMarkets;
