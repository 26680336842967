import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  editProfileData,
  deleteData
} from "../../actions/profileDetailsActions";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Input,
  FormGroup,
  Label,
  Card
} from "reactstrap";
class Emp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      modal_trash: false,
      index: 0,
      add: false,
      jobType: "",
      comName: "",
      designation: "",
      fromExpY: "",
      fromExpM: "",
      tillExpY: "",
      tillExpM: "",
      salL: "",
      salT: "",
      months: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec"
      ],
      empDetails: [
        {
          jobType: "",
          comName: "",
          designation: "",
          fromExpY: "",
          fromExpM: "",
          tillExpY: "",
          tillExpM: "",
          salL: "",
          salT: ""
        }
      ]
    };
  }
  add(cond) {
    this.setState({
      add: cond
    });
    if (cond == true) {
      this.setState({
        jobType: "",
        comName: "",
        designation: "",
        fromExpY: "",
        fromExpM: "",
        tillExpY: "",
        tillExpM: "",
        salL: "",
        salT: ""
      });
    }
  }
  toggle = () => {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  };
  modalData(data) {
    if (this.state.add == false) {
      this.setState({
        jobType: data.jobType,
        comName: data.comName,
        designation: data.designation,
        fromExpY: data.fromExpY,
        fromExpM: data.fromExpM,
        tillExpY: data.tillExpY,
        tillExpM: data.tillExpM,
        salL: data.salL,
        salT: data.salT
      });
    }
  }
  toggle_trash = () => {
    this.setState(prevState => ({
      modal_trash: !prevState.modal_trash
    }));
  };

  handleDelete = e => {
    e.preventDefault();
    this.props.deleteData(this.state.index, "employee");
  };
  handleSubmitEmp = e => {
    e.preventDefault();
    this.state.empDetails[0].jobType = this.state.jobType;
    this.state.empDetails[0].comName = this.state.comName;
    this.state.empDetails[0].designation = this.state.designation;
    this.state.empDetails[0].fromExpY = this.state.fromExpY;
    this.state.empDetails[0].fromExpM = this.state.fromExpM;
    this.state.empDetails[0].tillExpY = this.state.tillExpY;
    this.state.empDetails[0].tillExpM = this.state.tillExpM;
    this.state.empDetails[0].salL = this.state.salL;
    this.state.empDetails[0].salT = this.state.salT;
    if (this.state.add == false) {
      this.props.editProfileData(
        ...this.state.empDetails,
        this.state.index,
        "employee"
      );
    } else if (this.state.add == true && this.state.comName != "") {
      this.props.editProfileData(
        ...this.state.empDetails,
        this.props.data.employement.length,
        "employee"
      );
    }
  };
  selectYears() {
    let years = [];
    var d = new Date();
    var n = d.getFullYear();
    for (let i = n; i >= n - 10; i--) {
      years.push(
        <option key={i} value={i} className="text-center">
          {i}
        </option>
      );
    }
    return years;
  }
  createSelectItems() {
    let items = [];
    for (let i = 0; i <= 12; i++) {
      items.push(
        <option key={i} value={i} className="text-center">
          {i}
        </option>
      );
    }
    return items;
  }
  createSelectThousands() {
    let items = [];
    for (let i = 0; i <= 99; i += 10) {
      items.push(
        <option key={i} value={i} className="text-center">
          {i}
        </option>
      );
    }
    return items;
  }
  render() {
    return (
      <div className=" container editProfile mb-5">
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggle}>Experience</ModalHeader>
          <Form onSubmit={this.handleSubmitEmp}>
            <ModalBody>
              <FormGroup>
                <Label for="jobType" className="required_mark">
                  Job Type
                </Label>

                <Input
                  required
                  type="select"
                  name="jobType"
                  id="jobType"
                  onChange={e => {
                    this.state.empDetails[0].jobType = e.target.value;
                    this.state.jobType = e.target.value;
                  }}
                >
                  <option hidden>{this.state.jobType}</option>
                  <option>Full Time</option>
                  <option>Internship</option>
                </Input>
              </FormGroup>
              <FormGroup>
                <Label for="companyName" className="required_mark">
                  Company Name
                </Label>
                <Input
                  required
                  type="companyName"
                  name="companyName"
                  id="companyName"
                  value={this.state.comName}
                  onChange={e => {
                    this.setState({ comName: e.target.value });
                    this.state.empDetails[0].comName = e.target.value;
                  }}
                ></Input>
              </FormGroup>
              <FormGroup>
                <Label for="designation">Designation</Label>
                <Input
                  type="designation"
                  name="designation"
                  id="designation"
                  value={this.state.designation}
                  onChange={e => {
                    this.setState({ designation: e.target.value });
                    this.state.empDetails[0].designation = e.target.value;
                  }}
                />
              </FormGroup>
              <FormGroup>
                <div className="d-inline-block">
                  <Label className="d-block required_mark" for="experience">
                    Start Date
                  </Label>

                  <Input
                    type="select"
                    name="months"
                    id="months"
                    style={{ width: "42%" }}
                    className="d-inline-block"
                    onChange={e => {
                      this.state.empDetails[0].fromExpM = e.target.value;
                      this.setState({
                        fromExpM: e.target.value
                      });
                    }}
                  >
                    <option hidden>
                      {this.state.fromExpM ? this.state.fromExpM : "Months"}
                    </option>
                    {this.state.months.map((e, i) => (
                      <option key={i}>{e}</option>
                    ))}
                  </Input>
                  <Input
                    type="select"
                    name="years"
                    id="years"
                    style={{ width: "46%" }}
                    className="d-inline-block ml-3 "
                    onChange={e => {
                      this.state.empDetails[0].fromExpY = e.target.value;
                      this.setState({
                        fromExpY: e.target.value
                      });
                    }}
                  >
                    <option hidden>
                      {this.state.fromExpY ? this.state.fromExpY : "Years"}
                    </option>
                    {this.selectYears()}
                  </Input>
                </div>

                <div className="d-inline-block float-right">
                  <Label className="d-block" for="experience">
                    End Date
                  </Label>

                  <Input
                    type="select"
                    name="months"
                    id="months"
                    style={{ width: "46%" }}
                    className="d-inline-block "
                    onChange={e => {
                      this.state.empDetails[0].tillExpM = e.target.value;
                      this.state.tillExpM = e.target.value;
                    }}
                  >
                    <option hidden>
                      {this.state.tillExpM ? this.state.tillExpM : "Months"}
                    </option>
                    {this.state.months.map((e, i) => (
                      <option key={i}>{e}</option>
                    ))}
                  </Input>
                  <Input
                    type="select"
                    name="years"
                    id="years"
                    style={{ width: "49%" }}
                    className="d-inline-block ml-2"
                    onChange={e => {
                      this.state.empDetails[0].tillExpY = e.target.value;
                      this.state.tillExpY = e.target.value;
                    }}
                  >
                    <option hidden>
                      {this.state.tillExpY ? this.state.tillExpY : "Years"}
                    </option>
                    {this.selectYears()}
                  </Input>
                </div>
              </FormGroup>

              <FormGroup>
                <Label className="d-block" for="salary">
                  Salary (Only if full time job)
                </Label>
                <Input
                  type="select"
                  name="lakhs"
                  id="lakhs"
                  className="d-inline-block"
                  style={{ width: "46%" }}
                  onChange={e => {
                    this.state.empDetails[0].salL = e.target.value;
                    this.state.salL = e.target.value;
                  }}
                >
                  <option hidden>
                    {this.state.salL ? this.state.salL : "Lakhs"}
                  </option>
                  {this.createSelectItems()}
                </Input>
                <Input
                  type="select"
                  name="thousands"
                  id="thousands"
                  className="d-inline-block ml-3 "
                  style={{ width: "46%" }}
                  onChange={e => {
                    this.state.empDetails[0].salT = e.target.value;
                    this.state.salT = e.target.value;
                  }}
                >
                  <option hidden>
                    {this.state.salT ? this.state.salT : "Thousands"}
                  </option>
                  {this.createSelectThousands()}
                </Input>
              </FormGroup>
            </ModalBody>
            {this.state.comName != "" &&
            this.state.jobType != "" &&
            this.state.fromExpM != "" &&
            this.state.fromExpY != "" ? (
              <ModalFooter>
                <Button color="primary" type="submit" onClick={this.toggle}>
                  Save
                </Button>
                <Button color="secondary" onClick={this.toggle}>
                  Cancel
                </Button>
              </ModalFooter>
            ) : null}
          </Form>
        </Modal>

        <Modal isOpen={this.state.modal_trash} toggle={this.toggle_trash}>
          <Form onSubmit={this.handleDelete}>
            <ModalHeader toggle={this.toggle_trash}>
              <i
                className=" fa fa-trash d-inline-block profile_icon pr-3"
                aria-hidden="true"
              ></i>
              <span className="d-inline-block"> Confirm Delete ?</span>
            </ModalHeader>
            <ModalBody>
              <span>
                Are you sure that you want to permanently delete selected
                element ?
              </span>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" type="submit" onClick={this.toggle_trash}>
                Yes
              </Button>
              <Button color="secondary" onClick={this.toggle_trash}>
                No
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
        <Card className="mt-5">
          <div>
            <span
              className="d-inline-block  pl-4 pt-2 pb-4"
              style={{ fontSize: "23px" }}
            >
              Experience
            </span>
            <div className="d-inline-block float-right">
              <i
                className="fa fa-plus pr-4 pt-3 profile_icon"
                aria-hidden="true"
                onClick={async () => {
                  await this.add(true);
                  this.toggle();
                }}
              ></i>
            </div>
          </div>
          {this.props.data.employement ? (
            <div>
              {this.props.data.employement.map((e, i) => (
                <div key={i} className="mb-4">
                  <i
                    className="fa fa-building-o pl-4 float-left"
                    aria-hidden="true"
                    style={{
                      fontSize: "50px",
                      color: "#6f6f70"
                    }}
                  ></i>
                  <div>
                    <div className=" pl-5 d-inline-block  profile_span">
                      <h6>
                        <b>
                          {e.comName}
                          {e.jobType ? ` (${e.jobType})` : ""}
                        </b>
                      </h6>

                      <span>{e.designation}</span>
                      <span className="pl-1">
                        {e.fromExpM && !e.tillExpM && !e.tillExpY
                          ? `( ${e.fromExpM} ${e.fromExpY} )`
                          : e.tillExpM || e.tillExpY
                          ? `( ${e.fromExpM} ${e.fromExpY}`
                          : ""}
                      </span>
                      <span>
                        {e.tillExpM || e.tillExpY
                          ? ` - ${e.tillExpM} ${e.tillExpY} )`
                          : ""}
                      </span>
                    </div>
                    <div className="float-right">
                      <i
                        className=" fa fa-pencil-square-o d-inline-block  profile_icon pr-3"
                        aria-hidden="true"
                        onClick={async () => {
                          await this.add(false);
                          this.toggle();
                          this.modalData(e);
                          this.setState({
                            index: i
                          });
                        }}
                      ></i>
                      <i
                        className=" fa fa-trash d-inline-block profile_icon pr-3"
                        aria-hidden="true"
                        onClick={() => {
                          this.setState({
                            modal_trash: true,
                            index: i
                          });
                        }}
                      ></i>
                    </div>
                    {i == this.props.data.employement.length - 1 ? null : (
                      <div className="edit_hr"></div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          ) : null}
        </Card>
      </div>
    );
  }
}

Emp.PropType = {
  editProfileData: PropTypes.func.isRequired,
  deleteData: PropTypes.func.isRequired
};

const mapStateToProps = state => ({});
export default connect(
  mapStateToProps,
  { editProfileData, deleteData }
)(Emp);
