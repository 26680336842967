import React from "react";
import {
  DropdownToggle,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  FormGroup,
  Input,
  Button,
  Row,
  Col
} from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { saveResearch, getResearch } from "../../../actions/researchActions";
const segments = ["Large Cap", "Mid Cap", "Small Cap"];

class Sector2 extends React.Component {
  state = {
    segment1: "Large Cap",
    segment2: "Mid Cap",
    segment1_comp: "Please select company",
    segment2_comp: "Please select company",
    submiited: false
  };
  componentDidMount() {
    this.props.getResearch();
  }
  setsegment1 = e => {
    this.setState({ segment1: e.target.innerText });
  };
  setsegment2 = e => {
    this.setState({ segment2: e.target.innerText });
  };

  setsegment1_comp = e => {
    this.setState({ segment1_comp: e.target.innerText });
  };
  setsegment2_comp = e => {
    this.setState({ segment2_comp: e.target.innerText });
  };
  handleSubmit = e => {
    e.preventDefault();
    let data = {
      sector2: this.props.research[1].sector2,
      segment1: this.state.segment1,
      segment2: this.state.segment2,
      segment1_comp: this.state.segment1_comp,
      segment2_comp: this.state.segment2_comp,
      segment1_desc: e.target.segment1_desc.value,
      segment2_desc: e.target.segment2_desc.value
    };
    if (
      data.segment1_comp == "Please select company" ||
      data.segment2_comp == "Please select company" ||
      data.segment1_desc == "" ||
      data.segment1_desc == ""
    ) {
      this.props.showInvalidFormAlert();
    } else {
      this.props.saveResearch({ data, week: 3 });
      this.setState({ submitted: true });
    }
  };
  render() {
    if (
      this.props.scrips.length == 0 ||
      (this.state.submitted && !this.props.research[3])
    )
      return <div>Please wait...</div>;

    let submit_disabled = false;
    let attr = {
      seg1_input: {},
      seg2_input: {},
      seg1_sel: "",
      seg2_sel: "",
      seg1_cmp: "",
      seg2_cmp: ""
    };
    if (this.state.submitted || this.props.research[3]) {
      submit_disabled = true;
      attr["seg1_sel"] = this.props.research[3].segment1;
      attr["seg2_sel"] = this.props.research[3].segment2;
      attr["seg1_cmp"] = this.props.research[3].segment1_comp;
      attr["seg2_cmp"] = this.props.research[3].segment2_comp;
      attr["seg1_input"]["value"] = this.props.research[3].segment1_desc;
      attr["seg2_input"]["value"] = this.props.research[3].segment2_desc;
      attr["seg1_input"]["disabled"] = true;
      attr["seg2_input"]["disabled"] = true;
    }

    let sector_picks = this.props.research[1];
    if (!sector_picks) return null;
    let companies1 = this.props.scrips.filter(
      e =>
        e.cap_type == this.state.segment1 && e.industry == sector_picks.sector2
    );
    let companies2 = this.props.scrips.filter(
      e =>
        e.cap_type == this.state.segment2 && e.industry == sector_picks.sector2
    );
    return (
      <div className="sector1">
        <form onSubmit={this.handleSubmit}>
          <div>
            <p>
              Great to see that you have picked the sectors of your choice to
              conduct research on. The next step is to go deeper in those
              sectors and pick specific companies that you want to conduct this
              research on. You have to pick any 2 companies from each of the
              sectors that you picked. While deciding on the companies, it is
              important to cover all market segments - Large Cap, Mid Cap and
              Small Cap.
            </p>
          </div>
          <h4 className="inner_margin">Sector 2 : {sector_picks.sector2}</h4>
          <div className="mt-4 clearfix">
            <div className="float-left">
              <h5 className="d-inline-block pb-4">Segment :</h5>
              {submit_disabled ? (
                attr["seg1_sel"]
              ) : (
                <UncontrolledDropdown className="d-inline-block">
                  <DropdownToggle
                    tag="button"
                    name="segment1"
                    type="button"
                    className="form-control "
                    caret
                  >
                    {this.state.segment1}
                  </DropdownToggle>

                  <DropdownMenu onClick={this.setsegment1}>
                    {segments
                      .filter(e => e != this.state.segment2)
                      .map((e, i) => (
                        <DropdownItem key={e + "" + i}>{e}</DropdownItem>
                      ))}
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}
            </div>

            <div className="float-right">
              <h5 className="d-inline-block ">Company :</h5>{" "}
              {submit_disabled ? (
                attr["seg1_cmp"]
              ) : (
                <UncontrolledDropdown className="d-inline-block dropdown5">
                  <DropdownToggle
                    tag="button"
                    name="segment1_comp"
                    type="button"
                    className="form-control "
                    caret
                  >
                    {this.state.segment1_comp}
                  </DropdownToggle>
                  <DropdownMenu
                    style={{
                      height: "135px",
                      overflow: "auto",
                      paddingLeft: "20px"
                    }}
                  >
                    {companies1.map((e, i) => (
                      <DropdownItem onClick={this.setsegment1_comp} key={i}>
                        <span dangerouslySetInnerHTML={{ __html: e.company }} />
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}
            </div>
          </div>
          <div className="mt-3">
            <p>
              In not more than 250 words per company, you have to give a reason
              for your choice of company. This can be a brief of the company’s
              business activities and why you are personally interested in
              studying the company.
            </p>
            <FormGroup>
              <Input
                type="textarea"
                name="segment1_desc"
                {...attr["seg1_input"]}
              />
            </FormGroup>
          </div>
          <div className="inner_margin">
            <Row>
              <Col md="6">
                <div className="float-left">
                  <h5 className="d-inline-block pb-4">Segment:</h5>
                  {submit_disabled ? (
                    attr["seg2_sel"]
                  ) : (
                    <UncontrolledDropdown className="d-inline-block">
                      <DropdownToggle
                        tag="button"
                        type="button"
                        name="segment2"
                        className="form-control"
                        caret
                      >
                        {this.state.segment2}
                      </DropdownToggle>
                      <DropdownMenu onClick={this.setsegment2}>
                        {segments
                          .filter(e => e != this.state.segment1)
                          .map((e, i) => (
                            <DropdownItem key={e + "" + i}>{e}</DropdownItem>
                          ))}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  )}
                </div>
              </Col>
              <Col md="6">
                <div className="float-right">
                  <h5 className="d-inline-block pr-2">Company:</h5>{" "}
                  {submit_disabled ? (
                    attr["seg2_cmp"]
                  ) : (
                    <UncontrolledDropdown className="d-inline-block dropdown5">
                      <DropdownToggle
                        tag="button"
                        name="segment2_comp"
                        type="button"
                        className="form-control"
                        caret
                      >
                        {this.state.segment2_comp}
                      </DropdownToggle>

                      <DropdownMenu
                        style={{
                          height: "135px",
                          overflow: "auto",
                          paddingLeft: "20px"
                        }}
                      >
                        {companies2.map((e, i) => (
                          <DropdownItem onClick={this.setsegment2_comp} key={i}>
                            <span
                              dangerouslySetInnerHTML={{ __html: e.company }}
                            />
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  )}
                </div>
              </Col>
            </Row>
          </div>
          <div className="mt-3">
            <p>
              In not more than 250 words per company, you have to give a reason
              for your choice of company. This can be a brief of the company’s
              business activities and why you are personally interested in
              studying the company.
            </p>
            <FormGroup>
              <Input
                type="textarea"
                name="segment2_desc"
                {...attr["seg2_input"]}
              />
            </FormGroup>
          </div>
          {!submit_disabled ? (
            <div className="text-center">
              <Button className="btn btn-padding mt-4">Submit</Button>
            </div>
          ) : null}
        </form>
      </div>
    );
  }
}
Sector2.propstype = {
  saveResearch: PropTypes.func.isRequired,
  scrips: PropTypes.array.isRequired,
  research: PropTypes.object.isRequired,
  getResearch: PropTypes.func.isRequired
};
const mapStateToProps = state => {
  return {
    scrips: state.scrips.scrips,
    research: state.research.data
  };
};
export default connect(
  mapStateToProps,
  { saveResearch, getResearch }
)(Sector2);
