import { PORTFOLIO_LIST, USER_FINSCORE,USER_STOCK } from '../actions/types';

const initialState = {
	portfolios: [],
	segment: 'ALL',
	sort: 'MARKET CAP',
	industry: 'ALL',
	myScrips: [],
	finscore: {},
	stock:[]
};

export default function(state = initialState, action) {
	switch (action.type) {
		case PORTFOLIO_LIST:
			return {
				...state,
				...action.payload,
			};
		case USER_FINSCORE:
			return {
				...state,
				finscore: action.payload,
			};

			case USER_STOCK:
				
				return{
				...state,
				stock:action.payload
				};
		default:
			return state;
	}
}
