import axios from "axios";

import { ERRORS_GET, DASHBOARD_DATA_GET,DASHBOARD_NEWS_FEED } from "./types";

import { showLoader } from "./loaderActions";

export const getDashboardData = token => dispatch => {
  dispatch(showLoader(true));
  axios
    .get("/api/dashboard/data")
    .then(res => {
      dispatch({
        type: DASHBOARD_DATA_GET,
        payload: res.data
      });
      dispatch(showLoader(false));
    })
    .catch(err =>
      dispatch({
        type: ERRORS_GET
      })
    );
};



export const getFeed = token => dispatch => {
  axios
    .get("/api/dashboard/feed")
    .then(res => {
      dispatch({
        type: DASHBOARD_NEWS_FEED,
        payload: res.data
      });
    })
    .catch(err =>{}
      
    );
};
