import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import Profile from '../RiskProfile/Profile';

import HRQuestions from '../HR/questions';
import RiskProfile from '../RiskProfile';
import { Redirect, withRouter } from 'react-router-dom';

class PostSignup extends Component {
	constructor(props) {
		super(props);
		let activeTab = '1';
		if (this.props.location.pathname == '/signup/step2') {
			activeTab = '2';
		}
		this.state = {
			activeTab: activeTab,
			stepActivated: 1,
		};
	}

	componentDidUpdate(prevProp) {
		if (
			prevProp.location.pathname != this.props.location.pathname &&
			this.props.location.pathname == '/signup/step2'
		) {
			this.setState({
				activeTab: '2',
				stepActivated: 1,
			});
		} else if (
			prevProp.location.pathname != this.props.location.pathname &&
			this.props.location.pathname == '/signup/step1'
		) {
			this.setState({
				activeTab: '1',
				stepActivated: 1,
			});
		}
	}
	toggle = tab => {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab,
			});
		}
	};

	render() {
    const { info } = this.props.user;
		let url = '';
		if (info.post_signup_steps > -1) {
			switch (info.post_signup_steps) {
				case 0:
					url = '/signup/step1';
					break;
				case 1:
					url = '/signup/step2';
					break;
				case 2:
					url = '/portfolio';
					break;
				case 3:
					url = '/dashboard';
					break;
			}
		}
		if (url != this.props.location.pathname) {
			return <Redirect to={url} />;
		}
		if (this.state.activeTab == 1) {
			return <Profile nextScreen={() => {}} />;
		}
		return <RiskProfile />;
	}
}

PostSignup.propTypes = {
	user: PropTypes.object.isRequired,
	errors: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
	errors: state.errors,
	user: state.user,
});

export default withRouter(
	connect(
		mapStateToProps,
		{}
	)(PostSignup)
);
