import {
  EDUCATION_DETAILS,
  USER_DETAILS,
  SKILLS,
  BEHAVIOUR,
  PROFILE_PHOTO,
  SEARCH_COLLEGE
} from "../actions/types";

const initialstate = {
  education: [],
  colleges: [],
  university: [],
  skills: [],
  behaviour: [],
  userInfo: {},
  clgList: [],
  upload: ""
};

export default function(state = initialstate, action) {
  switch (action.type) {
    case EDUCATION_DETAILS:
      return {
        ...state,
        education: action.payload.response,
        university: action.payload.university,
        colleges: action.payload.colleges
      };
    case USER_DETAILS:
      return {
        ...state,
        userInfo: action.payload
      };

    case SEARCH_COLLEGE:
      return {
        ...state,
        clgList: action.payload.clgList
      };

    case SKILLS:
      return {
        ...state,
        skills: action.payload
      };
    case BEHAVIOUR:
      return {
        ...state,
        behaviour: action.payload
      };
    case PROFILE_PHOTO:
      return {
        ...state,
        upload: action.payload
      };
    default:
      return state;
  }
}
