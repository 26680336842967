import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter,Row, Col,Table, Input, Label } from 'reactstrap';
import Company from "../../Company";

export default class CompanyModal extends React.Component {
   
    render(){
        return (
            <Modal isOpen={this.props.show} toggle={this.props.onOk}  backdrop={true} size="lg" className="company-dlg">
                  <ModalHeader toggle={this.props.onOk} style={{backgroundColor:"white"}}>COMPANY STATUS</ModalHeader>
                <ModalBody style={{zoom:1, maxHeight: '100vh',overflowY:'auto',backgroundColor:"white",padding:"0px"}}>
                    <Company from_popup={true} sid={this.props.sid} />
                </ModalBody>
            </Modal>
                
        );
    }
}