import React from "react";

class PrimaryMarket extends React.Component {
  render() {
    return (
      <div className="resource">
        <h6>
          <u>
            <b>PRIMARY MARKET</b>
          </u>
        </h6>
        <p>
          The primary market is a part of the security market which involves a
          new issue of securities (debt or equity) and is sold directly to the
          investors by the issuing company. Hence, it is also called as a new
          issue market.
        </p>
        <p>
          Private companies and government entities can issue debt-based
          securities like bonds or equity-based securities like stocks for
          funding their business activities. The new issue of securities by such
          entities is involved in the primary market.
          <span>
            The issue of new securities which take place in primary markets can
            be of different types. Some of them are explained below:
          </span>
        </p>
        <p>
          A firm can decide to issue securities to the public, called public
          issue, via the following methods:
        </p>
        <p>
          <u>
            <b> Initial Public Offering (IPO)</b>
          </u>
        </p>
        <p>
          Under the initial public offering, a firm issues its equity shares for
          the first time in the stock market. This process is accompanied by the
          company becoming listed on a stock exchange for the sale of its
          shares. The shares of a private corporation, which were until now
          distributed amongst its owners and not listed on any stock exchange,
          are offered for sale to the general public during an initial public
          offer and once the company undergoes an IPO it becomes a publicly
          traded company. Usually, companies that are growing and are in need of
          a substantial amount of funds to expand their activities resort to the
          IPO method for raising resources.
        </p>
        <p>
          An example of how the IPO process works will give you a better
          understanding of the concept:
        </p>
        <p>
          In continuation with our hypothetical company ABC Ltd, we will now
          consider that the company was established in 2005 as a privately owned
          company (not listed on any stock exchange). After managing its
          operations successfully for more than 13 years, the company now plans
          to go public in the year 2019 because it requires a substantial amount
          of funds for expanding its operations into other businesses. It does
          so by issuing shares priced at Rs 10 to raise Rs 50 lacs - the company
          will issue 5,00,000 shares to raise the required amount from the
          general public. So buyers who are interested in buying the shares of
          ABC Ltd, which is new to the stock market, will purchase them and then
          later may hold onto them or sell them after some period of time.
          Therefore, when a company undergoes an IPO its ownership is
          distributed amongst thousands of shareholders. Once the company is
          listed on any stock exchange, its shares are now traded in the
          markets.
        </p>
        <p>
          <u>
            <b> Follow on Public Issue</b>
          </u>
        </p>
        <p>
          A follow on public issue can be considered a means of raising
          additional funds by a company which has already undergone an IPO (is
          listed on the exchange). Consequently, when a company decides to issue
          a fresh set of shares other than those issued during its IPO process,
          it can be said to be a follow on public issue method of raising
          capital (funds).
        </p>
        <p>
          Again considering the company ABC Ltd, we will continue our example
          from the time period when it became listed on the stock exchange.
          Supposing that the company underwent through an IPO in 2019 and thus
          became a publicly listed company. Nevertheless, 2 years after becoming
          a publicly listed company, ABC Ltd is planning to build a new plant
          for manufacturing its products and requires Rs 80 lacs for the same.
          The company again opts for meeting its funding needs by the issuance
          of shares. So, it decides to issue a fresh set of 80,000 shares in the
          year 2021 priced at Rs 100 each. This fresh/additional issue made by
          an already listed company is called a follow on public issue. The
          shares which are issued during a follow on public issue are issued in
          the primary market. Later, the same shares are traded between holders
          of these shares and those who are interested in buying these shares in
          the secondary market.
        </p>
        <p>
          <u>
            <b>Rights Issue</b>
          </u>
        </p>
        <p>
          This is a method of raising capital by issuing new shares to existing
          shareholders of the company. Under this, an already listed company
          issues fresh shares to its existing shareholders on the basis of the
          number of existing shares held by each shareholder. It is within their
          rights as a shareholder to purchase the stocks offered to them. It can
          be said that holders with a larger number of shares will have the
          opportunity of buying more shares under the rights issue, within a
          specified time period and at a specified price. But, it is not
          obligatory upon the existing shareholders to purchase the fresh shares
          issued to them.
        </p>
        <p>
          Example: Taking the company ABC Ltd into account, it can be observed
          that after undergoing an IPO and a follow on public offer, the company
          now has 5,80,000 outstanding shares. Suppose that the company opts for
          a rights issue for raising additional funds amounting to 1,00,000. The
          company then will price its fresh shares at a discounted rate of Rs 50
          which will be lesser than the ongoing market price of Rs 60 and allot
          them to its existing investors in accordance with the proportion of
          shares already held by them. The investors have the right to opt out
          of the rights issue or participate in the issue by purchasing the
          fresh shares at the discounted price. The company will allocate 2000
          shares to the interested shareholders at a specified price (Rs50) and
          on a specified date, before which investors will have to subscribe to
          these shares. Once the subscription procedure ends and the shares have
          been subscribed to, the company will now have 6,00,000 (2000*50)
          outstanding shares circulating in the market.
        </p>
        <p>
          <u>
            <b>Bonus Issue </b>
          </u>
        </p>
        <p>
          A bonus issue is a form of issuance of shares, similar to a rights
          issue, in which fresh shares are issued to existing shareholders but
          free of cost. Also, the free shares are issued to existing investors
          in proportion to the number of existing shares held by them. For
          example, a company may give 1 bonus shares for every 10 shares held by
          an investor.
          <br />
          It is important to understand the distinction between a rights issue
          and a bonus issue. The fundamental difference is that in a rights
          issue, investors have to pay for the fresh shares which are issued to
          them and only have the benefit of purchasing the shares at a
          discounted price when compared with the market price. However, in a
          bonus issue, investors do not have to pay anything for holding the
          fresh shares issued to them and it can be considered as a kind of gift
          given by the company to its investors.
        </p>
        <p>
          It can be concluded that the primary market is used by companies for
          raising fresh capital from the general public. To summarize –
        </p>
        <ol>
          <li style={{ fontWeight: "lighter" }}>
            When a company accesses public markets for the first time, it is
            known as an initial public offering.
          </li>
          <li style={{ fontWeight: "lighter" }}>
            When an already listed company accesses public markets to raise
            capital, it is known as a follow on public offer.
          </li>
          <li style={{ fontWeight: "lighter" }}>
            A rights issue is when a company raises capital but from existing
            shareholders only.
          </li>
        </ol>
      </div>
    );
  }
}

export default PrimaryMarket;
