import React from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink, Button } from "reactstrap";
import classnames from "classnames";
import { Alert } from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Market from "./Market";
import OrderBook from "./OrderBook";
import MyPortfolio from "./MyPortfolio";
import LeapModal from "./leap_modal";
import { Link, withRouter } from "react-router-dom";
import { postSignupDone, getstockstatus } from "../../actions/portfolioActions";
import { getCurrentUserDetails } from "../../actions/userActions";
import PortfolioScore from "./portfolioScore";
import moment from "moment";
import { paymentRenewal } from "../../actions/authActions";
import { profilePhoto } from "../../actions/profileDetailsActions";
import { getFinscore } from "../../actions/portfolioActions";
import {
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle
} from "reactstrap";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Table,
  Input,
  Label
} from "reactstrap";

class Portfolio extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "3",
      randomStr: "",
      leap_modal_show: false,
      showScore: false,
      Performance_stock: []
    };
  }

  componentDidMount = () => {
    //window.$("html,body").css("overflow-y", "hidden");
    window.scrollTo(0, 0);
    this.props.getFinscore();
    this.props.getCurrentUserDetails();
    this.setState({
      showScore: true
    });
  };
  showPortfolio = m => {
    this.setState({ showScore: m });
  };
  onD = () => {
    if (this.props.user.planned == "p999") {
      this.props.getstockstatus();
    }
  };
  toggle = tab => {
    if (this.state.activeTab !== tab) {
      // if (tab == "3") {
      //   window.$("html,body").css("overflow-y", "hidden");
      //   window.scrollTo(0, 0);
      // } else {
      //   window.$("html").css("overflow-y", "auto");
      //   window.scrollTo(0, 0);
      // }
      let randomStr = this.state.randomStr;
      if (tab == "1") {
        randomStr = Math.random();
      }
      this.setState({
        activeTab: tab,
        randomStr: randomStr
      });
    }
  };

  leapModalOk = () => {
    this.props.postSignupDone();
    this.props.history.push("/dashboard");
    this.setState({ leap_modal_show: false });
  };
  render() {
    let data = this.props.finscore;
    return (
      <div style={{ paddingBottom: "0px" }}>
        {moment(this.props.user.end_date)
          .add(1, "days")
          .format("YYYY-MM-DD") >= moment().format("YYYY-MM-DD") ? (
          <div>
            <Nav tabs className="myTab">
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === "1"
                  })}
                  onClick={async () => {
                    await this.onD();
                    await this.toggle("1");
                  }}
                >
                  My Portfolio Performance
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === "2"
                  })}
                  onClick={() => {
                    this.toggle("2");
                  }}
                >
                  My Order Book
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === "3"
                  })}
                  onClick={() => {
                    this.toggle("3");
                  }}
                >
                  Market
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent
              activeTab={this.state.activeTab}
              style={{ paddingBottom: "80px" }}
            >
              <TabPane tabId="1">
                <MyPortfolio
                  randomStr={this.state.randomStr}
                  performance={this.props.stock}
                />
              </TabPane>
              <TabPane tabId="2">
                <div style={{ padding: "15px 15px 80px 15px" }}>
                  <OrderBook />
                </div>
              </TabPane>
              <TabPane tabId="3">
                <Market toggleTab={this.toggle} />
              </TabPane>
            </TabContent>

            <LeapModal
              show={this.state.leap_modal_show}
              onOk={this.leapModalOk}
            />

            {/* <PortfolioScore
              showScore={this.state.showScore}
              removePortfolio={this.showPortfolio}
            /> */}
          </div>
        ) : (
          <Modal
            isOpen={true}
            modalClassName=".modal .modal-backdrop"
            size="lg"
            centered
          >
            <ModalBody
              style={{
                backgroundColor: "#E2EACE",
                fontSize: "18px!important"
              }}
            >
              <div>
                <Card>
                  <div className="text-center">
                    <img
                      style={{
                        backgroundColor: "#E2EACE",
                        height: "50px"
                      }}
                      src="/img/profile.png"
                      alt="arvind "
                    ></img>
                  </div>
                  <div className="mt-2 text-center">
                    <h6>
                      Your Program has ended on
                      <large className="p-2">
                        {moment(this.props.user.end_date)
                          .add(1, "days")
                          .format("YYYY-MM-DD")}
                      </large>
                    </h6>
                    <Label
                      className="site-white modal-trade-action"
                      style={{
                        background: "#a8bf6f"
                      }}
                    >
                      {" "}
                      Your updated FinScore is{" "}
                      {data ? parseFloat(data.Finscore).toFixed(2) : "5"}{" "}
                    </Label>
                    <p>
                      Incase you want to extend your program,following structure
                      and benefits will be applicable:
                    </p>
                  </div>

                  <div
                    className=" border p-6 ml-2 mr-2"
                    style={{ display: "inline-block" }}
                  >
                    <div style={{ float: "left" }}>
                      <small>
                        1.<b>Certificate of Work Experience</b> and{" "}
                        <b>Financial Markets Performance Report</b> would be
                        issued for a period of 4 months.
                      </small>
                    </div>
                    <div style={{ float: "left" }}>
                      <small>
                        2. In case you are not satisfied with your FinScore, you
                        can reset it and <b>Continue</b> the program with a
                        <b> new FinScore</b>.
                      </small>
                    </div>
                    <div style={{ float: "left" }}>
                      <small>
                        3. You can use the extended period to complete your
                        research project in case you haven't completed it yet.{" "}
                      </small>
                    </div>
                  </div>
                  <div className="mb-1">
                    <div
                      className="pt-1"
                      style={{ float: "left", width: "33%" }}
                    >
                      <div>
                        <div className="border p-2 text-center ml-2">
                          <div
                            className="text-center p-2 d-inline-block download_arrow-1"
                            style={{
                              backgroundColor: "#a8bf6f",
                              borderRadius: "20px"
                            }}
                          >
                            <a
                              href={`${window.BASE_URL}/pdfs/certificates/${this.props.user.user_id}_certificate.pdf`}
                              download={`${window.BASE_URL}/pdfs/certificates/${this.props.user.user_id}_certificate.pdf`}
                              className="text-secondary"
                            >
                              <i
                                className="fa fa-cloud-download"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </div>
                          <p className="d-inline-block ml-2">Certificate</p>
                        </div>
                      </div>
                    </div>

                    <div
                      className="pt-1"
                      style={{ float: "left", width: "33%" }}
                    >
                      <div>
                        <div className="border p-2 text-center ml-2">
                          <div
                            className="text-center p-2 d-inline-block download_arrow-1"
                            style={{
                              backgroundColor: "#a8bf6f",
                              borderRadius: "20px"
                            }}
                          >
                            <a
                              href={`${window.BASE_URL}/pdfs/reports/${this.props.user.user_id}_report.pdf`}
                              download={`${window.BASE_URL}/pdfs/reports/${this.props.user.user_id}_report.pdf`}
                              className="text-secondary"
                            >
                              <i
                                className="fa fa-cloud-download"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </div>
                          <p className="d-inline-block ml-2">
                            Performance Report
                          </p>
                        </div>
                      </div>
                    </div>
                    {moment(this.props.user.end_date)
                      .add(15, "days")
                      .format("YYYY-MM-DD") < moment().format("YYYY-MM-DD") ? (
                      <div
                        className="pt-1"
                        style={{ float: "left", width: "33%" }}
                      >
                        <div>
                          <div className="border p-2 text-center ml-2">
                            <div
                              className="text-center p-2 d-inline-block download_arrow-1"
                              style={{
                                backgroundColor: "#a8bf6f",
                                borderRadius: "20px"
                              }}
                            >
                              <a
                                href={`${window.BASE_URL}/pdfs/research/${this.props.user.user_id}_research.pdf`}
                                download={`${window.BASE_URL}/pdfs/research/${this.props.user.user_id}_research.pdf`}
                                className="text-secondary"
                              >
                                <i
                                  className="fa fa-cloud-download"
                                  aria-hidden="true"
                                ></i>
                              </a>
                            </div>
                            <p className="d-inline-block ml-2">
                              Research Report
                            </p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="pt-1"
                        style={{ float: "left", width: "33%" }}
                      >
                        <Alert color="primary" className=" text-center ml-2 ">
                          Research Report will be Available 14 days after your
                          end date
                        </Alert>
                      </div>
                    )}
                  </div>
                  <div className="mt-1 mb-1 text-center">
                    <div
                      className="d-inline-block border p-1 ml-2 float-left .bg-success"
                      style={{ width: "48%" }}
                      onClick={async () =>
                        await this.props.paymentRenewal(
                          this.props.user.email,
                          this.props.user.hash,
                          1
                        )
                      }
                    >
                      <Label
                        className="site-white "
                        style={{
                          marginTop: "8px",

                          background: "#a8bf6f"
                        }}
                      >
                        {" "}
                        <i
                          className="fa fa-external-link p-2"
                          aria-hidden="true"
                        ></i>
                        {""}
                        Proceed with new FinScore{" "}
                      </Label>
                    </div>
                    <div
                      className="d-inline-block border .bg-success p-1 float-right mr-2  .text-light "
                      style={{ width: "48%" }}
                      onClick={() =>
                        this.props.paymentRenewal(
                          this.props.user.email,
                          this.props.user.hash,
                          0
                        )
                      }
                    >
                      <Label
                        className="site-white modal-trade-action"
                        style={{
                          marginTop: "8px",

                          background: "#a8bf6f"
                        }}
                      >
                        {" "}
                        <i
                          className="fa fa-external-link p-2"
                          aria-hidden="true"
                        ></i>{" "}
                        Proceed wih same FinScore{" "}
                      </Label>
                    </div>
                  </div>
                  {parseFloat(data.Finscore).toFixed(2) >= 75.0 ? (
                    <div className="mt-1 mb-1 text-center">
                      <div>
                        <Label
                          className="site-white "
                          style={{
                            marginTop: "8px",

                            background: "#a8bf6f"
                          }}
                        ></Label>
                        <a
                          href={`${window.BASE_URL}/pdfs/lor/${this.props.user.user_id}_lor.pdf`}
                          download={`${window.BASE_URL}/pdfs/lor/${this.props.user.user_id}_lor.pdf`}
                          className="text-secondary"
                        >
                          <i
                            className="fa fa-cloud-download"
                            aria-hidden="true"
                          ></i>
                        </a>
                        <p className="d-inline-block ml-2">
                          <b>Download Your Lor</b>
                        </p>
                      </div>
                    </div>
                  ) : null}
                </Card>
              </div>
            </ModalBody>
          </Modal>
        )}
      </div>
    );
  }
}

Portfolio.propTypes = {
  user: PropTypes.object.isRequired,
  postSignupDone: PropTypes.func.isRequired,
  getCurrentUserDetails: PropTypes.func.isRequired,
  paymentRenewal: PropTypes.func.isRequired,
  profilePhoto: PropTypes.func.isRequired,
  getFinscore: PropTypes.func.isRequired,
  getstockstatus: PropTypes.func.isRequired,
  stock: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
  user: state.user.info,
  finscore: state.portfolio.finscore,
  stock: state.portfolio.stock
});

export default withRouter(
  connect(
    mapStateToProps,
    {
      postSignupDone,
      getCurrentUserDetails,
      paymentRenewal,
      profilePhoto,
      getFinscore,
      getstockstatus
    }
  )(Portfolio)
);
