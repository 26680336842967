import React from "react";
import {
  Card,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  Row,
  Col,
  Collapse
} from "reactstrap";
import { getResearchRating, getTop3Ratings } from "../../../utils/format";

class Sector1 extends React.Component {
  render() {
    const data = this.props.data;
    if (!data) return null;
    return (
      <div
        className="myScoreCards"
        onClick={() => this.props.onSelect("sector_1")}
        style={{ cursor: "pointer" }}
      >
        <Row style={{ backgroundColor: "#e4e4e4" }} className="p-2 mt-2">
          <Col lg="3" md="3" sm="3" xs="3">
            <i
              className={
                "fa fontsize-5 d-inline-block  align-middle " +
                (this.props.active == "sector_1" ? "fa-minus" : "fa-plus")
              }
            />
            <span className="d-inline-block">
              <b>Sector-1</b>
            </span>
          </Col>

          <Col className="text-center" lg="3" md="3" sm="3" xs="3">
            <span>
              <b>{data ? "Submitted" : "Locked"}</b>
            </span>
          </Col>
          <Col className="text-center" lg="3" md="3" sm="3" xs="3">
          <span> <b> {this.props.rated && this.props.rated >=3
                ?getResearchRating(data, this.props.rated)
                : "Please complete rate-research"}</b></span>
          </Col>
          <Col className="text-center" lg="3" md="3" sm="3" xs="3">
            <span>
              {this.props.rated && this.props.rated >= 1
                ? Math.round(this.props.rated * 33.333) + "%"
                : "--"}
            </span>
          </Col>
        </Row>
        <Collapse isOpen={this.props.active == "sector_1"}>
          <Card>
            <CardBody>
              <CardTitle>Your Response:</CardTitle>
              <CardTitle className="pt-4">
                Sector - 1: {data ? data.sector1 : ""}
              </CardTitle>
              <Row className="pt-4">
                <Col xs="6">
                  <CardTitle>
                    Company: {data ? data.segment1_comp : ""}
                  </CardTitle>
                </Col>
                <Col xs="6">
                  <CardTitle className=" text-right">
                    Segment: {data ? data.segment1 : ""}
                  </CardTitle>
                </Col>
              </Row>

              <CardText>{data ? data.segment1_desc : ""}</CardText>

              <Row className="pt-4">
                <Col xs="6">
                  <CardTitle>
                    Company: {data ? data.segment2_comp : ""}
                  </CardTitle>
                </Col>
                <Col xs="6">
                  <CardTitle className=" text-right">
                    Segment: {data ? data.segment2 : ""}
                  </CardTitle>
                </Col>
              </Row>

              <CardText>{data ? data.segment2_desc : ""}</CardText>
              <hr />
              <CardTitle className="pt-2">Peer Suggestion:</CardTitle>
              {getTop3Ratings(data).map((e, i) =>
                e.comment.trim() == "" ? null : (
                  <Row className="pb-3" key={e + "" + i}>
                    <Col xs="1">
                      <div className="text-center">
                        <span
                          className={"dot d-inline-block rate_research_by" + i}
                        />
                      </div>
                    </Col>
                    <Col xs="11" className="padding-3">
                      <p>{e.comment}</p>
                    </Col>
                  </Row>
                )
              )}
            </CardBody>
          </Card>
        </Collapse>
      </div>
    );
  }
}
export default Sector1;
