import React from "react";
class LeaverageRatios extends React.Component {
  render() {
    return (
      <div className="container resource">
        <div className="mt-5">
          <h5 className="text-center mb-4">
            <u>
              <b>LEVERAGE RATIOS</b>
            </u>
          </h5>
        </div>
        <p>
          Leverage ratios are financial indicators of the extent to which a
          company is financing its capital from debt and equity. They help in
          assessing a firm’s solvency, its financial obligations and whether it
          is able to meet its obligations. Also, they help in determining to
          what extent a firm is using debt capital and to what extent it is
          using equity capital. Different types of leverage ratio are explained
          below:
        </p>
        <ol>
          <li>Debt ratio</li>
          <p>
            The debt ratio expresses the total debt of a company relative to its
            assets. It measures the extent of a company’s leverage and the
            proportion of a company’s assets that are financed by its debts.
            <span>
              It is calculated by dividing total debt by total assets.
            </span>
          </p>
          <div className="g_boxing">
            <p className="text-center">Debt Ratio =Total debt / Total assets</p>
          </div>
          <p>
            The total debt component of the debt ratio includes the short term
            as well as long term debts. The lower the ratio, the lesser the
            assets are financed by borrowings and loans. A higher debt ratio can
            be a signal of a risky investment as the company might be leaning on
            a lot of borrowings. A debt ratio greater than 1.0 (100%) tells you
            that a company has more debt than assets. Meanwhile, a debt ratio of
            less than 1.0 (100%) indicates that a company has more assets than
            debt.
          </p>
          <p>
            However, a preferable debt ratio may vary from industry to industry
            because some industries do not require much capital and hence should
            desirably have a lower debt ratio, like the technology industry. But
            industries like utilities tend to have a higher debt ratio as they
            require substantial capital for their operations.
          </p>
          <li>Debt to Equity Ratio</li>
          <p>
            Similar to the debt ratio, debt to equity ratio discloses the extent
            to which a company is leveraged. The debt to equity ratio tells us
            about the proportion of debt availed by the company with respect to
            the amount of its equity. It is obtained by dividing the company’s
            total liabilities by the shareholder’s equity.
            <br />
            The difference between debt ratio and debt to equity ratio lies in
            the denominator, the total debt is divided by total assets in the
            calculation of debt ratio and the total debt is divided by total
            equity in the debt to equity ratio.
          </p>
          <p>
            The debt to equity ratio is obtained by using the given formula:
          </p>
          <div className="g_boxing">
            <p className="text-center">
              Debt to Equity Ratio =Total debt / Shareholder's equity
            </p>
          </div>
          <p>
            A ratio of less than 1 will indicate that for every rupee of equity
            the company has a lesser amount of debt and a ratio of more than 1
            will indicate that for every rupee of equity the company has a
            greater amount of debt holdings.
          </p>
        </ol>
      </div>
    );
  }
}

export default LeaverageRatios;
