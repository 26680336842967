import axios from "axios";

import {
  ERRORS_GET,
  SCRIP_INDUSTRIES,
  SCRIP_COMPANY_SEARCH,
  SCRIP_LIST_ALL,
  SCRIP_LIST_FOR_PORTFOLIO,
  SCRIP_WATCH_LIST,
  SCRIP_CMP_DATA,
  SCRIP_CHART_DATA
} from "./types";

import { showLoader } from "./loaderActions";

export const searchCompany = company => dispatch => {
  axios
    .get("/api/scrips/search-company", { params: { company: company } })
    .then(res => {
      dispatch({
        type: SCRIP_COMPANY_SEARCH,
        payload: { scrips: res.data.scrips, keyword: company }
      });
    })
    .catch(err =>
      dispatch({
        type: ERRORS_GET
      })
    );
};

export const getScrips = (
  { segment, industry, sort },
  for_portfolio,
  show_loader
) => dispatch => {
  show_loader = show_loader == undefined ? true : show_loader;
  if (for_portfolio && show_loader) {
    dispatch(showLoader(true));
  }
  axios
    .get("/api/scrips/list", {
      params: {
        segment: segment,
        sort: sort,
        industry: industry,
        for_ticker: !for_portfolio
      }
    })
    .then(res => {
      if (for_portfolio) {
        dispatch({
          type: SCRIP_LIST_FOR_PORTFOLIO,
          payload: {
            scrips: res.data.scrips,
            segment: segment,
            sort: sort,
            industry: industry
          }
        });
        dispatch(showLoader(false));
      } else {
        dispatch({
          type: SCRIP_LIST_ALL,
          payload: res.data
        });
      }
    })
    .catch(err =>
      dispatch({
        type: ERRORS_GET
      })
    );
};

export const getScripData = id => dispatch => {
  axios
    .get("/api/scrips/data/" + id)
    .then(res => {
      dispatch({
        type: SCRIP_CMP_DATA,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: ERRORS_GET
      })
    );
};


export const getChartData = id => dispatch => {
  axios
    .get("/api/scrips/chart-data/" + id)
    .then(res => {
      dispatch({
        type: SCRIP_CHART_DATA,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: ERRORS_GET
      })
    );
};

export const clearCompanySearch = () => dispatch => {
  dispatch({
    type: SCRIP_COMPANY_SEARCH,
    payload: { scrips: [], keyword: "" }
  });
};

export const getIndustries = () => dispatch => {
  axios
    .get("/api/scrips/industries")
    .then(res => {
      dispatch({
        type: SCRIP_INDUSTRIES,
        payload: res.data.industries
      });
    })
    .catch(err =>
      dispatch({
        type: ERRORS_GET
      })
    );
};

export const addWatch = (scrip_id, is_watch, seg) => dispatch => {
  axios
    .post(`/api/scrips/watch`, {
      scrip_id: scrip_id,
      is_watch: is_watch
    })
    .then(response => {
      dispatch(getWatch(seg));
    })
    .catch(error => {});
};

export const getWatch = cap => dispatch => {
  axios
    .get(`/api/scrips/watch/${cap}`)
    .then(response => {
      dispatch({
        type: SCRIP_WATCH_LIST,
        payload: response.data.watchList
      });
    })
    .catch(error => {});
};
