import React from "react";

class DragProfile1 extends React.Component {
  state = {
    skillsBackup: [],
    data2: []
  };
  componentDidMount() {
    this.props.skills.response.map((e, i) => {
      this.state.data2.push("");
      this.setState({ ...this.state.data2 });
    });
  }

  onDrag(e, name) {
    e.dataTransfer.setData("text", name);
  }

  onDragStart(e, name) {
    e.dataTransfer.setData("id", name);
  }

  onDragOver = ev => {
    ev.preventDefault();
  };

  onDrop = async (ev, i) => {
    let id = ev.dataTransfer.getData("id");
    let id2 = ev.dataTransfer.getData("text");

    if (await (id && id !== "")) {
      if (this.state.data2[i] == "") {
        this.state.data2.splice(i, 1, id);
        this.setState({ ...this.state.data2 });

        let index = this.props.skills.response.findIndex(o => o.skills == id);
        this.props.skills.response.splice(index, 1);
        this.setState({
          ...this.props.skills.response
        });
      }
    }

    if (await (id2 && id2 !== "")) {
      let index = this.state.data2.findIndex(o => o == id2);
      if (this.state.data2[i] !== "" && index < i) {
        for (var j = index; j <= i; j++) {
          this.state.data2[j] = this.state.data2[j + 1];
        }
        this.state.data2[i] = id2;
      } else if (this.state.data2[i] !== "" && index > i) {
        for (var j = index; j >= i; j--) {
          this.state.data2[j] = this.state.data2[j - 1];
        }
        this.state.data2[i] = id2;
      } else {
        this.state.data2[index] = this.state.data2[i];
        this.state.data2[i] = id2;
      }
      this.setState({ ...this.state.data2 });
    }

    await this.props.finalData(this.state.data2);
  };

  render() {
    return (
      <div className="dragProfile container">
        <div className="mt-3">
          <h4>My Evaluation</h4>
          <hr />
        </div>

        <div>
          <h5 style={{ fontSize: "20px" }}>
            Drag and drop the skillset based on your proficiency
          </h5>

          <div className="d-inline-block" style={{ width: "50%" }}>
            <span style={{ position: "absolute", marginTop: "13px" }}>
              Highly Efficient
            </span>
            {this.state.data2.map((a, i) => (
              <div className="d-table mt-2" key={i}>
                <div
                  className={
                    i < this.state.data2.length - 1
                      ? "circle_line"
                      : "circle_line1"
                  }
                  style={{ marginLeft: "120px" }}
                />
                <div
                  draggable
                  className={
                    this.state.data2[i] !== "" ? "dropRectangle" : "rectangle02"
                  }
                  onDragStart={e => {
                    this.onDrag(e, a);
                  }}
                  onDragOver={e => this.onDragOver(e)}
                  onDrop={e => this.onDrop(e, i)}
                >
                  {this.state.data2.map((drop, j) =>
                    i == j ? (
                      <span className={drop !== "" ? "rectangle3" : ""} key={j}>
                        {drop}
                      </span>
                    ) : (
                      ""
                    )
                  )}
                </div>
              </div>
            ))}
            <span style={{ position: "absolute", marginTop: "-35px" }}>
              Less Efficient
            </span>
          </div>
          {this.props.skills.response.length > 0 ? (
            <div className="d-inline-block  outer_box">
              <div className="draggble">
                {this.props.skills.response.map((a, i) => (
                  <div
                    className="dragbox"
                    draggable
                    key={i}
                    onDragStart={e => {
                      this.onDragStart(e, a.skills);
                    }}
                  >
                    <i className="fa fa-ellipsis-v d-inline-block" />
                    <i className="fa fa-ellipsis-v d-inline-block" />
                    <span
                      style={{ color: "white" }}
                      className="d-inline-block  pl-1"
                    >
                      {a.skills}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

export default DragProfile1;
