import React from "react";
import { Form, Label, Input, FormGroup, Button } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { saveRateResearch } from "../../../actions/newresearchActions";
class RateResearch3 extends React.Component {
  state = {
    submitted: false,
    keyCount: 0
  };
  componentDidUpdate(prevProp) {
    if (
      this.props.rate_research.research.id != prevProp.rate_research.research.id
    ) {
      this.setState({ submitted: false });
    }
  }
  handleSubmit = e => {
    e.preventDefault();
    if (
      !e.target.question1.value ||
      !e.target.question2.value ||
      !e.target.question3.value ||
      !e.target.question4.value ||
      !e.target.response.value.trim()
    ) {
      return this.props.showInvalidFormAlert();
    }
    let data = {
      question1_ans: e.target.question1.value,
      question2_ans: e.target.question2.value,
      question3_ans: e.target.question3.value,
      question4_ans: e.target.question4.value,
      rate_res: e.target.response.value
    };
    this.props.saveRateResearch({
      data,
      week: this.props.rate_research.week,
      research_id: this.props.rate_research.research.id,
      points:
        parseInt(e.target.question1.value) +
        parseInt(e.target.question2.value) +
        parseInt(e.target.question3.value) +
        parseInt(e.target.question4.value)
    });
    this.setState({ submitted: true });
  };
  textArea = async e => {
    await this.setState({
      keyCount: e.target.value.length
    });
  };
  render() {
    if (this.state.submitted) {
      return <div> submitted</div>;
    }
    const { research, week } = this.props.rate_research;
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="RateResearch3">
          <p>
            Please download and read the Research pdf and rate the it based on
            following parameters
          </p>
          <div className="border border-dark p-3">
            <h5>Download Research Pdf:</h5>
            <div className="text-center">
              <a
                href={`${window.BASE_URL}/uploads/${research.data.filename}`}
                download
              >
                <i className="fa fa-download" style={{ fontSize: "70px" }} />

                <h5>
                  <span style={{ color: "blue", fontSize: "20px" }}>Click</span>{" "}
                  here to download
                </h5>
              </a>
            </div>
          </div>
          <div>
            <h6 className="pt-3">
              Does the report by the author cover all the sub-sections mentioned
              in the research brief? (General Overview, Management
              Qualifications, SWOT Analysis, Competitive Analysis, Conclusion
              and way forward)
            </h6>
            <div className="pl-4">
              <div className="radio">
                <Label>
                  <Input type="radio" name="question1" value="2" />
                  Yes (2 points)
                </Label>
              </div>

              <div className="radio">
                <Label>
                  <Input type="radio" name="question1" value="0" />
                  No (0 points)
                </Label>
              </div>
            </div>
          </div>

          <div>
            <h6 className="pt-3">
              Is the report easy to read and well formatted?
            </h6>
            <div className="pl-4">
              <div className="radio">
                <Label>
                  <Input type="radio" name="question2" value="2" />
                  Yes (2 points)
                </Label>
              </div>

              <div className="radio">
                <Label>
                  <Input type="radio" name="question2" value="0" />
                  No (0 points)
                </Label>
              </div>
            </div>
          </div>

          <div>
            <h6 className="pt-3">
              Does the report use tables, graphs and interactive elements to
              justify the analysis given?
            </h6>
            <div className="pl-4">
              <div className="radio">
                <Label>
                  <Input type="radio" name="question3" value="1" />
                  Yes (1 point)
                </Label>
              </div>
              <div className="radio">
                <Label>
                  <Input type="radio" name="question3" value="0" />
                  No (0 point)
                </Label>
              </div>
            </div>
          </div>
          <div>
            <h6 className="pt-3">
              On a scale of 5, what would be your overall rating of the report
              submitted by the researcher?
            </h6>
            <div className="pl-4">
              <div className="radio-inline">
                <Label>
                  <Input type="radio" name="question4" value="1" />1
                </Label>
              </div>
              <div className="radio-inline">
                <Label>
                  <Input type="radio" name="question4" value="2" />2
                </Label>
              </div>
              <div className="radio-inline">
                <Label>
                  <Input type="radio" name="question4" value="3" />3
                </Label>
              </div>
              <div className="radio-inline">
                <Label>
                  <Input type="radio" name="question4" value="4" />4
                </Label>
              </div>
              <div className="radio-inline">
                <Label>
                  <Input type="radio" name="question4" value="5" />5
                </Label>
              </div>
            </div>
          </div>
          <div className="pt-3">
            <p>Any feedback that you would like to give to the researcher?</p>
            <FormGroup>
              <Input
                type="textarea"
                id="comment"
                onChange={this.textArea}
                minLength="140"
                maxLength="280"
                name="response"
                placeholder="Write feedback between 140 characters to 280 characters"
              />
              <span className="char_count">{this.state.keyCount}/280</span>
            </FormGroup>
          </div>
          <div className="text-center">
            <Button
              className="btn btn-padding btn-secondary mt-4"
              type="submit"
            >
              Submit
            </Button>
          </div>
        </div>
      </form>
    );
  }
}
RateResearch3.propTypes = {
  saveRateResearch: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {};
};
export default connect(
  mapStateToProps,
  { saveRateResearch }
)(RateResearch3);
