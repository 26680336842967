import React from "react";
import {
  Row,
  Col,
  Button,
  Card,
  CardImg,
  CardText,
  CardBody,
  CardFooter,
  CardTitle,
  CardSubtitle,
  UncontrolledTooltip,
  Table
} from "reactstrap";

import PropTypes from "prop-types";
import { connect } from "react-redux";

import { withRouter } from "react-router-dom";
import {
  getGameData,
  setWins,
  setRoundWins,
  getGameStats
} from "../../../actions/gamesActions";
import { formatCurrency } from "../../../utils/format";
import GamesPerformance from "../games_performance";

class G1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      card: 1,
      card0Class: "",
      showComCard: false,
      selField: "",
      wins: 0,
      cardTimer: 10
    };
  }
  timer = 0;
  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }
  setStat = field => {
    if (!this.state.showComCard) {
      const that = this;
      if (this.props.game == 2 && this.timer) {
        clearInterval(this.timer);
      }
      let my_ind = this.props.g1.data[this.state.card].industry;
      let my_id = this.props.g1.data[this.state.card].scrip_id;
      let matcheddata = this.props.g1.data.find(
        e => e.industry == my_ind && e.scrip_id != my_id
      );
      let my_card = matcheddata;
      let com_card = this.props.g1.data[this.state.card];
      let wins = this.state.wins;
      if (
        field != "" &&
        parseFloat(my_card[field]) >= parseFloat(com_card[field])
      ) {
        wins = wins + 1;
        this.props.setRoundWins("g" + this.props.game, 1);
      } else {
        this.props.setRoundWins("g" + this.props.game, -1);
      }
      this.setState({ selField: field, wins: wins, showComCard: true });
      if (this.state.card + 2 >= 20) {
        this.props.setWins("g" + this.props.game, wins);
      }
      // setTimeout(function() {
      //   if (that.state.card <= 30) {
      //     that.setCard(that.state.card + 2);
      //   }
      // }, 3000);
    }
  };
  setCard = () => {
    if (this.state.showComCard) {
      let card = this.state.card + 2;
      this.setState({
        cardTimer: 10,
        selField: "",
        card: card,
        showComCard: false
      });
    }
  };

  componentDidMount = () => {
    this.props.getGameData("g1");
  };

  getRowBg = (field, typ, msg) => {
    if (this.state.selField == field) {
      if (msg) {
        if (typ == 1 && msg == "Congrats! You win") {
          return { backgroundColor: "green", color: "#FFF", cursor: "pointer" };
        } else if (typ == 2 && msg != "Congrats! You win") {
          return { backgroundColor: "green", color: "#FFF" };
        } else {
          return { backgroundColor: "red", color: "#FFF" };
        }
      } else {
        return this.state.selField === field
          ? { backgroundColor: "#858585", color: "#000" }
          : null;
      }
    }
    return { cursor: "pointer" };
  };
  getCardBody = (card, typ, msg) => {
    console.log(card,"daaraaraara")
    return (
      <CardBody>
        <h5>{card.company.toUpperCase()}</h5>
        <Table striped className="card_game_table table-hover">
          <tbody>
            <tr
              style={this.getRowBg("eps", typ, msg)}
              onClick={() => this.setStat("eps")}
            >
              <td style={{ fontWeight: "600" }}>
                <div>
                  Earnings Per Share (EPS)
                  <i
                    className="fa fa-info-circle fa-sm d-inline-block pl-1"
                    id={"eps_s" + typ}
                  />
                  <UncontrolledTooltip placement="right" target={"eps_s" + typ}>
                    <b>Earnings per share (EPS)</b> is the portion of a
                    company's profit allocated to each share. Higher EPS means
                    higher profitability.
                  </UncontrolledTooltip>
                </div>
              </td>
              <td>{card.eps}</td>
            </tr>
            <tr
              style={this.getRowBg("pbt", typ, msg)}
              onClick={() => this.setStat("pbt")}
            >
              <td style={{ fontWeight: "600" }}>
                Profit before tax (in cr.)
                <i
                  className="fa fa-info-circle fa-sm d-inline-block pl-1"
                  id={"pbt" + typ}
                />
                <UncontrolledTooltip placement="right" target={"pbt" + typ}>
                  <b>Profit before tax (PBT)</b> measures the company's profits
                  before deducting corporate tax (company income tax). It
                  deducts all other expenses from revenue like operating expense
                  and interest expense.
                </UncontrolledTooltip>
              </td>
              <td>{card.pbt}</td>
            </tr>
            <tr
              style={this.getRowBg("current_ratio", typ, msg)}
              onClick={() => this.setStat("current_ratio")}
            >
              <td style={{ fontWeight: "600" }}>
                Current Ratio
                <i
                  className="fa fa-info-circle fa-sm d-inline-block pl-1"
                  id={"cr" + typ}
                />
                <UncontrolledTooltip placement="right" target={"cr" + typ}>
                  <b>Current Ratio</b> measures whether or not a firm is capable
                  of meeting its short-term liabilities. It is a measures a
                  firm’s liquidity. A higher current ratio is better for a firm.
                </UncontrolledTooltip>
              </td>
              <td>{card.current_ratio}</td>
            </tr>
            <tr
              style={this.getRowBg("revenue", typ, msg)}
              onClick={() => this.setStat("revenue")}
            >
              <td style={{ fontWeight: "600" }}>
                Revenue (in cr.)
                <i
                  className="fa fa-info-circle fa-sm d-inline-block pl-1"
                  id={"rev" + typ}
                />
                <UncontrolledTooltip placement="right" target={"rev" + typ}>
                  <b>Revenue</b> is the income generated from sales of goods or
                  services of a firm before deducting any costs or expenses.
                </UncontrolledTooltip>
              </td>
              <td>{card.revenue}</td>
            </tr>
            <tr
              style={this.getRowBg("mcap", typ, msg)}
              onClick={() => this.setStat("mcap")}
            >
              <td style={{ fontWeight: "600" }}>
                Market Capitalisation
                <i
                  className="fa fa-info-circle fa-sm d-inline-block pl-1"
                  id={"mcap" + typ}
                />
                <UncontrolledTooltip placement="right" target={"mcap" + typ}>
                  <b>Market Capitalization</b> is the valuation of a firm based
                  on its current market price and number of shares.
                </UncontrolledTooltip>
              </td>
              <td>{card.mcap} Cr.</td>
            </tr>
            <tr
              style={this.getRowBg("yr_1", typ, msg)}
              onClick={() => this.setStat("yr_1")}
            >
              <td style={{ fontWeight: "600" }}>
                1 Year Trend
                <i
                  className="fa fa-info-circle fa-sm d-inline-block pl-1"
                  id={"yr_1" + typ}
                />
                <UncontrolledTooltip placement="right" target={"yr_1" + typ}>
                  <b>1 Year Trend</b> indicates the trend of that company’s
                  stock price over the last 1 year.
                </UncontrolledTooltip>
              </td>
              <td>{card.yr_1}</td>
            </tr>
          </tbody>
        </Table>
      </CardBody>
    );
  };

  getScoreBoard = result_msg => {
    let win_points = this.props.game == 1 ? 100 : 100;
    let loose_points = this.props.game == 1 ? 50 : 50;

    let amt =
      this.state.wins * win_points -
      (this.state.showComCard
        ? parseInt(this.state.card / 2) + 1 - this.state.wins
        : parseInt(this.state.card / 2) - this.state.wins) *
        loose_points;
    let bg_footer = this.state.showComCard
      ? result_msg == "Congrats! You win"
        ? "green"
        : "red"
      : "#dee2e6";
    return (
      <div
        style={{
          maxWidth: "380px",
          height: "500px",
          margin: "auto",
          paddingTop: "30px",
          paddingBottom: "10px"
        }}
      >
        <h4 style={{ textAlign: "center" }}>Scoreboard</h4>
        <Card
          className="gameCd1"
          style={{
            width: "380px",
            height: "400px",
            borderRadius: "5px"
          }}
        >
          <table
            className="scoreboard-tbl"
            style={{
              borderRadius: "5px",
              margin: "1px",
              width: "100%"
            }}
          >
            <tbody>
              <tr
                style={{
                  color: "#fff",
                  fontWeight: 700,
                  backgroundColor: "green"
                }}
              >
                <td
                  style={{
                    borderRadius: "5px 0 0 0",
                    padding: "15px!important"
                  }}
                >
                  ROUND WINNINGS
                </td>
                <td
                  style={{
                    textAlign: "right",
                    borderRadius: "0px 5px 0 0",
                    padding: "15px!important"
                  }}
                >
                  {formatCurrency(amt, true)}
                </td>
              </tr>
              <tr
                style={{
                  color: "#fff",
                  fontWeight: 700,
                  backgroundColor: "green",
                  borderTop: "1px solid #e0e0e0"
                }}
              >
                <td style={{ padding: "15px!important" }}>CASH IN HAND</td>
                <td style={{ textAlign: "right", padding: "15px!important" }}>
                  {formatCurrency(this.props.user.total_money + amt, true)}
                </td>
              </tr>
              <tr
                style={{
                  color: "#fff",
                  fontWeight: 700,
                  backgroundColor: "green",
                  borderTop: "1px solid #e0e0e0"
                }}
              >
                <td style={{ padding: "15px!important" }}>ROUNDS</td>
                <td style={{ textAlign: "right", padding: "15px!important" }}>
                  {parseInt(this.state.card / 2) + 1}/10
                </td>
              </tr>
              <tr
                style={{
                  color: "#fff",
                  fontWeight: 700,
                  backgroundColor: "green",
                  borderTop: "1px solid #e0e0e0"
                }}
              >
                <td style={{ padding: "15px!important" }}>EARNING ROUNDS</td>
                <td style={{ textAlign: "right", padding: "15px!important" }}>
                  {this.state.wins}/{parseInt(this.state.card / 2) + 1}
                </td>
              </tr>
            </tbody>
          </table>
          <div>
            {!this.state.showComCard ? (
              this.props.game == 2 ? (
                <div
                  style={{
                    textAlign: "center",
                    marginTop: "2px",
                    fontSize: "34px",
                    fontWeight: "900"
                  }}
                >
                  <h1
                    className="pulse-button"
                    style={{
                      margin: "auto",
                      paddingTop: "28px",
                      borderRadius: "50px",
                      width: "100px",
                      height: "100px",
                      color: "#fff"
                    }}
                  >
                    {this.state.cardTimer}
                  </h1>
                </div>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </div>

          <CardFooter
            style={{
              backgroundColor: bg_footer,
              color: "white",
              position: "absolute",
              bottom: "0px",
              width: "380px",
              height: "60px"
            }}
          >
            {this.state.showComCard ? (
              <div
                className="animated fadeIn"
                style={{
                  textAlign: "center",
                  paddingBottom: "10px"
                }}
              >
                <h3>{result_msg}</h3>
              </div>
            ) : null}
          </CardFooter>
        </Card>
      </div>
    );
  };
  showCard = card => {
    if (this.props.game == 2 && this.state.cardTimer == 10) {
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.timer = setInterval(() => {
        if (this.state.cardTimer > 0) {
          this.setState({ cardTimer: this.state.cardTimer - 1 });
        } else {
          clearInterval(this.timer);
          this.timer = 0;
          this.setState({ showComCard: true });
        }
      }, 1000);
    }
    let my_ind = this.props.g1.data[this.state.card].industry;
    let my_id = this.props.g1.data[this.state.card].scrip_id;
    let matcheddata = this.props.g1.data.find(
      e => e.industry == my_ind && e.scrip_id != my_id
    );
    let my_card = matcheddata;
    let com_card = this.props.g1.data[this.state.card];
    let result_msg = "";
    if (this.state.showComCard) {
      if (
        this.state.selField != "" &&
        parseFloat(my_card[this.state.selField]) >
          parseFloat(com_card[this.state.selField])
      ) {
        result_msg = "Congrats! You win";
      } else {
        result_msg = "AI wins";
      }
    }

    let win_points = this.props.game == 1 ? 100 : 100;
    let loose_points = this.props.game == 1 ? 50 : 50;

    let amt =
      this.state.wins * win_points -
      (this.state.showComCard
        ? parseInt(this.state.card / 2) + 1 - this.state.wins
        : parseInt(this.state.card / 2) - this.state.wins) *
        loose_points;

    return (
      <div className="container mt-2">
        <div className="text-center">
          <h3>Stockdeck {this.props.game == 1 ? " Flash!" : " Shootout!"}</h3>
          <h5>Rounds - {parseInt(this.state.card / 2) + 1}/10</h5>
        </div>
        <div className="row">
          <div className="col-lg-5 col-md-12 col-sm-12 p-5">
            <div className="bg-color18 p-4">
              <div>
                <div
                  className="d-table"
                  style={{ width: "100%", paddingBottom: "20px" }}
                >
                  <div className="d-table-cell align-middle">
                    <img
                      src="/img/profile.png"
                      style={{ maxWidth: "125px", verticalAlign: "baseline" }}
                      className="mt-3"
                    />
                  </div>
                  <div className="earning18 table-cell align-middle">
                    <div className="float-right">
                      <div className="p-1 ml-1">Total Amount</div>
                      <div className="pb-2">
                        <span className={"bg-green btn-typ1 ml-2 "}>
                          {formatCurrency(
                            this.props.user.total_money + amt,
                            true
                          )}
                        </span>
                      </div>
                      <div className="p-1 ml-1">Game Earning</div>
                      <div>
                        {" "}
                        <span className={"bg-green btn-typ1 ml-2"}>
                          {formatCurrency(amt, true)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="rectangle20">
                {this.getCardBody(my_card, 1, result_msg)}
              </div>
            </div>
          </div>

          <div className="col-lg-2 col-md-12 col-sm-12 text-center">
            <div style={{ minHeight: "350px" }}>
              {this.state.showComCard ? (
                <div className="animated pulse">
                  <div>
                    <img
                      src={`/img/${
                        result_msg == "Congrats! You win" ? "profile" : "robo"
                      }.png`}
                      style={{ maxWidth: "125px" }}
                      className="mid-img20 "
                    />
                  </div>
                  <br />
                  <div>
                    <h4>{result_msg}</h4>
                  </div>
                  <div>
                    <Button
                      onClick={this.setCard}
                      className={"btn site-gray "}
                      style={{
                        padding: "5px 20px",
                        width: "100px"
                      }}
                    >
                      NEXT
                    </Button>
                  </div>
                </div>
              ) : null}
            </div>
            {!this.state.showComCard ? (
              this.props.game == 2 ? (
                <div
                  className="animated pulse"
                  style={{
                    textAlign: "center",
                    marginTop: "2px",
                    fontSize: "34px",
                    fontWeight: "900"
                  }}
                >
                  <h1
                    className="pulse-button"
                    style={{
                      margin: "auto",
                      paddingTop: "28px",
                      borderRadius: "50px",
                      width: "100px",
                      height: "100px"
                    }}
                  >
                    {this.state.cardTimer}
                  </h1>
                </div>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </div>

          <div className="col-lg-5 col-md-12 col-sm-12 p-5">
            <div className="bg-color18 p-4">
              <div>
                <img
                  src="/img/robo.png"
                  style={{ maxWidth: "125px" }}
                  className="float-right mt-4 mr-4 mb-4"
                />
              </div>

              <div className="rectangle20">
                {this.state.showComCard ? (
                  this.getCardBody(com_card, 2, result_msg)
                ) : (
                  <CardBody style={{ height: "403px" }}>
                    {this.props.game == 2 ? (
                      <div
                        style={{
                          fontSize: "24px",
                          textAlign: "center",
                          paddingTop: "50%",
                          marginTop: "-60px"
                        }}
                      >
                        {com_card.company.toUpperCase()}
                      </div>
                    ) : (
                      ""
                    )}
                  </CardBody>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  showStats = () => {
    return (
      <Row
        style={{
          fontSize: "18px"
        }}
      >
        <Col sm="12">
          <div
            style={{
              margin: "auto",
              paddingTop: "30px",
              paddingBottom: "10px",
              textAlign: "center",
              marginLeft: "-250px"
            }}
          >
            <h1>STOCKDECK{this.props.game == 1 ? " FLASH!" : " SHOUTOUT!"}</h1>
          </div>
        </Col>
        <Col sm="12">
          <GamesPerformance
            game={this.props.game}
            goFront={() => this.props.goFront()}
            resetGame={() =>
              this.setState({
                card: 1,
                card0Class: "",
                showComCard: false,
                selField: "",
                wins: 0
              })
            }
          />
        </Col>
      </Row>
    );
  };
  render() {
    if (!this.props.g1.data) return null;
    if (this.state.card < 20) {
      return this.showCard(this.state.card);
    } else {
      if (this.timer) {
        clearInterval(this.timer);
      }
      return this.showStats();
    }
    return null;
  }
}

G1.propTypes = {
  getGameData: PropTypes.func.isRequired,
  setWins: PropTypes.func.isRequired,
  setRoundWins: PropTypes.func.isRequired,
  getGameStats: PropTypes.func.isRequired,
  g1: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  g1: state.games.g1
});

export const Game = connect(mapStateToProps, {
  getGameData,
  setWins,
  setRoundWins,
  getGameStats
})(G1);

export const title = "Slam Card";
