import React from "react";
import { Table } from "reactstrap";
class SecondaryMarket extends React.Component {
  render() {
    return (
      <div className="secMarket resource">
        <h6>
          <u>
            <b>SECONDARY MARKET</b>
          </u>
        </h6>
        <p>
          The secondary market offers avenues for buying and selling of
          financial instruments like stocks and bonds between individual
          investors. The basic difference between a primary market and a
          secondary market is that in a primary market, shares are sold by the
          issuing company to investors, whereas, in a secondary market, shares
          are bought and sold between individual investors, thus depicting the
          demand and supply levels of stocks.
          <br />
          Also, as the company itself sells its shares in the primary market,
          the price bands are fixed beforehand by the company and securities are
          issued for the first time by the company. On the other hand, in the
          secondary market, trade takes place between thousands of investors
          every day. It involves selling and buying of securities which have
          been already sold in the primary market
        </p>
        <Table>
          <thead>
            <tr>
              <th />
              <th>PRIMARY MARKET</th>
              <th>SECONDARY MARKET</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Type of Transaction</td>
              <td>Direct</td>
              <td>Indirect</td>
            </tr>
            <tr>
              <td>Parties Involved</td>
              <td>Issuing Company and Investors</td>
              <td>Investors</td>
            </tr>
            <tr>
              <td>Price</td>
              <td>Fixed Price Bands</td>
              <td>Depends on the demand and supply level of the stock</td>
            </tr>
          </tbody>
        </Table>
        <p>
          For a better understanding, let us take the Finlatics Program into
          account. You are a virtual investor who has participated in this
          program. Will you be able to guess whether the virtual platform on
          which you are trading can be considered as a primary market or a
          secondary market?
        </p>
        <p>
          Yes, you have guessed it right, the platform can be considered to be a
          part of the secondary market where shares are bought and sold between
          investors. You can be considered to be an investor trading on the
          Finlatics platform which is similar to what exists in a secondary
          market.
        </p>
        <p>
          <u>PRIMARY MARKET :</u>
        </p>
        <div className="border border-dark p-3">
          <img
            src="/img/ONLYC.png"
            className="rounded mx-auto d-block"
            width="50%"
            alt="primary"
          />
        </div>

        <p>
          <u>SECONDARY MARKET :</u>
        </p>
        <div className="border border-dark p-3">
          <img
            src="/img/second.png"
            className="rounded mx-auto d-block"
            width="50%"
            alt="secondary"
          />
        </div>
        <p>
          Now, if you are wondering how the prices of various stocks are
          determined in the secondary market, remember that they depend on the
          demand and supply levels of the stock. It is common to observe stock
          prices of thousands of companies changing every minute of the day,
          with prices one day shooting up at the speed of a rocket and then
          another day hitting rock bottom.
        </p>
        <p>
          Basically, a company’s stock price is determined by the market demand
          and supply for the particular stock. These demand and supply levels
          are in turn affected by a number of factors.
        </p>
        <ul>
          <li>
            The fundamentals of the company influence its stock price to a large
            extent. The fundamentals of the company are reflected in the annual
            and quarterly reports published by the company.
            <br />
            The research blogs prepared and submitted by you on Finlatics are
            similar to performing a fundamental analysis of a company. As
            fundamental analysis involves researching the management of the
            company, its revenue growth, how well is it performing across
            financial ratios (price-earnings ratio, earnings per share ratio)
            and whether its performance is on par than the industry in which it
            operates. the research project assigned to you is a perfect example
            of practical level fundamental analysis work.
          </li>
          <li>
            Market news regarding the company influences the demand and supply
            of the company’s stock. If the company is in the news for positive
            reasons like posting a double-digit growth, then this will lead to
            an increase in demand for the stock under consideration which will
            cause its price to rise, as every investor would be interested in
            purchasing that stock. However, if the company is in the news for
            negative reasons like lower demand scenario, this will lead to a
            decrease in the demand for the particular stock and every investor
            would be interested in selling his shares of the company to avoid
            substantial loss which will eventually increase the supply level of
            the stock in the market, thus driving down its market price.
            However, there are interesting calls that one can take during
            negative news as well. While looking at the news as a factor, it is
            important to differentiate and subjectively analyze news, by gauging
            the impact it would have on the company from a time-based
            standpoint.
          </li>
          <li>
            There are some factors which influence the share price indirectly.
            These are called macroeconomic and industry-wide parameters. These
            include government announcements that are beneficial for the
            industry under which the company comes. Even the government
            announcing an increase in its public spending on infrastructure
            comes as a piece of great news to a number of industries involved in
            the building of such infrastructure and thus affects the share price
            of the companies under these industries in the anticipation that the
            company will post more profit levels and growth if it gets involved
            in the government projects.
            <br />
            Irrespective of whether the company is in the news for favorable
            reasons or for unfavorable reasons, your positioning as an investor
            can be anything. When a company is in the news for negative things
            and its share price has plunged, some investors might consider this
            situation as a great opportunity for buying these shares and exit
            the market after some period of time, once they are able to make
            substantial capital gains. So even if a company’s stock has been
            observing a downward trend for a few months, there always exists a
            possibility that it might overcome such a phase if the company has
            strong fundamentals.
          </li>
        </ul>
      </div>
    );
  }
}

export default SecondaryMarket;
