import React from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  getOrders,
  submitReason,
  cancelOrder,
} from "../../../actions/orderActions";

import {
  Table,
  Input,
  FormGroup,
  Button,
  UncontrolledTooltip,
} from "reactstrap";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import "react-bootstrap-table/dist/react-bootstrap-table.min.css";

import ReasonModal from "./reason_modal";

class OrderBook extends React.Component {
  state = {
    show_reason_modal: false,
    order_id_reason: 0,
  };

  componentDidMount() {
    this.props.getOrders();
  }

  hideReasonModal = () => {
    this.setState({ show_reason_modal: false });
  };

  handleReason = (reason) => {
    this.props.submitReason(this.state.order_id_reason, reason);
    this.setState({ show_reason_modal: false });
  };

  showReasonModal = (order_id) => {
    this.setState({ show_reason_modal: true, order_id_reason: order_id });
  };

  cancelOrderDlg = (id) => {
    var that = this;
    confirmAlert({
      title: "Cancel Order?",
      message: "Are you sure you want to do this?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            that.props.cancelOrder(id);
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  getDate(dt, cell) {
    var todayTime = new Date(dt);
    var month = todayTime.getMonth() + 1;
    var day = todayTime.getDate();
    var year = todayTime.getFullYear();

    month = month < 10 ? "0" + month : month;
    day = day < 10 ? "0" + day : day;

    var hours = todayTime.getHours();
    var minutes = todayTime.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;

    return (
      <div style={{ textAlign: "center" }}>
        {hours}:{minutes} {ampm} <br />
        {day}/{month}/{year}
      </div>
    );
  }

  getCompany = (data, cell) => {
    var market_update = this.props.new_market_data[cell.Scrip.srCode];

    if (
      market_update &&
      parseFloat(market_update) - parseFloat(cell.Scrip.prev_close) < 0
    ) {
      return <div className="tdNeg" style={{ width: "95%" }}>{data.company}</div>;
    } else if (
      parseFloat(cell.Scrip.market_price) - parseFloat(cell.Scrip.prev_close) <
      0
    ) {
      return <div className="tdNeg">{data.company}</div>;
    } else {
      return <div className="tdPos">{data.company}</div>;
    }
  };
  getAction = (data, cell) => {
    return cell.qty > -1 ? (
      <div>
      <div
      style={{
        color: "white",
        textAlign: "center",
        fontSize: "12px",
        backgroundColor: " #31B258",
        paddingTop: "5px",
        paddingBottom: "5px",
        height: "fit-content",
        borderRadius: "5px",
        width: "80%",
      }}
      className=""
      >
        BUY
      </div>
      </div>
    ) : (
      <div>
      <div
      style={{
        color: "white",
        textAlign: "center",
        fontSize: "12px",
        backgroundColor: "red",
        paddingTop: "5px",
        paddingBottom: "5px",
        height: "fit-content",
        borderRadius: "5px",
        width: "80%",
      }}
      className=""
      >
        SELL
      </div>
      </div>
    );
  };

  getType = (data, cell) => {
    switch (cell.typ) {
      case "LIMIT":
        return (
          <div>
            <div
                style={{
                  color: "white",
                  textAlign: "center",
                  fontSize: "12px",
                  backgroundColor: "black",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  height: "fit-content",
                  borderRadius: "5px",
                }}
                className="btn-market"
            >
              LIMIT
            </div>
            </div>
        );
        break;
      case "MARKET":
        return (
          <div>
            <div
            style={{
              color: "white",
              textAlign: "center",
              fontSize: "12px",
              backgroundColor: "black",
              paddingTop: "5px",
              paddingBottom: "5px",
              height: "fit-content",
              borderRadius: "5px",
            }}
            className="btn-market"
            >
              MARKET
            </div>
            </div>
        );
        break;

      case "LIMITSP":
        return (
          <div>
            <div
          style={{
            color: "white",
            textAlign: "center",
            fontSize: "12px",
            backgroundColor: "black",
            paddingTop: "5px",
            paddingBottom: "5px",
            height: "fit-content",
            borderRadius: "5px",
          }}
          className="btn-market"
            >
              STOPLOSS
            </div>
            </div>
        );
        break;

      case "TRAILSP":
        return (
          <div>
            <div
             style={{
              color: "white",
              textAlign: "center",
              fontSize: "12px",
              backgroundColor: "black",
              paddingTop: "5px",
              paddingBottom: "5px",
              height: "fit-content",
              borderRadius: "5px",
            }}
            className="btn-market"
            >
              TRAILSTOP
            </div>
            </div>
        );
        break;

      case "MARKETSP":
        return (
          <div>
            <div
              style={{
                color: "white",
                textAlign: "center",
                fontSize: "12px",
                backgroundColor: "black",
                paddingTop: "5px",
                paddingBottom: "5px",
                height: "fit-content",
                borderRadius: "5px",
              }}
              className="btn-market"
            >
              STOPLOSS
            </div>
            </div>
        );
        break;
    }
  };

  getStatus = (data, cell) => {
    switch (cell.status) {
      case "PENDING":
        return (
          <div>
             <div style={{ textAlign: "center" }}>
            <div
             style={{
              color: "white",
              fontSize: "12px",
              backgroundColor: "black",
              paddingTop: "3px",
              paddingBottom: "3px",
              height: "fit-content",
              borderRadius: "5px",
              marginBottom: "2px",
              marginTop: "3px",
            }}
            className="btn-market"
            >
              WORKING
            </div>
            <div style={{ fontSize: "14px", paddingBottom: "4px" }}>
              at ₹{cell.price}
            </div>
            {cell.typ != "TRAILSP" ? (
              <div
                onClick={() => this.cancelOrderDlg(cell.id)}
                style={{
                  color: "white",
                  textAlign: "center",
                  fontSize: "10px",
                  width: "70px",
                  margin: "auto",
                  height: "fit-content",
                  borderRadius: "5px",
                }}
                className="site-cancel hover-cursor p-1"
              >
                CANCEL
              </div>
            ) : null}
          </div>

          </div>
         
        );
        break;
      case "REJECTED":
        return (
          <div>

         
          <div
          style={{
            color: "white",
            textAlign: "center",
            fontSize: "12px",
            backgroundColor: "red",
            paddingTop: "5px",
            paddingBottom: "5px",
            height: "fit-content",
            borderRadius: "5px",
          }}
          className="btn-market"
          >
            REJECTED
          </div>
          </div>
        );
        break;
      case "CANCELLED":
        return (
          <div>
          <div
          style={{
            color: "white",
            textAlign: "center",
            fontSize: "12px",
            backgroundColor: "black",
            paddingTop: "5px",
            paddingBottom: "5px",
            height: "fit-content",
            borderRadius: "5px",
          }}
          className="btn-market"
          >
            CANCELLED
          </div>
           </div>
        );
        break;
      case "DONE":
        return (
          <div>
          <div
          style={{
            color: "white",
            textAlign: "center",
            fontSize: "12px",
            backgroundColor: "black",
            paddingTop: "5px",
            paddingBottom: "5px",
            height: "fit-content",
            borderRadius: "5px",
          }}
          className="btn-market"
          >
            EXECUTED
          </div>
          </div>
        );
        break;
    }
  };

  getTotal = (data, cell) => {
    var myPrice = Math.abs(cell.qty * cell.price)
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    if (cell.qty > 0) {
      if (
        this.props.new_market_data[cell.Scrip.srCode] &&
        parseFloat(this.props.new_market_data[cell.Scrip.srCode]) * cell.qty <
          myPrice
      ) {
        return <div className="tdNeg">{myPrice}</div>;
      } else if (
        parseFloat(cell.Scrip.market_price) * parseFloat(cell.qty) <
        myPrice
      ) {
        return <div className="tdNeg">{myPrice}</div>;
      } else {
        return <div className="tdPos">{myPrice}</div>;
      }
    } else {
      return <div style={{ textAlign: "center" }}>{myPrice}</div>;
    }
  };
  getReason = (data, cell) => {
    return data == "" ? (
      <Button type="button" onClick={() => this.showReasonModal(cell.id)}>
        Submit
      </Button>
    ) : (
      <span className="fa fa-check" />
    );
  };

  render() {
    return (
      <div    style={{
        overflow: "hidden",
        backgroundColor: "#ECECEC",
        borderRadius: "10px",
        padding: "5px",
      }}
      className="orderbookTable">
        <BootstrapTable hover keyField="id" data={this.props.orders} pagination  className="Orderbook-table">
          <TableHeaderColumn dataFormat={this.getDate} dataField="createdAt">
          <div>
              Date &<br />
              Time
            </div>
          </TableHeaderColumn>
          <TableHeaderColumn
            width="275px"
            dataFormat={this.getCompany}
            dataField="Scrip"
          >
             <div>Company</div>
          </TableHeaderColumn>
          <TableHeaderColumn
            dataFormat={(qty) =>
              parseInt(qty) > -1 ? qty : parseInt(qty) * -1
            }
            dataField="qty"
          >
         <div>Quantity</div>
          </TableHeaderColumn>
          <TableHeaderColumn
            dataFormat={(data) =>
              data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            dataField="price"
          >
            <div>Trade Price</div>
          </TableHeaderColumn>
          <TableHeaderColumn dataFormat={this.getAction} dataField="price">
          <div>Action</div>
          </TableHeaderColumn>
          <TableHeaderColumn dataFormat={this.getType} dataField="price">
          <div> Type of order</div>
          </TableHeaderColumn>
          <TableHeaderColumn dataFormat={this.getStatus} dataField="price">
            <div>
            <span>
              Status
              <i className="fa fa-info-circle ml-2" id={"oStatusTT"} />
              <UncontrolledTooltip placement="right" target={"oStatusTT"}>
                The status of an order shows its position on that time. It can
                range from executed to working to cancelled to rejected.
                Executed orders are those that are filled in, while working ones
                are generally limit orders that will only be executed once price
                entered matches with market price. Cancelled orders are those
                that could not be executed.
              </UncontrolledTooltip>
            </span>

            </div>
           
          </TableHeaderColumn>
          <TableHeaderColumn dataFormat={this.getTotal} dataField="price">
          <div>Total Value</div>
          </TableHeaderColumn>
        </BootstrapTable>
        <ReasonModal
          show={this.state.show_reason_modal}
          hideModal={this.hideReasonModal}
          handleReason={this.handleReason}
        />
      </div>
    );
  }
}

OrderBook.propTypes = {
  getOrders: PropTypes.func.isRequired,
  submitReason: PropTypes.func.isRequired,
  new_market_data: PropTypes.object.isRequired,
  orders: PropTypes.array.isRequired,
  portfolio: PropTypes.object.isRequired,
  cancelOrder: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  portfolio: state.scrips.scrips_portfolio,
  orders: state.orders.orders,
  new_market_data: state.socketData.new_market_data,
});

export default connect(
  mapStateToProps,
  { getOrders, submitReason, cancelOrder }
)(OrderBook);
