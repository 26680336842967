import React from "react";
import {
  Table,
  Input,
  FormGroup,
  Button,
  UncontrolledTooltip,
} from "reactstrap";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  getScrips,
  addWatch,
  getIndustries,
  getWatch,
} from "../../../actions/scripsActions";

import MarketRow from "./market_row";
import PortfolioHeader from "../header";
import BuyModal from "./buy_modal";
import AlertModal from "./alert_modal";
import CompanyModal from "./company_modal";
import { placeOrder, submitReason } from "../../../actions/orderActions";
import { trueDependencies } from "mathjs";

class Market extends React.Component {
  state = {
    alert_msg: "",
    show_buy_modal: false,
    show_alert_modal: false,
    company_id: 0,
    company: "",
    action: "",
    active_sl: 0,
    qty: 0,
    typ: 0,
    price: 0,
    sprice: 0,
    reasonBuy: "",
    company_str: "",
    company_code_str: "",
    company_modal_show: false,
    company_info: "",
    showMyPortfolio: false,
    showMyWatchlist: false,
    

  };
  tableOffset = -1;
  sectorArray =(scrips)=> {
    // return Array.from(new Set(scrips.map(e=>e.industry)));
      console.log(scrips);
      return  scrips.map(s => s.industry);
     }

  setIndustry = (e) => {
    const { segment, sort } = this.props.scrips_portfolio;
    const industry = e.target.value;
    this.props.getScrips(
      { segment: segment, industry: industry, sort: sort },
      true
    );
  };

  setSegment = (e) => {
    const { industry, sort } = this.props.scrips_portfolio;
    const segment = e.target.value;
    this.props.getScrips(
      { segment: segment, industry: industry, sort: sort },
      true
    );
    this.props.getWatch(segment.toUpperCase());
  };
  pqr = () => {
    this.props.getScrips(
      { segment: "Large Cap", industry: "ALL", sort: "MARKET CAP" },
      true
    );
  };
  setSort = (e) => {
    const { segment, industry } = this.props.scrips_portfolio;
    const sort = e.target.value;
    this.props.getScrips(
      { segment: segment, industry: industry, sort: sort },
      true
    );
  };

  setSector = (e) => {
    const { segment, sort } = this.props.scrips_portfolio;
    const sector = e.target.value;
    this.props.getScrips(
      { segment: segment, industry: sector, sort: sort },
      true
    );
  };

  active_buysl = (e) => {
    this.setState({ active_sl: e });
    console.log(e, "func1");
  };

  active_buyslprice = (e) => {
    this.setState({ sprice: e });
    console.log(e, "func1price");
  };

  // resizeTable() {
  //   let $ = window.$;

  //   var $table = $("table.market-tbl"),
  //     $bodyCells = $table.find("tbody tr:first td"),
  //     colWidth;
  //   if (this.tableOffset == -1) {
  //     this.tableOffset = $table.offset() ? $table.offset().top : -1;
  //   }
  //   // Get the tbody columns width array
  //   colWidth = $bodyCells
  //     .map(function() {
  //       return $(this).width();
  //     })
  //     .get();

  //   // Set the width of thead columns
  //   $table.find("thead tr th").each(function(i, v) {
  //     $(v).width(colWidth[i] + (i == 0 ? 1 : 0));
  //   });

  //   if ($(window).width() < 1200) {
  //     $("body").css("zoom", (0.0007 * $(window).width()).toFixed(2));
  //   } else {
  //     $("body").css("zoom", 0.9);
  //   }
  //   return null;
  // }
  componentWillUnmount = () => {
    //window.$(window).off("resize", this.resizeTable);
  };
  componentDidUpdate = () => {
    //this.resizeTable();
  };
  

  componentDidMount = () => {
    this.props.getScrips(
      { segment: "Large Cap", industry: "ALL", sort: "MARKET CAP" },
      true
    );
    //setInterval(async()=>{ this.pqr(); }, 240000);
    if (this.props.industries.length == 0) {
      this.props.getIndustries();
    }
   
    this.props.getWatch("LARGE CAP");
    window.scrollTo(0, 0);
    //this.resizeTable();
    // Adjust the width of thead cells when window resizes
    // window
    //   .$(window)
    //   .resize(this.resizeTable)
    //   .resize();
    let $ = window.$;
    let that = this;
   
    // $(document).on("mouseenter", ".btn-market-filter", function() {
    //   $(".tab-pane:eq(2) .portfolio-header").css(
    //     "width",
    //     $(".market-tbl").width() + 2 + "px"
    //   );
    //   $(".tab-pane:eq(2) .portfolio-header")
    //     .removeClass("animated bounceOutLeft bounceInLeft")
    //     .addClass("portfolio-header-sticky animated bounceInLeft");
    //   $(document).off("mouseleave", ".portfolio-header-sticky");
    //   setTimeout(function() {
    //     $(document).on("mouseleave", ".portfolio-header-sticky", function() {
    //       // $(".tab-pane:eq(2) .portfolio-header").css(
    //       //   "width",
    //       //   $(".market-tbl").width() + "px"
    //       // );
    //       $(".tab-pane:eq(2) .portfolio-header")
    //         .removeClass("animated bounceInLeft")
    //         .addClass("animated bounceOutLeft");
    //       setTimeout(function() {
    //         $(".tab-pane:eq(2) .portfolio-header")
    //           .removeClass("portfolio-header-sticky")
    //           .removeClass("animated bounceOutLeft bounceInLeft");
    //       }, 500);
    //     });
    //   }, 1000);
    // });

    // $(window).bind("scroll", function() {
    //   var offset = $(this).scrollTop();
    //   if (offset > 156 && !$(".market-tbl thead").hasClass("sticky-header")) {
    //     $(".market-tbl thead").addClass("sticky-header");
    //     $(".btn-market-filter")
    //       .removeClass("d-none")
    //       .show();
    //   } else if (
    //     offset <= 156 &&
    //     $(".market-tbl thead").hasClass("sticky-header")
    //   ) {
    //     $(".market-tbl thead").removeClass("sticky-header");
    //     $(".btn-market-filter").hide();
    //     $(".tab-pane:eq(2) .portfolio-header")
    //       .removeClass("portfolio-header-sticky")
    //       .removeClass("animated bounceOutLeft bounceInLeft");
    //   }
    // });
  };
  hadleCompanySearch = (e) => {
    this.setState({ company_str: e.target.value });
  };

  setReasonBuy = (reason) => {
    this.setState({ active_sl: 0 });
    this.setState({ sprice: "" });
    this.setState({ show_buy_modal: false });
    this.props.submitReason(this.props.recent_order_id, reason);
  };

  hideAlertModal = () => {
    this.setState({ show_alert_modal: false });
  };

  hideBuyModal = () => {
    this.setState({ active_sl: 0 });
    this.setState({ sprice: "" });
    this.setState({ show_buy_modal: false });
  };

  buy = (id, company, action, qty, type, price, market_update, up) => {
    qty = parseInt(qty);
    price = parseFloat(price);
    var total_price = qty * price;
    if (type == 2 && !price) {
      this.setState({
        show_alert_modal: true,
        alert_msg: "Please set Limit Price",
      });
    } else if (type == 3) {
      this.setState({
        show_alert_modal: true,
        alert_msg: "Please select Market or Limit trade",
      });
    } else if (
      Number.isNaN(qty) ||
      qty <= 0 ||
      qty == "" ||
      (type == 2 && (price == "" || price == 0))
    ) {
      this.setState({
        show_alert_modal: true,
        alert_msg: "Quantiy or Price not set",
      });
    } else if (action == "SELL" && ((up && qty > up.qty) || !up)) {
      this.setState({
        show_alert_modal: true,
        alert_msg: "Quantity can't be greater than your portfolio quantity",
      });
    } else if (
      action == "BUY" &&
      this.props.user.total_money - total_price <
        this.props.user.min_allowed_balance
    ) {
      this.setState({
        show_alert_modal: true,
        alert_msg:
          "You can't spent more than " +
          (
            this.props.user.total_money - this.props.user.min_allowed_balance
          ).toFixed(2),
      });
    } else if (
      action == "SELL" &&
      parseFloat(price) < parseFloat(market_update)
    ) {
      this.setState({
        show_alert_modal: true,
        alert_msg: "Sell limit price can't be less than current market price",
      });
    } else if (
      action == "BUY" &&
      parseFloat(price) > parseFloat(market_update)
    ) {
      this.setState({
        show_alert_modal: true,
        alert_msg: "Buy limit price can't be greater than current market price",
      });
    } else {
      this.setState({
        company_id: id,
        company: company,
        action: action,
        qty: qty,
        typ: type,
        price: price,
        show_buy_modal: true,
      });
    }
  };
  handleBuy = () => {
    this.props.placeOrder({
      scrip_id: this.state.company_id,
      typ: this.state.typ,
      action: this.state.action,
      qty: this.state.qty,
      price: this.state.price,
      reason: this.state.reasonBuy,
      active_sl: this.state.active_sl,
      sprice: this.state.sprice,
    });
  };

  onCompanyInfo = (sid) => {
    this.setState({ company_modal_show: true, company_info: sid });
  };

  getLatestPrice = (company) => {
    return this.props.new_market_data[company.srCode]
      ? parseFloat(this.props.new_market_data[company.srCode])
      : company.market_price;
  };

  getMarketRow = (e) => {
    const cmp = e.company.toLowerCase();
    const cmp_str = this.state.company_str.toLowerCase();
    const sc = e.shortCode.toLowerCase();
    // console.log(this.props.watchList,'---------------------314 -----index market')
    const is_watched =
      this.props.watchList.findIndex((p) => p.scrip_id == e.id) > -1;
    if (
      cmp_str == "" ||
      (cmp.indexOf(cmp_str) > -1 || sc.indexOf(cmp_str) > -1)
    ) {
      return (
        <MarketRow
          segment={this.props.scrips_portfolio.segment}
          is_watched={is_watched}
          addWatch={this.props.addWatch}
          market_update={this.getLatestPrice(e)}
          company_str={this.state.company_str}
          company_code_str={this.state.company_code_str}
          {...e}
          key={e.id}
          buy={this.buy}
          toggleTab={this.props.toggleTab}
          onCompanyInfo={() => this.onCompanyInfo(e.id)}
        />
      );
    } else {
      return null;
    }
  };

  onMyPortfolioChange = (option) => {
    this.setState({ showMyPortfolio: option });
  };
  onMyWatchlistChange = (option) => {
    this.setState({ showMyWatchlist: option });
  };

  render() {
    
    if (
      !this.props.portfolio.scrips ||
      this.props.user.total_money == undefined
    )
      return null;
    let scrips = [...this.props.portfolio.scrips];
    if (this.state.showMyPortfolio) {
      var d1 = [],
        d2 = [];
      // console.log(scrips, "............line 358...index/market");
      scrips.map((el) => {
        if (
          el.UserPortfolio &&
          el.UserPortfolio.qty &&
          el.UserPortfolio.qty > 0
        ) {
          d1.push(el);
        } else {
          d2.push(el);
        }
        scrips = [...d1, ...d2];
      });
    }

    // my watchlist logic

    if (this.state.showMyWatchlist) {
   
      var d1 = [...this.props.watchList];
          function sort(element) {
        if(d1.length <= 0){ return false;}
         for(let i=0 ; i < d1.length ;i++){
          if(element.id === d1[i].scrip_id){
            return true;
          }
         }
        return false;
      }
      // d2 = [];
      let d2 = scrips.filter((el) => {
      
        if (sort(el)) return true;
        else return false;
      });

      scrips = [...d2];
      // console.log(scrips);
    }
    
    console.log(this.props.industries,'----------------395-----------')

    return (
      <div
        style={{
          padding: "15px 35px 80px 35px",
          overflowY: "hidden",
        }}
      >
        <div className="myPortfolioSort">
          {this.props.user.planned == "combo" ||
          this.props.user.planned == "p999" ? (
            <p style={{ margin: "1rem" }}>
              <b>
                You can go over the financial markets and technical analysis
                parts of the resource section in order to gain a better
                understanding of how to pick stocks.We regularly send updates
                via WhatsApp so please save Nisant Mohta's team's number (+91
                9372298192) on your phone. In case the number isn't saved on
                your phone, our communication with you would be limited.
              </b>
            </p>
          ) : null}
          <PortfolioHeader
            segment={this.props.scrips_portfolio.segment}
            industry={this.props.scrips_portfolio.industry}
            sort={this.props.scrips_portfolio.sort}
            setSegment={this.setSegment}
            setSort={this.setSort}
            // sector = {this.sectorArray(this.props.scrips_portfolio.scrips)}
            sector = {this.sectorArray(this.props.industries) }
            setSector={this.setSector}
            setIndustry={this.setIndustry}
           
            src="market"
            onMyPortfolioChange={this.onMyPortfolioChange}
            onMyWatchlistChange={this.onMyWatchlistChange}
          />
        </div>
        <div className="marketTablePort"
          style={{
            backgroundColor: " #ECECEC",
            borderRadius: "15px",
            padding: "0.3rem",
          }}
        >
          <Table hover className="market-tbl">
            <thead className="tableHeadings">
              <tr>
                <th>
                  <div>
                    <i>My Watchlist</i>
                    <i className="fa fa-info-circle ml-2" id={"watchTT"} />
                    <UncontrolledTooltip placement="right" target={"watchTT"}>
                      There are times when you do not want to immediately trade
                      in a company and want to observe it. This feature helps
                      you do just that. It pins the company on home screen to
                      help you access it quickly.
                    </UncontrolledTooltip>
                  </div>
                </th>
                <th>
                  <div className="cmp-link-div text-center">
                    <i>Company</i>

                    <div
                      className="input-group"
                      style={{ width: "100px", margin: "5px auto" }}
                    >
                      <input
                        style={{ borderRight: "0px" }}
                        onChange={this.hadleCompanySearch}
                        className="form-control"
                      />
                      <div
                        className="input-group-append"
                        style={{ borderLeft: "0px" }}
                      >
                        <span
                          className="input-group-text fa fa-search"
                          style={{
                            background: "#fff",
                            borderRadius: "0px",
                            border: "1px solid #ced4da",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </th>
                {/* <th>
                <div className="text-center">
                  <div>
                    <i>Sector</i>
                    <i className="fa fa-info-circle ml-2" id={"sector2TT"} />
                    <UncontrolledTooltip placement="right" target={"sector2TT"}>
                      Finlatics helps you trade among all sectors in the
                      exchange. Use this to quicken your access to companies in
                      particular sectors.
                    </UncontrolledTooltip>
                  </div>
                  <Input
                    type="select"
                    value={this.props.scrips_portfolio.industry}
                    onChange={this.setIndustry}
                  >
                    <option>ALL</option>
                    {this.props.industries.map(e => (
                      <option key={e.industry}>{e.industry}</option>
                    ))}
                  </Input>
                </div>
              </th> */}
                <th>
                  <div>
                    <i>Quantity</i>
                  </div>
                </th>
                <th>
                  <div>
                    <i>My Average Cost</i>
                    <i className="fa fa-info-circle ml-2" id={"avgcTT"} />
                    <UncontrolledTooltip placement="right" target={"avgcTT"}>
                      This shows the average cost of all your purchased shares
                      in that company. For example, if you purchased 10 shares
                      of Tata Consultancy Services (TCS) at Rs. 1000, then 10
                      again at Rs. 998 and 10 again at Rs. 996, then your
                      average cost of the 30 shares will be Rs. 998. It follows
                      first in first out (FIFO) method; therefore, if you sell
                      10 shares out of the 30, then average cost will drop to
                      Rs. 997.
                    </UncontrolledTooltip>
                  </div>
                </th>
                <th>
                  <div>
                    <i>Market Price</i>
                    <i className="fa fa-info-circle ml-2" id={"mpTT"} />
                    <UncontrolledTooltip placement="right" target={"mpTT"}>
                      Current Market Price (CMP) is price at which the company
                      is currently traded in the market. Tread carefully here,
                      it changes every minute between 9:15 AM and 3:30 PM!
                    </UncontrolledTooltip>
                  </div>
                </th>
                <th>
                  <div>
                    {" "}
                    <i>
                      Type of Order{" "}
                      <i
                        className="fa fa-info-circle ml-2"
                        id={"typeOrderTT"}
                      />
                      <UncontrolledTooltip
                        placement="right"
                        target={"typeOrderTT"}
                      >
                        Whenever you’re placing a trade, an important criterion
                        is the type of order you want to place.
                      </UncontrolledTooltip>
                    </i>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {scrips.map((e) => {
                // console.log(e,'----------------560')
                if (e != undefined) {
                  return this.getMarketRow(e);
                } else return null;
              })}
            </tbody>
          </Table>
        </div>

        <BuyModal
          company={this.state.company}
          action={this.state.action}
          qty={this.state.qty}
          typ={this.state.typ}
          price={this.state.price}
          show={this.state.show_buy_modal}
          hideModal={this.hideBuyModal}
          handleBuy={this.handleBuy}
          setActive_buysl={this.active_buysl}
          setActive_buyslprice={this.active_buyslprice}
          handleReason={this.setReasonBuy}
        />
        <AlertModal
          show={this.state.show_alert_modal}
          hideModal={this.hideAlertModal}
        >
          <h5>{this.state.alert_msg}</h5>
        </AlertModal>
        <CompanyModal
          sid={this.state.company_info}
          show={this.state.company_modal_show}
          onOk={() => this.setState({ company_modal_show: false })}
        />
      </div>
    );
  }
}

Market.propTypes = {
  placeOrder: PropTypes.func.isRequired,
  new_market_data: PropTypes.object.isRequired,
  portfolio: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  getScrips: PropTypes.func.isRequired,
  getWatch: PropTypes.func.isRequired,
  addWatch: PropTypes.func.isRequired,
  recent_order_id: PropTypes.number.isRequired,
  submitReason: PropTypes.func.isRequired,
  scrips_portfolio: PropTypes.object.isRequired,
  industries: PropTypes.array.isRequired,
  watchList: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  portfolio: state.scrips.scrips_portfolio,
  new_market_data: state.socketData.new_market_data,
  industries: state.scrips.industries,
  user: state.user.info,
  scrips_portfolio: state.scrips.scrips_portfolio,
  recent_order_id: state.orders.recent_order_id,
  watchList: state.scrips.watchList,
});

export default connect(
  mapStateToProps,
  { getScrips, placeOrder, submitReason, addWatch, getIndustries, getWatch }
)(Market);
