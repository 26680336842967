import {
  RESOURCE_QUESTIONS,
  RESOURCE_VIDEOQUESTIONS,
  RESOURCE_VIDEOS,
  RESOURCE_FINLATICS_VIDEOS,
  RESOURCE_ALL_VIDEOS,
  RESOURCE_FETCH_SCORE,
  RESOURCE_VIDEOFETCH_SCORE
} from "../actions/types";

const initialState = {
  questions: {},
  vquestions:{},
  videos: {},
  allVideos: {},
  scores: {},
  vscores: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case RESOURCE_QUESTIONS:
      return {
        ...state,
        questions: action.payload
      };
      case RESOURCE_VIDEOQUESTIONS:
      return {
        ...state,
        vquestions: action.payload
      };
    case RESOURCE_FETCH_SCORE:
      return {
        ...state,
        scores: action.payload
      };
      case RESOURCE_VIDEOFETCH_SCORE:
      return {
        ...state,
        vscores: action.payload
      };
    case RESOURCE_VIDEOS:
      return {
        ...state,
        videos: action.payload
      };
    case RESOURCE_FINLATICS_VIDEOS:
      return {
        ...state,
        videos: action.payload
      };
    case RESOURCE_ALL_VIDEOS:
      return {
        ...state,
        allVideos: action.payload
      };

    default:
      return state;
  }
}
