import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getLeaderBoard } from "../../actions/leaderAction";

const LeaderHolder = styled.div`
  position: relative;
  top: 10px;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  a {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .fa-trophy {
    font-size: 24px;
  }

  .badge {
    margin-top: -8px;
  }

  .dropdown-menu {
    position: absolute;
    top: 100%; /* Ensure it drops below the icon */
    right: 0;
    left: auto; /* Prevent it from going off-screen */
    transform: none;
    z-index: 1050;

    @media (max-width: 576px) {
      top: auto;
      bottom: 0; /* Position it from the bottom if it's near the top edge */
      right: 10px; /* Add padding for mobile screens */
      left: 10px; /* Center the dropdown within the screen */
      transform: translateY(10px);
      max-height: 50vh; /* Limit height for better UX */
      overflow-y: auto; /* Enable scrolling if content overflows */
    }
  }
`;



class LeaderBoard extends React.Component {
  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
    this.state = {
      leaderOpen: false,
    };
  }

  toggle = () => {
    this.setState((prevState) => ({
      leaderOpen: !prevState.leaderOpen,
    }));
  };

  componentDidMount() {
    const { user, getLeaderBoard } = this.props;

    if (user && user.eventname) {
      getLeaderBoard(user.eventname); // Fetch leaderboard details based on eventname
    }
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user !== this.props.user) {
      const { user, getLeaderBoard } = this.props;

      if (user && user.eventname) {
        console.log(user, user.eventname, "eventname");
        getLeaderBoard(user.eventname); // Pass eventname to the action
      } else {
        console.log("no user found");
      }
    }
  }

  handleClickOutside = (event) => {
    if (
      this.wrapperRef.current &&
      this.wrapperRef.current.contains &&
      !this.wrapperRef.current.contains(event.target) &&
      this.state.leaderOpen
    ) {
      this.setState({
        leaderOpen: false,
      });
    }
  };

  render() {
    const { eventDetails } = this.props;
    const { leaderOpen } = this.state;

    let notiClass =
      "dropdown-menu dropdown-menu-right animated flipInX " +
      (leaderOpen ? "show" : "");

    return (
      <LeaderHolder ref={this.wrapperRef}>
        <a
          className="nav-link dropdown-toggle-nocaret"
          onClick={this.toggle}
          aria-expanded="true"
        >
          <em className="fa fa-trophy" /> LeaderBoard
          {eventDetails && eventDetails.length > 0 ? (
            <span className="badge badge-info">{eventDetails.length}</span>
          ) : null}
        </a>
        <div className={notiClass}>
          <div className="dropdown-item">
            <div className="list-group">
              {eventDetails && eventDetails.length > 0 ? (
                eventDetails.map((detail, index) => (
                  <div
                    key={index}
                    className="list-group-item list-group-item-action"
                  >
                    <div className="media">
                      <div className="align-self-start mr-2">
                        <em className="fa fa-user fa-2x text-primary" />
                      </div>
                      <div className="media-body">
                        {/* Ranking */}
                        <h6 className="text-muted m-0">Rank {index + 1}</h6>
                        {/* User Info */}
                        <p className="m-0">{detail.user}</p>
                      
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="list-group-item text-muted">
                  No leaderboard data available
                </div>
              )}

              <div className="list-group-item list-group-item-action">
                <span className="d-flex align-items-center">
                  {/* Optionally, add navigation to full leaderboard */}
                </span>
              </div>
            </div>
          </div>
        </div>
      </LeaderHolder>
    );
  }
}

LeaderBoard.propTypes = {
  user: PropTypes.object.isRequired,
  eventDetails: PropTypes.array.isRequired,
  getLeaderBoard: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user.info,
  eventDetails: state.leaderBoard.data.eventDetails || [],
});

export default connect(mapStateToProps, { getLeaderBoard })(LeaderBoard);
