import React from "react";
import { Nav, NavItem, NavLink, UncontrolledCollapse } from "reactstrap";

class Sidebar extends React.Component {
  state = {
    showComponent: false
  };

  onButtonClick = () => {
    this.setState({
      showComponent: true
    });
  };

  render() {
    return (
      <div className="resource">
        <Nav vertical className="sidebar">
          <NavItem>
            <NavLink
              onClick={() => {
                this.props.onSelect("m-1_intro");
              }}
              className={
                this.props.active == "m-1_intro" ||
                this.props.active == "m-1_quiz"
                  ? "active"
                  : ""
              }
              id="toggler1"
            >
              Module-1
            </NavLink>
            <UncontrolledCollapse
              toggler="#toggler1"
              className="collapse-items"
            >
              <Nav navbar>
                <NavItem>
                  <NavLink
                    onClick={() => {
                      this.props.onSelect("m-1_intro");
                    }}
                    className={
                      this.props.active == "m-1_intro" ? "activeSub" : ""
                    }
                  >
                    Introduction to Financial Markets
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    onClick={() => {
                      this.props.onSelect("m-1_quiz");

                      this.props.onToggle(true);
                    }}
                    className={
                      this.props.active == "m-1_quiz" ? "activeSub" : ""
                    }
                  >
                    Quiz
                  </NavLink>
                </NavItem>
              </Nav>
            </UncontrolledCollapse>
          </NavItem>

          <NavItem>
            <NavLink
              id="toggler2"
              onClick={() => {
                this.props.onSelect("m-2_indices");
              }}
              className={
                this.props.active == "m-2_indices" ||
                this.props.active == "m-2_quiz" ||
                this.props.active == "m-2_markets"
                  ? "active"
                  : ""
              }
            >
              Module-2
            </NavLink>
            <UncontrolledCollapse
              toggler="#toggler2"
              className="collapse-items"
            >
              <Nav navbar>
                <NavItem>
                  <NavLink
                    onClick={() => {
                      this.props.onSelect("m-2_indices");
                    }}
                    className={
                      this.props.active == "m-2_indices" ? "activeSub" : ""
                    }
                  >
                    Indices
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    onClick={() => {
                      this.props.onSelect("m-2_markets");
                    }}
                    className={
                      this.props.active == "m-2_markets" ? "activeSub" : ""
                    }
                  >
                    Structure of Securities Market
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    onClick={() => {
                      this.props.onSelect("m-2_quiz");
                      this.props.onToggle(true);
                    }}
                    className={
                      this.props.active == "m-2_quiz" ? "activeSub" : ""
                    }
                  >
                    Quiz
                  </NavLink>
                </NavItem>
              </Nav>
            </UncontrolledCollapse>
          </NavItem>
          <NavItem>
            <NavLink
              id="toggler3"
              onClick={() => {
                this.props.onSelect("m-3_termo");
              }}
              className={
                this.props.active == "m-3_termo" ||
                this.props.active == "m-3_quiz"
                  ? "active"
                  : ""
              }
            >
              Module-3
            </NavLink>
            <UncontrolledCollapse
              toggler="#toggler3"
              className="collapse-items"
            >
              <Nav navbar>
                <NavItem>
                  <NavLink
                    onClick={() => {
                      this.props.onSelect("m-3_termo");
                    }}
                    className={
                      this.props.active == "m-3_termo" ? "activeSub" : ""
                    }
                  >
                    Terminologies in Equity Market
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    onClick={() => {
                      this.props.onSelect("m-3_quiz");
                      this.props.onToggle(true);
                    }}
                    className={
                      this.props.active == "m-3_quiz" ? "activeSub" : ""
                    }
                  >
                    Quiz
                  </NavLink>
                </NavItem>
              </Nav>
            </UncontrolledCollapse>
          </NavItem>
          <NavItem>
            <NavLink
              id="toggler4"
              onClick={() => {
                this.props.onSelect("m-4_val");
              }}
              className={
                this.props.active == "m-4_val" ||
                this.props.active == "m-4_leq" ||
                this.props.active == "m-4_eff" ||
                this.props.active == "m-4_lev" ||
                this.props.active == "m-4_quiz"
                  ? "active"
                  : ""
              }
            >
              Module-4
            </NavLink>
            <UncontrolledCollapse
              toggler="#toggler4"
              className="collapse-items"
            >
              <Nav navbar>
                <NavItem>
                  <NavLink
                    onClick={() => {
                      this.props.onSelect("m-4_val");
                    }}
                    className={
                      this.props.active == "m-4_val" ? "activeSub" : ""
                    }
                  >
                    Valuation Ratios
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    onClick={() => {
                      this.props.onSelect("m-4_leq");
                    }}
                    className={
                      this.props.active == "m-4_leq" ? "activeSub" : ""
                    }
                  >
                    Liquidity Ratios
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    onClick={() => {
                      this.props.onSelect("m-4_eff");
                    }}
                    className={
                      this.props.active == "m-4_eff" ? "activeSub" : ""
                    }
                  >
                    Efficiency Ratios
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    onClick={() => {
                      this.props.onSelect("m-4_lev");
                    }}
                    className={
                      this.props.active == "m-4_lev" ? "activeSub" : ""
                    }
                  >
                    Leverage Ratios
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    onClick={() => {
                      this.props.onSelect("m-4_quiz");
                      this.props.onToggle(true);
                    }}
                    className={
                      this.props.active == "m-4_quiz" ? "activeSub" : ""
                    }
                  >
                    Quiz
                  </NavLink>
                </NavItem>
              </Nav>
            </UncontrolledCollapse>
          </NavItem>
          <NavItem>
            <NavLink
              id="toggler5"
              onClick={() => {
                this.props.onSelect("m-5_fundamental");
              }}
              className={
                this.props.active == "m-5_fundamental" ||
                this.props.active == "m-5_tech" ||
                this.props.active == "m-5_quiz"
                  ? "active"
                  : ""
              }
            >
              Module-5
            </NavLink>
            <UncontrolledCollapse
              toggler="#toggler5"
              className="collapse-items"
            >
              <Nav navbar>
                <NavItem>
                  <NavLink
                    onClick={() => {
                      this.props.onSelect("m-5_fundamental");
                    }}
                    className={
                      this.props.active == "m-5_fundamental" ? "activeSub" : ""
                    }
                  >
                    Fundamental Analysis
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    onClick={() => {
                      this.props.onSelect("m-5_tech");
                    }}
                    className={
                      this.props.active == "m-5_tech" ? "activeSub" : ""
                    }
                  >
                    Technical Analysis
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    onClick={() => {
                      this.props.onSelect("m-5_quiz");
                      this.props.onToggle(true);
                    }}
                    className={
                      this.props.active == "m-5_quiz" ? "activeSub" : ""
                    }
                  >
                    Quiz
                  </NavLink>
                </NavItem>
              </Nav>
            </UncontrolledCollapse>
          </NavItem>
          <NavItem>
            <NavLink
              onClick={() => {
                this.props.onSelect("videoLectures");
              }}
              className={this.props.active == "videoLectures" ? "active" : ""}
            >
              Video Lab
            </NavLink>
          </NavItem>
        </Nav>
      </div>
    );
  }
}

export default Sidebar;
