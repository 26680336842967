import axios from "axios";

import {
  ERRORS_GET,
  HR_QUESTIONS_GET,
  HR_RESPONSE_SAVE,
  HR_ANSWER_SET,
  HR_ADD_BLOCK,
  HR_REMOVE_BLOCK
} from "./types";

import { showLoader } from "./loaderActions";

export const getHrQuestions = () => dispatch => {
  dispatch(showLoader(true));
  axios
    .get("/api/hr/questions")
    .then(res => {
      dispatch(showLoader(false));
      dispatch({
        type: HR_QUESTIONS_GET,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: ERRORS_GET
      })
    );
};

export const removeBlock = (block_id, index) => dispatch => {
  dispatch({
    type: HR_REMOVE_BLOCK,
    payload: {
      block_id: block_id,
      index: index
    }
  });
};
export const addBlock = block_id => dispatch => {
  dispatch({
    type: HR_ADD_BLOCK,
    payload: {
      block_id: block_id
    }
  });
};
export const setAnswer = (qid, answer, block_id, reapeat_index) => dispatch => {
  dispatch({
    type: HR_ANSWER_SET,
    payload: {
      qid: qid,
      answer: answer,
      block_id: block_id,
      reapeat_index: reapeat_index
    }
  });
};

export const saveAnswer = answer => dispatch => {
  // const data = questions.map((q, index) => ({
  //   qid: q.id,
  //   value: answer[index],
  //   type: q.type
  // }));
  axios
    .post("/api/hr/response", {
      answer: answer
    })
    .then(res => {})
    .catch(err =>
      dispatch({
        type: ERRORS_GET
      })
    );
};
