import axios from 'axios';

import { ERRORS_GET, AUTHENTICATION_TOKEN_VERIFY } from './types';

import { showLoader } from './loaderActions';
import { getCurrentUserDetails } from './userActions';
import Cookies from 'js-cookie';

export const verifyToken = token => dispatch => {
	axios
		.post('/api/user/verify-token', {
			token: token,
		})
		.then(res => {
			let clientHash = '';
			[...document.getElementsByTagName('script')].forEach(s => {
				if (s.src) {
					let f = /main\.(.*)\.chunk\.js/g.exec(s.src);
					if (f && f.length > 1) {
						clientHash = f[1];
					}
				}
			});
			if (res.data.isValid) {
				if (clientHash == '' || res.data.clientHash == '' || clientHash == res.data.clientHash) {
					dispatch(setVerificationStatus(res.data.isValid));
					dispatch(showLoader(false));
					dispatch(getCurrentUserDetails());
				} else {
					dispatch(logout());
				}
			} else {
				dispatch(logout());
			}
		})
		.catch(err => dispatch(logout()));
};

export const logout = () => dispatch => {
	Cookies.remove('token');
	localStorage.removeItem('token');
	navigator.serviceWorker.getRegistrations().then(function(registrations) {
		for (let registration of registrations) {
			registration.unregister();
		}
		window.location = window.BASE_URL;
	});
};
export const paymentRenewal = (email, activationKey, renewal_status) => dispatch => {
	navigator.serviceWorker.getRegistrations().then(function(registrations) {
		for (let registration of registrations) {
			registration.unregister();
		}
		window.location =
			window.BASE_URL + `/renewal_activate?email=${email}&hash=${activationKey}&status=${renewal_status}`;
	});
};
const setVerificationStatus = decoded => {
	return {
		type: AUTHENTICATION_TOKEN_VERIFY,
		payload: decoded,
	};
};
