import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import DragProfile1 from "./DragProfile1";
import DragProfile2 from "./DragProfile2";
import swal from "sweetalert";
import {
  getSkills,
  getDropData,
  getBehaviour
} from "../../../actions/profileDetailsActions";

class DragProfile extends React.Component {
  state = {
    skillArray: false,
    proceed1: false,
    proceed2: false,
    arr1: [],
    arr2: []
  };

  getArrays = async () => {
    if (this.state.proceed1 && this.state.proceed2) {
      this.setState({ skillArray: true }, async () => {
        await this.props.getDropData(this.state.arr1, this.state.arr2);
        await this.props.nextScreen();
      });
    } else {
      swal({
        title: "Please fill out all feilds",
        icon: "warning",
        dangerMode: true
      });
    }
  };

  finalDataS = data => {
    var count = 0;

    data.map(e => {
      if (e !== "") {
        count = count + 1;
        return count;
      }
    });
    if (count == this.props.skills.length) {
      this.setState({ proceed1: true });
      this.setState({ arr1: [...data] });
    }
  };
  finalDataB = data => {
    var behaviourCount = 0;

    data.map(e => {
      if (e !== "") {
        behaviourCount = behaviourCount + 1;
        return behaviourCount;
      }
    });
    if (behaviourCount == this.props.behaviour.length) {
      this.setState({ proceed2: true });
      this.setState({ arr2: [...data] });
    }
  };

  componentDidMount() {
    this.props.getSkills();
    this.props.getBehaviour();
  }
  render() {
    if (this.props.skills.length < 1 || this.props.behaviour.length < 1)
      return null;

    return (
      <div className="container">
        <DragProfile1 skills={this.props.skills} finalData={this.finalDataS} />
        <DragProfile2
          behaviour={this.props.behaviour}
          finalData={this.finalDataB}
        />
        <div className="text-center profile mt-5 mb-5">
          <button className="btn btn-3" onClick={this.getArrays}>
            Save & Proceed
          </button>
        </div>
      </div>
    );
  }
}

DragProfile.propTypes = {
  getSkills: PropTypes.func.isRequired,
  getDropData: PropTypes.func.isRequired,
  getBehaviour: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  skills: state.profile.skills,
  behaviour: state.profile.behaviour
});

export default connect(
  mapStateToProps,
  { getSkills, getDropData, getBehaviour }
)(DragProfile);
