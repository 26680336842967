import React from "react";
import {
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import { Link } from "react-router-dom";

export default class MarketIndices extends React.Component {
  constructor(props) {
    super(props);
  }

  getIndexTR = index => {
    const indexLabel = this.props.indicesLabels.find(
      el => el.code == index.code
    );

    if (!indexLabel) {
      return null;
    }

    let color = "#008700";
    let str = "▲";
    if (index.price - index.previous_eod < 0) {
      color = "red";
      str = "▼";
    }
    let mp = parseFloat(
      ((index.price - index.previous_eod) / index.previous_eod) * 100
    ).toFixed(2);
    let dif = (index.price - index.previous_eod).toFixed(2);
    str = str + dif + `(${mp}%)`;

    return (
      <tr style={{ background: color, color: "#fff" }} key={index.code}>
        <td>{indexLabel.short}</td>
        <td>{index.price}</td>
        <td>{str}</td>
      </tr>
    );
  };

  render() {
    return (
      <div style={{ padding: "20px" }}>
        <div
          className="darkbg font-weight-bold"
          style={{
            textTransform: "uppercase"
          }}
        >
          Market Indices
        </div>
        <table
          className="table table-hover table-bordered"
          style={{ width: "100%" }}
        >
          <tbody>
            {Object.keys(this.props.spIndices).map(key =>
              this.getIndexTR(this.props.spIndices[key])
            )}
          </tbody>
        </table>
      </div>
    );
  }
}
