import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Education from "./edu";
import Employee from "./emp";
import UploadResume from "./resume";

import {
  getEduDetails,
  userDetails,
  resumeUpload,
  searchClg
} from "../../../actions/profileDetailsActions";

class Profile extends React.Component {
  state = {
    collapse: "",
    submit: false,
    education: [],
    emp: [],
    file: {},
    getEdu: false
  };
  eduData = (data, action) => {
    this.setState({
      education: [...data]
    });
    if (this.state.education.length > 0) {
      this.setState({
        getEdu: action
      });
    }
  };

  searchCollege = clg => {
    this.props.searchClg(clg);
  };
  empData = (data, action) => {
    this.setState({
      emp: [...data]
    });
    this.setState({
      getEmp: action
    });
  };

  newCollapse = c => {
    this.setState({
      collapse: c
    });
  };
  resume = (data, action) => {
    this.setState({
      file: data
    });
    this.setState({
      getResume: action
    });
  };

  componentDidMount() {
    this.props.getEduDetails();
  }

  render() {
    if (!this.props.educationDetails) {
			return <div className="m-4">Please wait...</div>;
		}
    return (
      <div className="container">
        {this.state.getEdu ? (
          ""
        ) : (
          <div className="mb-4 mt-4">
            <h5 style={{ color: "#6f6f70" }}>
              KINDLY FILL YOUR DETAILS TO PROCEED
            </h5>
          </div>
        )}

        <Education
          educationDetails={this.props.educationDetails}
          university={this.props.university}
          colleges={this.props.colleges.map(o => o.College_Name)}
          active={this.state.collapse}
          collapseValue={this.newCollapse}
          eduData={this.eduData}
          searchClg={this.searchCollege}
          clgList={this.props.clgList}
        />
        <Employee
          active={this.state.collapse}
          collapseValue={this.newCollapse}
          empData={this.empData}
        />
        <UploadResume
          active={this.state.collapse}
          collapseValue={this.newCollapse}
          resumeUpload={this.resume}
        />
        {this.state.getEdu ? (
          <div className="text-center profile mt-5">
            <button
              className="btn btn-3"
              onClick={() => {
                this.props.nextScreen();
                this.setState({ submit: true });
                this.props.userDetails(this.state.education, this.state.emp);
                this.props.resumeUpload(this.state.file);
              }}
            >
              Save & Proceed
            </button>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

Profile.propTypes = {
  getEduDetails: PropTypes.func.isRequired,

  userDetails: PropTypes.func.isRequired,
  resumeUpload: PropTypes.func.isRequired,
  searchClg: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  educationDetails: state.profile.education,
  university: state.profile.university,
  colleges: state.profile.colleges,
  clgList: state.profile.clgList
});

export default connect(
  mapStateToProps,
  { getEduDetails, userDetails, resumeUpload, searchClg }
)(Profile);
