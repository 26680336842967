import React from "react";
import {
  Card,
  CardText,
  CardBody,
  CardTitle,
  Collapse,
  Row,
  Col
} from "reactstrap";
class Question1 extends React.Component {
  state = {
    is_open: false
  };
  toggle = () => {
    this.setState({ is_open: !this.state.is_open });
  };
  render() {
    const data = this.props.data;
    return (
      <div  className="container" onClick={this.toggle} style={{ cursor: "pointer" }}>
        <div style={{ backgroundColor: "#e4e4e4"}} className="p-2 mt-2">
          <i
            className={
              "fa fontsize-5 d-inline-block  align-middle " +
              (this.state.is_open ? "fa-minus" : "fa-plus")
            }
          />
          <span className="d-inline-block pl-2 pt-1">
            <h5>What is portfolio management and how should I go about it?</h5>
          </span>
        </div>

        <Collapse isOpen={this.state.is_open}>
          <Card >
            <CardBody onClick={this.toggle}>
              <CardTitle />
              <CardText className="">
             <p> Portfolio management is the first task that you have to entail in the next 2 months. 
                 You have 2 lakhs as virtual money in your account and your task is to invest this proportionately to maximise returns over the next two months. There are three segments in the market: </p>
   <p> 1. Large Cap - This is the segment that contains the top 100 most valuable companies in India. Value of a company is determined by its market capitalisation. Market Capitalisation is the product of a company on basis of its current market price and number of outstanding shares in the market. A higher market capitalisation typically means that the company has a higher valuation.</p>
   <p>  2. Mid Cap - This is the segment that contains the companies, ranked 100 - 250 in India; in terms of value; that is in terms of market capitalisation. </p>
   <p> 3. Small Cap - This is the segment that contains the companies, ranked 250 - 500 in India; in terms of value, that is in terms of market capitalisation.</p>

   <p>The portfolio that you create at FMEP shall be covering the BSE 500 index, i.e. the top 500 companies in India.</p>

   <p>A general thumb rule while looking at companies in different segments:</p>
<table class="table table-bordered table-striped">
    <thead>
      <tr>
        <th style={{textAlign : "left"}}>Segment</th>
        <th style={{textAlign : "left"}}>Return Potential</th>
        <th style={{textAlign : "left"}}>Risk</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Large Cap</td>
        <td>Low</td>
        <td>Low</td>
      </tr>
      <tr>
        <td>Mid Cap</td>
        <td>Medium</td>
        <td>Medium</td>
      </tr>
      <tr>
        <td>Small Cap</td>
        <td>High</td>
        <td>High</td>
      </tr>
    </tbody>
  </table>


  <p>Above seems like the first challenge that you have to face and encounter; you have to create a ‘balanced’ portfolio, one that has a balanced risk and return counter.</p>
  <p> For this, it is important that you trade and invest in quality stocks across all segments.</p>

              </CardText>
            </CardBody>
          </Card>
        </Collapse>
      </div>
    );
  }
}
export default Question1;
