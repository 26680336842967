import React from "react";
import {
  Card,
  CardText,
  CardBody,
  CardTitle,
  Collapse,
  Row,
  Col
} from "reactstrap";
class Question8 extends React.Component {
  state = {
    is_open: false
  };
  toggle = () => {
    this.setState({ is_open: !this.state.is_open });
  };
  render() {
    const data = this.props.data;
    return (
      <div onClick={this.toggle} style={{ cursor: "pointer" }}>
        <div style={{ backgroundColor: "#e4e4e4"}} className="p-2 mt-2">
          <i
            className={
              "fa fontsize-5 d-inline-block  align-middle " +
              (this.state.is_open ? "fa-minus" : "fa-plus")
            }
          />
          <span className="d-inline-block pl-2 pt-1">
            <h5>What does My Scores in research section signify and how is it calculated?</h5>
          </span>
        </div>

        <Collapse isOpen={this.state.is_open}>
          <Card >
            <CardBody onClick={this.toggle}>
              <CardTitle />
              <CardText>
                  <p>This particular section shows the scores that peers have given you based on various research activities done by you.</p> 
                  <p>This is your research grade and will be present in your Financial Markets Performance Report.</p> 
                  <p>A key way to have a good research score is to rate other’s research in the ‘Rate Research’ section and not slack off from it.</p>
                  <p> There are additional points that you get from rating other’s research, which will reflect in your performance report.</p>
                           </CardText>
            </CardBody>
          </Card>
        </Collapse>
      </div>
    );
  }
}
export default Question8;
