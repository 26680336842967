import { LOADER_SHOW } from "../actions/types";

const initialState = { show: true };

export default function(state = initialState, action) {
  switch (action.type) {
    case LOADER_SHOW:
      return state.show == action.payload
        ? state
        : { show: action.payload };
    default:
      return state;
  }
}
